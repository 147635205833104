module.exports = {
    "account": {
        "activateAccount": {
            "post": {
                "descOne": "你的初始充值已經收到，並且轉至新的賬戶，新賬戶已經激活！",
                "descTwo": "一部分充值被保留用於涵蓋<a href='https://docs.near.org/docs/concepts/account' rel='noopener noreferrer' target='_blank'>賬戶的存儲成本</a>。",
                "title": "你的賬戶已經激活。"
            },
            "pre": {
                "desc": "開始使用 NEAR 錢包前，請先充值<b>至少 ${amount}</b> 至你的賬戶（或稱地址）。",
                "title": "即將完成註冊！請為賬戶進行首次充值。"
            }
        },
        "available": {
            "error": "無法找到該用戶。",
            "errorSameAccount": "無法向你自己發送。",
            "implicitAccount": "轉賬不可逆，請確保賬戶 ID 準確",
            "implicitAccountModal": "請再確認賬戶 ID，如果賬戶輸入錯誤，你的資產將永久無法找回。",
            "success": "該用戶已找到。"
        },
        "create": {
            "checkingAvailablity": {
                "check": "正在檢查",
                "create": "正在檢查可用性"
            },
            "errorAccountNotExist": "創建賬戶出錯，請重試！",
            "errorInvalidAccountIdLength": "用戶名的長度需為 2 至 64 個字符。"
        },
        "createImplicit": {
            "post": {
                "descOne": "你的初始充值已經收到，一旦認領，你的充值會轉移到新認領的賬戶。",
                "descTwo": "重要：你的單次充值地址將不再有效。請確認用新的賬戶 ID 替換舊地址，以防資產丟失。",
                "modal": {
                    "descOne": "點擊確認，意味著你已經知曉單次充值地址將會無效，任何充值到舊地址的資產會丟失。",
                    "descTwo": "請記得在各個交易所或錢包內用新的賬戶 ID 替換舊地址。",
                    "title": "更新你的地址"
                },
                "title": "認領你的賬戶"
            },
            "pre": {
                "addressHeader": "單次充值地址",
                "descOne": "為初始化賬戶，請向臨時充值地址轉賬至少 <b>${amount}</b>。",
                "descTwo": "充值完成後，請返回本頁繼續完成賬戶創建。",
                "moonPay": {
                    "buyWith": "打開",
                    "desc": "通過 MoonPay 支持的支付方式進行你的首次充值。",
                    "title": "通過 MoonPay 充值"
                },
                "title": "充值至賬戶",
                "utorg": {
                    "buyWith": "打開",
                    "desc": "通過 Utorg 支持的支付方式進行你的首次充值。",
                    "title": "通過 Utorg 充值"
                },
                "whereToBuy": {
                    "button": "我可以在哪裡購買 NEAR？",
                    "desc": "NEAR 通證可以在以下交易所購買",
                    "title": "購買 NEAR 通證"
                }
            },
            "success": {
                "button": "前往賬戶頁",
                "descOne": "恭喜 <b>${accountId}</b>！你的賬戶已成功註冊。",
                "descThree": "請在交易所或其他設備上更新你的地址。",
                "descTwo": "你現在可以使用賬戶 ID 作為你的 NEAR 主要地址。請在交易所或其他設備上更新你的地址。",
                "modal": {
                    "desc": "恭喜你！你的賬戶已經成功註冊。",
                    "title": "歡迎使用 NEAR 錢包。"
                },
                "title": "歡迎使用 NEAR"
            }
        },
        "createImplicitAccount": {
            "createCustomNameModal": {
                "desc": "不像默認的賬戶地址，自定義 ID 可以是任選，並且容易記住並分享的 ID。",
                "title": "添加自定義賬戶 ID"
            },
            "customAddress": "自定義 ID 可以是任選，並且容易記住並分享的 ID。",
            "customAmount": "自定義",
            "exchange": "交易所",
            "orAskFriend": "或詢問朋友轉賬。",
            "orSendNear": "...或發送至少 ${amount} NEAR 至你的錢包地址。",
            "sendFrom": "發送來自",
            "title": "即將完成！完成前，可以先通過 MoonPay 購買一些 NEAR...",
            "titleAlt": "即將完成！完成前，請發送至少 ${amount} NEAR 至你的錢包地址。"
        },
        "fundedStatus": {
            "active": "活躍",
            "awaitingDeposit": "等待充值",
            "initialDeposit": "初始充值",
            "minDeposit": "最低充值",
            "nearName": "賬戶 ID",
            "ready": "可供領取",
            "singleUse": "單次充值地址",
            "status": "賬戶狀態"
        },
        "login": {
            "details": {
                "warning": "這將可以訪問你的所有餘額，請謹慎操作。"
            },
            "incorrectContractId": {
                "error": "合約 ${contractId} 不存在。這可能是你正在使用的應用的問題，或者可能是合約已經被刪除。你將重新回到應用。",
                "errorTitle": "無效的合約 ID"
            }
        },
        "nameDoesntMatch": "賬戶名稱不匹配",
        "recoverAccount": {
            "error": "恢復賬戶失敗。",
            "errorInvalidSeedPhrase": "助記詞無效",
            "success": "成功通過助記詞恢復。<br/><b>${numberOfAccounts} 個賬戶</b>成功恢復。<br/>已切換至最後賬戶。",
            "zeroBalance": {
                "success": {
                    "desc": "該賬戶還未充值。請購買 $NEAR 並充值到該賬戶。",
                    "ledger": "該賬戶成功通過 Ledger 密鑰導入：",
                    "phrase": "該賬戶成功通過助記詞導入：",
                    "title": "賬戶已導入"
                }
            }
        },
        "requestCode": {
            "error": "發送短信失敗。",
            "success": "短信已成功發送。"
        },
        "sendMoney": {
            "error": "請檢查你的設備已經連接並打開了 NEAR 應用。"
        },
        "sendNewRecoveryLink": {
            "error": "賬戶恢復鏈接發送失敗，請重試。",
            "success": "賬戶恢復鏈接已經發送！"
        },
        "verifySeedPhrase": {
            "error": "你輸入了錯誤的助記詞"
        }
    },
    "accountSelector": {
        "noAccountDesc": "目前你沒有登錄其他賬戶。",
        "signInButton": "登錄不同的賬戶"
    },
    "actions": {
        "AddKey": {
            "forContract": "已為合約添加訪問密鑰：${permissionReceiverId}",
            "forReceiver": "已為 ${receiverId} 新增密鑰"
        },
        "CreateAccount": "新賬戶已創建：${receiverId}",
        "DeleteAccount": "賬戶已刪除：${receiverId}",
        "DeleteKey": "密鑰已刪除",
        "DeployContract": "合約已經部署：${receiverId}",
        "FunctionCall": "函數已調用：合約 ${receiverId} 的 ${methodName}",
        "Stake": "已質押 ${stake}",
        "Transfer": {
            "received": "收到來自 ${signerId} 的轉賬 ${deposit} ",
            "transferred": "已轉賬 ${deposit} 至 ${receiverId}"
        }
    },
    "actionsSign": {
        "addKey": "正在新增訪問密鑰",
        "createAccount": "正在創建賬戶：${receiverId}",
        "deleteAccount": "正在刪除賬戶：${receiverId}",
        "deleteKey": "正在刪除訪問密鑰",
        "deployContract": "正在部署合約：${receiverId}",
        "functionCall": "正在調用函數：${methodName}",
        "stake": "正在質押：${stake} &#9411; ${publicKey}...",
        "transfer": "正在轉賬：${deposit} &#9411; 至 ${receiverId}"
    },
    "addNode": {
        "desc": "在此添加前，請先通過命令行配置你的節點。",
        "ipAddressInput": {
            "placeholder": "例如：0.0.0.0",
            "title": "請輸入你的節點 IP 地址"
        },
        "nicknameInput": {
            "placeholder": "例如：AWS Instance",
            "title": "請設置一個昵稱（可選）"
        },
        "pageText": "通過命令行配置你的節點，並在這裏添加。",
        "pageTitle": "添加節點"
    },
    "amount": "數量",
    "arguments": "參數",
    "authorizedApps": {
        "dashboardNoApps": "你還未授權任何應用",
        "feeAllowance": "費用配額",
        "ledger": "Ledger",
        "pageTitle": "已授權應用",
        "publicKey": "公鑰"
    },
    "availableBalanceInfo": "你可以使用的 NEAR 餘額，不包括鎖定或質押的資金。<a href='/profile'>查看資產組合詳情 →</a>",
    "availableBalanceProfile": "「可用餘額」是指你當前賬號內可消耗 NEAR 通證，可用餘額少於你的總共餘額。",
    "back": "返回",
    "balance": {
        "balance": "總餘額",
        "balanceLoading": "餘額加載中"
    },
    "balanceBreakdown": {
        "available": "可用餘額",
        "reserved": "費用預留"
    },
    "batchExportAccounts": {
        "confirmExportModal": {
            "accountToExport": "賬戶備份",
            "title": "批准賬戶備份",
            "transactionDetails": "+ 交易細節"
        },
        "exportScreen": {
            "desc": "開始備份，並在提示時確認賬戶。",
            "weFound": "找到 ${noOfAccounts} 個賬戶可備份至你的設備。"
        },
        "successScreen": {
            "title": "${noOfAccounts} 個賬戶成功備份至你的設備"
        }
    },
    "batchImportAccounts": {
        "confirmImportModal": {
            "accountToImport": "賬戶導入",
            "desc": "為確保賬戶安全，請確認 URL 是否正確：",
            "title": "批准賬戶導入自",
            "transactionDetails": "+ 交易細節"
        },
        "confirmUrlModal": {
            "desc": "為確保賬戶安全，請確認 URL 是否正確：",
            "title": "確認你的 URL"
        },
        "importScreen": {
            "desc": "開始導入，並在提示時確認賬戶。",
            "title": "準備導入 ${noOfAccounts} 個賬戶，來自</br>"
        },
        "successScreen": {
            "desc": "選擇一個賬戶，並跳轉至概覽頁。",
            "title": "${noOfAccounts} 個賬戶成功導入自"
        }
    },
    "button": {
        "acceptAndContinue": "接受並繼續",
        "addACustomAddress": "自定義賬戶 ID",
        "addFunds": "充值",
        "addNode": "添加節點",
        "allow": "允許",
        "approve": "批准",
        "authorize": "授權",
        "authorizing": "正在授權",
        "backToSwap": "返回兌換",
        "beginExport": "開始備份",
        "beginImport": "開始導入",
        "browseApps": "瀏覽應用",
        "buy": "購買",
        "cancel": "取消",
        "cancelOperation": "取消操作",
        "cancelTransfer": "取消轉賬",
        "claimAccount": "認領賬戶",
        "claimMyAccount": "認領我的賬戶",
        "close": "關閉",
        "Close": "關閉",
        "confirm": "確認",
        "confirmAndSend": "確認並發送",
        "connect": "連接",
        "connecting": "正在連接",
        "connectLedger": "綁定 Ledger",
        "continue": "繼續",
        "continueSetup": "繼續設置",
        "continueToMyAccount": "前往我的賬戶",
        "copy": "複製",
        "copyImplicitAddress": "複製賬戶 ID",
        "copyPhrase": "複製助記詞",
        "copyUrl": "複製鏈接",
        "createAccount": "創建賬戶",
        "createAccountCapital": "創建賬戶",
        "createNewAccount": "創建新的賬戶",
        "creatingAccount": "創建賬戶",
        "deauthorize": "取消授權",
        "deAuthorizing": "正在取消授權",
        "deny": "拒絕",
        "disable": "禁用",
        "disabling": "正在禁用",
        "dismiss": "關閉",
        "edit": "編輯",
        "enable": "啟用",
        "enabling": "正在開啟",
        "findMyAccount": "查找我的賬戶",
        "finish": "完成",
        "fundWith": "充值",
        "generateNew": "重新生成",
        "getStarted": "開始",
        "goBack": "返回",
        "gotIt": "知曉",
        "goToDashboard": "訪問概覽頁",
        "importAccount": "導入已有賬戶",
        "importExistingAccount": "導入已有賬戶",
        "learnMore": "了解更多",
        "learnMoreAboutNear": "了解更多關於 NEAR",
        "loading": "正在加載",
        "looksGood": "確認",
        "moreInformation": "更多信息",
        "needToEditGoBack": "需要修改？請返回",
        "next": "下一步",
        "protectAccount": "保護賬戶",
        "receive": "接收",
        "reconnect": "重新連接",
        "recoverAccount": "恢復賬戶",
        "recovering": "正在恢復賬戶",
        "recoverYourAccount": "恢復賬戶",
        "removeAccount": "登出賬戶",
        "removeNode": "刪除節點",
        "removingKeys": "刪除密鑰",
        "reserveMyAccountId": "保留賬戶 ID",
        "resubmit": "重新提交",
        "retry": "重試",
        "returnToApp": "返回至應用",
        "saveChanges": "保存更改",
        "secureMyAccount": "加強賬戶安全",
        "send": "發送",
        "setupPhrase": "設置助記詞",
        "signIn": "登錄",
        "signingIn": "正在登錄",
        "signInLedger": "通過 Ledger 登錄",
        "skip": "跳過",
        "skipThisForNow": "暫時跳過",
        "stake": "質押",
        "staking": "正在質押",
        "startOver": "重新開始",
        "subscribe": "訂閱",
        "swap": "兌換",
        "swapUSN": "兌換 USN",
        "ToMaine": "返回主頁",
        "topUp": "充值",
        "transferring": "正在轉賬",
        "tryAgain": "再次嘗試",
        "unstaking": "正在贖回質押",
        "useMax": "最多",
        "verify": "驗證並完成",
        "verifyCode": "驗證代碼",
        "verifyCodeEnable": "驗證代碼並開啟",
        "verifying": "正在驗證",
        "viewAll": "查看全部",
        "viewFAQ": "查看常見問題",
        "viewOnExplorer": "通過瀏覽器查看",
        "viewPrivateKey": "查看私鑰",
        "withdrawing": "正在提現"
    },
    "buyNear": {
        "bridgeSubTitle": "通過彩虹橋將你的通證從以太坊轉至 NEAR。",
        "bridgeTokens": "通過以太坊跨鏈橋轉移通證",
        "buyWith": "打開",
        "coinMarketCap": "CoinMarketCap",
        "coinMarketLink": "CoinMarketCap 了解更多",
        "descMoonpay": "可在 MoonPay 使用偏好的支付方式購買 NEAR。",
        "descThree": "通過跨鏈橋將以太坊鏈上通證轉至 NEAR 錢包。",
        "descTwo": "NEAR 已經在以下交易所流通，可交易。",
        "descUtorg": "可在 Utorg 使用偏好的支付方式購買 NEAR。",
        "moonPay": "MoonPay",
        "nearPurchaseSubTitle": "使用你偏好的支付方式購買 $NEAR。",
        "nearPurchaseTitle": "購買 NEAR",
        "notSupported": "未在你所在地區支持",
        "seeMore": "查看更多：",
        "subTitle": "請選擇一種方式來購買充值 NEAR。",
        "supportedExchanges": "交易所",
        "supportedSubTitle": " $NEAR 可以在以下交易所交易購買。",
        "title": "充值 NEAR",
        "utorg": "Utorg"
    },
    "confirmLedgerModal": {
        "header": {
            "confirm": "請在設備上確認此操作...",
            "processing": "正在處理",
            "success": "賬戶已經成功從 Ledger 錢包導入。",
            "weFound": "我們發現 ${totalAccounts} 個賬戶正通過你的設備保護。"
        },
        "one": "請在 Ledger 硬件設備上確認登錄細節。",
        "two": "請在設備上根據指示確認並授權每個你希望導入的賬戶。"
    },
    "connecting": "連接中",
    "connectLedger": {
        "connectLedger": "連接 Ledger",
        "ledgerConnected": "Ledger 已連接",
        "modal": {
            "connect": {
                "cancelButton": "取消",
                "connectButton": "連接",
                "connectionPrompt": "請確保 Ledger 已安全連接，並且 NEAR 應用已在設備上打開。",
                "header": "連接至 Ledger 設備。"
            },
            "connection-error": {
                "cancelButton": "取消",
                "connectButton": "重試",
                "connectionPrompt": "請確保 Ledger 已安全連接，並且 NEAR 應用已在設備上打開。此外，您必须使用受支持的 <a href='https://nearhelp.zendesk.com/hc/en-us/articles/13972685751191-What-browsers-work-with-Ledger-Connect-' target=' _blank' rel='noopener noreferrer'>浏览器</a>。",
                "header": "連接失敗。"
            },
            "disconnected": {
                "cancelButton": "取消",
                "connectButton": "重新連接",
                "connectionPrompt": "請確保 Ledger 已安全連接，並且 NEAR 應用已在設備上打開。",
                "header": "你的設備已斷開連接。"
            }
        },
        "youMayNow": "你現在可以在設備上進行操作。"
    },
    "copy": {
        "default": "已複製！",
        "title": "複製"
    },
    "createAccount": {
        "accountIdInput": {
            "placeholder": "yourname.${data}",
            "title": "輸入用戶名"
        },
        "addACustomAddress": "添加自定義地址",
        "alreadyHaveAnAccount": "已經擁有賬戶？",
        "invalidLinkDrop": {
            "one": "NEAR Drop 已經被領取。",
            "title": "鏈接無效",
            "two": "NEAR Drop 只能被領取一次，之後鏈接會無效。"
        },
        "landing": {
            "descOne": "Genin Wallet 是一款安全的錢包，而且是 NEAR 區塊鏈的賬戶管理器。",
            "descTwo": "一旦你創建賬戶，你可以用它和 NEAR 鏈上應用交互，安全地存儲通證資產和 NFT 收藏品。",
            "title": "創建賬戶"
        },
        "note": {
            "canContain": "你的用戶名可以輸入以下任何字符：",
            "cannotContain": "你的用戶名不可以包括：",
            "characters": "符號 \"@\" 或 \".\"",
            "digits": "數字 (0-9)",
            "lowercase": "小寫英文字母 (a-z)",
            "maxCharacters": "超過 64 個字符（包括 .${accountSuffix}）",
            "minCharacters": "少於 2 個字符",
            "separators": "字符 (_-) 用於分割"
        },
        "pageText": "只需要輸入用戶名即完成註冊。",
        "pageTitle": "創建新的賬戶",
        "recoverItHere": "恢復賬戶",
        "setupPassphrase": {
            "generatePassphrase": {
                "desc": "為更高的安全性，我們建議為每一個新的錢包賬戶生成唯一的助記詞。",
                "descTwo": "作為一個快捷選項，你可以選擇在多個賬戶間共用一組助記詞。任何時候你都可以修改。",
                "tite": "創建新的助記詞？"
            },
            "sharedPassphrase": {
                "desc": "你的助記詞已驗證。一旦你向新賬戶充值，你的助記詞將在 <b>${numberOfAccounts} 個賬戶</b>間共享。",
                "descTwo": "一旦當你使用該助記詞恢復賬戶，以下賬戶也將會被恢復：",
                "tite": "你的共享助記詞"
            },
            "verifyPassphrase": {
                "desc": "請輸入已有的助記詞來綁定新的賬戶。",
                "passPhrasePlaceholder": "bubble word sun join impact exist ramp skull title hollow symbol very",
                "tite": "驗證助記詞",
                "yourPassphrase": "你的助記詞"
            }
        },
        "step": "步驟 ${step}/${total}",
        "terms": {
            "agreeBtn": "同意並繼續",
            "declineBtn": "拒絕",
            "desc": "註冊 NEAR 賬戶意味著你同意 Genin Wallet 服務條款和隱私政策。 我們建議你仔細閱讀：",
            "privacyCheck": "我同意隱私政策",
            "privacyLink": "隱私政策",
            "termsCheck": "我同意服務條款",
            "termsLink": "服務條款",
            "title": "服務條款"
        },
        "termsPage": {
            "descOne": "NEAR 賬戶要求進行一筆一次性<b>至少 ${amount}</b> 的充值來涵蓋<a href='https://docs.near.org/docs/concepts/account' rel='noopener noreferrer' target='_blank'>你的賬戶存儲成本</a>。",
            "descTwo": "在保留你的個性化賬戶 ID 之後，你需要進行一筆初始充值來激活的賬戶。",
            "disclaimer": "通過註冊NEAR 賬戶，意味著你同意MyNearWallet 錢包的<a href='/terms' rel='noopener noreferrer' target='_blank'>服務條款</a>和<a href='/privacy' rel='noopener noreferrer' target='_blank'>隱私策略</a>。",
            "title": "在開始之前..."
        }
    },
    "dashboard": {
        "activity": "最近動態",
        "noActivity": "該賬戶暫無動態。"
    },
    "dashboardActivity": {
        "message": {
            "AddKey": {
                "forContract": "<span>至</span> <span>${permissionReceiverId}</span>",
                "forReceiver": "<span>至</span> <span>${receiverId}</span>"
            },
            "CreateAccount": "<span>賬戶</span> <span>${receiverId}</span>",
            "DeleteAccount": "<span>賬戶</span> <span>${receiverId}<span>",
            "DeleteKey": "密鑰已刪除",
            "DeployContract": "<span>合約</span> <span>${receiverId}</span>",
            "FunctionCall": "<span>合約 ${receiverId}</span> 的<span>方法 ${methodName}</span>",
            "FunctionCallDetails": {
                "first": "<span>方法</span> <span>${methodName}</span>",
                "second": "<span>合約</span> <span>${receiverId}</span>"
            },
            "Stake": "<span>質押</span> <span>${stake}</span>",
            "Transfer": {
                "received": "<span>來自</span> <span>${signerId}</span>",
                "transferred": "<span>至</span> <span>${receiverId}</span>"
            }
        },
        "title": {
            "AddKey": {
                "forContract": "訪問密鑰已添加",
                "forReceiver": "訪問密鑰已添加"
            },
            "CreateAccount": "新賬戶已創建",
            "DeleteAccount": "賬戶已刪除",
            "DeleteKey": "密鑰已刪除",
            "DeployContract": "合約已部署",
            "FunctionCall": "方法已調用",
            "Stake": "已質押",
            "Transfer": {
                "received": "已接收 NEAR",
                "transferred": "已發送 NEAR"
            }
        }
    },
    "disclaimer": {
        "text": "這是開發者的預覽版錢包，僅限用於 NEAR 協議 DevNet。了解更多：",
        "title": "聲明"
    },
    "emailSubscribe": {
        "placeholder": "你的郵箱地址...",
        "title": "早期測試註冊"
    },
    "enterAccountNameLedgerModal": {
        "header": "輸入賬戶名稱",
        "one": "請輸入你嘗試登錄的賬戶名稱"
    },
    "error": "失敗",
    "errors": {
        "ledger": {
            "disconnected": "Ledger 已斷開連接。",
            "U2F_4": "無法通過硬件錢包登錄：U2F DEVICE_INELIGIBLE。請確保 Ledger 設備已連接並解鎖。",
            "U2FNotSupported": "Ledger 硬件錢包需要 U2F 瀏覽器支持。請使用 Chrome、Opera 或 Firefox 安裝 U2F 擴展，並確保你是通過 HTTPS 加密連接。"
        },
        "type": {
            "LackBalanceForState": "你的賬戶餘額過低，無法執行任何操作。請向你的賬戶轉賬 NEAR，然後再重新導入賬戶。",
            "RetriesExceeded": "此交易超出最大重試次數。"
        }
    },
    "existingAccount": {
        "fundNewAccount": {
            "titleOne": "批准交易",
            "titleTwo": "充值激活新賬戶"
        },
        "selectAccount": {
            "desc": "如果你有其他賬戶（<b>包括至少 ${amount} NEAR</b>），你可以從這個賬戶轉賬來激活你的新賬戶。",
            "descTwo": "切換或登錄至你的已有賬戶，並轉賬通證至新賬戶完成新賬戶激活。",
            "title": "通過已有賬戶充值激活。"
        }
    },
    "exploreApps": {
        "desc": "賺取、鑄造和玩遊戲！發現 NEAR 生態的應用。",
        "exploreApps": "瀏覽應用",
        "exploreDeFi": "瀏覽 DeFi 應用",
        "title": "尋找 NEAR 生態和應用？"
    },
    "exportPrivateKey": {
        "button": "備份本地私鑰",
        "desc": "請不要分享你的私鑰！任何獲得你私鑰的人也將獲得你的賬戶的完整訪問權限。<br /><br />",
        "enterAccountAddress": "請輸入你的賬戶地址來查看你的私鑰。",
        "title": "查看私鑰？"
    },
    "footer": {
        "contactSupport": "加入社區",
        "copyrights": "Genin Saifu LLC. 版權所有。",
        "desc": "Solana是原子可組合的世界計算機，無縫運行殺手級應用程序，速度快如光。",
        "learnMore": "了解更多",
        "needHelp": "需要幫助？",
        "privacyPolicy": "隱私策略",
        "termsOfService": "服務條款"
    },
    "fullAccessKeys": {
        "authorizedTo": "已授權",
        "dashboardNoKeys": "你沒有完全訪問密鑰",
        "deAuthorizeConfirm": {
            "desc": "完全訪問密鑰將會被永久取消。",
            "title": "是否取消完全訪問密鑰？"
        },
        "noKeys": "你沒有將 NEAR 錢包連接至任何應用。當連接後，你可以在這裡管理。<br /><br />查看基於 NEAR 開發的應用：",
        "pageTitle": "完全訪問密鑰",
        "submitAnyTransaction": "代表你發起交易",
        "useContract": "代表你使用 <b>${receiverId}</b> 合約",
        "viewYourAccountName": "查看你的賬戶名稱"
    },
    "getBalance": "查看餘額",
    "hardwareDevices": {
        "desc": "通過硬件錢包加強賬戶安全性。",
        "disable": {
            "desc": "禁用前請確保已經開啟其他賬戶恢復方式。",
            "disable": "禁用",
            "keep": "取消",
            "title": "你是否確定禁用硬件錢包？"
        },
        "ledger": {
            "auth": "已授權",
            "connect": "你的 Ledger 設備目前沒有連接，我們建議你重新連接確保賬戶安全。",
            "disclaimer": "在禁用硬件錢包前，必須開啟其他賬戶恢復方式。",
            "title": "Ledger 硬件錢包"
        },
        "title": "硬件錢包"
    },
    "importAccount": {
        "noId": "正在導入賬戶",
        "noIdFailed": "導入賬戶失敗。",
        "withId": "導入賬戶：${accountId}",
        "withIdFailed": "導入賬戶失敗：${accountId}"
    },
    "importAccountWithLink": {
        "accountFound": "找到 1 個賬戶",
        "accountImported": "賬戶已導入",
        "accountsFound": "找到 ${count} 個賬戶",
        "alreadyImported": "由該鏈接保護的賬戶已導入。",
        "continue": "請在你選擇的瀏覽器中繼續該流程。",
        "copyUrl": "複製鏈接",
        "foundAccount": "我們找到 <b>1 個由該鏈接保護的賬戶</b>。",
        "foundAccounts": "我們找到 <b>${count} 個由該鏈接保護的賬戶</b>。",
        "goToAccount": "訪問賬戶",
        "import": "導入",
        "importAccount": "導入你的賬戶",
        "preferedBrowser": "如果這不是你偏好的瀏覽器，"
    },
    "importing": "正在導入",
    "initialDeposit": {
        "claimAccount": {
            "desc": "你的初始充值已到賬，一旦認領賬戶，你充值的通證會被轉移到新認領的賬戶。",
            "disclaimer": {
                "checkBox": "同意 & 繼續",
                "desc": "重要：你之前的單次充值地址將失效。若你在交易所和其他設備保存了單次充值地址，請將其替換為你的新賬戶ID(例：XXXX.near)"
            },
            "title": "認領你的賬戶"
        },
        "creditCard": {
            "desc": "Complete your purchase of <b>at least ${amount} NEAR</b> through MoonPay. MoonPay will automatically deposit your funds into your new account.",
            "title": "Fund your new account using a credit card."
        },
        "manualDeposit": {
            "desc": "請向單次充值地址轉賬<b>至少${amount} NEAR</b>，以滿足最低充值要求。",
            "title": "充值至賬戶"
        }
    },
    "input": {
        "accountId": {
            "placeholder": "例如：johndoe.near",
            "placeHolderAlt": "賬戶 ID",
            "subLabel": "你的賬戶 ID 必須是頂級賬戶（如 <b>.near</b>）或 64 位字符的地址。",
            "title": "賬戶 ID"
        },
        "enterWord": {
            "placeholder": "",
            "title": "請輸入第 <b>${wordId}</b> 個助記詞"
        }
    },
    "landing": {
        "banner": "NEAR 錢包已經在內測",
        "desc": "使用 <span>NEAR 錢包</span>安全地存儲、使用您的資產。",
        "or": "或",
        "title": "NEAR 數字錢包"
    },
    "ledgerSignTxModal": {
        "ADD_ACCESS_KEY": "請在 Ledger 設備上確認登錄信息。",
        "ADD_ACCESS_KEY_SEED_PHRASE": "請在 Ledger 設備上確認完全訪問信息。",
        "ADD_LEDGER_ACCESS_KEY": "請在 Ledger 設備上確認公鑰信息。",
        "CREATE_ACCOUNT_FROM_IMPLICIT": "請在 Ledger 設備上確認賬戶創建，這包括必要的臨時賬戶的轉賬。",
        "CREATE_NEW_ACCOUNT": "請在 Ledger 設備上確認新賬戶密鑰信息。",
        "DEFAULT": "請在 Ledger 上確認細節信息。",
        "DELETE_RECOVERY_METHOD": "請在 Ledger 設備上確認刪除恢復方式信息。",
        "DISABLE_LEDGER": "請在 Ledger 上確認公鑰細節信息。",
        "GET_LEDGER_PUBLIC_KEY": "請在 Ledger 設備上確認公鑰訪問信息。",
        "REMOVE_ACCESS_KEY": "請在 Ledger 設備上確認刪除密鑰信息。",
        "REMOVE_NON_LEDGER_ACCESS_KEYS": "請在 Ledger 設備上確認信息。",
        "SEND_MONEY": "請在 Ledger 設備上確認轉賬信息。",
        "SET_SIGN_TRANSACTION_STATUS": "請在 Ledger 設備上確認存款細節信息。",
        "SETUP_RECOVERY_MESSAGE": "請在 Ledger 設備上確認完全訪問細節信息。",
        "SIGN_AND_SEND_TRANSACTIONS": "請在 Ledger 設備上確認存款信息。",
        "STAKE": "請在 Ledger 上確認質押。",
        "UNSTAKE": "請在 Ledger 上確認贖回質押。",
        "WITHDRAW": "請在 Ledger 上確認提現。"
    },
    "link": {
        "account": "賬戶",
        "authorizedApps": "已授權應用",
        "buyUSN": "購買 $USN",
        "donateToUkraine": "捐助烏克蘭",
        "fullAccessKeys": "完全訪問密鑰",
        "help": "幫助",
        "noAccount": "你未登錄其他賬號",
        "receive": "接收",
        "send": "發送",
        "staking": "質押",
        "swap": "兌換",
        "switchAccount": "切換賬號",
        "wallet": "錢包"
    },
    "linkdropLanding": {
        "claiming": "正在領取",
        "ctaAccount": "用已登錄的賬戶領取",
        "ctaLogin": "登錄並領取",
        "ctaNew": "註冊新賬戶並領取",
        "desc": "你可以登錄已有的賬戶領取，或創建新的賬戶領取。",
        "modal": {
            "desc": "你的 NEAR 空投已自動領取至你的賬戶。",
            "title": "你的 NEAR 空投已領取"
        },
        "or": "或",
        "title": "你收到了 NEAR 空投！"
    },
    "loading": "加載中...",
    "loadingNoDots": "加載中",
    "lockedBalance": "NEAR 鎖定在鎖定合約中，但無法取出。你可能仍委托或質押著這些 NEAR。一旦 NEAR 解鎖，你可以在未鎖定餘額中查看，並選擇取出（轉移到「可用餘額」）。",
    "login": {
        "cliLoginSuccess": {
            "pageText": "請關閉該窗口，並按照終端內的指導操作。",
            "pageTitle": "你已經成功授權 NEAR Shell！"
        },
        "confirm": {
            "pageText": "你正在向 ${appTitle} 授權<b>完全訪問</b>！",
            "pageTextSecondLine": "<b>為確認授權</b>，請輸入你的用戶名。",
            "pageTitle": "是否確認？",
            "username": "用戶名"
        },
        "details": {
            "callFunctions": "在任何智能合約上調用函數",
            "createAndDeleteAccessKeys": "創建和刪除訪問密鑰",
            "createNewAccounts": "創建新的賬戶",
            "deploySmartContracts": "部署智能合約",
            "detailedDescription": "交易的詳細描述",
            "forContract": "合約",
            "function": "函數",
            "noDescription": "該方法沒有詳細描述",
            "stakeAndUnstake": "質押和贖回質押 NEAR 通證",
            "thisAllows": "這將允許 ${appTitle}：",
            "transferTokens": "從你的賬戶向其他賬戶發送通證"
        },
        "form": {
            "accessYourAccount": "訪問你的賬戶。",
            "accountIdOnly": "正在請求獲取你的賬戶 ID。",
            "isRequestingFullAccess": "正在請求<b>完全訪問</b>",
            "isRequestingTo": "正在請求",
            "thisDoesNotAllow": "不允許該應用轉賬任何通證。",
            "thisProvidesAccess": "提供<b>所有通證</b>操作權限。<br />請謹慎操作！",
            "toYourAccount": "你的賬戶。"
        },
        "v2": {
            "connectConfirm": {
                "desc": "請連接你信任的應用。一旦連接，<a href='${contractIdUrl}' target='_blank' rel='noreferrer'>${appReferrer}</a> 將擁有 <b>${accessType}</b> 權限：",
                "feeAllowance": {
                    "desc": "授權該應用在使用過程中支付最多 <b>${amount} NEAR</b> 的網絡費用。",
                    "title": "網絡費用授權"
                },
                "fullAccess": "完全訪問",
                "fullAccessModal": {
                    "desc": "請謹慎授權，完整訪問權限允許應用和合約操作你的賬戶。若確定繼續，請輸入你的賬戶 ID 確認繼續。",
                    "title": "授權完整賬戶訪問？"
                },
                "fullAccessWarning": "此應用正在請求你的賬戶的完全訪問權限。為開啟完全訪問，你將會被要求確認即將連接的賬戶 ID。",
                "limitedAccess": "受限訪問",
                "permissions": {
                    "callMethodsOnContract": "以你的授權賬戶的名義調用該智能合約的調用方法",
                    "notTransferTokens": "該應用沒有權限轉賬 NEAR 通證",
                    "transferTokens": "從你的賬戶轉賬 NEAR 通證",
                    "viewAddress": "查看你連接的賬戶地址",
                    "viewBalance": "查看你連接的賬戶餘額"
                },
                "title": "正在連接："
            },
            "connectWithNear": {
                "desc": "應用正在請求你的賬戶的 <b>${accessType}</b> 權限，選擇希望連接的賬戶。",
                "fullAccess": "完全訪問",
                "limitedAccess": "受限訪問",
                "title": "連接 NEAR"
            }
        }
    },
    "minimumBalance": "這是維持你賬號活躍狀態的 NEAR 通證「保留餘額」，該餘額代表你正在使用的 NEAR 區塊鏈存儲空間，會在使用量變化後產生對應變化。",
    "mobileDeviceAccess": {
        "importCode": {
            "desc": "如果你已經在電腦上登錄，掃描手機訪問二維碼可以導入賬戶。"
        },
        "showCode": {
            "button": "查看二維碼",
            "desc": "掃描手機導入二維碼授權完全賬戶訪問權限。",
            "modal": {
                "desc": "點擊「顯示」按鈕並掃碼可以在手機上導入你的賬戶。 ",
                "disclaimer": "<b>此二維碼包含高度敏感的數據，並會在手機上授權完全賬戶訪問權限。</b> 請不要分享或曝光此二維碼，並且只在信任的手機上導入賬戶。",
                "reveal": "顯示",
                "title": "手機導入二維碼"
            }
        },
        "title": "手機導入"
    },
    "networkBanner": {
        "desc": "當前錢包（和網絡）僅供測試。測試網通證和其他資產均無價值。測試網賬戶不會遷移至主網。",
        "header": "NEAR 測試錢包",
        "title": "測試網錢包"
    },
    "NFTDetail": {
        "owner": "所有者",
        "transfer": "發送"
    },
    "NFTs": {
        "emptyState": "似乎你還沒有收藏品。",
        "loadMore": "加載更多"
    },
    "NFTTransfer": {
        "cancel": "取消",
        "confirm": "確認",
        "continue": "繼續",
        "enterReceiver": "輸入接收方的地址，然後確認發送。",
        "next": "下一步",
        "transactionComplete": "發送完成！",
        "transferNft": "發送 NFT",
        "viewTransaction": "查看結果",
        "youSent": "你已經向 <span class='color-green'>${receiverId}</span> 發送了 <span class='color-blue'>${title}</span>。"
    },
    "nodeDetails": {
        "pageText": "更新節點的詳細信息。",
        "pageTitle": "節點詳情"
    },
    "nodeStaking": {
        "nodes": {
            "noNode": {
                "text": "這將連接你的節點至你的錢包賬戶。",
                "title": "已經配置完節點？在這裏添加。"
            },
            "title": "節點"
        },
        "pageText": "nodeandstaking.near",
        "pageTitle": "節點和質押",
        "staking": {
            "title": "質押",
            "youAreStaking": "正在進行質押"
        },
        "steps": {
            "one": {
                "desc": "具體操作步驟可以查看文檔",
                "title": "1. 通過命令行配置並運行節點"
            },
            "three": {
                "desc": "從這裏或者命令行進行質押。可以查看文檔尋找幫助。",
                "title": "3. 開始質押"
            },
            "title": "為運行節點或質押，請執行以下步驟：",
            "two": {
                "desc": "未同步節點無法產生或驗證區塊。",
                "title": "2. 等待節點同步"
            }
        }
    },
    "of": "/",
    "ofTotal": "占總數",
    "or": "或",
    "pageNotFound": {
        "displayTitle": "404",
        "returnToWallet": "返回錢包",
        "title": "抱歉，無法找到該頁面。"
    },
    "postMigration": {
        "cta": "遷移賬戶",
        "message": "NEAR 錢包已遷移至新的域名：<b>mynearwallet.com</b>。<br/>你需要在 <b>${endDate}</b> 之前遷移你的賬戶，或從賬戶設置中備份（導出）賬戶密鑰。"
    },
    "poweredByCoinGecko": "價格數據來自 <a href='https://www.coingecko.com/en/api' target='_blank' rel='noopener noreferrer'>CoinGecko</a>",
    "preMigration": {
        "calendarEvent": {
            "details": "這是官方 NEAR 錢包遷移的開始日期。請盡快開始你的賬戶遷移。",
            "text": "NEAR 錢包遷移"
        },
        "cta": "設置提醒",
        "message": "我們將遷移！NEAR 錢包將會遷移至新的域名（<b>mynearwallet.com</b>），自 <b>${startDate}</b> 起開始遷移。<br/>可以等新域名上線後再訪問遷移你的賬戶，或在<a href='/profile'>賬戶設置</a>中備份你的賬戶密鑰。"
    },
    "profile": {
        "account": {
            "available": "可用餘額",
            "availableToTransfer": "可轉賬",
            "availableToWithdraw": "可提現",
            "inStakingPools": "質押中",
            "pendingRelease": "請求釋放",
            "reservedForStorage": "為存儲預留",
            "reservedForTransactions": "為交易預留",
            "staked": "已質押",
            "staking": "質押",
            "unstaked": "未質押",
            "walletBalance": "錢包餘額",
            "walletId": "錢包 ID"
        },
        "accountDoesNotExistBanner": {
            "desc": "<a href='https://explorer.near.org/accounts/${data}' rel='noopener noreferrer' target='_blank'><b>此賬戶</b></a>暫無動態。向你的賬戶發送 NEAR 開始使用所有 NEAR 錢包功能。"
        },
        "authorizedApps": {
            "title": "已授權應用"
        },
        "details": {
            "availableBalance": "可用餘額",
            "locked": "鎖定的",
            "lockPopup": {
                "createAnotherAccount": "創建其他賬戶",
                "text": "用戶名是你在系統中的唯一識別號，並且與數據和資產綁定，因此無法修改。但是你可以${link}以使用想要的名字，並在需要時轉賬資產。",
                "title": "為何是鎖定？"
            },
            "minBalance": "保留餘額",
            "profile": "資料",
            "public": "公開",
            "qrDesc": "可以使用手機相機來掃描該二維碼",
            "staked": "已質押的",
            "totalBalance": "餘額",
            "unlocked": "未鎖的",
            "unvested": "未兌現的",
            "username": "用戶名",
            "visibleTo": "是否公開"
        },
        "lockup": {
            "locked": "已鎖定",
            "lockupBalance": "鎖定賬戶餘額",
            "lockupId": "鎖定賬戶 ID",
            "unlocked": "未鎖定"
        },
        "lockupBanner": {
            "cta": "轉賬至錢包",
            "title": "你有 <b>${amount}</b> 可從你的鎖定賬戶提現。"
        },
        "pageTitle": {
            "default": "賬戶",
            "loading": "加載中...",
            "notFound": "賬戶 ${accountId} 未找到"
        },
        "security": {
            "lessSecure": "備選方式",
            "lessSecureDesc": "推薦餘額較小的賬戶使用這些恢復方式，非常方便。但如果手機或郵箱被盜，你的資產將會有風險。",
            "mostSecure": "最安全的方式（推薦）",
            "mostSecureDesc": "Ledger 是最安全的選擇之一。助記詞也很安全，前提是安全記錄並保存。",
            "title": "安全與恢復賬戶"
        },
        "twoFactor": "雙重認證方式",
        "twoFactorDesc": "登錄或轉賬時，將通過短信或郵箱方式授權。"
    },
    "reCAPTCHA": {
        "disclaimer": "此站點由 reCAPTCHA 保護，並遵循 Google <a href='https://policies.google.com/privacy' target='_blank' rel='noopener noreferrer'>隱私策略</a>和<a href='https://policies.google.com/terms' target='_blank' rel='noopener noreferrer'>服務條款</a>。",
        "fail": {
            "desc": "註冊新賬戶時必須通過 reCAPTCHA 驗證。",
            "link": "充值你的賬戶？",
            "title": "加載 reCAPTCHA 失敗。"
        },
        "loading": "正在加載 reCAPTCHA..."
    },
    "receivePage": {
        "addressTitle": "你的地址",
        "copyAddressLinkLong": "複製地址鏈接",
        "copyAddressLinkShort": "複製",
        "qrCodeTitle": "掃描二維碼",
        "snackbarCopySuccess": "地址鏈接已複製！"
    },
    "recoverAccount": {
        "actionRequired": "此消息包含恢復鏈接，請點擊鏈接開始恢復賬戶流程！",
        "actionType": "恢復",
        "cannotResend": "此消息是在開啟 Email/手機號恢復時發送。我們只發送一次，並且無法重新發送。",
        "email": {
            "desc": "請檢查來自 near.org 地址的郵件，標題是：",
            "subject": "「重要：NEAR 錢包恢復郵件」。",
            "title": "郵箱"
        },
        "ledger": {
            "desc": "請確認 Ledger 已安全連接，並在設備上打開 NEAR 應用。",
            "title": "Ledger"
        },
        "pageText": "如果你已經設置賬戶恢復方式，請根據以下步驟開始恢復流程。",
        "pageTitle": "恢復你的賬戶",
        "phone": {
            "desc": "在你的手機上檢查來自以下手機號的短信：",
            "number": "+14086179592。",
            "title": "手機"
        },
        "phrase": {
            "desc": "請確認已準備 12 個單詞組成的助記詞，然後點擊下方開始恢復賬戶流程。",
            "title": "助記詞"
        }
    },
    "recoverSeedPhrase": {
        "couldNotFindAccountModal": {
            "buttonImport": "仍然導入",
            "desc": {
                "ledger": "我們無法在此 Ledger 上找到<b>任何活躍賬戶</b>。這可能是因為賬戶還沒任何動態。",
                "phrase": "我們無法通過此助記詞找到<b>任何活躍賬戶</b>。這可能是因為助記詞錯誤，或者賬戶還沒有任何動態。"
            },
            "title": "無法找到賬戶"
        },
        "pageText": "請輸入與此賬戶關聯的助記詞。",
        "pageTitle": "通過助記詞恢復賬戶",
        "seedPhraseInput": {
            "placeholder": "correct horse battery staple...",
            "title": "助記詞（12 個單詞）"
        }
    },
    "recoverWithLink": {
        "errorP": "請檢查你的郵箱或手機最新消息，之前消息裏的鏈接已經無效。",
        "errorTitle": "鏈接已過期",
        "pOne": "點擊 \"繼續\" 恢復你的賬戶：",
        "pTwo": "如果這不是你偏好的瀏覽器，請複製鏈接並在你偏好的瀏覽器中完成恢復流程。",
        "snackbarCopySuccess": "恢復鏈接已複製",
        "title": "恢復賬戶"
    },
    "recoveryMgmt": {
        "disableInputPlaceholder": "請輸入用戶名確認",
        "disableNo": "不，保留。",
        "disableNotAllowed": "賬戶必須擁有至少一種恢復方式，請首先添加另外一種方式再繼續刪除此方式。",
        "disableTextLink": "你收到的恢復鏈接將會被永久禁用。",
        "disableTextPhrase": "當前助記詞將會被永久禁用。",
        "disableTitle": "是否確定禁用該恢復賬戶方式？",
        "enabled": "已啟用",
        "methodTitle": {
            "email": "郵箱地址",
            "phone": "手機號",
            "phrase": "助記詞"
        },
        "noRecoveryMethod": "你還不能恢復賬戶，請先添加以下至少一種方式。",
        "recoveryLinkSent": "恢復鏈接已發送！",
        "resend": {
            "email": "重新發送郵件",
            "phone": "重新發送短信"
        },
        "title": "賬戶恢復方式"
    },
    "reduxActions": {
        "ADD_ACCESS_KEY": {
            "error": "在授權此操作時發生了錯誤，請重試。",
            "success": "${title} 現已授權使用你的賬戶。"
        },
        "ADD_ACCESS_KEY_SEED_PHRASE": {
            "error": "在設置助記詞恢復方式時發生了錯誤，請重試。",
            "success": "助記詞恢復設置已經完成。"
        },
        "ADD_LEDGER_ACCOUNT_ID": {
            "error": "賬戶的訪問被拒絕，恢復賬戶失敗。<br/>若要繼續，需要在 Ledger 設備上授權每個賬戶的只讀訪問，請再重試。",
            "success": ""
        },
        "CHECK_ACCOUNT_AVAILABLE": {
            "error": "用戶未找到。",
            "success": "用戶已找到。"
        },
        "CHECK_IS_NEW": {
            "error": "用戶名已被註冊，請嘗試其他。",
            "success": "恭喜！${accountId} 可被註冊。"
        },
        "CHECK_NEW_ACCOUNT": {
            "error": "用戶名已被註冊，請嘗試其他。",
            "success": "恭喜！${accountId} 可被註冊。"
        },
        "CREATE_ACCOUNT_WITH_SEED_PHRASE": {
            "error": "",
            "success": ""
        },
        "CREATE_NEW_ACCOUNT": {
            "error": "用戶名已被註冊，請嘗試其他。",
            "success": "恭喜！${accountId} 可被註冊。"
        },
        "default": {
            "error": "出現問題，請重試。",
            "success": ""
        },
        "DELETE_RECOVERY_METHOD": {
            "error": "",
            "success": ""
        },
        "DEPLOY_MULTISIG": {
            "error": "",
            "success": ""
        },
        "Deserialization": "",
        "GET_LEDGER_ACCOUNT_IDS": {
            "error": "公鑰訪問被拒絕，恢復賬戶失敗。<br/>若要繼續，需要允許 NEAR 錢包訪問你的公鑰，請再重試。",
            "success": ""
        },
        "LackBalanceForState": "你的賬戶可用餘額過低，無法執行操作。請充值 NEAR 後重試。",
        "NotEnoughBalance": "你的賬戶沒有足夠餘額進行此操作。",
        "PROMPT_TWO_FACTOR": {
            "invalidCode": "無效的雙重認證驗證碼，請重試。"
        },
        "RECOVER_ACCOUNT_SEED_PHRASE": {
            "error": "恢復賬戶失敗。",
            "success": "成功通過助記詞恢復賬戶。<br/><b>${numberOfAccounts} 個賬戶</b>成功恢復，已切換至最新恢復的賬戶。"
        },
        "REFRESH_ACCOUNT_EXTERNAL": {
            "error": "賬戶 <b>${accountId}</b> 未找到"
        },
        "REMOVE_ACCESS_KEY": {
            "error": "請檢查你的設備已經連接並打開了 NEAR 應用。",
            "success": ""
        },
        "RetriesExceeded": "此交易超出最大重試次數。",
        "SAVE_AND_SELECT_LEDGER_ACCOUNTS": {
            "error": "請檢查硬件錢包已經安全連接，並且 NEAR 應用已經打開。",
            "success": "你已經成功登錄。<br/><b>${numberOfAccounts} 個賬戶</b>已經成功恢復：<b>${accountList}</b>。<br/>最後一個賬戶設置為活躍賬戶。"
        },
        "SEND_MONEY": {
            "error": "請檢查你的設備已經連接並打開了 NEAR 應用。"
        },
        "SETUP_RECOVERY_MESSAGE": {
            "error": "設置賬戶恢復方式時發生錯誤，請重試。",
            "success": "賬戶恢復設置已完成。"
        },
        "SIGN_AND_SEND_TRANSACTIONS": {
            "error": "",
            "success": ""
        },
        "TRANSFER_ALL_FROM_LOCKUP": {
            "error": "出現問題，請重試。",
            "success": "成功從鎖定賬戶轉移通證！"
        },
        "U2F_4": "無法通過硬件錢包登錄：U2F DEVICE_INELIGIBLE。請確保 Ledger 設備已連接並解鎖。",
        "VERIFY_TWO_FACTOR": {
            "error": "雙重認證設置失敗，請重試。",
            "success": "雙重認證設置成功！"
        }
    },
    "releaseNotesModal": {
        "desc": "你的建議我們已採納！最新版本帶來了賬戶餘額的顯示改進，創建新的賬戶也變得更加容易。",
        "subText1": "在初次使用時，你可以使用短信來驗證並初始化你的賬戶。不希望提供手機信息的用戶仍可以通過充值一些 NEAR 通證來初始化新賬戶。",
        "subText2": "NEAR 錢包內的主要餘額現在是「可用餘額」，之前此處是顯示「總餘額」。可用餘額意味著你可以第一眼就能看到可以使用的 NEAR 通證數量和對應價值。但不包括無法使用的通證價值。質押的通證數量可以在賬戶或質押頁面查看。 ",
        "subTitle1": "新功能",
        "subTitle2": "功能增強",
        "title": "更新說明"
    },
    "removeAccount": {
        "button": "從錢包中登出賬戶",
        "desc": "你需要在將來通過恢復方式重新導入該賬戶。請確保你已經保存好助記詞或其他恢復方式。",
        "disclaimer": "我確認有 <b>${accountId}</b> 賬戶的恢復方式",
        "title": "登出賬戶？"
    },
    "reservedForFeesInfo": "保留 ${data} NEAR，以承擔交易成本。",
    "selectAccountDropdown": {
        "account": "賬戶",
        "createAccount": "創建新的賬戶",
        "noOtherAccounts": "你沒有其他賬戶",
        "selectAccount": "選擇賬戶",
        "switchAccount": "切換賬戶",
        "switchAccounthNotAllowed": "該應用不支持賬戶切換"
    },
    "sending": "發送中",
    "sendMoney": {
        "account": {
            "title": "轉賬至"
        },
        "amount": {
            "available": "可轉賬餘額"
        },
        "amountStatusId": {
            "available": "可用餘額：",
            "howMuch": "你想要發送多少通證？",
            "noMoreThan": "不可超過 5 位數",
            "notEnoughTokens": "賬戶內至少保留 ${amount} NEAR",
            "sending": "正在發送："
        },
        "banner": {
            "insufficient": "你將轉賬所有的可用餘額，請至少保留 <b>${data} NEAR</b> 來保證交易費。",
            "useMax": "${data} NEAR 已經為交易費而預留。"
        },
        "button": {
            "confirm": "確認並轉賬",
            "dashboard": "訪問概覽頁",
            "send": "提交"
        },
        "confirmModal": {
            "title": "確認交易"
        },
        "onceConfirmed": "一旦確認，這將不可撤銷。",
        "subtitle": {
            "default": "請輸入你將轉賬的金額，以及接收方賬戶 ID",
            "success": "成功轉賬 NEAR",
            "to": "發送至："
        },
        "title": {
            "default": "發送",
            "success": "成功！"
        },
        "to": "至",
        "wasSentTo": "被發送至：",
        "youAreSending": "你正在發送"
    },
    "sendV2": {
        "accordionTriggerTitle": {
            "balanceDetails": "餘額詳情",
            "transactionDetails": "交易詳情"
        },
        "review": {
            "title": "你正在發送"
        },
        "selectAsset": {
            "assetInputPlaceholder": "搜索資產名稱",
            "assetListNameTitle": "名稱",
            "asssetListBalanceTitle": "可轉賬餘額",
            "title": "選擇資產"
        },
        "selectReceiver": {
            "receiverInputLabel": "發送至"
        },
        "selectTokenButtonTitle": "選擇資產",
        "success": {
            "title": "交易完成</br>你發送了<span class='color-blue'>${amount}</span></br>至<span class='color-green'>${receiverId }</span>"
        },
        "translateIdInfoTooltip": {
            "estimatedFees": "處理這筆交易的預估費用。",
            "estimatedTotal": "這筆交易的預估總數，包括費用。",
            "networkFees": "涵蓋了處理這筆交易的最終數額。",
            "reservedForFees": "你賬戶餘額中保留的部分，這部分涵蓋了存儲消耗成本。更多信息請訪問 NEAR 文檔。"
        },
        "TXEntry": {
            "status": {
                "Failure": "失敗",
                "notAvailable": "狀態不可用",
                "SuccessValue": "成功"
            },
            "title": {
                "amount": "數量",
                "availableBalance": "可用餘額",
                "availableToSend": "可轉賬餘額",
                "estimatedFees": "預估費用",
                "estimatedTotal": "預估總計",
                "from": "來自",
                "networkFees": "網絡費用",
                "receiverId": "發送至",
                "reservedForFees": "保留費用",
                "status": "狀態",
                "timeStamp": "日期和時間",
                "to": "至",
                "token": "資產"
            }
        }
    },
    "setRecoveryConfirm": {
        "didNotReceive": "未收到驗證碼？",
        "email": "郵箱",
        "inputError": "請輸入驗證碼。",
        "inputHeader": "輸入 6 位驗證碼",
        "inputPlaceholder": "驗證碼",
        "or": "或",
        "pageText": "我們已經發送 6 位驗證碼至",
        "phone": "手機號",
        "reenter": {
            "link": "點擊此處",
            "one": {
                "email": "如果你沒有收到郵件，或者上面的郵箱地址輸入錯誤，",
                "phoneNumber": "如果你沒有收到短信，或上面的手機號輸入錯誤，"
            },
            "two": {
                "email": "重新輸入郵箱地址並重新發送。",
                "phoneNumber": "重新輸入手機號並重新發送。"
            }
        },
        "resendCode": "重新發送",
        "resending": "正在重新發送...",
        "sendToDifferent": "發送至其他",
        "title": "輸入驗證碼"
    },
    "setupLedger": {
        "header": "綁定至你的硬件錢包",
        "one": "通過 USB 連接 Ledger Nano S/X 至你的電腦或手機，並<b>打開 NEAR 應用</b>。",
        "two": "如果你還沒有安裝 NEAR 賬本應用，請按照",
        "twoLink": "步驟安裝"
    },
    "setupLedgerSteps": {
        "five": "在第二個彈窗中選擇設備，並點擊連接。",
        "four": "在首個彈窗中，點擊連接按鈕。",
        "header": "在硬件錢包上安裝 NEAR",
        "install": "如果你還沒有安裝 NEAR Ledger 應用，請按照",
        "installLink": "<a href='https://www.ledger.com/ledger-live' target='_blank'>以下步驟操作</a>。",
        "one": "打開 <a href='https://www.ledger.com/ledger-live' target='_blank'>Ledger Live</a>，並安裝固件更新。",
        "six": "點擊繼續按鈕來完成設置 Ledger。",
        "three": "根據步驟在設備上安裝 <span class='color-black'>NEAR 應用</span>。",
        "two": "返回至<span class='color-black'>管理器</span> Tab 並搜尋 <span class='color-black'>NEAR</span>。"
    },
    "setupLedgerSuccess": {
        "header": "你的賬戶已通過 Ledger 硬件保護！",
        "nextStep": {
            "header": {
                "keep": "保留密鑰？",
                "remove": "確認刪除密鑰"
            },
            "one": {
                "keep": "<span class='color-red'>保留多個恢復方式和設備增加了賬戶被盜的風險。</span>",
                "remove": "在刪除現有密鑰前，請確認已經安全記錄和保存錢包的助記詞。"
            },
            "two": {
                "remove": "如果你丟失了助記詞，NEAR 公司將無法幫你恢復賬戶和資產。"
            }
        },
        "one": "我們推薦你刪除其他所有密鑰（恢復方式和設備）。",
        "primaryCta": "刪除其他密鑰",
        "secondaryCta": "保留密鑰",
        "two": "保留多個密鑰增加了賬戶被盜的風險。"
    },
    "setupRecovery": {
        "advancedSecurity": "高級安全",
        "advancedSecurityDesc": "記錄 12 個單詞組成的助記詞，並安全保存。",
        "basicSecurity": "基礎安全",
        "basicSecurityDesc": "請輸入你的郵箱地址或手機號以獲取恢復鏈接",
        "emailDesc": "通過郵箱方式接收驗證碼和賬戶恢復鏈接。",
        "emailPlaceholder": "example@email.com",
        "emailTitle": "郵箱方式",
        "existingPhraseDesc": "通過共享助記詞來保護新的地址。",
        "existingPhraseTitle": "使用已有的助記詞",
        "header": "保護你的賬戶",
        "ledgerDesc": "通過 Ledger 硬件錢包保護你的賬戶安全。",
        "ledgerTitle": "Ledger 硬件錢包",
        "newPhraseDesc": "通過新的助記詞保護新地址。",
        "newPhraseTitle": "創建新助記詞",
        "notSupportedPhone": "抱歉，目前我們暫未對你所在的地區開放短信恢復方式。請選擇郵箱方式。",
        "phoneDesc": "請輸入你的手機號以接收恢復鏈接。",
        "phonePlaceholder": "+1 415 797 8554",
        "phoneTitle": "手機短信方式",
        "phraseDesc": "生成並安全地存儲唯一的助記詞。",
        "phraseTitle": "安全助記詞",
        "subHeader": "請設置一種賬戶恢復方式："
    },
    "setupSeedPhrase": {
        "pageText": "請在安全的位置按順序記錄以下單詞。<b>任何獲得助記詞的人都將可以完全訪問該賬戶！</b>接下來將驗證你記錄的助記詞。",
        "pageTitle": "設置你的安全助記詞",
        "snackbarCopyImplicitAddress": "賬戶 ID 已複製！",
        "snackbarCopySuccess": "助記詞已複製！"
    },
    "setupSeedPhraseSuccess": {
        "pageText": "使用助記詞來恢復你的賬戶。",
        "pageTextSecondLine": "任何能夠查看你的助記詞的人都可以訪問你的賬戶，因此請離線安全地保存助記詞。",
        "pageTitle": "恢復設置已完成"
    },
    "setupSeedPhraseVerify": {
        "inputError": "請檢查你的助記詞，並重試。",
        "pageText": "請根據你的助記詞輸入以下單詞完成設置。",
        "pageTitle": "驗證助記詞",
        "startOverText": "沒有記錄下來？"
    },
    "sign": {
        "accountNotFound": {
            "body": "${signCallbackUrl} 正在請求授權的 NEAR 賬戶無法找到：<b>${signTransactionSignerId}</b><br/><br/>為授權任何交易，請先導入該賬戶。",
            "title": "賬戶無法找到"
        },
        "ActionWarrning": {
            "binaryData": "參數中包含二進制數據",
            "deleteAccount": "你將要刪除你的賬戶！你的 NEAR 餘額將被清空，並且你的賬戶數據都將被刪除。",
            "deployContract": "你正在向你的賬戶部署合約！該合約可以訪問你的 NEAR 餘額，並代表你和其他合約交互。",
            "functionCall": "該函數沒有描述。",
            "stake": "你正在質押 NEAR 通證。這些通證將會被鎖定，並且如果你的驗證節點無法即時響應，這些通證有丟失風險。"
        },
        "approveTransaction": "授權交易",
        "authorizationRequestSuccessful": "授權請求成功",
        "authorizing": "正在授權",
        "availableBalance": "可用餘額",
        "availableToTransfer": "可轉賬",
        "contract": "合約：",
        "contractDetails": "合約細節",
        "details": {
            "detailedDescription": "交易的詳細描述",
            "forContract": "合約",
            "gasLimit": "Gas 限制",
            "gasPriceUnavailable": "Gas 價格估計不可用",
            "transactionFees": "交易費"
        },
        "estimatedFees": "預估費用",
        "feeLimit": "費用限制",
        "function": "方法：",
        "gasLimit": "Gas 限制",
        "hereAreSomeDetails": "這裏是能夠幫助你的一些信息。",
        "insufficientFunds": "資產不足",
        "insufficientFundsDesc": "你沒有足夠的可用 NEAR 完成該操作。",
        "invalidTransaction": {
            "body": "該請求無效，無法簽名。",
            "title": "無效交易"
        },
        "isRequesting": {
            "authorization": "正在請求授權",
            "transferOf": "正在請求轉賬"
        },
        "multipleAccountsError": {
            "body": "<a href='${signCallbackUrl}' target='_blank' rel='noreferrer'>${signCallbackUrl}</a> has requested authorization from more than one account.<br/><br/>Genin Wallet may only authorize transactions for a single account at a time.",
            "title": "Batch Transaction Error"
        },
        "networkFees": "網絡費用",
        "nothingHasBeenTransferred": "沒有進行轉賬。",
        "retry": {
            "estimatedFees": "預估費用",
            "feeLimit": "費用限制",
            "link": "什麼是費用限制？",
            "networkFees": "網絡費用",
            "text": "默認的網絡費用不足以涵蓋交易成本。<br/><br/>你可以重新提交交易，費用限制將自動提高。",
            "title": "網絡費用不足"
        },
        "transactionCancelled": "交易取消",
        "transactionDetails": "交易細節",
        "transferring": "正在轉賬",
        "unexpectedStatus": "意外狀態",
        "unknownApp": "未知應用",
        "wasTransferredSuccessfully": "被成功轉賬"
    },
    "signInLedger": {
        "advanced": {
            "desc": "指定 HD 路徑來導入已鏈接的賬戶。",
            "exportDesc": "指定 HD 路徑來導出你的賬戶，你需要記住這個路徑。<br/><b>為額外的安全性，請為不同的賬戶使用不同的 HD 路徑。</b>",
            "setPath": "設置 HD 路徑",
            "subTitle": "HD 路徑",
            "title": "高級選項"
        },
        "firefoxBanner": {
            "desc": "以下浏览器不支持 Ledger Connect Firefox 版本 112+、Safari。 Chrome / Chromium 浏览器完全支持它。对于给您带来的不便，我们深表歉意。"
        },
        "header": "通過 Ledger 硬件登錄",
        "modal": {
            "accountsApproved": "賬戶已授權",
            "confirmPublicKey": "確認公鑰",
            "ledgerMustAdd": "Ledger 必須為每個賬戶添加訪問密鑰：",
            "status": {
                "confirm": "確認",
                "error": "錯誤",
                "pending": "請求",
                "rejected": "拒絕",
                "success": "授權",
                "waiting": "下一個"
            }
        },
        "one": "請確認硬件錢包已經安全連接，並且 NEAR 應用已經打開。"
    },
    "stagingBanner": {
        "desc": "註意：這是預發布版本 NEAR 錢包，可能有 Bugs，這些 Bugs 可能會導致丟失通證。繼續使用此版本則表示你理解並接受此風險，並知曉 NEAR 錢包團隊將無法幫助你。",
        "title": "註意：這是預發布版本錢包，風險自擔！"
    },
    "stakedBalance": "NEAR 被用於支持驗證節點並加強網絡。當你決定不質押 NEAR，則需要等待一些時間才能出現在「可用餘額」，NEAR 需要花 3 epochs（約 36 小時）贖回質押。",
    "staking": {
        "alertBanner": {
            "button": "查看當前驗證節點",
            "title": "你必須首先從驗證節點中贖回質押，並提現你的通證，才能選擇新的驗證節點。"
        },
        "amountStakingInput": {
            "placeholder": "例如：15",
            "title": "請輸入質押數量"
        },
        "balanceBanner": {
            "currency": "NEAR",
            "title": "可用鎖定餘額"
        },
        "balanceBox": {
            "available": {
                "button": "提現",
                "info": "這些通證已經贖回質押，並可以提現。",
                "title": "可提現"
            },
            "farm": {
                "button": "領取",
                "info": "該節點質押獎勵將以非 NEAR 通證給予。"
            },
            "farmed": {
                "info": "在質押期間賺得的通證。",
                "title": "可領取"
            },
            "pending": {
                "info": "這些通證並未質押，但還不可提現。通常在贖回質押後 52 - 65 小時變為可提現。",
                "title": "請求釋放"
            },
            "staked": {
                "button": "贖回質押",
                "info": "NEAR 通證目前已委托驗證節點質押。這些通證將累積獎勵。為使用這些通證，你需要首先贖回質押並提現。",
                "title": "質押總數"
            },
            "unclaimed": {
                "info": "已賺得的獎勵，但還未提現。NEAR 通證獎勵是<a target='_blank' href='https://www.investopedia.com/terms/c/compoundinterest.asp'>複利計算的</a>，並會自動質押。",
                "title": "未提現獎勵",
                "unavailable": {
                    "cta": "了解更多",
                    "modalDescOne": "當雙重認證開啟時，我們當前無法計算獎勵。我們預計會在將來支持此功能。",
                    "modalDescTwo": "此時，你可以在「總質押數」中看到你的獎勵累計，這個總數包括了獎勵，並會隨著質押而逐步增加。",
                    "modalTitle": "無法顯示質押獎勵的金額",
                    "title": "無法顯示獎勵金額。"
                }
            }
        },
        "claimSuccess": {
            "button": "返回概覽頁",
            "desc": "來自該節點的獎勵已經成功領取",
            "descTwo": "你的通證已經可被領取",
            "title": "成功！"
        },
        "noValidators": {
            "title": "你目前沒有委托任何驗證節點質押。"
        },
        "pageText": "通過命令行配置你的節點，並在這裏添加。",
        "pageTitle": "質押",
        "stake": {
            "accounts": "如果你擁有鎖定的 NEAR，你可以選擇質押鎖定 NEAR（lockup.near），或解鎖的 NEAR（你的賬戶名下）。你將看到指定賬戶名下的質押。如果你沒有鎖定的 NEAR，請忽略此區域。",
            "amount": "數量",
            "banner": {
                "insufficientBalance": "你將質押所有餘額，請至少預留 <b>${data} NEAR</b> 來保證交易費。",
                "stakeMax": "${data} NEAR 已經為交易費而預留。"
            },
            "button": "提交質押",
            "confirm": "你將質押",
            "desc": "請輸入計劃質押的 NEAR 通證數量。",
            "from": "來自",
            "input": {
                "availableBalance": "可用餘額：",
                "near": "NEAR"
            },
            "ledgerDisclaimer": "註意：如果你是首次委托該驗證節點質押，你需要確認兩筆交易。",
            "stakeWith": "委托質押節點",
            "title": "質押數量",
            "useMax": "全部",
            "with": "委托"
        },
        "stakeSuccess": {
            "button": "返回質押頁",
            "desc": "你的質押成功委托給已選擇的驗證節點：",
            "descTwo": "你可以在質押頁查看你的委托和質押獎勵。",
            "title": "成功！"
        },
        "staking": {
            "available": "可用餘額",
            "button": "質押 NEAR",
            "checkingValidator": "檢查驗證節點",
            "currentValidators": "當前驗證節點",
            "desc": "選擇驗證節點質押 NEAR 通證",
            "noValidators": "你還沒有委托驗證節點質押。",
            "selectAccount": "選擇質押賬戶",
            "title": "質押",
            "totalStaked": "總質押"
        },
        "unstake": {
            "beforeUnstakeDisclaimer": "贖回質押通證通常在贖回質押後 52 - 65 小時釋放。",
            "button": "贖回質押通證",
            "confirm": "你正在贖回質押",
            "desc": "請輸入計劃贖回質押的 NEAR 通證數量。",
            "input": {
                "availableBalance": "可贖回質押數量：",
                "near": "NEAR"
            },
            "stakeWith": "贖回質押以下節點的質押：",
            "title": "贖回質押通證"
        },
        "unstakeSuccess": {
            "button": "返回質押頁",
            "desc": "你質押的 <b>${amount}</b> 已經成功從驗證節點的質押池釋放：",
            "descTwo": "你的通證請求釋放將會在 52 - 65 小時釋放。",
            "title": "成功！"
        },
        "validator": {
            "activeStake": "活躍質押",
            "apy": "APY",
            "button": "委託節點質押",
            "claimFarmRewards": "你將領取",
            "claiming": "正在領取",
            "desc": "這是你將支付給驗證節點委託質押的費用，僅從你的獎勵中支付。",
            "errorClaimRewards": "領取質押獎勵時出現錯誤，請稍後重試",
            "fee": "質押費",
            "notWhitelistedTokenWarning": "該通證還未通過 Ref Finance 白名單，風險自負。",
            "notWhitelistedValidatorWarning": "該節點獎勵的通證還未通過 Ref Finance 白名單，風險自負。",
            "rewards": "獎勵",
            "title": "驗證節點：<br/> @${validator}",
            "unstake": "你正在贖回質押",
            "withdraw": "你正在提現",
            "withdrawalDisclaimer": "請求釋放的資金將會在 52 - 65 小時內釋放。"
        },
        "validatorBox": {
            "cta": "選擇",
            "farming": "挖礦",
            "fee": "費用",
            "near": "NEAR",
            "staking": "質押",
            "state": {
                "active": "活躍",
                "inactive": "非活躍"
            },
            "with": "委托給"
        },
        "validators": {
            "available": "可選驗證節點",
            "button": "查看驗證節點",
            "desc": {
                "account": "輸入並選擇一個驗證節點來質押 NEAR 通證。",
                "lockup": "請輸入一個已知節點的名稱或選擇節點開始質押。同時間，你只能質押到一個節點。",
                "unstake": "請選擇你希望贖回通證的驗證節點。",
                "withdraw": "請選擇你希望提現的驗證節點。"
            },
            "inputLabel": "輸入驗證節點賬戶 ID",
            "inputPlaceholder": "validator-name.near",
            "search": {
                "error": "無匹配驗證節點。",
                "success": "已找到驗證節點！"
            },
            "title": "選擇驗證節點"
        }
    },
    "stakingPoolUnstaked": "這部分 NEAR 目前在質押池中，但未贖回。可能仍在請求釋放的階段。",
    "success": "成功",
    "swap": {
        "confirm": "確認並兌換",
        "details": "交易細節",
        "fee": "估計費用",
        "from": "來自",
        "max": "最多：${amount} ${symbol}",
        "minReceived": "收到的最低數額",
        "price": "價格",
        "priceImpact": "兌換率影響",
        "review": "確認兌換",
        "reviewInfo": "你將兌換",
        "slippage": "滑动公差",
        "successTitle": "交易完成！</br>你兌換了 <span class='color-blue space'>${amountFrom}</span></br>至 <span class='color-green'>${amountTo}</span>",
        "title": "兌換",
        "to": "至",
        "translateIdInfoTooltip": {
            "fee": "處理此筆交易的預估費用。",
            "minimumReceived": "從此筆交易中你將最少收到的數額。",
            "priceImpact": "市場價格和預估價格之間的區別是由於交易規模。",
            "slippage": "交易失败前价格可能上涨的百分比."
        }
    },
    "swapping": "正在兌換",
    "sync": "刷新",
    "tokenBox": {
        "priceUnavailable": "無法獲取價格",
        "swapTo": "換成"
    },
    "topLevelAccounts": {
        "body": "賬戶名稱類似於域名，只有 ${data} 才能創建比如 yourname.${data} 的賬戶，並且只有 yourname.${data} 可以創建 app.yourname.${data}。所有在此錢包創建的賬戶都使用 .${data} 頂級賬戶（TLA）。了解更多關於賬戶名稱和創建你的頂級賬戶（TLA），請訪問 <a rel='noopener noreferrer' href='https://docs.nearprotocol.com/docs/concepts/account'>NEAR 文檔</a>。",
        "header": "頂級賬戶"
    },
    "total": "總共",
    "totalBalance": "所有餘額包括所有你擁有的 NEAR 通證。許多情況，你將無法立刻使用所有餘額（比如如果是鎖定的、委托的或質押的）。想了解你可以立刻使用、轉賬、委托或質押的 NEAR 通證，請查看可用餘額部分。",
    "transaction": {
        "status": {
            "checkingStatus": "檢查狀態...",
            "Failure": "失敗",
            "notAvailable": "狀態不可用",
            "NotStarted": "未開始",
            "Started": "已開始",
            "SuccessValue": "成功"
        }
    },
    "transfer": {
        "estimatedFees": "預估費用",
        "from": "來自",
        "to": "至"
    },
    "twoFactor": {
        "action": {
            "addKey": {
                "full": "警告：輸入驗證碼將授權該 NEAR 賬戶的完整權限：${accountId}。如果這不是你發起的操作，請忽略。<br /><br />只有當你為賬戶添加完全訪問密鑰時才需要操作。在其他情況下，此操作非常危險。<br /><br /> 你添加的公鑰：${publicKey} ",
                "limited": "添加密鑰 ${publicKey}，僅限於調用方法 ${methodNames}（來自合約 ${receiverId}），並且限制最多使用 ${allowance} NEAR 的 Gas 費"
            },
            "deleteKey": "刪除密鑰 ${publicKey}",
            "functionCall": "調用方法：${methodName}（來自合約 ${receiverId}），並附加 ${deposit} NEAR 和參數 <pre>${args}</pre>",
            "stake": "質押：${amount} NEAR 至節點 ${receiverId}",
            "transfer": "轉賬：${amount} NEAR 至賬戶 ${receiverId}"
        },
        "active": "已開啟",
        "alertBanner": {
            "button": "了解更多",
            "title": "開啟雙重認證後，<b>${data}</b> 將會被鎖定，以涵蓋存儲的消耗。"
        },
        "checkBox": "我已知曉：開啟雙重認證後，${amount} 將會被鎖定，以涵蓋合約的存儲。",
        "desc": "通過雙重認證保護你的賬戶，在授權交易時必須通過短信或郵箱確認。",
        "disable": {
            "desc": "禁用雙重認證後，交易將不再要求雙重認證確認。",
            "disable": "禁用雙重認證",
            "keep": "不，保留雙重認證",
            "phoneDesc": "雙重認證中的短信選項將被放棄，無法重新開啟。",
            "title": "是否確認禁用雙重認證？"
        },
        "email": "郵箱地址",
        "enable": "開啟雙重認證",
        "notEnabled": "雙重認證未開啟",
        "notEnoughBalance": "為開啟雙重認證，你的賬戶需要最少餘額 ${amount}",
        "phone": "手機號",
        "promptDesc": "我們強烈建議你開啟雙重認證的方式來增強你的賬戶和資產安全性。",
        "select": "選擇驗證方式",
        "since": "自",
        "subHeader": "Two factor authentication adds an extra layer of security to your account. <b>Passphrase and Ledger keys continue to allow full access to your account.</b>",
        "title": "雙重認證",
        "verify": {
            "desc": "一組 6 位驗證代碼已經發送至：",
            "didntReceive": "未收到驗證碼？",
            "inputLabel": "輸入你的 6 位驗證碼",
            "placeholder": "驗證碼",
            "resend": "重新發送",
            "resending": "發送中...",
            "resent": "驗證碼已發送！",
            "title": "輸入雙重認證驗證碼"
        }
    },
    "unlockedAvailTransfer": "這部分 NEAR 未鎖定，可以從你的鎖定賬戶中轉賬出來。",
    "unlockedBalance": "NEAR 仍在鎖定合約中，並可以取出。如果你選擇取出，它將出現於「可用餘額」。",
    "unvestedBalance": "未兌現的 NEAR 屬於你，但還未實際可使用。你可以委托或質押這些 NEAR，獎勵也將歸屬於你。當 NEAR 兌現後，將出現在鎖定的或未鎖定的餘額中。",
    "verifyAccount": {
        "desc": "為避免發送垃圾信息，我們需要驗證你是賬戶所有者，你可以通過一次性驗證碼或初始充值進行驗證。",
        "descNoFunding": "請選擇一種方式充值，從而初始化你的新賬戶。",
        "modal": {
            "email": {
                "desc": "你需要使用其他郵箱地址或選擇其他方式來驗證此賬戶，不過你可以使用此郵箱作為恢復方式。",
                "title": "此郵箱地址已被用於驗證其他賬戶"
            },
            "phone": {
                "desc": "你需要使用其他手機號碼或選擇其他方式來驗證此賬戶，不過你可以使用此號碼作為恢復方式。",
                "title": "此手機號碼已被用於驗證其他賬戶"
            }
        },
        "option": {
            "creditCard": {
                "desc": "通過 MoonPay 購買 NEAR 通證來激活新註冊賬戶。",
                "title": "通過信用卡充值"
            },
            "email": {
                "desc": "通過郵箱接收一次性驗證碼。",
                "title": "郵箱"
            },
            "existingAccount": {
                "desc": "從一個已有賬戶轉賬少量 NEAR 通證來激活新註冊賬戶。",
                "title": "通過已有賬戶充值"
            },
            "manualDeposit": {
                "desc": "通過單次充值地址進行充值",
                "title": "手動充值"
            },
            "phone": {
                "desc": "通過手機短信接收一次性驗證碼。",
                "title": "手機（暫不支持中國大陸地區）"
            }
        },
        "options": {
            "initialDeposit": "初始充值",
            "passCode": "一次性驗證碼"
        },
        "title": "只差一步！驗證你的新賬戶。",
        "titleNoFunding": "即將完成！請為新賬戶充值。"
    },
    "verifyWalletDomainBanner": {
        "title": "請確認你正在訪問"
    },
    "wallet": {
        "availableBalance": "可用餘額",
        "balance": "餘額",
        "balances": "餘額",
        "balanceTitle": "NEAR 餘額",
        "collectibles": "收藏品",
        "dateAndTime": "日期和時間",
        "depositNear": {
            "desc": "請充值 NEAR 通證後才能使用 NEAR 錢包。",
            "title": "充值 NEAR"
        },
        "status": "狀態",
        "tokenBalance": "通證餘額",
        "tokens": "通證",
        "totalBalance": "總餘額",
        "totalBalanceTitle": "總餘額",
        "yourPortfolio": "你的資產"
    },
    "walletErrorCodes": {
        "addAccessKey": {
            "error": "發生錯誤。<br />為訪問你的賬戶，請輸入上一步的助記詞。"
        },
        "addAccessKeySeedPhrase": {
            "errorSecond": "出現問題。<br />該助記詞無法恢復你的賬戶，請檢查後重試。"
        },
        "addAccessKeyZeroBalanceAccountSetup": {
            "error": "在完成導入時遇到錯誤。請在錢包中重新導入你的賬戶。"
        },
        "addLedgerAccountId": {
            "errorRpc": "恢復賬戶時發生錯誤。"
        },
        "connectLedger": {
            "noClient": "Ledger 設備還沒連接，請先連接 Ledger。"
        },
        "createNewAccount": {
            "accountCreated": {
                "error": "賬戶創建成功，但出現錯誤，請重新導入你的賬戶。"
            },
            "accountExists": {
                "error": "賬戶創建失敗，該賬戶已經存在。"
            },
            "error": "賬戶創建失敗，請重試。"
        },
        "emailProviderInvalid": "${domainName} 郵箱無法用於賬戶驗證。請嘗試其他域名的郵箱。",
        "getLedgerAccountIds": {
            "aborted": "操作被終止。",
            "noAccounts": "該 Ledger 設備沒有綁定任何賬戶，你需要先創建一個賬戶，並將此賬戶綁定至 Ledger 才能登錄。",
            "noAccountsAccepted": "無賬戶授權。",
            "U2FNotSupported": "需要瀏覽器支持 U2F，請使用 Chrome、Opera 或 Firefox 安裝 U2F 擴展。"
        },
        "initiateZeroBalanceAccount": {
            "error": "在初始化你的賬戶時出現錯誤。請重試。"
        },
        "invalidRecaptchaCode": "reCAPTCHA 驗證失敗，請重試。",
        "lockup": {
            "transferAllWithStakingPoolBalance": "為將你剩餘鎖定餘額轉賬至錢包，請從質押池贖回並提現所有質押資產。"
        },
        "promptTwoFactor": {
            "userCancelled": "此操作已被取消。"
        },
        "recoverAccountLink": {
            "error": "導入賬戶失敗，請重試或聯繫服務支持。"
        },
        "recoverAccountSeedPhrase": {
            "errorInvalidSeedPhrase": "助記詞無效",
            "errorNotAbleToImportAccount": "導入賬戶失敗。請重試，或聯繫客服獲得幫助。",
            "errorSeedPhraseNotValid": "助記詞無效，請檢查後重試。"
        },
        "recoveryMethods": {
            "lastMethod": "無法刪除最後一個恢復方式。除非你添加了 Ledger，你需要保留至少一種賬戶恢復方式。",
            "setupMethod": "發生錯誤。請檢查你的賬戶恢復方式。"
        },
        "refreshAccountOwner": {
            "error": "在讀取賬戶時出現錯誤。部分錢包數據可能不是最新，請刷新網站。"
        },
        "sendFungibleToken": {
            "error": "發生錯誤，發送交易取消。"
        },
        "setupRecoveryMessage": {
            "error": "設置恢復方式時出現錯誤，請重試。"
        },
        "setupRecoveryMessageNewAccount": {
            "invalidCode": "無效驗證碼"
        },
        "signAndSendTransactions": {
            "notEnoughTokens": "沒有足夠通證。"
        },
        "staking": {
            "noLockup": "賬戶無鎖定合約",
            "noWithdraw": "無法從驗證節點提現餘額",
            "unableToCheckFAK": "抱歉，我們無法檢查驗證節點是否擁有完全訪問密鑰。",
            "validatorHasFAK": "抱歉，出於安全考量，我們不支持你質押到擁有完全訪問密鑰的驗證節點。"
        }
    },
    "warning": "警告",
    "zeroBalance": {
        "addLedgerKey": {
            "error": {
                "header": "出現問題",
                "message": "我們無法為你的賬戶添加額外的 Ledger 密鑰。請重試或重新導入你的 Ledger 賬戶。"
            },
            "success": {
                "header": "Ledger 訪問密鑰成功添加！",
                "message": "新的 Ledger 密鑰已經導入至你的賬戶。你現在可以通過 Ledger 設備管理賬戶的恢復方式。"
            }
        },
        "addPhraseKey": {
            "error": {
                "header": "出現問題",
                "message": "我們無法為你的賬戶添加新的 Ledger 密鑰。請重試或重新導入你的 Ledger 賬戶。"
            },
            "success": {
                "header": "你的賬戶已成功導入！",
                "message": "現在你的賬戶已經激活，新的訪問密鑰也已可用於批准交易。"
            }
        },
        "ledgerModal": {
            "addLedgerKey": "添加 Ledger 密鑰",
            "confirmOnLedger": "請在 Ledger 設備上確認該操作。",
            "desc": "添加 Ledger 受限訪問密鑰至錢包來管理多個恢復方式。",
            "title": "你的賬戶已經激活！"
        }
    }
}
;
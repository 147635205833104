import React from 'react';

const IconLogout = (props) => (
    <svg
        width={25}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M9.5 21H5.5C4.96957 21 4.46086 20.7893 4.08579 20.4142C3.71071 20.0391 3.5 19.5304 3.5 19V5C3.5 4.46957 3.71071 3.96086 4.08579 3.58579C4.46086 3.21071 4.96957 3 5.5 3H9.5" stroke="#5CB4B9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.5 17L21.5 12L16.5 7" stroke="#5CB4B9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.5 12H9.5" stroke="#5CB4B9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
);

export default IconLogout;






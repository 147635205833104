import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import styled from 'styled-components';

import LandingBackground from './LandingBackground';
import {
    WEP_DISABLE_ACCOUNT_CREATION,
    WEP_PHASE_ONE,
} from '../../../../../features';
import ecoIcon from '../../images/ecosystem-icon.png';
import hardwareIcon from '../../images/hardware-icon.png';
import iPhoneMockup from '../../images/iphone-mockup.png';
import meLogo from '../../images/magic-eden-logo.png';
import nftIcon from '../../images/NFT-icon.png';
import custodyIcon from '../../images/non-custodial-icon.png';
import pollumLogo from '../../images/pollum-logo.png';
import privacyIcon from '../../images/privacy-icon.png';
import scaleIcon from '../../images/scalability-icon.png';
import solanaLogoW from '../../images/solana-white.svg';
import solanaLogo2 from '../../images/solanaLogo-2.svg';
import solscanLogo from '../../images/solscan-logo.png';
import superteamLogo from '../../images/superteam-logo.png';
import isMobile from '../../utils/isMobile';
import FormButton from '../common/FormButton';
import Container from '../common/styled/Container.css';
import { WalletSelectorGetAWallet } from '../common/wallet_selector/WalletSelectorGetAWallet';

const StyledContainer = styled.div`
    &&& {
        margin: 35px 0px 0 0px;
        position: relative;
        text-align: center;

        @media (max-width: 767px) {
            margin: 0;
            overflow: hidden;
            margin-top: -13px;
        }

        svg {
            opacity: 0.4;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            z-index: -1;

            @media (max-width: 992px) {
                top: -120px;
            }

            @media (max-width: 470px) {
                top: -86px;
                width: 900px;
                left: unset;
            }
        }

        .small-centered {
            padding-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        h1 {
            font-size: 70px;
            font-weight: 600;
        }

        .heading {
            font-size: 39px;
        }

        .partners-heading {
            font-size: 32px;
            padding-right: 20px;;
        }

        .privacy-security {
            font-size: 27px;
        }

        h3 {
            font-weight: 400 !important;
            line-height: 150%;

            span {
                span {
                    font-weight: 500;
                }
            }

            @media (max-width: 767px) {
                font-size: 16px !important;
            }
        }

        .paragraph-3 {
            width: 280px;
            max-width: none;
            color: #262626;
            font-size: 15px;
            line-height: 25px;
            text-align: center;
        }

        .buttons {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 1;
            width: 100%;
            
            .blue {
                font-weight: 500 !important;
                margin: 0;
                text-transform: none;

                :not(.link) {
                    min-width: 200px;
                    max-width: 220px;
                    height: auto;
                    text-transform: none;
                    padding: 12px 6px;
                }
            }

            .link {
                text-decoration: none;
                padding: 0;
                :hover {
                    background-color: transparent;
                    text-decoration: underline;
                }
            }

            span {
                margin: 20px;
            }

            @media (min-width: 768px) {
                flex-direction: row;
            }
        }

        .column-3 {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 0 10px;
        }

        .img-wrapper {
            min-height: 300px;

            @media (min-width: 768px) {
                min-height: 600px;
            }
        }

        img {
            margin-top: 65px;
            margin-bottom: 50px;
            width: 500px;
            height: auto;

            @media (min-width: 768px) {
                width: 675px;
                margin-bottom: 75px;
            }
        }

        .img-privacy {
            max-width: 100%;
            vertical-align: middle;
            display: inline-block;
            margin: 0px;
        }

        .email-subscribe {
            margin-top: -140px;
            margin-bottom: 50px;
            padding-top: 80px;

            @media (max-width: 767px) {
                margin-bottom: 0;
                margin-top: -100px;
            }
        }
       
        .section {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .divider {
            display: block;
            width: 100%;
            height: 1px;
            margin-bottom: 24px;
            background-color: #e9f1fc;
        }
    }
`;

const CardContainer = styled.div`
  display: flex;
  background-color: #f5f7f7;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 480px;
  width: 840px;
  align-content: center !important;
`;

const ImageSection = styled.div`
  flex: 1;
  background-image: url('../../images/NFT-icon.png');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextSection = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 60px;
  text-align: left;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, #9945FF, #14F195);
  opacity: 0.8 !important; 
  height: 800px;
  width: 100%;
  text-align: center;
`;

const Logo = styled.div`
  margin-bottom: 24px;
  margin-top: 24px;
`;

const Subtitle = styled.p`
  color: white;
  max-width: 500px;
  font-size: 1.25rem;
  margin-bottom: 32px;
`;

export function GuestLanding({ history }) {
    const [walletSelectorModal, setWalletSelectorModal] = useState();
    const [showModal, setShowModal] = useState();

    return (
        <StyledContainer>
            <WalletSelectorGetAWallet
                setWalletSelectorModal={(modal) =>
                    setWalletSelectorModal(modal)
                }
                setShowModal={(modal) => {
                    setShowModal(null);
                    if (modal === 'wallet-selector') {
                        walletSelectorModal.show();
                    }
                }}
                showModal={showModal}
            />
            <LandingBackground />
            <Container>
                <h1 ><Translate id='landing.title' /></h1>
                <h3><Translate id='landing.desc' /></h3>
                <div className='buttons' style={{ gap: '20px' }}>
                    <FormButton
                        onClick={() => {
                            if (WEP_DISABLE_ACCOUNT_CREATION) {
                                setShowModal('more-near-wallets');
                            } else {
                                history.push('/set-recovery-implicit-account');
                                // history.push('/setup-passphrase-new-account');
                            }
                        }}
                        trackingId="Click create account button"
                        data-test-id="landingPageCreateAccount"
                    >
                        <Translate id="button.createAccount" />
                    </FormButton>
                    <FormButton
                        data-test-id="homePageImportAccountButton"
                        linkTo="/recover-account"
                        className="seafoam-blue-white"
                        trackingId="Click import existing link"
                    >
                        {WEP_PHASE_ONE ? <Translate id="button.recoverExistingAccount" /> : <Translate id="button.importAccount" />}
                    </FormButton>
                </div>
                <div className='img-wrapper'><img src={iPhoneMockup} alt='Sign up' /></div>
                <h1 className='heading' style={{ marginInline: '100px' }}><Translate id='landing.discoverAllTheFeaturesOfSol' /></h1>
                {/* TODO: Add all texts on translate json */}
                <div className={'section'} style={{ paddingBottom: '61px', paddingTop: '61px' }}>
                    <CardContainer>
                        <ImageSection>
                            <img className='img-privacy' src={scaleIcon} alt='' />
                        </ImageSection>
                        <TextSection>
                            <h1 className='heading'>True Scalability</h1>
                            <h3>The fastest web3 onboarding experience around so you can leverage Solana in no time.</h3>
                            <FormButton
                                onClick={() => {
                                    window.open('https://solana.com/solutions', '_blank');
                                }}
                                trackingId="Where to buy NFT"
                                data-test-id="WheretobuyNFT"
                            >
                                Check out solutions
                            </FormButton>
                        </TextSection>
                    </CardContainer>
                </div>
                <div className={'section'} style={{ paddingBottom: '61px' }}>
                    <CardContainer style={{ backgroundColor: '#ecf4ff' }}>
                        <TextSection>
                            <h1 className='heading'>Next-gen Tokens</h1>
                            <h3>Trade, collect, stake, and evolve your tokenized experience across NFTs, SPL tokens and more!</h3>
                            <FormButton
                                onClick={() => {
                                    window.open('https://solana.com/ecosystem', '_blank');
                                }}
                                trackingId="How to stake SOL"
                                data-test-id="HowtoStakeSOL"
                            >
                                Explore
                            </FormButton>
                        </TextSection>
                        <ImageSection>
                            <img className='img-privacy' src={nftIcon} alt='' />
                        </ImageSection>
                    </CardContainer>
                </div>
                <div className={'section'} style={{ paddingBottom: '61px' }}>
                    <CardContainer style={{ backgroundColor: '#faecff' }}>
                        <ImageSection>
                            <img className='img-privacy' src={ecoIcon} alt='' />
                        </ImageSection>
                        <TextSection>
                            <div>
                                <h1 className='heading'>Explore dApps</h1>
                                <h3>Hundreds of projects are building on SOL. Choose the ones you like, connect your wallet and enjoy!</h3>
                            </div>
                            <FormButton
                                onClick={() => {
                                    //    TODO: add route link
                                }}
                                trackingId="Click create account button"
                                data-test-id="landingPageCreateAccount"
                            >
                                Coming soon...
                            </FormButton>
                        </TextSection>
                    </CardContainer>
                </div>
                <h1 className='heading' style={{ marginInline: '100px' }}>Private & Secure</h1>
                <div className={'section'} style={{ paddingBottom: '61px' }}>
                    <div style={{ display: 'flex', flexDirection: isMobile() ? 'column' : 'row' }}>
                        <div className='column-3'>
                            <div>
                                <img className='img-privacy' src={custodyIcon} alt='' style={{ width: '119px', height: '134px' }} />
                            </div>
                            <div>
                                <h1 className='privacy-security'>Non-Custodial</h1>
                                <p className='paragraph-3'>We never have access to any of your data or funds. Your funds are stored on your device.</p>
                            </div>

                        </div>
                        <div className='column-3'>
                            <div>
                                <img className='img-privacy' src={privacyIcon} alt='' style={{ width: '119px', height: '134px' }} />
                            </div>
                            <div>
                                <h1 className='privacy-security'>Privacy</h1>
                                <p className='paragraph-3'>We don't track any personal identifiable information, your account addresses, or asset balance.</p>
                            </div>

                        </div>
                        <div className='column-3'>
                            <div>
                                <img className='img-privacy' src={hardwareIcon} alt='' style={{ width: '119px', height: '134px' }} />
                            </div>
                            <div>
                                <h1 className='privacy-security'>Hardware Support</h1>
                                <p className='paragraph-3'>You can connect your hardware wallet for advanced security as well as get our DePin solution 🐸</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='divider' />
                <div className={'section'} style={{ paddingBottom: '61px' }}>
                    <div style={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%'
                    }}>
                        <h1 className='partners-heading'>
                            Partners
                        </h1>
                        <div style={{ display: 'flex', flexDirection: isMobile() ? 'column' : 'row', justifyContent: 'center', width: '100%', alignItems: 'center', gap: '12px' }}>
                            <div>
                                <img className='img-privacy' src={meLogo} alt='' style={{ height: '35px', maxWidth: '50px' }} />
                            </div>
                            <div>
                                <img className='img-privacy' src={solscanLogo} alt='' style={{ maxWidth: '190px', height: '30px' }} />
                            </div>
                            <div>
                                <img className='img-privacy' src={superteamLogo} alt='' style={{ maxWidth: '280px', height: '40px' }} />
                            </div>
                            <div>
                                <img className='img-privacy' src={solanaLogo2} alt='' style={{ maxWidth: '180px', height: '40px' }} />
                            </div>
                            <div>
                                <img className='img-privacy' src={pollumLogo} alt='' style={{ maxWidth: '180px', height: '40px' }} />
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
            <MainContainer>
                <Logo>
                    <div>
                        <img className='img-privacy' src={solanaLogoW} alt='' style={{ width: '200px' }} />
                    </div>
                </Logo>
                <h1 style={{ color: 'white' }}>Join the Solana Ecosystem...</h1>
                <Subtitle>and be part of the most engaged web3 community built around the fastest blockchain, no compromises</Subtitle>
                <div style={{ width: '200px', paddingBottom: '20px' }}>
                    <FormButton
                        onClick={() => {
                            //    TODO: add route link
                        }}
                        trackingId="Click create account button"
                        data-test-id="landingPageCreateAccount"
                    >
                        Create Account
                    </FormButton>
                </div>
            </MainContainer>
        </StyledContainer>
    );
}

import { parseUrl } from 'query-string';
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';

import { EXPLORER_URL_SOL } from '../../config';
import { showCustomAlert } from '../../redux/actions/status';
import { selectAccountId } from '../../redux/slices/account';
import { selectStatusLocalAlert } from '../../redux/slices/status';
import classNames from '../../utils/classNames';
import { reorganizeAndInsertInURL } from '../../utils/commom-utils';
import { wallet } from '../../utils/wallet';
import FormButton from '../common/FormButton';
import Container from '../common/styled/Container.css';


export const StyledContainer = styled(Container)`
    &&& {
        .header {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #272729;
            font-weight: 600;
            font-size: 20px;
            word-break: break-all;

            .back-arrow-button {
                position: absolute;
                left: 0;
            }
        }

        .payment-container {
            display: flex;
            max-width: 500px;
            alin-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 10px;
            padding: 20px;
            border: 1px solid #f5f5f5;
            > img {
                margin: 0 auto;
            }
        }

        .buttons-bottom-buttons {
            margin-top: 55px;

            > button {
                display: block;
                width: 100%;
            }

            .link {
                display: block;
                margin: 20px auto;
            }
        }


        .breakdown {
            display: flex;
            width: 100%;
            margin-top: 40px;
            padding: 15px;
            flex-direction: column;
            > div {
                display: flex;
                width: 100%;
                max-width: 400px;
                justify-content: space-between;
                gap: 10px;
                margin: 0 auto;
                font-weight: 600;
                :first-of-type {
                    border-bottom: 1px solid #f5f5f3;
                }
                > div {
                    display: flex;
                    align-items: center;
                    color: #292526;
                    font-size: 14px;
                    widht: 100%;
                    max-width: 400px;
                }
            }
        }

        h2 {
            color: #272729;
            font-size: 20px;
            margin-bottom: 20px;
            text-align: center;
            font-weight: 600;
        }

        .cancel-button {
            display: flex;
            margin: 20px auto ;
            width: 100%;
            justify-content: center;
            align-items: center;
        }

        @media (max-width: 500px) {
            .buttons-bottom {
                display: flex;
                flex-direction: column;
                min-height: calc(100vh - 160px);

                .buttons-bottom-buttons {
                    margin-top: auto;
                }
            }

            &.showing-banner {
                .buttons-bottom {
                    min-height: calc(100vh - 218px);
                }
            }
        }
    }
`;

export function PayContainer({ match, showCustomAlert }) {
    const paymentInfoURL = match.params.paymentInfoURL ? decodeURIComponent(match.params.paymentInfoURL) : '';
    const accountId = useSelector(selectAccountId);
    const [transactionHash, setTransactionHash] = useState(null);
    const [paymentInfo, setPaymentInfo] = useState(null);
    const [estimatedFee, setEstimatedFee] = useState(0);
    const [rawTransaction, setRawTransaction] = useState(null);
    const [isPaying, setIsPaying] = useState(false);
    const reference = parseUrl(paymentInfoURL).query?.reference ?? '';

    useEffect(() => {
        if (!accountId) {
            return;
        }

        fetchPaymentInfo(paymentInfoURL);

    }, [accountId]);

    async function fetchPaymentInfo(url) {
        try {
            const response = await fetch(url);
            const data = await response.json();

            console.log(data);
            setPaymentInfo(data);

            if (data) {
                const response = await fetch(url, {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify({ account: accountId }),
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                });

                const data = await response.json();

                // const rawTx = wallet.recoverTransaction(data.transaction);

                // const fee = await rawTx.getEstimatedFee(wallet.connection);

                // setEstimatedFee(fee / 1e9);

                setRawTransaction(data.transaction);
            }
        } catch (error) {
            console.log(error);
        }
    }


    async function signTransaction(rawTransaction) {
        try {
            setIsPaying(true);
            const hash = await wallet.signTransaction(rawTransaction);

            setTransactionHash(hash);
            setIsPaying(false);
        } catch (e) {
            setIsPaying(false);
            console.log(e);
            showCustomAlert({
                success: false,
                messageCodeHeader: 'error',
                // messageCode: 'walletErrorCodes.signAndSendTransactions',
                errorMessage: e.message
            });
        }
    }

    return (
        <StyledContainer className={classNames(['small-centered', { 'showing-banner': true }])}>
            {paymentInfo && (
                <div className='payment-container'>
                    <h2>{paymentInfo.label}</h2>
                    {paymentInfo.icon && <img src={paymentInfo.icon} alt={paymentInfo.label} width={120} />}
                    <div>
                        <div className='breakdown'>
                            <div>
                                <p>Reference: </p>
                                <div title={reference}>{reference.substring(0, 10) + '....' + reference.slice(-4)}</div>
                            </div>

                            <div>
                                <p>Estimated Fee: </p>
                                <div>{estimatedFee} SOL</div>
                            </div>
                        </div>

                        {!transactionHash ? (
                            <>
                                <FormButton onClick={() => signTransaction(rawTransaction)}
                                    disabled={isPaying}
                                    sendingString='Minting'
                                    color='green'
                                    id='confirm-button'
                                    trackingId='solana-pay-confirm'
                                >
                                    {isPaying ? 'Processing' : <Translate id='button.confirm' />}
                                </FormButton>

                                <FormButton className="cancel-button" linkTo='/' color='link red' id='close-button'>
                                    <Translate id='button.cancel' />
                                </FormButton>
                            </>
                        ) : (
                            <FormButton
                                color='gray-gray'
                                linkTo={reorganizeAndInsertInURL(EXPLORER_URL_SOL, `/tx/${transactionHash}`)}
                                id='view-on-explorer-button'
                            >
                                <Translate id='button.viewOnExplorer' />
                            </FormButton>
                        )}
                    </div>

                </div>
            )
            }
        </StyledContainer >
    );

}

const mapDispatchToProps = {
    showCustomAlert,
};

const mapStateToProps = (state, { match }) => ({
    // ...selectAccountSlice(state),
    // router: getRouter(state),
    localAlert: selectStatusLocalAlert(state),
});


export const PayContainerWrapper = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PayContainer));

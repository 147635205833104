import React from 'react';

const QRCodeIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 2H2V10H10V2Z" stroke="#5CB4B9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22 2H14V10H22V2Z" stroke="#5CB4B9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 14H2V22H10V14Z" stroke="#5CB4B9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 20V22H22M14 14V16H18.0714V14H22V18" stroke="#5CB4B9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <rect x="5" y="5" width="2" height="2" fill="#5CB4B9" />
            <rect x="5" y="17" width="2" height="2" fill="#5CB4B9" />
            <rect x="17" y="5" width="2" height="2" fill="#5CB4B9" />
        </svg>
    );
};

export default QRCodeIcon;

import styled from 'styled-components';

export const StyledContainer = styled.div`
    position: ${({ position }) => position};
    right: 0;
    left: 0;
    bottom: 0;
    padding: 10px 35px 10px 25px;
    background-color: #f8f8f8;
    font-size: 12px;
    color: #999999;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${({ pathname, isLoggedIn }) => (pathname === '/' && !isLoggedIn) ? '0' : '20px'};

    gap: 30px;

    .center {
        display: none;
        color: #24272a;
        width: 30%;

        @media (min-width: 992px) {
            display: block;
        }
    }

    .right {
        display: none;
        font-size: 18px;
        font-weight: 600;
        line-height: 130%;

        @media (min-width: 768px) {
            display: block;
        }
    }
`;

export const FooterLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
        flex-direction: row;
    }

    > div {
        text-align: center;

        @media (min-width: 768px) {
            text-align: left;
            margin: 0 0 0 20px;
        }
    }

    img {
        opacity: 0.3;
        width: 125px;
    }

    a {
        color: #999999;
        text-decoration: underline;
    }
`;


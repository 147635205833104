import React from 'react';
import { Translate } from 'react-localize-redux';
import styled from 'styled-components';

import FormButton from '../common/FormButton';
import PlusSignIcon from '../svg/PlusSignIcon';

const Button = styled(FormButton)`
    padding: 0;
    margin-top: 15px !important;

    svg {
        width: 24px !important;
        height: 24px !important;
        margin: -4px 3px -6px 0 !important;
    }
`;

const CreateAccountBtn = ({ onClick }) => (
    <Button onClick={onClick} trackingId='CA Click create new account button' color='gray-blue'>
        <PlusSignIcon color='#5CB4B9' />
        <Translate id='button.createNewAccount' />
    </Button>
);

export default CreateAccountBtn;

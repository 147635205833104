module.exports = {
    "account": {
        "activateAccount": {
            "post": {
                "descOne": "Ваш початковий депозит було отримано та переведено на ваш новий рахунок. Ваш обліковий запис тепер активний!",
                "descTwo": "Частина вашого депозиту була зарезервована для покриття <a href='https://docs.near.org/docs/concepts/account' rel='noopener noreferrer' target='_blank'>вартість розміщення вашого облікового запису</a>.",
                "title": "Ваш обліковий запис активовано."
            },
            "pre": {
                "desc": "Щоб почати користуватися гаманцем NEAR, внесіть мінімальний початковий депозит, відправивши <b>принаймні ${amount}</b> до логіну вашого облікового запису (іноді має назву 'Адреса').",
                "title": "Майже готово! Внесіть свій первинний депозит."
            }
        },
        "available": {
            "error": "Обліковий запис не знайдено!",
            "errorSameAccount": "Неможливо відправити самому собі!",
            "implicitAccount": "Завжди двічі перевіряйте ім'я користувача",
            "implicitAccountModal": "Будь ласка, перевірте двічі ім'я користувача, особливо якщо це біржовий обліковий запис. Якщо неправильно ввести ім'я користувача, то ви назавжди втратите свої кошти.",
            "success": "Дійсний обліковий запис"
        },
        "create": {
            "checkingAvailablity": {
                "check": "Триває перевірка",
                "create": "Перевіряємо доступність"
            },
            "errorAccountNotExist": "При створенні облікового запису виникла помилка. Будь ласка, спробуйте ще раз.",
            "errorInvalidAccountIdLength": "Ім'я користувача має містити від 2 до 64 символів"
        },
        "createImplicit": {
            "post": {
                "descOne": "Ваш первинний депозит було отримано! Після отримання заявки ваш депозит буде переведено на новий рахунок.",
                "descTwo": "Важливо: Ваша адреса для одноразового фінансування більше не буде дійсною. Обов'язково замініть його логіном вашого облікового запису на будь-яких біржах або інших пристроях, де він міг бути збережений.",
                "modal": {
                    "descOne": "Продовжуючи, ви підтверджуєте, що адреса одноразового фінансування буде замінена, і будь-які додаткові кошти, надіслані на стару адресу, будуть втрачені.",
                    "descTwo": "Не забудьте замінити його логіномом вашого облікового запису на будь-яких біржах або інших пристроях, де він міг бути збережений.",
                    "title": "Оновіть свою адресу"
                },
                "title": "Востребуйте свой аккаунт"
            },
            "pre": {
                "addressHeader": "Одноразова адреса для поповнення",
                "descOne": "Ініціалізуйте свій обліковий запис, відправивши хоча б <b>${amount}</b> на вказану нижче одноразову адресу для поповнення.",
                "descTwo": "Поверніться на екран після поповнення коштів, щоб завершити процес створення облікового запису.",
                "moonPay": {
                    "buyWith": "Купуйте з",
                    "desc": "Внесіть свій первинний депозит за допомогою MoonPay, використовуючи спосіб оплати, якому ви віддаєте перевагу.",
                    "title": "Сплатити за допомогою MoonPay"
                },
                "title": "Поповніть свій обліковий запис",
                "whereToBuy": {
                    "button": "Де можна купити NEAR?",
                    "desc": "NEAR токени доступні для купівлі на наступних біржах",
                    "title": "Покупка токенів NEAR"
                }
            },
            "success": {
                "button": "Перейти до облікового запису",
                "descOne": "Вітаємо, <b>${accountId}</b>! Ваш обліковий запис успішно створено.",
                "descThree": "Будь ласка, оновіть адресу на всіх біржах або інших пристроях.",
                "descTwo": "Ви повинні використовувати цей обліковий запис для всіх операцій з NEAR.",
                "modal": {
                    "desc": "Вітаємо! Ваш обліковий запис успішно створено.",
                    "title": "Ласкаво просимо до Genin Wallet!"
                },
                "title": "Ласкаво просимо до NEAR"
            }
        },
        "createImplicitAccount": {
            "createCustomNameModal": {
                "desc": "На відміну від вашої адреси за замовчуванням, адреса користувача може бути будь-якою, що полегшує її запам'ятовування та обмін інформацією!",
                "title": "Додайте адресу користувача в свій гаманець"
            },
            "customAddress": "Адреса користувача може бути будь-якою, що полегшує її запам'ятовування та обмін!",
            "customAmount": "Інша сума",
            "exchange": "Обмін",
            "orAskFriend": "або спитай у друга!",
            "orSendNear": "...або надішліть принаймні ${amount} NEAR на адресу вашого гаманця.",
            "sendFrom": "Надіслати з",
            "title": "Майже готово! Для початку купіть кілька монет за допомогою MoonPay.",
            "titleAlt": "Майже готово! Спочатку відправте хоча б ${amount} NEAR на адресу вашого гаманця."
        },
        "fundedStatus": {
            "active": "Активний",
            "awaitingDeposit": "Очікує депозит",
            "initialDeposit": "Початковий депозит",
            "minDeposit": "Мінімальний депозит",
            "nearName": "Обліковий запис",
            "ready": "Готово для отримання",
            "singleUse": "Одноразова адреса для поповнення",
            "status": "Статус "
        },
        "login": {
            "details": {
                "warning": "Це дозволить отримати доступ до вашого гаманця. Будь ласка, дійте з обережністю!"
            },
            "incorrectContractId": {
                "error": "Контракту ${contractId} не існує. Це може бути проблема з додатком, який ви намагаєтеся використати, або цей контракт, можливо, був вилучений. Ви будете перенаправлені назад до додатку.",
                "errorTitle": "Неправильний ID контракту"
            }
        },
        "nameDoesntMatch": "Ім'я облікового запису не співпадає",
        "recoverAccount": {
            "error": "Не вдалося відновити обліковий запис.",
            "errorInvalidSeedPhrase": "Облікові записи, що відповідають цій менімонійній фразі, не знайдено.",
            "success": "Відновлення за менімонічною фразою завершено.<br/>Ви успішно відновили <b>${numberOfAccounts} облковий запис(и)</b>.<br/>Останній з них вибрано як активний."
        },
        "requestCode": {
            "error": "Не вдалося надіслати SMS-повідомлення.",
            "success": "SMS-повідомлення успішно надіслано."
        },
        "sendMoney": {
            "error": "На жаль, сталася помилка. Спробуйте ще раз."
        },
        "sendNewRecoveryLink": {
            "error": "Помилка надсилання. Будь ласка, спробуйте ще раз.",
            "success": "Посилання для відновлення успішно надіслано!"
        },
        "verifySeedPhrase": {
            "error": "Ви ввели невірне слово!"
        }
    },
    "accountSelector": {
        "noAccountDesc": "Наразі ви не ввійшли в жодні альтернативні облікові записи.",
        "signInButton": "Імпортувати інший обліковий запис"
    },
    "actions": {
        "AddKey": {
            "forContract": "Ключ доступу для контракту: ${permissionReceiverId} додано",
            "forReceiver": "Новий ключ для ${receiverId} додано"
        },
        "CreateAccount": "Новий обліковий запис створено: ${receiverId}",
        "DeleteAccount": "Ім'я користувача видалено: ${receiverId}",
        "DeleteKey": "Ключ видалено",
        "DeployContract": "Контракт розміщенно: ${receiverId}",
        "FunctionCall": "Виклик методу: ${methodName} у контракті: ${receiverId} TODD",
        "Stake": "Стейкінг ${stake}",
        "Transfer": {
            "received": "Отримано ${deposit} від ${signerId}",
            "transferred": "Відправлено ${deposit} до ${receiverId}"
        }
    },
    "actionsSign": {
        "addKey": "Додавання ключа доступу",
        "createAccount": "Створення облікового запису: ${receiverId}",
        "deleteAccount": "Видалення облікового запису: ${receiverId}",
        "deleteKey": "Видалення ключа доступу",
        "deployContract": "Розміщення контракту: ${receiverId}",
        "functionCall": "Виклик функції: ${methodName}",
        "stake": "Стейкінг: ${stake}Ⓝ ${publicKey}...",
        "transfer": "Переказ: ${deposit}Ⓝ до ${receiverId}"
    },
    "addNode": {
        "desc": "Ваш вузол(node) має бути налаштований через CLI, перш ніж додавати його сюди.",
        "ipAddressInput": {
            "placeholder": "Приклад: 0.0.0.0",
            "title": "Введіть IP-адресу вашого вузла(node)"
        },
        "nicknameInput": {
            "placeholder": "Приклад: AWS",
            "title": "Придумайте ім'я (необов'язково)"
        },
        "pageText": "Налаштуйте вузол за допомогою CLI та додайте його тут",
        "pageTitle": "Додайте вузол(node)"
    },
    "amount": "сума",
    "arguments": "Параметри",
    "authorizedApps": {
        "dashboardNoApps": "Ви не авторизували жодний застосунок",
        "feeAllowance": "Оплата за зберігання",
        "ledger": "Ledger",
        "pageTitle": "Авторизовані додатки",
        "publicKey": "Публічний ключ"
    },
    "availableBalanceInfo": "Ваш баланс для витрат в Near, за винятком застейканих або розміщених коштів. <a href='/profile'>Перегляд балансу для витрат →</a>",
    "availableBalanceProfile": "Це ваш баланс для витрат в NEAR, кошти на ньому можуть бути негайно витрачені або використані для переказу.",
    "back": "Назад",
    "balance": {
        "balance": "Загальний баланс",
        "balanceLoading": "Завантаження балансу"
    },
    "balanceBreakdown": {
        "available": "Доступний баланс",
        "reserved": "Зарезервовано для комісій"
    },
    "button": {
        "acceptAndContinue": "Прийняти та продовжити",
        "addACustomAddress": "Додати іншу адресу",
        "addFunds": "Додати кошти",
        "addNode": "Додати вузол(node)",
        "allow": "Дозволити",
        "approve": "Схвалити",
        "authorize": "Авторизувати",
        "authorizing": "Авторизація",
        "browseApps": "Огляд застосунків",
        "buy": "Купити",
        "cancel": "Скасувати",
        "cancelOperation": "Скасувати операцію",
        "cancelTransfer": "Скасувати переказ",
        "claimAccount": "Запросити акаунт",
        "claimMyAccount": "Запросити мій акаунт",
        "close": "Закрити",
        "Close": "Закрити",
        "confirm": "Підтвердити",
        "confirmAndSend": "Підтвердити і відправити",
        "connect": "Підключити",
        "connecting": "Підключення",
        "connectLedger": "Підключити до Ledger",
        "continue": "Продовжити",
        "continueSetup": "Продовжити встановлення",
        "continueToMyAccount": "Продовижити до мого акаунту",
        "copy": "Зкопіювати",
        "copyImplicitAddress": "Зкопіювати адресу для поповнення",
        "copyPhrase": "Зкопіювати фразу",
        "copyUrl": "Зкопіювати URL посилання",
        "createAccount": "Створити акаунт",
        "createAccountCapital": "Створити акаунт",
        "createNewAccount": "Створити новий акаунт",
        "creatingAccount": "Створення акаунту",
        "deauthorize": "Скасувати авторизацію",
        "deAuthorizing": "Скасування авторизації",
        "deny": "Відхилити",
        "disable": "Відключити",
        "disabling": "Відключення",
        "dismiss": "Відхилити",
        "edit": "Редагувати",
        "enable": "Увімкнути",
        "enabling": "Увімкнення",
        "findMyAccount": "Знайти свій акаунт",
        "finish": "Завершити",
        "fundWith": "Поповнити через",
        "generateNew": "Створити новий",
        "getStarted": "Почати",
        "goBack": "Назад",
        "gotIt": "Зрозуміло",
        "goToDashboard": "Перейти до панелі керування",
        "importAccount": "Імпорт акаунту",
        "importExistingAccount": "Імпорт існуючого акаунту",
        "learnMore": "Докладніше",
        "learnMoreAboutNear": "Докладніше про NEAR",
        "loading": "Завантажити",
        "moreInformation": "Більше інформації",
        "needToEditGoBack": "Повернутись назад для редагування",
        "next": "Далі",
        "protectAccount": "Захистити акаунт",
        "receive": "Отримати",
        "recoverAccount": "Відновити акаунт",
        "recovering": "Відновлення",
        "recoverYourAccount": "Відновити свій акаунт",
        "removeNode": "Видалити вузол(node)",
        "removingKeys": "Видалення ключів",
        "reserveMyAccountId": "Зарезервувати ID акаунту",
        "resubmit": "Надіслати повторно",
        "retry": "Повторити",
        "returnToApp": "Повернутись до застосунку",
        "saveChanges": "Зберегти зміни",
        "secureMyAccount": "Захистити свій акаунт",
        "send": "Надіслати",
        "setupPhrase": "Встановити фразу відновлення",
        "signIn": "Увійти",
        "signingIn": "Входимо",
        "signInLedger": "Увійти за допомогою Ledger",
        "skip": "Пропустити",
        "skipThisForNow": "Поки що пропустити",
        "stake": "Застейкати",
        "staking": "Стейкінг",
        "startOver": "Почати спочатку",
        "subscribe": "Підписатися",
        "topUp": "Поповнити",
        "transferring": "Грошовий переказ",
        "tryAgain": "Спробувати ще раз",
        "unstaking": "Зняття зі стейкінгу",
        "useMax": "Максимальна кількість",
        "verify": "Перевірити та завершити",
        "verifyCode": "Верифікувати код",
        "verifyCodeEnable": "Верифікувати та продовжити",
        "verifying": "Верифікація",
        "viewAll": "Повний список",
        "viewFAQ": "Подивитись FAQ",
        "viewOnExplorer": "Подивитись через Explorer",
        "withdrawing": "Виведення грошей"
    },
    "buyNear": {
        "bridgeTokens": "Переказати токени з Ethereum",
        "buyWith": "Купити через",
        "coinMarketCap": "CoinMarketCap",
        "descMoonpay": "Купуйте NEAR через MoonPay, використовуючи бажаний спосіб оплати.",
        "descThree": "Перевести свої токени з Ethereum в Genin Wallet за допомогою моста.",
        "descTwo": "NEAR можна придбати через наступні біржі.",
        "descUtorg": "Купуйте NEAR через Utorg, використовуючи бажаний спосіб оплати.",
        "moonPay": "MoonPay",
        "notSupported": "Не підтримується у вашому регіоні",
        "seeMore": "Докладніше на",
        "supportedExchanges": "Підтримувані біржі",
        "title": "Поповнити",
        "utorg": "Utorg"
    },
    "confirmLedgerModal": {
        "header": {
            "confirm": "Будь ласка, підтвердіть операцію на вашому пристрої...",
            "processing": "Обробка",
            "success": "облікові записи успішно імпортовано з вашого пристрою Ledger.",
            "weFound": "Ми знайшли ${totalAccounts} облікових записів, захищених вашим пристроєм."
        },
        "one": "Ви повинні будете підтвердити дані входу на вашому пристрої Ledger.",
        "two": "Дотримуйтесь інструкцій на своєму пристрої, щоб підтвердити та надати доступ до кожного облікового запису, який потрібно імпортувати."
    },
    "connecting": "З'єднання",
    "connectLedger": {
        "connectLedger": "Підключіть Ledger",
        "ledgerConnected": "Ledger підключено",
        "modal": {
            "connect": {
                "header": "Підключіться до пристрою Ledger.",
                "one": "Переконайтеся, що ваш Ledger надійно підключений, а програма NEAR запущена на пристрої."
            },
            "disconnected": {
                "header": "Ваш пристрій було від'єднано.",
                "one": "Переконайтеся, що ваш Ledger надійно підключений, а програма NEAR запущена на пристрої."
            },
            "error": {
                "header": "Невдале підключення.",
                "one": "Переконайтеся, що ваш Ledger надійно підключений, а програма NEAR запущена на пристрої."
            }
        },
        "youMayNow": "Тепер ви можете виконувати операції за допомогою свого пристрою."
    },
    "copy": {
        "default": "Скопійовано!",
        "title": "Зкопіювати"
    },
    "createAccount": {
        "accountIdInput": {
            "placeholder": "yourname.${data}",
            "title": "Оберіть ім'я користувача"
        },
        "addACustomAddress": "Додати адресу користувача",
        "alreadyHaveAnAccount": "Вже є акаунт?",
        "invalidLinkDrop": {
            "one": "Посилання NEAR Drop вже було активовано раніше.",
            "title": "Некоректне посилання",
            "two": "Посилання NEAR Drop може бути використане для створення одного акаунту, після чого посилання стане недійсним."
        },
        "landing": {
            "descOne": "Genin Wallet — це безпечний гаманець і менеджер облікових записів для ваших облікових записів у блокчейні NEAR.",
            "descTwo": "Після створення облікового запису він знадобиться для взаємодії з додатками на NEAR і для безпечного зберігання різних токенів і предметів колекціонування (NFT).",
            "title": "Створити акаунт"
        },
        "note": {
            "canContain": "Ваше ім'я користувача може містити будь-яке з наступних значень:",
            "cannotContain": "Ваше ім'я користувача не може містити:",
            "characters": "Символи \"@\" або \".\"",
            "digits": "Цифри (0-9)",
            "lowercase": "Рядкові літери (a-z)",
            "maxCharacters": "Більше 64 символів (в т.ч .${accountSuffix})",
            "minCharacters": "Менше 2 символів",
            "separators": "Символи (_-) можуть бути використані як роздільники"
        },
        "pageText": "Введіть ваш ID облікового запису для використання акаунту NEAR. Ваш ID облікового запису використовуватиметься для всіх операцій NEAR, включаючи надсилання та отримання активів.",
        "pageTitle": "Зарезервуйте Account ID",
        "recoverItHere": "Імпортувати існуючий акаунт",
        "setupPassphrase": {
            "generatePassphrase": {
                "desc": "Для найкращої безпеки ми рекомендуємо генерувати унікальну мнемонічну фразу для кожного нового облікового запису у вашому гаманці.",
                "descTwo": "Як більш зручний варіант, ви можете натомість поділитися наявною мнемонічною фразою між кількома обліковими записами. Ви можете змінити це в будь-який час.",
                "tite": "Згенерувати нову мнемонічну фразу?"
            },
            "sharedPassphrase": {
                "desc": "Ваша мнемонічна фраза перевірена! Після того, як ви внесете кошти на свою нову адресу, ваша мнемонічна фраза буде поширене серед <b>${numberOfAccounts} акаунтів.</b>",
                "descTwo": "Щоразу, коли ця мнемонічна фраза використовуватиметься для відновлення вашого гаманця, імпортуватимуться наступні облікові записи:",
                "tite": "Ваша мнемонічна фраза"
            },
            "verifyPassphrase": {
                "desc": "Введіть наявну мнемонічну фразу нижче, щоб захистити свій новий акаунт.",
                "passPhrasePlaceholder": "bubble word sun join impact exist ramp skull title hollow symbol very",
                "tite": "Верифікуйте свою мнемонічну фразу",
                "yourPassphrase": "Ваша мнемонічна фраза"
            }
        },
        "step": "Шаг ${step}/${total}",
        "terms": {
            "agreeBtn": "Погодитись і продовжити",
            "declineBtn": "Відхилити",
            "desc": "Продовжуючи створювати акаунт NEAR, ви погоджуєтеся з Умовами використання та Політикою конфіденційності Genin Wallet. Ми закликаємо вас уважно її прочитати:",
            "privacyCheck": "Я погоджуюся з Політикою конфіденційності",
            "privacyLink": "Політика конфіденційності",
            "termsCheck": "Я погоджуюся з Умовами використання",
            "termsLink": "Умови використання",
            "title": "Умови використання"
        },
        "termsPage": {
            "descOne": "NEAR акаунт вимагає мінімального балансу <b>принаймні ${amount}</b> для <a href='https://docs.near.org/docs/concepts/account' rel='noopener noreferrer' target=' _blank'>займаного місця в мережі</a>",
            "descTwo": "Після резервування унікальної адреси облікового запису вам буде запропоновано внести невеликий початковий депозит для активації вашого облікового запису.",
            "disclaimer": "Створюючи акаунт NEAR, ви погоджуєтесь на використання гаманця NEAR <a href='/terms' rel='noopener noreferrer' target='_blank'>,умови обслуговування</a> та <a href='https://near.org /privacy' rel='noopener noreferrer' target='_blank'>політику конфіденційності</a>.",
            "title": "Перш ніж ви почнете..."
        }
    },
    "dashboard": {
        "activity": "Транзакції",
        "noActivity": "Для цього облікового запису не зафіксовано жодної активності."
    },
    "dashboardActivity": {
        "message": {
            "AddKey": {
                "forContract": "<span>для</span> <span>${permissionReceiverId}</span>",
                "forReceiver": "<span>для</span> <span>${receiverId}</span>"
            },
            "CreateAccount": "<span>${receiverId}</span>",
            "DeleteAccount": "<span>${receiverId}<span>",
            "DeleteKey": "",
            "DeployContract": "<span>${receiverId}</span>",
            "FunctionCall": "<span>${methodName}</span> у контракті <span>${receiverId}</span>",
            "Stake": "<span>${stake}</span>",
            "Transfer": {
                "received": "від <span>${signerId}</span>",
                "transferred": "до <span>${receiverId}</span>"
            }
        },
        "title": {
            "AddKey": {
                "forContract": "Ключ доступу додано",
                "forReceiver": "Ключ доступу додано"
            },
            "CreateAccount": "Створено новий обліковий запис",
            "DeleteAccount": "Обліковий запис видалено",
            "DeleteKey": "Ключ видалено",
            "DeployContract": "Контракт розміщено",
            "FunctionCall": "Метод визвано",
            "Stake": "Застейкано",
            "Transfer": {
                "received": "Отримано NEAR",
                "transferred": "Надіслано NEAR"
            }
        }
    },
    "disclaimer": {
        "text": "Це тестовий гаманець розробників. Він має використовуватись тільки для NEAR Protocol DevNet. Дізнайтесь більше на сайті ",
        "title": "ВІДМОВА ВІД ВІДПОВІДАЛЬНОСТІ"
    },
    "emailSubscribe": {
        "placeholder": "Ваша електронна адреса...",
        "title": "Підпишіться нижче для отримання раннього доступу"
    },
    "enterAccountNameLedgerModal": {
        "header": "Введіть ім'я акаунту",
        "one": "Введіть ім'я акаунту, в який ви здійснюєте вхід"
    },
    "error": "Помилка",
    "errors": {
        "ledger": {
            "U2F_4": "Помилка входу через пристрій Ledger: U2F DEVICE_INELIGIBLE. Будь ласка, переконайтеся, що ваш пристрій Ledger підключено та розблоковано.",
            "U2FNotSupported": "Для використання пристрою Ledger потрібна підтримка U2F у браузері. Будь ласка, використовуйте Chrome, Opera або Firefox із розширенням U2F. Також переконайтеся, що ви використовуєте HTTPS з'єднання"
        },
        "type": {
            "LackBalanceForState": "Ваш доступний баланс занадто малий для виконання будь-яких дій з вашим акаунтом. Надішліть NEAR на свій рахунок і спробуйте ще раз.",
            "RetriesExceeded": "Перевищено максимальну кількість спроб щодо цієї транзакції."
        }
    },
    "existingAccount": {
        "fundNewAccount": {
            "titleOne": "Схвалити транзакцію",
            "titleTwo": "Поповнити існуючий акаунт"
        },
        "selectAccount": {
            "desc": "Якщо у вас є альтернативний акаунт із балансом <b>принаймні ${amount} NEAR</b>, ви можете використовувати його для фінансування та ініціалізації свого нового акаунту!",
            "descTwo": "Виберіть або увійдіть в акаунт, який ви хочете використовувати, а потім продовжуйте схвалювати мінімальний депозит.",
            "title": "Поповнити за допомогою наявного рахунку."
        }
    },
    "exploreApps": {
        "desc": "Заробляйте, створюйте та грайте! Відкрийте для себе екосистему програм NEAR.",
        "exploreApps": "Перегляньте додатки",
        "exploreDeFi": "Перегляньте DeFi",
        "title": "Що вже є на NEAR?"
    },
    "footer": {
        "contactSupport": "Зверніться в службу підтримки",
        "copyrights": "Genin Saifu LLC. Всі права захищені.",
        "desc": "Solana - це атомарно компонований світовий комп'ютер, який без зусиль запускає вбивчі додатки зі швидкістю світла.",
        "learnMore": "Дізнатися більше",
        "needHelp": "Потрібна допомога?",
        "privacyPolicy": "Політика конфіденційності",
        "termsOfService": "Умови обслуговування"
    },
    "fullAccessKeys": {
        "authorizedTo": "АВТОРИЗОВАНО",
        "dashboardNoKeys": "У вас немає ключів повного доступу",
        "deAuthorizeConfirm": {
            "desc": "Ключ повного доступу буде назавжди деактивований.",
            "title": "Ви впевнені, що хочете деактивувати цей ключ повного доступу?"
        },
        "noKeys": "Ви не підключили свій NEAR гаманець до будь-яких програм. Коли ви це зробите, ви зможете керувати ними тут.<br /><br />Погляньте, що було збудовано за допомогою NEAR:",
        "pageTitle": "Ключі Повного Доступу",
        "submitAnyTransaction": "Надсилайте будь-які транзакції від вашого імені",
        "useContract": "Використовуйте <b>${receiverId}</b> контракт від вашого імені",
        "viewYourAccountName": "Перегляд імені вашого облікового запису"
    },
    "getBalance": "Подивитись баланс",
    "hardwareDevices": {
        "desc": "Підвищте безпеку вашого облікового запису за допомогою апаратних гаманців.",
        "disable": {
            "desc": "Переконайтеся, що у вас під'єднані інші методи відновлення, і лише потім від'єднайте наявний метод.",
            "disable": "Від'єднати Ledger",
            "keep": "Ні, залишити Ledger",
            "title": "Ви впевнені, що хочете від'єднати Ledger?"
        },
        "ledger": {
            "auth": "Авторизовано",
            "connect": "Ваш пристрій Ledger на даний момент не під'єднаний. Ми радимо перепід'єднати свій Ledger, щоб переконатися в захищеності облікового запису.",
            "disclaimer": "Перед тим як від'єднати пристрій Ledger, ви повинні під'єднати альтернативний метод відновлення.",
            "title": "Апаратний гаманець Ledger"
        },
        "title": "Апаратні пристрої"
    },
    "importAccount": {
        "noId": "Імпорт облікового запису",
        "noIdFailed": "Не вдалося імпортувати обліковий запис.",
        "withId": "Імпорт облікового запису: ${accountId}",
        "withIdFailed": "Не вдалося імпортувати обліковий запис: ${accountId}"
    },
    "importAccountWithLink": {
        "accountFound": "Знайдено 1 обліковий запис",
        "accountImported": "Обліковий запис імпортовано",
        "accountsFound": "Знайдено ${count} облікових записів",
        "alreadyImported": "Обліковий запис, захищений цим посиланням, імпортовано.",
        "continue": "і продовжуйте цей процес у вибраному браузері.",
        "copyUrl": "скопіюйте URL-адресу",
        "foundAccount": "Ми знайшли <b>1 обліковий запис</b>, захищений цим посиланням.",
        "foundAccounts": "Ми знайшли <b>${count} облікових записів</b>, захищених цим посиланням.",
        "goToAccount": "Перейдіть до облікового запису",
        "import": "Імпортувати",
        "importAccount": "Імпортувати свій обліковий запис",
        "preferedBrowser": "Якщо це не ваш браузер за замовчуванням,"
    },
    "importing": "Імпортування",
    "initialDeposit": {
        "claimAccount": {
            "desc": "Ваш депозит було отримано! Після отримання заявки ваш депозит буде переведено на ваш новий обліковий запис.",
            "disclaimer": {
                "checkBox": "Погоджуйтесь та продовжуйте",
                "desc": "Важливо: Стара адреса одноразового фінансування більше не буде дійсною. Обов'язково замініть її новою адресою на будь-яких біржах або інших пристроях, де вона могла бути збережена."
            },
            "title": "Запросіть свій аккаунт"
        },
        "creditCard": {
            "desc": "Завершіть покупку <b>принаймні ${amount} NEAR</b> через MoonPay. MoonPay автоматично переведе ваші кошти на ваш новий обліковий запис.",
            "title": "Поповніть свій новий обліковий запис за допомогою банківської картки."
        },
        "manualDeposit": {
            "desc": "Зберіть мінімальний початковий депозит, надіславши <b>принаймні ${amount} NEAR</b> на вказану нижче адресу одноразового фінансування.",
            "title": "Надішліть депозит вручну."
        }
    },
    "input": {
        "accountId": {
            "placeholder": "наприклад, iryna.near",
            "subLabel": "Ім'я облікового запису має містити ім'я облікового запису вищого рівня, наприклад, <b>irene.near</b> або містити рівно 64 символи.",
            "title": "Обліковий запис"
        },
        "enterWord": {
            "placeholder": "",
            "title": "Слово <b>#${wordId}</b>"
        }
    },
    "landing": {
        "banner": "Гаманець NEAR знаходиться на стадії Private Beta",
        "desc": "Безпечно зберігайте токени NEAR та інші активи, що підтримуються в <span>гаманці NEAR</span>.",
        "or": "або",
        "title": "NEAR поруч."
    },
    "ledgerSignTxModal": {
        "ADD_ACCESS_KEY": "Ви повинні будете підтвердити дані для входу на вашому пристрої Ledger.",
        "ADD_ACCESS_KEY_SEED_PHRASE": "Ви повинні будете підтвердити дані для повного доступу на вашому пристрої Ledger.",
        "ADD_LEDGER_ACCESS_KEY": "Ви повинні будете підтвердити дані публічного ключа на вашому пристрої Ledger.",
        "CREATE_ACCOUNT_FROM_IMPLICIT": "Ви повинні підтвердити створення облікового запису на вашому пристрої Ledger. Це включає транзакції, необхідні для переведення балансу з тимчасового облікового запису.",
        "CREATE_NEW_ACCOUNT": "Ви повинні підтвердити додавання ключа для нового облікового запису на вашому пристрої Ledger.",
        "DEFAULT": "Вам потрібно буде підтвердити деталі транзакції на вашому Ledger..",
        "DELETE_RECOVERY_METHOD": "Ви повинні будете підтвердити дані для видалення ключа на вашому пристрої Ledger.",
        "DISABLE_LEDGER": "Ви повинні будете підтвердити дані публічного ключа на вашому пристрої Ledger.",
        "GET_LEDGER_PUBLIC_KEY": "Ви повинні підтвердити доступ до публічного ключа на вашому пристрої Ledger.",
        "REMOVE_ACCESS_KEY": "Ви повинні будете підтвердити дані для видалення ключа на вашому пристрої Ledger.",
        "REMOVE_NON_LEDGER_ACCESS_KEYS": "Ви повинні будете підтвердити дані на вашому пристрої Ledger.",
        "SEND_MONEY": "Ви повинні будете підтвердити реквізити переказу на вашому пристрої Ledger.",
        "SET_SIGN_TRANSACTION_STATUS": "Ви повинні будете підтвердити дані поповнення на вашому пристрої Ledger.",
        "SETUP_RECOVERY_MESSAGE": "Ви повинні будете підтвердити дані повного доступу на вашому пристрої Ledger.",
        "SIGN_AND_SEND_TRANSACTIONS": "Ви повинні будете підтвердити дані поповнення на вашому пристрої Ledger.",
        "STAKE": "Ви повинні будете підтвердити стейкінг на вашому пристрої Ledger",
        "UNSTAKE": "Ви повинні будете підтвердити зняття зі стейкінгу на вашому пристрої Ledger",
        "WITHDRAW": "Ви повинні будете підтвердити виведення коштів на вашому пристрої Ledger"
    },
    "link": {
        "account": "Акаунт",
        "authorizedApps": "Авторизовані додатки",
        "buyUSN": "Buy $USN",
        "donateToUkraine": "Пожертвувати Україні",
        "fullAccessKeys": "Ключі повного доступу",
        "help": "Допомога",
        "noAccount": "У вас немає інших облікових записів",
        "receive": "Отримати",
        "send": "Відправити",
        "staking": "Стейкінг",
        "switchAccount": "Переключити обліковий запис",
        "wallet": "Гаманець"
    },
    "linkdropLanding": {
        "claiming": "Запрошування",
        "ctaAccount": "Запросити використовуючи існуючий акаунт",
        "ctaLogin": "Увійти та запросити",
        "ctaNew": "Запросити використовуючи новий акаунт",
        "desc": "Ви можете запросити ваш NEAR drop за допомогою існуючого акаунту (ви маєте бути залогінені), або створити новий акаунт щоб отримати його як початковий депозит.",
        "modal": {
            "desc": "Ваш NEAR drop був автоматично переведений на ваш акаунт.",
            "title": "Ваш NEAR drop був отриманий"
        },
        "or": "або",
        "title": "Ви отримали NEAR drop!"
    },
    "loading": "Завантаження...",
    "loadingNoDots": "Завантаження",
    "lockedBalance": "Ці NEAR заблоковані у lockup-контракті та не можуть бути виведені. Ви все ще можете делегувати або відправляти ці токени NEAR в стейкінг. Після того, як NEAR стануть розблокованими, ви можете побачити їх на своєму розблокованому балансі та вивести їх (що означатиме їх перехід на «Доступний баланс»).",
    "login": {
        "cliLoginSuccess": {
            "pageText": "Будь ласка, закрийте це вікно та дотримуйтесь інструкцій у терміналі.",
            "pageTitle": "Ви успішно авторизували NEAR Shell!"
        },
        "confirm": {
            "pageText": "Ви даєте згоду <b>на повний доступ</b> до ${appTitle}!",
            "pageTextSecondLine": "<b>Для підтвердження</b>, будь ласка, введіть своє ім'я акаунту нижче.",
            "pageTitle": "Ви впевнені?",
            "username": "ID акаунту"
        },
        "details": {
            "callFunctions": "Робити виклики функції у будь-якому смарт контракті",
            "createAndDeleteAccessKeys": "Створювати та видаляти ключі доступу",
            "createNewAccounts": "Створювати нові акаунти",
            "deploySmartContracts": "Розміщувати смарт контракти",
            "detailedDescription": "Детальний опис транзакції",
            "forContract": "Для контрактів",
            "function": "Функція",
            "noDescription": "Опис цього методу не вказано",
            "stakeAndUnstake": "Починати та зупиняти стейкінг NEAR токенів",
            "thisAllows": "Це надасть доступ ${appTitle} до:",
            "transferTokens": "Відправляйте токени зі свого облікового запису на інші облікові записи"
        },
        "form": {
            "accessYourAccount": "доступ до вашого акаунту.",
            "accountIdOnly": "запитує ідентифікатор вашого облікового запису.",
            "isRequestingFullAccess": "запитує <b>повний доступ</b>",
            "isRequestingTo": "запитує",
            "thisDoesNotAllow": "Це не дозволить програмі передавати токени NEAR.",
            "thisProvidesAccess": "Це забезпечить доступ <b>до всіх ваших токенів</b>.<br />Будьте обережні!",
            "toYourAccount": "до вашого акаунту."
        },
        "v2": {
            "connectConfirm": {
                "desc": "Підключайтеся лише до сайтів, яким ви довіряєте. Сайти, до яких ви підключалися, <a href='${contractIdUrl}' target='_blank' rel='noreferrer'>${appReferrer}</a> матимуть <b>${accessType}</b>:",
                "feeAllowance": {
                    "desc": "Додатку буде дозволено витратити до <b>${amount} NEAR</b> за рахунок зборів, понесених під час використання.",
                    "title": "Надбавка за мережну плату"
                },
                "fullAccess": "Повний доступ",
                "fullAccessModal": {
                    "desc": "Дійте з обережністю. Дозволи на повний доступ дозволяють контрактам та програмам отримувати доступ до вашого балансу токенів. Щоб підтвердити, введіть свій логін акаунту нижче.",
                    "title": "Надати повний доступ до акаунту?"
                },
                "fullAccessWarning": "Цей сайт вимагає повного доступу до вашого облікового запису. Щоб увімкнути повний доступ, вам буде запропоновано підтвердити логін вашого акаунту.",
                "limitedAccess": "Обмежений доступ",
                "permissions": {
                    "callMethodsOnContract": "Викликати методи смарт-контракту від імені дозволеного облікового запису",
                    "notTransferTokens": "Це не дозволить програмі передавати токени",
                    "transferTokens": "Переказ токенів з вашого акаунту",
                    "viewAddress": "Перегляд адресу дозволеного акаунту",
                    "viewBalance": "Перегляд балансу дозволеного  акаунту"
                },
                "title": "Підключення до:"
            },
            "connectWithNear": {
                "desc": "Програма запитує <b>${accessType}</b> до вашого акаунту Near. Виберіть акаунт, який потрібно підключити.",
                "fullAccess": "повний доступ",
                "limitedAccess": "обмежений доступ",
                "title": "Під'єднуйтесь з NEAR"
            }
        }
    },
    "minimumBalance": "Мінімальний баланс NEAR, який повинен зберігатися на обліковому записі для того, щоб він залишався активним. Цей баланс являє собою витрати на підтримку сховища для вашого облікового запису в блокчейні NEAR (з невеликим запасом), і може зростати або падати в залежності від того, чи потрібно для облікового запису більше або менше місця..",
    "mobileDeviceAccess": {
        "importCode": {
            "desc": "Якщо ви вже увійшли до системи на настільному пристрої, відскануйте код мобільного доступу, щоб імпортувати свій обліковий запис."
        },
        "showCode": {
            "button": "Перегляд коду мобільного телефону",
            "desc": "Відскануйте свій код доступу до мобільного телефону, щоб надати повний доступ до акаунту своєму мобільному пристрою",
            "modal": {
                "desc": "Відкрийте та відскануйте QR-код нижче, щоб імпортувати свій обліковий запис на свій мобільний пристрій.",
                "disclaimer": "<b>Цей код містить конфіденційні дані та надає повний доступ до облікового запису вашого мобільного пристрою.</b> Будьте обережні, не діліться і не розкривайте його, імпортуйте свій обліковий запис тільки на захищений пристрій, яким ви володієте.",
                "reveal": "Розкрити",
                "title": "Код мобільного доступу"
            }
        },
        "title": "Доступ до мобільного пристрою"
    },
    "networkBanner": {
        "desc": "Цей гаманець (і мережа) доступні лише для тестування. Токени та інші активи не мають цінності. Створені облікові записи не будуть перенесені до Mainnet..",
        "header": "Тестовий гаманець NEAR",
        "title": "Тестовий гаманець"
    },
    "NFTDetail": {
        "owner": "Власник",
        "transfer": "Переказ"
    },
    "NFTs": {
        "emptyState": "Схоже, у вас ще немає предметів колекціонування.",
        "loadMore": "Завантажити ще"
    },
    "NFTTransfer": {
        "cancel": "Скасувати",
        "confirm": "Підтвердити",
        "continue": "Продовжити",
        "enterReceiver": "Введіть адресу одержувача, а потім перейдіть до підтвердження транзакції.",
        "next": "Далі",
        "transactionComplete": "Транзакція завершена!",
        "transferNft": "Переказ NFT",
        "viewTransaction": "Переглянути квитанцію",
        "youSent": "Ви надіслали <span class='color-blue'>${title}</span> на акаунт <span class='color-green'>${receiverId}</span>"
    },
    "nodeDetails": {
        "pageText": "Оновити відомості про ваш вузол (node).",
        "pageTitle": "Деталі вузла (node)"
    },
    "nodeStaking": {
        "nodes": {
            "noNode": {
                "text": "Це з'єднає ваш вузол(node) з вашим обліковим записом гаманця.",
                "title": "У вас вже є налаштований вузол(node)? Додайте його сюди."
            },
            "title": "Вузли(Nodes)"
        },
        "pageText": "nodeandstaking.near",
        "pageTitle": "Вузол(node) та Стейкінг",
        "staking": {
            "title": "Стейкінг",
            "youAreStaking": "Ви у процесі стейкінгу"
        },
        "steps": {
            "one": {
                "desc": "Інструкції доступні у документації",
                "title": "1. Встановіть та запустіть вузол(node) через CLI"
            },
            "three": {
                "desc": "Стейкінг звідси чи через CLI. Ви можете звернутися до документації.",
                "title": "3. Почати стейкінг"
            },
            "title": "Щоб запустити вузол або стейкінг, виконайте наступні дії::",
            "two": {
                "desc": "Несинхронізований вузол не може виробляти чи валідувати блоки",
                "title": "2. Дочекайтесь синхронізації вузла(node)"
            }
        }
    },
    "of": "",
    "ofTotal": "загального",
    "or": "або",
    "pageNotFound": {
        "displayTitle": "404",
        "returnToWallet": "Повернутися до гаманеця",
        "title": "Упс, схоже, ця сторінка відсутня."
    },
    "profile": {
        "account": {
            "available": "Доступний баланс",
            "availableToTransfer": "Доступно для переказу",
            "availableToWithdraw": "Доступно для зняття",
            "inStakingPools": "У стейкінг-пулах",
            "pendingRelease": "В очікуванні виходу зі стейкінгу",
            "reservedForStorage": "Зарезервовано під оплату сховища",
            "reservedForTransactions": "Зарезервовано для транзакцій",
            "staked": "У стейкінгу",
            "staking": "Стейкінг",
            "unstaked": "Знято зі стейкінгу",
            "walletBalance": "Баланс гаманця",
            "walletId": "ID гаманця"
        },
        "authorizedApps": {
            "title": "Авторизовані додатки"
        },
        "details": {
            "availableBalance": "Доступний баланс",
            "locked": "Заблоковано",
            "lockPopup": {
                "createAnotherAccount": "створити інший акаунт",
                "text": "Ваше ім'я користувача є вашим унікальним логіномом у систему і не може бути змінено, оскільки до нього прив'язані дані та право власності на активи. Однак ви можете ${link} бажане ім'я і надсилати активи за необхідності.",
                "title": "Чому це заблоковано?"
            },
            "minBalance": "Мінімальний баланс",
            "profile": "ПРОФІЛЬ",
            "public": "Публічний",
            "qrDesc": "Використовуйте камеру вашого телефону для надсилання на цю адресу",
            "staked": "У стейкінгу",
            "totalBalance": "Загальний баланс",
            "unlocked": "Розблоковано",
            "unvested": "Зарезервовані токени",
            "username": "ID акаунту",
            "visibleTo": "ВИДИМИЙ"
        },
        "lockup": {
            "locked": "Заблоковано",
            "lockupBalance": "Баланс Lockup-контракту",
            "lockupId": "ID Lockup-контракту",
            "unlocked": "Розблоковано"
        },
        "lockupBanner": {
            "cta": "Переказати до гаманця",
            "title": "<b>${amount}</b> доступні для виведення з вашого lockup-контракту!"
        },
        "pageTitle": {
            "default": "Ім'я користувача",
            "loading": "Завантаження...",
            "notFound": "Ім'я користувача ${accountId} не знайдено"
        },
        "security": {
            "lessSecure": "Менш безпечні",
            "lessSecureDesc": "Ці варіанти рекомендуються для облікових записів із невеликими сумами NEAR та інших активів. Вони зручні, але становлять додатковий ризик у порівнянні з більш безпечними варіантами. Якщо вашу адресу електронної пошти або SMS-повідомлення буде скомпрометовано, ваші кошти можуть опинитися під загрозою.",
            "mostSecure": "Найбільш безпечні (рекомендовано)",
            "mostSecureDesc": "Ledger – це найбезпечніший варіант. Мнемонічна фраза залишається у безпеці у разі правильного використання (має бути записана та збережена у надійному місці).",
            "title": "Безпека та відновлення"
        },
        "twoFactor": "Двофакторна аутентифікація",
        "twoFactorDesc": "Аутентифікація через SMS або електронну пошту при авторизації транзакцій та/або вході до свого облікового запису."
    },
    "reCAPTCHA": {
        "disclaimer": "Цей сайт захищений reCAPTCHA, політикою конфіденційності Google <a href='https://policies.google.com/privacy' target='_blank' rel='noopener noreferrer'></a>. <a href='https:/ /policies.google.com/terms' target='_blank' rel='noopener noreferrer'>умови обслуговування</a>.",
        "fail": {
            "desc": "reCAPTCHA потрібна для авторизації нових облікових записів, що поповнюються NEAR.",
            "link": "Поповнити свій власний обліковий запис?",
            "title": "Не вдалося завантажити reCAPTCHA."
        },
        "loading": "Завантаження reCAPTCHA..."
    },
    "receivePage": {
        "addressTitle": "Адреса вашого гаманця",
        "copyAddressLinkLong": "Зкопіювати посилання",
        "copyAddressLinkShort": "Копіювати",
        "qrCodeTitle": "Сканувати QR код",
        "snackbarCopySuccess": "Посилання скопійоване"
    },
    "recoverAccount": {
        "actionRequired": "Це повідомлення містить чарівне посилання. Перейдіть за посиланням, щоб розпочати процес відновлення!",
        "actionType": "Відновлення",
        "cannotResend": "Це повідомлення було надіслано вам під час увімкнення методу відновлення електронною поштою/SMS. Ми надсилаємо це повідомлення лише один раз і не зможемо переслати його ще раз.",
        "email": {
            "desc": "Перевірте свою електронну пошту від near.org з темою листа:",
            "subject": "\"Важливо: Відновлення гаманця Near\".",
            "title": "За допомогою електронної пошти"
        },
        "ledger": {
            "desc": "Переконайтеся, що Ledger надійно підключений, а програма NEAR запущена на пристрої.",
            "title": "За допомогою Ledger"
        },
        "pageText": "Якщо ви налаштували один або кілька методів відновлення облікового запису, виконайте наведені нижче інструкції, щоб імпортувати свій обліковий запис.",
        "pageTitle": "Відновити обліковий запис",
        "phone": {
            "desc": "Перевірте ваш мобільний телефон на наявність SMS-повідомлень від",
            "number": "+14086179592.",
            "title": "За допомогою мобільного телефону"
        },
        "phrase": {
            "desc": "Переконайтеся, що у вас є менімонічна фраза відновлення з 12 слів, а потім натисніть нижче, щоб почати процес відновлення.",
            "title": "За допомогою мнемонічної фрази"
        }
    },
    "recoverSeedPhrase": {
        "pageText": "Введіть менімонічну фразу резервної копії, пов'язану з обліковим записом.",
        "pageTitle": "Відновлення за допомогою мнемонічної фрази",
        "seedPhraseInput": {
            "placeholder": "correct horse battery staple...",
            "title": "Мнемонічна фраза (12 слів)"
        }
    },
    "recoverWithLink": {
        "errorP": "Будь ласка, перевірте свою електронну пошту або телефон на наявність останнього повідомлення. Посилання у всіх попередніх повідомленнях є недійсними.",
        "errorTitle": "Термін дії посилання закінчився",
        "pOne": "Натисніть кнопку \"Продовжити\" щоб відновити обліковий запис:",
        "pTwo": "Якщо це не ваш браузер за замовчуванням, скопіюйте URL-адресу та завершіть процес відновлення у зручному для вас браузері.",
        "snackbarCopySuccess": "Посилання для відновлення скопійоване",
        "title": "Відновлення облікового запису"
    },
    "recoveryMgmt": {
        "disableInputPlaceholder": "Введіть своє ім'я користувача для підтвердження",
        "disableNo": "Ні, продовжити",
        "disableNotAllowed": "Облікові записи вимагають наявності хоча б одного активного способу відновлення. Будь ласка, увімкніть альтернативний спосіб відновлення.",
        "disableTextLink": "Отримане вами чарівне посилання буде назавжди деактивовано.",
        "disableTextPhrase": "Ваша поточна мнемонічна фраза буде назавжди деактивована.",
        "disableTitle": "Ви впевнені, що бажаєте відключити цей метод?",
        "enabled": "Підключено",
        "methodTitle": {
            "email": "Електронна адреса",
            "phone": "Телефонний номер",
            "phrase": "Мнемонічна фраза"
        },
        "noRecoveryMethod": "У вас немає доступних методів відновлення облікового запису, будь ласка, додайте один з методів нижче.",
        "recoveryLinkSent": "Посилання на відновлення надіслано!",
        "resend": {
            "email": "Надіслати електронний лист повторно",
            "phone": "Надіслати SMS повторно"
        },
        "title": "Методи відновлення"
    },
    "reduxActions": {
        "ADD_ACCESS_KEY": {
            "error": "Під час підтвердження цієї дії сталася помилка. Будь ласка, спробуйте ще раз!",
            "success": "${title} тепер має право використовувати ваш обліковий запис."
        },
        "ADD_ACCESS_KEY_SEED_PHRASE": {
            "error": "Під час налаштування менімонічної фрази сталася помилка. Будь ласка, спробуйте ще раз!",
            "success": "Налаштування менімонічної фрази відновлення успішно завершено."
        },
        "ADD_LEDGER_ACCOUNT_ID": {
            "error": "У доступі до одного з облікових записів було відмовлено. Облікові записи не були відновлені.<br>Для продовження ви повинні авторизувати доступ для читання до кожного облікового запису на своєму пристрої Ledger. Будь ласка, спробуйте ще раз.",
            "success": ""
        },
        "CHECK_ACCOUNT_AVAILABLE": {
            "error": "Користувач не знайдений.",
            "success": "Користувач знайдений."
        },
        "CHECK_IS_NEW": {
            "error": "Ім'я користувача зайняте. Спробуйте інше.",
            "success": "Отлично! Імя користувача ${accountId} доступно."
        },
        "CHECK_NEW_ACCOUNT": {
            "error": "Ім'я користувача зайняте. Спробуйте інше.",
            "success": "Отлично! Імя користувача ${accountId} доступно."
        },
        "CREATE_ACCOUNT_WITH_SEED_PHRASE": {
            "error": "",
            "success": ""
        },
        "CREATE_NEW_ACCOUNT": {
            "error": "Ім'я користувача зайняте. Спробуйте інше.",
            "success": "Отлично! Імя користувача ${accountId} доступно."
        },
        "default": {
            "error": "Вибачте, сталася помилка. Будь ласка, спробуйте ще раз.",
            "success": ""
        },
        "DELETE_RECOVERY_METHOD": {
            "error": "",
            "success": ""
        },
        "DEPLOY_MULTISIG": {
            "error": "",
            "success": ""
        },
        "Deserialization": "",
        "GET_LEDGER_ACCOUNT_IDS": {
            "error": "Публічний ключ було відхилено. Облікові записи не були відновлені.<br/>Для продовження ви повинні дати дозвіл NEAR гаманцю на перегляд вашого публічного ключа. Будь ласка, спробуйте ще раз.",
            "success": ""
        },
        "LackBalanceForState": "Ваш доступний баланс замалий для виконання будь-яких дій у вашому обліковому записі. Будь ласка, поповніть ваш NEAR акаунт, а потім повторіть спробу.",
        "NotEnoughBalance": "На вашому рахунку недостатньо балансу для цієї операції.",
        "PROMPT_TWO_FACTOR": {
            "invalidCode": "Неправильний код 2FA. Будь ласка, спробуйте ще раз."
        },
        "RECOVER_ACCOUNT_SEED_PHRASE": {
            "error": "Не вдалося відновити обліковий запис. За цією мнемонічною фразою не знайдено жодних облікових записів.",
            "success": "Відновлення за менімонічною фразою завершено.<br/>Ви успішно відновили <b>${numberOfAccounts} обліковий(і) запис(и)</b>.<br/>Останній з них вибрано як активний."
        },
        "REFRESH_ACCOUNT_EXTERNAL": {
            "error": "Обліковий запис <b>${accountId}</b> не знайдено"
        },
        "REMOVE_ACCESS_KEY": {
            "error": "Будь ласка, перевірте підключення пристрою та переконайтеся, що там відкрито програму NEAR.",
            "success": ""
        },
        "RetriesExceeded": "Перевищено максимальну кількість спроб щодо цієї транзакції. Будь ласка, спробуйте ще раз.",
        "SAVE_AND_SELECT_LEDGER_ACCOUNTS": {
            "error": "Будь ласка, перевірте підключення пристрою та переконайтеся, що там відкрито програму NEAR.",
            "success": "Ви успішно авторизувалися.<br/>Ви успішно відновили <b>${numberOfAccounts} обліковий(і) запис(и).</b> Останній з них вибрано як активний."
        },
        "SEND_MONEY": {
            "error": "На жаль, сталася помилка. Спробуйте ще раз."
        },
        "SETUP_RECOVERY_MESSAGE": {
            "error": "Під час встановлення методу відновлення виникла помилка. Будь ласка, спробуйте ще раз!",
            "success": "Встановлення методу відновлення завершено."
        },
        "SIGN_AND_SEND_TRANSACTIONS": {
            "error": "",
            "success": ""
        },
        "TRANSFER_ALL_FROM_LOCKUP": {
            "error": "Щось пішло не так. Будь ласка, спробуйте ще раз.",
            "success": "Ви успішно переказали токени з вашого lockup-контракту!!"
        },
        "U2F_4": "Помилка входу через пристрій Ledger: U2F DEVICE_INELIGIBLE. Будь ласка, переконайтеся, що ваш пристрій Ledger підключено та розблоковано.",
        "VERIFY_TWO_FACTOR": {
            "error": "Двофакторна аутентифікація не була встановлена. Будь ласка, спробуйте ще раз.",
            "success": "Двофакторна аутентифікація була успішно увімкнена!"
        }
    },
    "releaseNotesModal": {
        "desc": "Ви просили, ми дослухались! Останнє оновлення вносить корективи в те, як  відображаються баланси в гаманці, а також дозволяє простіше створювати нові облікові записи.",
        "subText1": "Під час реєстрації ви можете використовувати номер телефону з підтримкою SMS для підтвердження та ініціалізації вашого нового облікового запису. Користувачі, які не бажають надавати цю інформацію, все ще зможуть ініціалізувати нові облікові записи за допомогою початкового депозиту.",
        "subText2": "Основний баланс, який відображається в гаманці, тепер є вашим 'Доступним балансом', де раніше він був вашим 'Загальним балансом'. Доступний баланс є найбільш корисним для користувачів, які хочуть відразу дізнатися, що вони можуть витрачати/стейкати/і використовувати для взаємодії з екосистемою; він не включає токени, які неможливо витратити, наприклад, застейкані токени. Ви все одно можете переглядати інформацію про баланс ваших застейканих токенів на екранах 'Обліковий запис' і Стейкінг.",
        "subTitle1": "Нові можливості",
        "subTitle2": "Покращення",
        "title": "Release Notes"
    },
    "removeAccount": {
        "button": "Видалити обліковий запис з гаманця",
        "desc": "Вам знадобиться ваш метод відновлення, щоб повторно імпортувати цей обліковий запис у майбутньому. Переконайтеся, що у вас все ще є доступ до нього.",
        "disclaimer": "У мене є доступ до методу відновлення для <b>${accountId}</b>",
        "title": "Видалити обліковий запис?"
    },
    "reservedForFeesInfo": "До ${data} NEAR зарезервовано для покриття витрат на транзакції.",
    "selectAccountDropdown": {
        "account": "Обліковий запис",
        "createAccount": "Створити новий обліковий запис",
        "noOtherAccounts": "У вас немає інших облікових записів",
        "selectAccount": "Вибрати обліковий запис",
        "switchAccount": "Змінити обліковий запис",
        "switchAccounthNotAllowed": "Цей додаток не дозволяє змінювати обліковий запис"
    },
    "sending": "Надсилання",
    "sendMoney": {
        "account": {
            "title": "Надіслати на"
        },
        "amount": {
            "available": "Доступно для надсилання"
        },
        "amountStatusId": {
            "available": "Доступний баланс:",
            "howMuch": "Скільки ви хочете відправити?",
            "noMoreThan": "НЕ БІЛЬШЕ 5 ДЕСЯТИЧНИХ ЗНАКІВ",
            "notEnoughTokens": "Ви повинні залишити хоча б ${amount} NEAR на своєму акаунті",
            "sending": "Надсилання:"
        },
        "banner": {
            "insufficient": "Ви намагаєтеся надіслати весь доступний баланс. Хоча б <b>${data} NEAR</b> повинні бути зарезервовані для покриття комісій на транзакції.",
            "useMax": "Невелика частина (${data} NEAR) доступного балансу була зарезервована для покриття комісій на транзакції."
        },
        "button": {
            "confirm": "Підтвердіть та відправте",
            "dashboard": "Перейти до панелі керування",
            "send": "Підтвердити"
        },
        "confirmModal": {
            "title": "Підтвердити транзакцію"
        },
        "onceConfirmed": "Скасувати транзакцію після підтвердження буде неможливо.",
        "subtitle": {
            "default": "Введіть кількість токенів NEAR для надсилання та ім'я акаунту одержувача.",
            "success": "Ви успішно відправили NEAR",
            "to": "на:"
        },
        "title": {
            "default": "Надіслати",
            "success": "Успішно!"
        },
        "to": "на",
        "wasSentTo": "Надіслано на акаунт:",
        "youAreSending": "Ви збираєтеся надіслати"
    },
    "sendV2": {
        "accordionTriggerTitle": {
            "balanceDetails": "Відомості про баланс",
            "transactionDetails": "Деталі транзакції"
        },
        "review": {
            "title": "ви надсилаєте"
        },
        "selectAsset": {
            "assetInputPlaceholder": "Ім'я активу для пошуку",
            "assetListNameTitle": "Ім'я активу",
            "asssetListBalanceTitle": "Доступно для надсилання",
            "title": "Виберіть актив"
        },
        "selectReceiver": {
            "receiverInputLabel": "Надіслати на акаунт"
        },
        "selectTokenButtonTitle": "Виберіть актив",
        "success": {
            "title": "Транзакція завершена!</br>Ви надіслали <span class='color-blue'>${amount}</span></br>до <span class='color-green'>${receiverId }</span>"
        },
        "translateIdInfoTooltip": {
            "estimatedFees": "Орієнтовна вартість обробки вашої транзакції.",
            "estimatedTotal": "Очікувана загальна сума вашої транзакції, включаючи комісійні.",
            "networkFees": "Остаточна сума, сплачена для покриття витрат на обробку вашої транзакції.",
            "reservedForFees": "Частина балансу вашого облікового запису, яка не може бути витрачена. Ця сума зарезервована для покриття витрат на зберігання вашого облікового запису. Для отримання додаткової інформації відвідайте нашу документацію."
        },
        "TXEntry": {
            "status": {
                "Failure": "Невдача",
                "notAvailable": "Статус недоступний",
                "SuccessValue": "Успіх"
            },
            "title": {
                "amount": "Сума",
                "availableBalance": "Доступний баланс",
                "availableToSend": "Доступно для надсилання",
                "estimatedFees": "Очікувані комісії",
                "estimatedTotal": "Estimated total",
                "from": "Від",
                "networkFees": "Мережеві комісії",
                "receiverId": "Надіслано на",
                "reservedForFees": "Зарезервовано для комісій",
                "status": "Статус транзакції",
                "timeStamp": "Дата та час",
                "to": "на",
                "token": "Токен"
            }
        }
    },
    "setRecoveryConfirm": {
        "didNotReceive": "Не отримали код?",
        "email": "адреса електронної пошти",
        "inputError": "Введіть код підтвердження!",
        "inputHeader": "Enter verification code",
        "inputPlaceholder": "Код підтвердження",
        "or": "або",
        "pageText": "Ми надіслали код верифікації із 6 цифр на ",
        "phone": "номер телефону",
        "reenter": {
            "link": "натисніть тут",
            "one": {
                "email": "Якщо ви не отримали цей електронний лист або адреса електронної пошти вище некоректна, ",
                "phoneNumber": "Якщо ви не отримали це SMS або номер телефону вище некоректний, "
            },
            "two": {
                "email": " заново ввести адресу електронної пошти та надіслати повідомлення.",
                "phoneNumber": " заново ввести номер телефону та надіслати повідомлення."
            }
        },
        "resendCode": "Надіслати код ще раз",
        "resending": "Надсилаємо...",
        "sendToDifferent": "Надіслати на інший",
        "title": "Введіть код підтвердження"
    },
    "setupLedger": {
        "header": "Підключіть свій пристрій Ledger",
        "one": "Підключіть Ledger Nano S до свого комп'ютера або мобільного пристрою через USB та <b>відкрийте програму NEAR</b>. <br><br>Потім <b>натисніть обидві кнопки</b> на екрані 'Pending Ledger Review'.",
        "two": "Якщо ви ще не встановили програму NEAR на Ledger, слідуйте",
        "twoLink": "даним інструкціям"
    },
    "setupLedgerSteps": {
        "header": "Встановіть NEAR на вашому пристрої Ledger",
        "one": "Відкрийте <a href='https://www.ledger.com/ledger-live' target='_blank'>Ledger Live,</a> встановіть будь-які доступні оновлення.",
        "three": "Дотримуйтесь інструкцій, щоб встановити <span class='color-black'>програму NEAR</span> на своєму пристрої.",
        "two": "Натисніть вкладку <span class='color-black'>Менеджер</span>, а потім знайдіть <span class='color-black'>НЕЙКО</span>."
    },
    "setupLedgerSuccess": {
        "header": "Ledger підтверджено у вашому обліковому записі!",
        "nextStep": {
            "header": {
                "keep": "Зберегти існуючі ключі?",
                "remove": "Підтвердіть видалення ключа"
            },
            "one": {
                "keep": "<span class='color-red'>Використання декількох методів відновлення та пристроїв підвищує вразливість вашого облікового запису.",
                "remove": "Перед видаленням існуючих ключів та методів відновлення переконайтеся, що ви записали та надійно зберегли менімонічну фразу Ledger."
            },
            "two": {
                "remove": "Перед видаленням існуючих ключів та методів відновлення переконайтеся, що ви записали та надійно зберегли вихідну менімонічну фразу Ledger."
            }
        },
        "one": "Ми рекомендуємо видалити всі наявні ключі (методи відновлення та пристрої).",
        "primaryCta": "Видалить існуючі ключі та методи відновлення",
        "secondaryCta": "Зберегти існуючі ключі",
        "two": "Використання кількох ключів та методів відновлення (включаючи менімонічні фрази) збільшує вразливість вашого облікового запису."
    },
    "setupRecovery": {
        "advancedSecurity": "Підвищена безпека (Recommended)",
        "advancedSecurityDesc": "Запишіть фразу з 12 слів і надійно зберігайте її.",
        "basicSecurity": "Базова безпека",
        "basicSecurityDesc": "Введіть адресу електронної пошти або номер телефону для отримання чарівного посилання",
        "emailDesc": "Отримайте код підтвердження та посилання для відновлення облікового запису на електронну пошту.",
        "emailPlaceholder": "example@email.com",
        "emailTitle": "Електронна пошта для відновлення",
        "existingPhraseDesc": "Захистіть свою нову адресу, надавши доступ до наявної мнемонічної фрази.",
        "existingPhraseTitle": "Використувати існуючу мнемонічну фразу",
        "header": "Оберіть метод забезпечення безпеки",
        "ledgerDesc": "Захистіть свій обліковий запис за допомогою апаратного пристрою Ledger.",
        "ledgerTitle": "Апаратний гаманець Ledger",
        "newPhraseDesc": "Захистіть свою нову адресу за допомогою нової унікальної мнемонічної фрази.",
        "newPhraseTitle": "Створити нову мнемонічну фразу",
        "notSupportedPhone": "На жаль, ми не можемо надати SMS-сервіс у вашому регіоні. Будь ласка, виберіть метод відновлення електронною поштою.",
        "phoneDesc": "Введіть нижче свій номер телефону та отримайте чарівне посилання для відновлення.",
        "phonePlaceholder": "+380 95 555 5555",
        "phoneTitle": "Телефон для відновлення",
        "phraseDesc": "Створіть та безпечно зберігайте унікальну мнемонічну фразу.",
        "phraseTitle": "Мнемонічна фраза",
        "subHeader": "Виберіть спосіб захисту та відновлення облікового запису. Він використовуватиметься для перевірки важливої активності, відновлення облікового запису та доступу до облікового запису з інших пристроїв."
    },
    "setupSeedPhrase": {
        "pageText": "Запишіть наступні слова по порядку і зберігайте їх у надійному місці. <b>Будь-хто, хто має доступ до них, також матиме доступ до вашого облікового запису!</b> Потім вас попросять підтвердити вашу мнемонічну фразу.",
        "pageTitle": "Налаштуйте Свою Безпечну мнемонічну фразу",
        "snackbarCopyImplicitAddress": "Адреса для поповнення скопійована!",
        "snackbarCopySuccess": "Мнемонічна фраза скопійована!"
    },
    "setupSeedPhraseSuccess": {
        "pageText": "Використати мнемонічну фразу, щоб відновити ваш обліковий запис.",
        "pageTextSecondLine": "Будь-яка людина, яка має доступ до вашої мнемонічної фрази, може отримати доступ до ваших коштів, тримайте її в безпечному місці.",
        "pageTitle": "Встановлення відновлення завершено"
    },
    "setupSeedPhraseVerify": {
        "inputError": "Будь ласка, перевірте свою мнемонічну фразу та спробуйте ще раз.",
        "pageText": "Введіть наступне слово із мнемонічної фрази, щоб завершити процес встановлення.",
        "pageTitle": "Перевірити фразу",
        "startOverText": "Не записали фразу?"
    },
    "sign": {
        "accountNotFound": {
            "body": "запитує авторизацію з облікового запису NEAR, який не можна знайти: <b>${signTransactionSignerId}</b><br/><br/>Щоб схвалити будь-які трансакції, вам спочатку потрібно імпортувати обліковий запис.",
            "title": "Акаунт не знайдено"
        },
        "ActionWarrning": {
            "binaryData": "Параметри містять двійкові дані",
            "deleteAccount": "Ви збираєтеся видалити свій обліковий запис! Ваш баланс NEAR буде знищено, а всі дані вашого облікового запису видалені.",
            "deployContract": "Ви збираєтеся розмістити контракт на вашому обліковому записі! Цей контракт може отримати доступ до вашого балансу NEAR і взаємодіяти з іншими контрактами від вашого імені.",
            "functionCall": "Для цієї функції не вказано опис.",
            "stake": "Ви збираєтеся застейкати ваші токени NEAR. Ці токени будуть заблоковані і можуть бути втрачені, якщо ваш валідатор перестане відповідати на запити."
        },
        "approveTransaction": "Схвалити транзакцію",
        "authorizationRequestSuccessful": "Запит на авторизацію успішно виконано",
        "authorizing": "Виконується авторизація",
        "availableBalance": "Доступний баланс",
        "availableToTransfer": "Доступно для переказу",
        "contract": "Контракт:",
        "contractDetails": "Деталі контракту",
        "details": {
            "detailedDescription": "Докладний опис транзакції",
            "forContract": "Для контракту",
            "gasLimit": "Ліміт газу",
            "gasPriceUnavailable": "Оцінка ціни на газ недоступна",
            "transactionFees": "Вартість угоди"
        },
        "estimatedFees": "Орієнтовані комісії",
        "feeLimit": "Ліміт комісії",
        "function": "Функція:",
        "gasLimit": "Ліміт Газу",
        "hereAreSomeDetails": "Ось деякі деталі, які допоможуть вам.",
        "insufficientFunds": "Недостатньо коштів",
        "insufficientFundsDesc": "У вас недостатньо NEAR, щоб завершити цю транзакцію.",
        "invalidTransaction": {
            "body": "Запит недійсний і його неможливо підписати.",
            "title": "Недійсна транзакція"
        },
        "isRequesting": {
            "authorization": "запитує дозвіл",
            "transferOf": "запитує переказ"
        },
        "multipleAccountsError": {
            "body": "<a href='${signCallbackUrl}' target='_blank' rel='noreferrer'>${signCallbackUrl}</a> надіслав запит на авторизацію з кількох облікових записів.<br/><br/>Genin Wallet може авторизувати лише транзакції для одного облікового запису за раз.",
            "title": "Batch Transaction Error"
        },
        "networkFees": "Мережеві комісії",
        "nothingHasBeenTransferred": "Переказ не відбувся.",
        "retry": {
            "estimatedFees": "Очікувані комісії",
            "feeLimit": "Ліміт комісії",
            "link": "Який тут ліміт комісії?",
            "networkFees": "Мережеві комісії",
            "text": "Мережевої комісії за замовчуванням було недостатньо для покриття вартості транзакції.<br/><br/>Ви можете повторно надіслати транзакцію, щоб її ліміт комісії автоматично збільшувався.",
            "title": "Недостатня плата за мережу"
        },
        "transactionCancelled": "Транзакція скасована",
        "transactionDetails": "Деталі транзакції",
        "transferring": "Відбувається переказ",
        "unexpectedStatus": "Несподіваний статус",
        "unknownApp": "Невідомий додаток",
        "wasTransferredSuccessfully": "транзакція успішно виконана"
    },
    "signInLedger": {
        "advanced": {
            "desc": "Вкажіть HD Path для імпорту приєднаних акаунтів.",
            "setPath": "Вкажіть HD Path",
            "subTitle": "HD Path",
            "title": "Додаткові параметри"
        },
        "firefoxBanner": {
            "desc": "Ledger Connect не підтримується в таких браузерах: Firefox версії 112+, Safari. Він повністю підтримується в браузерах Chrome / Chromium. Просимо вибачення за незручності."
        },
        "header": "Увійти за допомогою пристрою Ledger",
        "modal": {
            "accountsApproved": "акунт(и) підтверджено",
            "confirmPublicKey": "Підтвердіть публічний ключ",
            "ledgerMustAdd": "Ledger повинен додати ключ доступу до кожного облікового запису, захищеного вашим пристроєм:",
            "status": {
                "confirm": "Підтвердити",
                "error": "Помилка",
                "pending": "В обробці",
                "rejected": "Відмовлено",
                "success": "Підтверджено",
                "waiting": "На черзі"
            }
        },
        "one": "Перевірте, що ваш Ledger надійно підключений, а програма NEAR запущена на пристрої."
    },
    "stagingBanner": {
        "desc": "Увага: Це дорелізна версія NEAR гаманця. Тут можуть бути помилки, ці помилки можуть призвести до втрати коштів. Продовжуючи використовувати цю версію, ви погоджуєтесь, що розумієте і приймаєте дані ризики, а також розумієте, що команда NEAR не зможе вам допомогти.",
        "title": "Увага: Це дорелізна версія гаманця. Використовуйте на свій ризик!"
    },
    "stakedBalance": "Ці токени NEAR активно використовуються для підтримки валідатора та захисту мережі. Коли ви вирішите зняти зі стейкінгу ці NEAR, то знадобиться деякий час, щоб вона відобразилася у вашому «Доступному балансі», тому що NEAR потребує 4 епохи (~ 52-65 годин) ), щоб зняти токени зі стейкінгу.",
    "staking": {
        "alertBanner": {
            "button": "Подивитися поточного валідатора",
            "title": "Перед тим як почати стейкінг в пулі нового валідатора, ви повинні зняти токени зі стейкінгу вашого поточного валідатора і вивести їх з пулу."
        },
        "amountStakingInput": {
            "placeholder": "наприклад: 15",
            "title": "Введіть суму для стейкінгу"
        },
        "balanceBanner": {
            "currency": "NEAR",
            "title": "Доступний lockup-баланс"
        },
        "balanceBox": {
            "available": {
                "button": "Вивести",
                "info": "Ці токени знято зі стейкінгу і тепер можуть бути виведені.",
                "title": "Доступно для виведення"
            },
            "farm": {
                "button": "Отримати",
                "info": "Даний валідатор видає нагороду від стейкінгу не у NEAR токенах."
            },
            "pending": {
                "info": "Ці токени знято зі стейкінгу, але ще не можуть бути виведені. Токенам потрібно від 52 до 65 годин на виведення зі стейкінгу.",
                "title": "Очікується зняття"
            },
            "staked": {
                "button": "Зняти зі стейкінгу",
                "info": "Токени NEAR наразі застейкані за допомогою валідаторів. Ці токени накопичують винагороду. Щоб отримати доступ до цих токенів, ви повинні спочатку зняти їх зі стейкінгу, а потім вивести їх.",
                "title": "Загальний розмір стейкінгу"
            },
            "unclaimed": {
                "info": "Нагороди, які були зароблені, але не зняті. Нагороди в Near токені <a target='_blank' href='https://www.investopedia.com/terms/c/compoundinterest.asp'>реінвестуються</a> та автоматично стейкаються знов.",
                "title": "Отримані винагороди",
                "unavailable": {
                    "cta": "Докладніше",
                    "modalDescOne": "Наразі ми не можемо порахувати нагороду, якщо включена двофакторна аутентифікація. Ми сподіваємося включити цю можливість у найближчому майбутньому.",
                    "modalDescTwo": "Тим часом ви можете бачити, як накопичуються ваші нагороди, в розділі 'Загальний розмір стейкінгу'. Цей баланс буде зростати, поки ваші токени знаходяться в стейкінгу, оскільки отримані нагороди автоматично направляються в стейкінг-пул поточного валідатора.",
                    "modalTitle": "Неможливо відобразити отримані нагороди стейкінгу",
                    "title": "Неможливо відобразити отримані нагороди."
                }
            }
        },
        "claimSuccess": {
            "button": "Повернутися до панелі керування",
            "desc": "Ваш стейкінг у розмірі <b>${amount}</b> був успішно вилучений з пула вибраного валідатора",
            "descTwo": "Ваші токени вже доступні",
            "title": "Готово!"
        },
        "noValidators": {
            "title": "Наразі у вас немає стейкінгу в пулі будь-якого валідатора."
        },
        "pageText": "Налаштуйте свій вузол(node) через CLI, а потім додайте його сюди.",
        "pageTitle": "Стейкінг",
        "stake": {
            "accounts": "Якщо у вас є заблоковані токени NEAR, ви можете відправити їх у стейкінг за допомогою lockup-контракту NEAR (lockup.near) або розблокованого контракту NEAR (ім'я вашого облікового запису). Ви побачите розмір стейкінгу тільки для вибраного облікового запису . Якщо у вас немає заблокованих токенів NEAR, ви можете проігнорувати цей розділ.",
            "amount": "Кількість",
            "banner": {
                "insufficientBalance": "Ви намагаєтеся надіслати в стейкінг весь доступний баланс. Хоча б <b>${data} NEAR</b> повинні бути зарезервовані для покриття комісій на транзакції.",
                "stakeMax": "Невелика частина (${data} NEAR) доступного балансу була зарезервована для покриття комісій на транзакції."
            },
            "button": "Підтвердити стейкінг",
            "confirm": "Ваш стейкінг",
            "desc": "Введіть розмір стейкінгу, який буде делегований вибраному валідатору.",
            "from": "від",
            "input": {
                "availableBalance": "Доступно для стейкінгу",
                "near": "NEAR"
            },
            "ledgerDisclaimer": "Примітка: Так як ви вперше відправляєте токени в стейкінг даному валідатор, вам потрібно буде підтвердити 2 транзакції.",
            "stakeWith": "Стейкнути з",
            "title": "Розмір стейкінгу",
            "useMax": "Максимальна кількість",
            "with": "з"
        },
        "stakeSuccess": {
            "button": "Повернутися до панелі керування",
            "desc": "Ваш стейкінг було успішно делеговано обраному валідатору:",
            "descTwo": "Ви можете переглянути своє делегування та нагороди за стейкінг в панелі керування стейкінгом.",
            "title": "Готово!"
        },
        "staking": {
            "available": "Доступний баланс",
            "button": "Відправити токени NEAR у стейкінг ",
            "currentValidators": "Ваші поточні валідатори",
            "desc": "Відправте токени NEAR у стейкінг валідатору",
            "noValidators": "Ви ще не почали стейкінг у пулі валідатора.",
            "selectAccount": "Стейкінг від",
            "title": "Стейкінг",
            "totalStaked": "Усього в стейкінгу"
        },
        "unstake": {
            "beforeUnstakeDisclaimer": "Зняті зі стейкінгу токени стануть доступні після періоду в ~ 52-65 годин (4 епохи).",
            "button": "Зняти зі стейкінгу токени",
            "confirm": "Ви знімаєте зі стейкінгу",
            "desc": "Введіть кількість токенів, які ви бажаєте зняти зі стейкінгу від поточного валідатора.",
            "input": {
                "availableBalance": "Доступно для зняття зі стейкінгу",
                "near": "NEAR"
            },
            "stakeWith": "Зняти зі стейкінгу від",
            "title": "Вивести токени зі стейкінгу"
        },
        "unstakeSuccess": {
            "button": "Return to Dashboard",
            "desc": "<b>${amount}</b> has successfully been unstaked from this validator:",
            "descTwo": "Your tokens are pending release and will be made available within ~52-65hrs (4 epochs).",
            "title": "Success!"
        },
        "validator": {
            "activeStake": "Активний стейкійнг",
            "apy": "APY",
            "button": "Вiдправити на стейкiнг до валiдатора",
            "claimFarmRewards": "Ви вимагаєте",
            "claiming": "Вимога",
            "desc": "Ця комісія виплачується валідатору стейкінгу від вашого імені. Комісія накладається тільки на ваші нагороди.",
            "errorClaimRewards": "Щось пішло не так під час отримання  фармінг винагороди. Будь ласка, спробуйте ще раз пізніше.",
            "fee": "Комісія за стейкінг",
            "notWhitelistedTokenWarning": "Даний токен не був доданий до більго списку ref-finance. Продовжуйте на свій страх і ризик.",
            "notWhitelistedValidatorWarning": "Токени надані цим валідатором не були додані до білого списку ref-finance. Продовжуйте на свій страх і ризик.",
            "rewards": "Нагороди",
            "title": "Валідатор: <br/>${validator}",
            "unstake": "Ви знімаєте зі стейкінгу",
            "withdraw": "Ви виводите",
            "withdrawalDisclaimer": "Токени стануть доступними для виведення після періоду очікування виходу зі стейкінгу, який займає ~52-65 годин (4 епохи)."
        },
        "validatorBox": {
            "cta": "Виберіть",
            "farming": "Фармінг",
            "fee": "Комісія",
            "near": "NEAR",
            "staking": "Стейкинг",
            "state": {
                "active": "активний",
                "inactive": "неактивний"
            },
            "with": "with"
        },
        "validators": {
            "available": "Доступні валидатори",
            "button": "Перегляд валідатора",
            "desc": {
                "account": "Введіть назву існуючого валідатора або виберіть валідатора зі списку для стейкінгу ваших токенів NEAR.",
                "lockup": "Введіть назву існуючого валідатора або виберіть валідатора зі списку для стейкінгу ваших токенів NEAR. Ви можете створити стейкінг в пулі лише одного обраного валідатора в будь-який момент часу.",
                "unstake": "Оберіть пул валідатора з якого ви хочете зняти токени зі стейкінгу.",
                "withdraw": "Оберіть пул валідатора з якого ви хочете вивести токени."
            },
            "inputLabel": "Вкажіть назву пулу валідатора",
            "inputPlaceholder": "validator-name.near",
            "search": {
                "error": "Відповідного валідатора не знайдено.",
                "success": "Валідатора знайдено!"
            },
            "title": "Виберіть валідатора"
        }
    },
    "stakingPoolUnstaked": "Ці NEAR наразі знаходяться в стейкінг-пулі, але не беруть участь у стейкінгу. Наприклад, вони можуть перебувати у стані зняття зі стейкінгу, чекаючи операції виведення.",
    "success": "Успіх",
    "swap": {
        "confirm": "Confirm & Swap",
        "details": "Transaction details",
        "fee": "Estimated fees",
        "from": "From",
        "max": "Max: ${amount} ${symbol}",
        "minReceived": "Minimum received",
        "price": "Price",
        "priceImpact": "Price impact",
        "review": "Review Swap",
        "reviewInfo": "You are swapping",
        "slippage": "Slippage tolerance",
        "successTitle": "Transaction complete!</br>You swapped <span class='color-blue space'>${amountFrom}</span></br>to <span class='color-green'>${amountTo}</span>",
        "title": "Swap",
        "to": "To",
        "translateIdInfoTooltip": {
            "fee": "The estimated cost of processing your transaction.",
            "minimumReceived": "The minimum amount you will receive from the trade.",
            "priceImpact": "The difference between the market price and estimated price due to trade size.",
            "slippage": "The percentage the price may increase before the transaction fails."
        }
    },
    "swapping": "Swapping",
    "sync": "Синхронізація",
    "tokenBox": {
        "priceUnavailable": "Ціна недоступна"
    },
    "topLevelAccounts": {
        "body": "Імена облікових записів схожі на доменні імена. Тільки обліковий запис ${data} може створювати такі облікові записи, як yourname.${data}, і тільки yourname.${data} може створити app.yourname.${ data} Всі облікові записи, створені в цьому гаманці, використовують .${data} Облікові записи верхнього рівня (TLA) Щоб дізнатися більше про імена облікових записів та створити власний обліковий запис верхнього рівня, прочитайте документацію <a rel='noopener noreferrer ' href='https://docs.near.org/docs/concepts/account'>docs</a>.",
        "header": "Облікові записи верхнього рівня"
    },
    "total": "повна кількість",
    "totalBalance": "Загальний баланс показує всі токени NEAR, які знаходяться під вашим контролем. У багатьох випадках у вас не буде негайного доступу до всього вмісту загального балансу (наприклад, якщо він заблокований, делегований або знаходиться в стейкінгу). Перевірте свій 'Доступний баланс', щоб дізнатися кількість токенів NEAR, який ви можете активно використовувати, передавати, делегувати та відправляти в стейкінг.",
    "transaction": {
        "status": {
            "checkingStatus": "Перевірка стану...",
            "Failure": "Помилка",
            "notAvailable": "Статус недоступний",
            "NotStarted": "Не розпочато",
            "Started": "Почато",
            "SuccessValue": "Успіх"
        }
    },
    "transfer": {
        "estimatedFees": "Прогнозовані комісії",
        "from": "Від",
        "to": "Кому"
    },
    "twoFactor": {
        "action": {
            "addKey": {
                "full": "ПОПЕРЕДЖЕННЯ. Введення коду нижче дозволить отримати повний доступ до вашого облікового запису NEAR: ${accountId}. Якщо ви не ініціювали цю дію, будь ласка, НЕ продовжуйте.<br /><br />Це має бути зроблено лише якщо ви додаєте новий ключ повного доступу до свого облікового запису. У всіх інших випадках це дуже небезпечно.<br /><br /> Публічний ключ, який ви додаєте: ${publicKey} ",
                "limited": "Додавання ключа ${publicKey} з можливістю виклику функцій ${methodNames} на акаунті ${receiverId} з обмеженням витрати газу понад ${allowance} NEAR"
            },
            "deleteKey": "Видалення ключа ${publicKey}",
            "functionCall": "Виклки методу: ${methodName} в контракті: ${receiverId} з сумою депозиту ${deposit} NEAR і параметрами: <pre>${args}</pre>",
            "stake": "Стейкінг: ${amount} NEAR з валідатором: ${receiverId}",
            "transfer": "Переказ: ${amount} NEAR на акаунт ${receiverId}"
        },
        "active": "Активна",
        "alertBanner": {
            "button": "Докладніше",
            "title": "<b>${data}</b> будуть заблоковані для покриття витрат на зберігання контракту двофакторної аутентифікації доти, доки вона включена.."
        },
        "checkBox": "Я визнаю, що ввімкнувши двофакторну аутентифікацію, ${amount} буде зарезервовано для покриття зберігання контракту.",
        "desc": "Захистіть свій обліковий запис за рахунок обов'язкового підтвердження електронною поштою або SMS всіх транзакцій, що потребують авторизації.",
        "disable": {
            "desc": "Майте на увазі, що після вимкнення транзакції, не вимагатимуть підтвердження за допомогою 2FA.",
            "disable": "Вимкнути 2FA",
            "keep": "Ні, залишити 2FA",
            "phoneDesc": "Опція SMS для двофакторної аутентифікації не підтримується, її не можна повторно активувати.",
            "title": "Ви впевнені, що хочете вимкнути 2FA?"
        },
        "email": "Адреса електронної пошти",
        "enable": "Увімкнути двофакторну аутентифікацію",
        "notEnabled": "2FA не активна",
        "notEnoughBalance": "Щоб підключити 2FA, ваш обліковий запис вимагає мінімального балансу ${amount}",
        "phone": "Телефонний номер",
        "promptDesc": "Ми дуже рекомендуємо налаштувати метод двофакторної аутентифікації для підвищення безпеки вашого облікового запису та наявних коштів",
        "select": "Виберіть метод аутентифікації",
        "since": "починаючи з",
        "subHeader": "Двофакторна аутентифікація додає додатковий рівень безпеки вашого облікового запису. <b>Мнемонічна фраза та Ledger ключі продовжують надавати повний доступ до вашого облікового запису.</b>",
        "title": "Двофакторна аутентифікація",
        "verify": {
            "desc": "Верифікаційний код із 6 цифр був відправлений на:",
            "didntReceive": "Не отримали свій код?",
            "inputLabel": "Введіть код підтвердження з 6 цифр",
            "placeholder": "Код підтвердження",
            "resend": "Надіслати заново",
            "resending": "Надсилаємо...",
            "resent": "Код надіслано!",
            "title": "Введіть двофакторний код підтвердження"
        }
    },
    "unlockedAvailTransfer": "Ці токени NEAR розблоковані та можуть бути виведені з вашого lockup-контракту.",
    "unlockedBalance": "Ці токени NEAR все ще знаходяться в lockup-контракті, але вони розблоковані.",
    "unvestedBalance": "Ці токени NEAR зарезервовані для вас, але ще не знаходяться у вашій власності. Ви, як і раніше, можете делегувати або відправити їх у стейкінг, і нагороди будуть повністю вашими. Як тільки ці NEAR перейдуть у вашу власність, вони з'являться на вашому заблокованому або розблокованому балансі.",
    "verifyAccount": {
        "desc": "Щоб запобігти спаму нових облікових записів, нам потрібно підтвердити вас як власника. Ви можете підтвердити, використовуючи одноразовий пароль або внісши невеликий початковий депозит.",
        "descNoFunding": "Виберіть метод нижче для фінансування та ініціалізації вашого нового облікового запису.",
        "modal": {
            "email": {
                "desc": "Вам потрібно буде використовувати іншу адресу електронної пошти або вибрати альтернативний метод для підтвердження цього облікового запису. Ви все одно можете використовувати цю адресу як метод відновлення.",
                "title": "Ця електронна адреса вже була використана для підтвердження іншого облікового запису."
            },
            "phone": {
                "desc": "Вам потрібно буде використовувати інший номер телефону або вибрати альтернативний метод для підтвердження цього облікового запису. Ви все одно можете використовувати цей номер як метод відновлення.",
                "title": "Цей номер телефону вже використовувався для підтвердження іншого облікового запису."
            }
        },
        "option": {
            "creditCard": {
                "desc": "Придбайте NEAR через MoonPay для початкового депозиту.",
                "title": "Поповнити за допомогою банківської картки"
            },
            "email": {
                "desc": "Отримайте одноразовий код підтвердження на електронну пошту.",
                "title": "Поповнити за допомогою електронної пошти"
            },
            "existingAccount": {
                "desc": "Використовуйте наявний обліковий запис для фінансування та ініціалізації нового облікового запису.",
                "title": "Поповнити за допомогою наявного облікового запису"
            },
            "manualDeposit": {
                "desc": "Внесіть депозит вручну за допомогою одноразової адреси фінансування.",
                "title": "Поповнити вручну"
            },
            "phone": {
                "desc": "Отримайте одноразовий код підтвердження через SMS.",
                "title": "Поповнити за допомогою телефона"
            }
        },
        "options": {
            "initialDeposit": "Початковий депозит",
            "passCode": "Одноразовий пароль"
        },
        "title": "Майже готово! Підтвердьте свій новий обліковий запис.",
        "titleNoFunding": "Майже готово! Поповніть свій новий обліковий запис."
    },
    "verifyWalletDomainBanner": {
        "title": "Будь ласка, переконайтеся, що ви відвідуєте"
    },
    "wallet": {
        "availableBalance": "Доступний баланс",
        "balance": "Баланс",
        "balances": "Баланси",
        "balanceTitle": "Баланс NEAR",
        "collectibles": "Колекційні об'єкти",
        "dateAndTime": "Дата та час",
        "depositNear": {
            "desc": "Вам знадобляться токени NEAR для здійснення транзакцій з вашим гаманцем.",
            "title": "Депозит NEAR"
        },
        "status": "Статус",
        "tokenBalance": "Баланс токенів",
        "tokens": "Токени",
        "totalBalance": "Загальний баланс",
        "totalBalanceTitle": "Загальний баланс",
        "yourPortfolio": "Ваше портфоліо"
    },
    "walletErrorCodes": {
        "addAccessKey": {
            "error": "Відбулася помилка.<br />Для доступу до вашого облікового запису, будь ласка, введіть нижче менімонічну фразу з попереднього кроку."
        },
        "addAccessKeySeedPhrase": {
            "errorSecond": "Відбулася помилка.<br />Мнемонічна фраза не була додана до вашого облікового запису. Будь ласка, спробуйте ще раз."
        },
        "addLedgerAccountId": {
            "errorRpc": "Під час відновлення облікового запису виникла помилка."
        },
        "createNewAccount": {
            "accountCreated": {
                "error": "Обліковий запис створено успішно, але сталася помилка. Будь ласка, імпортуйте свій обліковий запис."
            },
            "accountExists": {
                "error": "Не вдалося створити обліковий запис. Обліковий запис уже існує."
            },
            "error": "Не вдалося створити обліковий запис. Ви можете спробувати ще раз."
        },
        "emailProviderInvalid": "${domainName} не можна використовувати для підтвердження облікового запису. Надайте адресу електронної пошти іншого постачальника послуг електронної пошти.",
        "getLedgerAccountIds": {
            "aborted": "Дія була перервана.",
            "noAccounts": "Жодний обліковий запис не був пов'язаний з пристроєм Ledger. Ви повинні спочатку створити обліковий запис і потім використовувати Ledger для входу.",
            "noAccountsAccepted": "Жодний обліковий запис не був підтверджений.",
            "U2FNotSupported": "Для використання пристрою Ledger потрібна підтримка U2F у браузері. Будь ласка, використовуйте Chrome, Opera або Firefox з розширенням U2F."
        },
        "invalidRecaptchaCode": "Недійсний код reCAPTCHA; спробуйте ще раз!",
        "lockup": {
            "transferAllWithStakingPoolBalance": "Зніміть зі стейкінгу та виведіть усі кошти з пулу стейкінга, щоб переказати ваш залишок lockup-балансу на гаманець."
        },
        "promptTwoFactor": {
            "userCancelled": "2FA-код не був коректно верифікований."
        },
        "recoverAccountLink": {
            "error": "Не вдалося імпортувати ваш обліковий запис. Спробуйте ще раз або зв’яжіться зі службою підтримки."
        },
        "recoverAccountSeedPhrase": {
            "errorInvalidSeedPhrase": "Облікові записи, що відповідають даній менімонійній фразі, не знайдені."
        },
        "recoveryMethods": {
            "lastMethod": "Неможливо видалити останній метод відновлення. Поки ви використовуєте Ledger, вам потрібно залишити хоча б один метод відновлення, щоб гарантувати можливість відновлення вашого облікового запису.",
            "setupMethod": "Відбулася помилка. Будь ласка, перевірте свій спосіб відновлення."
        },
        "sendFungibleToken": {
            "error": "Сталася помилка. Вашу транзакцію відправлення скасовано."
        },
        "setupRecoveryMessage": {
            "error": "Під час налаштування методу відновлення сталася помилка. Спробуйте ще раз!"
        },
        "setupRecoveryMessageNewAccount": {
            "invalidCode": "Недійсний код"
        },
        "signAndSendTransactions": {
            "notEnoughTokens": "Недостатньо токенів."
        },
        "staking": {
            "noLockup": "Lockup-контракт не знайдений у цьому обліковому записі",
            "noWithdraw": "Очікуючий баланс неможливо вивести від валідатора"
        }
    },
    "warning": "Попередження"
}
;
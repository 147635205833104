import { Keypair } from '@solana/web3.js';
import bs58 from 'bs58';

const LOCAL_STORAGE_KEY_PREFIX = 'keystore:';

export class KeyStore {
    localStorage;
    prefix;

    constructor(localStorage = window.localStorage, prefix = LOCAL_STORAGE_KEY_PREFIX) {
        this.localStorage = localStorage;
        this.prefix = prefix;
    }


    async setKey(networkId, accountId, keyPair) {
        const secretKey = bs58.encode(Buffer.from(keyPair.secretKey));
        this.localStorage.setItem(this.storageKeyForSecretKey(networkId, accountId), secretKey);
    }

    async getKey(networkId, accountId) {
        const value = this.localStorage.getItem(this.storageKeyForSecretKey(networkId, accountId));
        if (!value) {
            return null;
        }
        const secretKey = new Uint8Array(bs58.decode(value));
        return Keypair.fromSecretKey(secretKey);
    }

    async removeKey(networkId, accountId) {
        this.localStorage.removeItem(this.storageKeyForSecretKey(networkId, accountId));
    }

    async clear() {
        for (const key of this.storageKeys()) {
            if (key.startsWith(this.prefix)) {
                this.localStorage.removeItem(key);
            }
        }
    }

    async getNetworks() {
        const result = new Set();
        for (const key of this.storageKeys()) {
            if (key.startsWith(this.prefix)) {
                const parts = key.substring(this.prefix.length).split(':');
                result.add(parts[1]);
            }
        }
        return Array.from(result.values());
    }

    async getAccounts(networkId) {
        const result = new Array();
        for (const key of this.storageKeys()) {
            if (key.startsWith(this.prefix)) {
                const parts = key.substring(this.prefix.length).split(':');
                if (parts[1] === networkId) {
                    result.push(parts[0]);
                }
            }
        }
        return result;
    }

    storageKeyForSecretKey(networkId, accountId) {
        return `${this.prefix}${accountId}:${networkId}`;
    }

    *storageKeys() {
        for (let i = 0; i < this.localStorage.length; i++) {
            yield this.localStorage.key(i);
        }
    }
}

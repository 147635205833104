module.exports = {
    "account": {
        "activateAccount": {
            "post": {
                "descOne": "초기 예치금이 계정에 입금되었습니다. 계정이 활성화되었습니다!",
                "descTwo": "입금액의 일부분이 <a href='https://docs.near.org/docs/concepts/account' rel='noopener noreferrer' target='_blank'>계정을 호스트하기 위한 비용</a>을 위해 유보됩니다.",
                "title": "계정이 활성화되었습니다."
            },
            "pre": {
                "desc": "NEAR 지갑을 사용하려면 초기 예치금으로 <b>최소 ${amount}</b>의 금액을 계정에 보내십시오.",
                "title": "거의 다 왔습니다. 계정에 초기 예치금을 보내 계정을 활성화하십시오."
            }
        },
        "available": {
            "error": "유저를 찾지 못했습니다!",
            "errorSameAccount": "자기 자신에게 보낼 수 없습니다.",
            "implicitAccount": "항상 ID를 한 번 더 확인하십시오.",
            "implicitAccountModal": "특히 거래소 계정라면 ID를 한 번 더 확인하십시오. ID가 잘못 입력되면 자금을 찾을 수 없습니다.",
            "success": "유저를 찾았습니다!"
        },
        "create": {
            "checkingAvailablity": {
                "check": "확인중",
                "create": "확인중"
            },
            "errorAccountNotExist": "계정을 만드는 데 문제가 생겼습니다. 다시 시도하십시오.",
            "errorInvalidAccountIdLength": "ID의 길이는 2 ~ 64사이여야 합니다."
        },
        "createImplicit": {
            "post": {
                "descOne": "초기 예치금이 입금되었습니다. 클레임시에 신규 계정에 입금액이 전송됩니다.",
                "descTwo": "중요: 일회성 펀딩 주소는 더 이상 유효하지 않습니다. 다른 디바이스나 거래소에 등록된 일회성 펀딩 주소를 만들어진 계정 ID로 교체하십시오.",
                "modal": {
                    "descOne": "계속 진행하면 일회성 펀딩 주소가 새로 만들어진 계정으로 교체하는 것을 동의하게 됩니다. 일회성 펀딩 주소로 추가적으로 보내어진 자금은 찾을 수 없습니다.",
                    "descTwo": "다른 디바이스나 거래소에 등록된 일회성 펀딩 주소를 계정 ID로 교체하십시오.",
                    "title": "주소를 업데이트하십시오."
                },
                "title": "계정을 클레임하기"
            },
            "pre": {
                "addressHeader": "일회성 펀딩 주소",
                "descOne": "NEAR 지갑을 사용하려면 초기 예치금으로 <b>최소 ${amount}</b>의 금액을 아래 일회성 펀딩 주소에 보내십시오.",
                "descTwo": "초기 예치금 입금이 완료되면 계정 생성을 위해 이 화면으로 다시 돌아오십시오.",
                "moonPay": {
                    "buyWith": "다음으로 구매하기:",
                    "desc": "MoonPay로 최초예치금을 입금하십시오.",
                    "title": "MoonPay로 입금하기"
                },
                "title": "거의 다 왔습니다. 계정에 1회 입금을 해서 계정을 활성화하십시오.",
                "utorg": {
                    "buyWith": "다음으로 구매하기:",
                    "desc": "Utorg로 최초예치금을 입금하십시오.",
                    "title": "Utrong으로 입금하기"
                },
                "whereToBuy": {
                    "button": "NEAR를 구매하는 곳",
                    "desc": "NEAR 토큰은 다음의 거래소들에서 구매할 수 있습니다.",
                    "title": "NEAR 토큰 구매하기"
                }
            },
            "success": {
                "button": "계정으로 가기",
                "descOne": "축하합니다, <b>${accountId}</b>! 계정이 성공적으로 생성되었습니다.",
                "descThree": "거래소나 다른 디바이스에 계정을 업데이트하십시오.",
                "descTwo": "이제 NEAR에 관한 모든 활동에서 주소대신 계정 ID를 사용해야 합니다.",
                "modal": {
                    "desc": "축하합니다! 계정이 성공적으로 생성되었습니다.",
                    "title": "NEAR 지갑에 오신 것을 환영합니다!"
                },
                "title": "NEAR 지갑에 오신 것을 환영합니다"
            }
        },
        "createImplicitAccount": {
            "createCustomNameModal": {
                "desc": "디폴트 주소와는 다르게 무엇이든 커스텀 주소로 고를 수 있어 기억하고 공유하기 쉽습니다!",
                "title": "지갑에 커스텀 주소를 추가하십시오"
            },
            "customAddress": "커스텀 주소는 무엇이든 될 수 있어 기억하고 공유하기 쉽습니다!",
            "customAmount": "커스텀",
            "exchange": "거래소",
            "orAskFriend": "or ask a friend!",
            "orSendNear": "...or send at least ${amount} NEAR to your wallet address.",
            "sendFrom": "다음으로부터 보내기:",
            "title": "거의 다 왔습니다! 시작하려면 NEAR를 구매하십시오",
            "titleAlt": "거의 다 왔습니다! 시작하려면 최소 ${amount} NEAR를 지갑에 보내십시오."
        },
        "fundedStatus": {
            "active": "활성화됨",
            "awaitingDeposit": "입금 대기중",
            "initialDeposit": "초기 예치금",
            "minDeposit": "최소 예치금",
            "nearName": "계정 ID",
            "ready": "클레임 가능",
            "singleUse": "일회성 펀딩 주소",
            "status": "계정 상태"
        },
        "login": {
            "details": {
                "warning": "지갑의 모든 자산에 대한 접근을 허용합니다. 주의하십시오. 사용하려는 어플리케이션의 문제이거나 컨트랙트가 삭제되었을 수 있습니다. 어플리케이션으로 다시 돌아갑니다."
            },
            "incorrectContractId": {
                "error": "컨트랙트 ${contractId}가 존재하지 않습니다. ",
                "errorTitle": "잘못된 컨트랙트 ID"
            }
        },
        "nameDoesntMatch": "계정 이름이 맞지 않습니다.",
        "recoverAccount": {
            "error": "계정을 복구하는 데 실패했습니다. 이 비밀구문에 대한 계정을 찾을 수 없습니다.",
            "errorInvalidSeedPhrase": "이 비밀구문에 대한 계정을 찾을 수 없습니다.",
            "success": "비밀구문으로 계정복구를 완료했습니다. <br/><b>${numberOfAccounts}의 계정(들)이</b> 성공적으로 복구되었습니다.<br/>The last one is set as active.",
            "zeroBalance": {
                "success": {
                    "desc": "계정에 아직 자금이 입금되지 않았습니다. 계정의 기능을 사용하려면 NEAR를 구매하십시오.",
                    "ledger": "다음의 계정을 제공된 Ledger 키를 통해 성공적으로 가져왔습니다:",
                    "phrase": "다음의 계정을 제공된 복구 구문을 통해 성공적으로 가져왔습니다:",
                    "title": "계정 가져옴"
                }
            }
        },
        "requestCode": {
            "error": "SMS로 코드를 보내는 데 실패했습니다.",
            "success": "SMS로 코드를 보냈습니다."
        },
        "sendMoney": {
            "error": "오류가 발생했습니다. 한 번 더 시도하십시오."
        },
        "sendNewRecoveryLink": {
            "error": "전송 실패했습니다. 한 번 더 시도하십시오.",
            "success": "복구 구문이 성공적으로 전송되었습니다!"
        },
        "verifySeedPhrase": {
            "error": "잘못된 단어를 입력했습니다."
        }
    },
    "accountSelector": {
        "noAccountDesc": "현재 어떠한 다른 계정에도 연결되어 있지 않습니다.",
        "signInButton": "다른 계정 가져오기"
    },
    "actions": {
        "AddKey": {
            "forContract": "다음 계정에 액세스 키가 추가됨: ${permissionReceiverId}",
            "forReceiver": "다음 계정에 완전 권환 엑세스 키 추가됨: ${receiverId}"
        },
        "CreateAccount": "신규 계정이 생성됨: ${receiverId}",
        "DeleteAccount": "계정이 삭제됨: ${receiverId}",
        "DeleteKey": "키 삭제됨",
        "DeployContract": "컨트랙트 배포됨: ${receiverId}",
        "FunctionCall": "메소드 호출함: ${methodName} 컨트랙트: ${receiverId}",
        "Stake": "${stake} 스테이킹함",
        "Transfer": {
            "received": "${signerId}으로부터 ${deposit} 받음",
            "transferred": "${receiverId}에게 ${deposit} 보냄"
        }
    },
    "actionsSign": {
        "addKey": "액세스 키 추가중",
        "createAccount": "계정 생성중: ${receiverId}",
        "deleteAccount": "계정 삭제중: ${receiverId}",
        "deleteKey": "액세스키 삭제중",
        "deployContract": "컨트랙트 배포중: ${receiverId}",
        "functionCall": "함수 호출중: ${methodName}",
        "stake": "스테이킹중: ${stake}Ⓝ ${publicKey}...",
        "transfer": "전송중: ${receiverId}에게 ${deposit}Ⓝ"
    },
    "addNode": {
        "desc": "이곳에 노드를 추가하기 전에 CLI를 통해서 설정해야 합니다.",
        "ipAddressInput": {
            "placeholder": "example: 0.0.0.0",
            "title": "노드의 IP 주소를 입력하십시오."
        },
        "nicknameInput": {
            "placeholder": "example: AWS Instance",
            "title": "닉네임을 기입하십시오 (선택사항)"
        },
        "pageText": "CLI를 통해 설정한 후 노드를 이곳에 추가하십시오.",
        "pageTitle": "노드 추가"
    },
    "amount": "양",
    "arguments": "인자",
    "authorizedApps": {
        "dashboardNoApps": "권한을 승인한 앱이 없습니다.",
        "feeAllowance": "수수료 한도",
        "ledger": "Ledger",
        "pageTitle": "권한을 승인받은 앱",
        "publicKey": "퍼블릭키"
    },
    "availableBalanceInfo": "락업이나 스테이킹을 제외한 사용가능한 잔고 <a href='/profile'>세부사항 보기→</a>",
    "availableBalanceProfile": "사용하거나 전송할 수 있는 NEAR 잔고",
    "back": "Back",
    "balance": {
        "balance": "총 잔고",
        "balanceLoading": "잔고 불러오는 중"
    },
    "balanceBreakdown": {
        "available": "사용가능 잔고",
        "reserved": "수수료를 위해 예약된"
    },
    "batchExportAccounts": {
        "confirmExportModal": {
            "accountToExport": "내보낼 계정",
            "title": "계정 내보내기 승인",
            "transactionDetails": "+ 트랜잭션 세부사항"
        },
        "exportScreen": {
            "desc": "내보내기를 시작하고 요청시</br> 각 계정을 확인하십시오.",
            "weFound": "${noOfAccounts}개의 디바이스로 내보낼 수 있는 계정을 찾았습니다."
        },
        "successScreen": {
            "title": "${noOfAccounts}개의 계정이 성공적으로 </br> 디바이스로 내보내졌습니다."
        }
    },
    "batchImportAccounts": {
        "confirmImportModal": {
            "accountToImport": "가져올 계정",
            "desc": "계정 보안을 위해 올바른 URL인지 확인하십시오:",
            "title": "다음의 계정 가져오기:",
            "transactionDetails": "+ 트랜잭션 세부사항"
        },
        "confirmUrlModal": {
            "desc": "계정 보안을 위해 올바른 URL인지 확인하십시오:",
            "title": "URL을 확인하십시오"
        },
        "enterKeyForm": {
            "confirmCaption": "패스워드 확인",
            "desc": "계정을 가져오려면 패스워드를 입력하십시오:",
            "placeholder": "2cCzKMPiCkFnF5mGbJcPgTGNykvxK4f3xyNAio4q931b",
            "title": "MyNearWallet에 오신 것을 환영합니다!"
        },
        "importScreen": {
            "desc": "가져오기를 시작하고 요청시</br> 각 계정을 확인하십시오.",
            "title": "다음으로부터 ${noOfAccounts} 계정 가져오기 준비됨 </br>"
        },
        "successScreen": {
            "desc": "계정 대시보드로 들어가려면 계정을 선택하십시오.",
            "title": "다음으로부터 ${noOfAccounts} 계정을 성공적으로 가져옴: </br> "
        }
    },
    "button": {
        "acceptAndContinue": "승인 및 진행",
        "addACustomAddress": "커스텀 주소 추가",
        "addFunds": "펀드 추가",
        "addNode": "노드 추가",
        "allow": "허가",
        "approve": "승인",
        "authorize": "권한 승인",
        "authorizing": "권한 승인중",
        "backToSwap": "스왑으로 돌아가기",
        "beginExport": "내보내기",
        "beginImport": "가져오기",
        "browseApps": "앱 찾아보기",
        "buy": "구매",
        "cancel": "취소",
        "cancelOperation": "오퍼레이션 취소",
        "cancelTransfer": "전송 취소",
        "claimAccount": "계정 클레임",
        "claimMyAccount": "내 계정 클레임",
        "close": "닫기",
        "Close": "닫기",
        "confirm": "확인",
        "confirmAndSend": "확인 및 보내기",
        "connect": "연결",
        "connecting": "연결중",
        "connectLedger": "Ledger에 연결",
        "continue": "계속",
        "continueSetup": "Setup으로 계속",
        "continueToMyAccount": "내 계정으로 계속",
        "copy": "복사",
        "copyImplicitAddress": "펀딩 주소 복사",
        "copyPhrase": "구문 복사",
        "copyUrl": "URL 복사",
        "createAccount": "계정 생성",
        "createAccountCapital": "계정 생성",
        "createNewAccount": "신규 계정 생성",
        "creatingAccount": "계정 생성중",
        "deauthorize": "권한 해제",
        "deAuthorizing": "권한 해제중",
        "deny": "무시",
        "disable": "제거",
        "disabling": "비활성화중",
        "dismiss": "종료",
        "download": "다운로드",
        "edit": "편집",
        "enable": "활성화",
        "enabling": "활성화 중",
        "findMyAccount": "계정 찾기",
        "finish": "완료",
        "fundWith": "Fund with",
        "generateNew": "새로 생성",
        "getStarted": "시작",
        "goBack": "돌아가기",
        "gotIt": "알았습니다",
        "goToDashboard": "대시보드로 가기",
        "importAccount": "계정 가져오기",
        "importExistingAccount": "기존 계정 가져오기",
        "learnMore": "더 알아보기",
        "learnMoreAboutNear": "NEAR에 대해서 더 알아보기",
        "loading": "로딩",
        "looksGood": "괜찮아 보입니다",
        "moreInformation": "More information",
        "needToEditGoBack": "편집으로 돌아가기",
        "next": "다음",
        "protectAccount": "계정 보호",
        "receive": "받기",
        "reconnect": "재연결",
        "recoverAccount": "계정 복구",
        "recoverExistingAccount": "기존 계정 복구",
        "recovering": "계정 찾는 중",
        "recoverYourAccount": "계정 복구",
        "removeAccount": "계정 제거",
        "removeNode": "노드 제거",
        "removingKeys": "키를 제거하는중",
        "reserveMyAccountId": "계정 ID 선정",
        "resubmit": "다시 제출",
        "retry": "다시 시도",
        "returnToApp": "앱으로 돌아가기",
        "saveChanges": "수정사항 저장",
        "secureMyAccount": "계정 보호하기",
        "send": "보내기",
        "setupPhrase": "복구 구문 설정",
        "signIn": "등록",
        "signingIn": "등록중",
        "signInLedger": "Ledger로 등록",
        "skip": "스킵",
        "skipThisForNow": "지금은 스킵",
        "stake": "스테이크",
        "staking": "스테이킹중",
        "startOver": "다시 시작",
        "subscribe": "구독",
        "swap": "스왑",
        "swapUSN": "USN 스왑",
        "ToMaine": "메인 스크린으로",
        "topUp": "구매",
        "transferring": "전송중",
        "tryAgain": "다시 시도",
        "unstaking": "스테이킹 해제중",
        "useMax": "Max",
        "verify": "확인 및 완료",
        "verifyCode": "코드 확인",
        "verifyCodeEnable": "확인 및 계속",
        "verifying": "확인중",
        "viewAll": "전체 보기",
        "viewFAQ": "FAQ 보기",
        "viewOnExplorer": "익스플로러에서 보기",
        "viewPrivateKey": "개인 키 보기",
        "withdrawing": "인출중"
    },
    "buyNear": {
        "bridgeSubTitle": "레인보우 브릿지를 사용해서 토큰을 Ethereum 네트워크에서 NEAR 네트워크로 보내십시오.",
        "bridgeTokens": "Ethereum에서 브릿지",
        "buyWith": "다음으로 구매하기:",
        "coinMarketCap": "CoinMarketCap",
        "coinMarketLink": "CoinMarketCap에서 더 알아보기",
        "descMoonpay": "원하는 지불 수단을 사용해서 MoonPay로 NEAR를 구매하십시오.",
        "descThree": "브릿지를 사용해서 토큰을 Ethereum 네트워크에서 NEAR 네트워크로 보내십시오.",
        "descTwo": "NEAR는 다음의 거래소에서 구매할 수 있습니다.",
        "descUtorg": "Utorg로 NEAR를 구매하십시오.",
        "moonPay": "MoonPay",
        "nearPurchaseSubTitle": "원하는 지불 수단을 사용하여 <br>NEAR를 구매하십시오",
        "nearPurchaseTitle": "NEAR 구매",
        "notSupported": "는 귀하의 지역에서 지원되지 않습니다.",
        "seeMore": "더 보기",
        "subTitle": "다양한 옵션을 통해 NEAR를 구매하십시오!",
        "supportedExchanges": "지원 거래소",
        "supportedSubTitle": "다양한 거래소에서 NEAR를 거래할 수 있습니다.",
        "title": "구매",
        "utorg": "Utorg"
    },
    "confirmLedgerModal": {
        "header": {
            "confirm": "디바이스에서 활동을 승인해 주십시오.",
            "processing": "처리중",
            "success": "Ledger 디바이스에서 계정(들)을 성공적으로 가져왔습니다.",
            "weFound": "디바이스에서 ${totalAccounts} 계정을 찾았습니다."
        },
        "one": "Ledger에서 sign-in 세부사항을 확인해야 합니다.",
        "two": "가져오고 싶은 각 계정에 대한 권한을 승인하기 위해 디바이스의 지시사항을 따라주십시오."
    },
    "connecting": "연결중",
    "connectLedger": {
        "connectLedger": "Ledger 연결",
        "ledgerConnected": "Ledger 연결됨",
        "modal": {
            "connect": {
                "cancelButton": "취소",
                "connectButton": "연결",
                "connectionPrompt": "Ledger가 안전한지 확인하고, NEAR 앱이 실행되었는지 확인하십시오.",
                "header": "Ledger 디바이스에 연결하십시오."
            },
            "connection-error": {
                "cancelButton": "취소",
                "connectButton": "재시도",
                "connectionPrompt": "Ledger가 안전한지 확인하고, NEAR 앱이 실행되었는지 확인하십시오. 또한 지원되는 기기에 있어야 합니다 <a href='https://nearhelp.zendesk.com/hc/en-us/articles/13972685751191-What-browsers-work-with-Ledger-Connect-' target='_blank' rel='noopener noreferrer'>브라우저</a>.",
                "header": "연결 실패."
            },
            "disconnected": {
                "cancelButton": "종료",
                "connectButton": "재연결",
                "connectionPrompt": "Ledger가 안전한지 확인하고, NEAR 앱이 실행되었는지 확인하십시오.",
                "header": "디바이스 연결이 해제되었습니다."
            }
        },
        "youMayNow": "이제 디바이스를 통해 "
    },
    "copy": {
        "default": "copied",
        "title": "복사"
    },
    "createAccount": {
        "accountIdInput": {
            "placeholder": "yourname.${data}",
            "title": "계정 ID"
        },
        "addACustomAddress": "커스텀 주소 추가",
        "alreadyHaveAnAccount": "이미 계정이 있습니까?",
        "invalidLinkDrop": {
            "one": "이 NEAR 드롭은 클레임되었습니다.",
            "title": "잘못된 링크",
            "two": "NEAR 드롭은 단일 계정을 생성하는 데에 사용되며, 이후 링크가 만료됩니다."
        },
        "landing": {
            "descOne": "NEAR 지갑은 NEAR 블록체인 상의 계정을 관리하는 안전한 지갑입니다.",
            "descTwo": "계정은 NEAR의 앱을 사용하거나 토큰이나 컬렉터블 (NFT)를 안전하게 보관하는 데 사용할 수 있습니다.",
            "title": "계정 생성"
        },
        "note": {
            "canContain": "계정 ID는 다음을 포함할 수 있습니다.:",
            "cannotContain": "계정 ID는 다음을 포함할 수 없습니다.:",
            "characters": "\"@\"와 \".\"",
            "digits": "숫자 (0-9)",
            "lowercase": "소문자 (a-z)",
            "maxCharacters": "64 글자 초과 (.${accountSuffix} 포함)",
            "minCharacters": "2 글자 미만",
            "separators": "(_-)는 분리 기호로 사용할 수 있습니다"
        },
        "pageText": "NEAR 계정을 사용하기 위해 계정 ID를 입력하십시오. 계정 ID는 자산을 보내고 받는 것을 포함한 NEAR의 모든 활동에 사용됩니다.",
        "pageTitle": "계정 ID 선정",
        "recoverItHere": "기존 계정 가져오기",
        "setupPassphrase": {
            "generatePassphrase": {
                "desc": "안전을 위해 계정당 하나의 복구 구문을 만드는 것을 추천합니다.",
                "descTwo": "더 편리한 옵션으로 계정 간에 기존의 복구 구문을 사용할 수 있습니다. 이 옵션은 언제나 다시 설정할 수 있습니다.",
                "tite": "새로운 복구 구문을 만들겠습니까?"
            },
            "sharedPassphrase": {
                "desc": "복구 구문이 확인되었습니다. 신규 계정에 자금을 넣으면 <b>${numberOfAccounts} 계정</b>이 복구 구문을 공유합니다.",
                "descTwo": "이 복구 구문을 사용할 때마다 다음의 계정을 가져옵니다:",
                "tite": "공통 복구 구문"
            },
            "verifyPassphrase": {
                "desc": "신규 계정을 보호하기 위해 기존의 복구 구문을 입력하십시오..",
                "passPhrasePlaceholder": "bubble word sun join impact exist ramp skull title hollow symbol very",
                "tite": "귀하의 복구 구문을 확인하십시오",
                "yourPassphrase": "귀하의 복구 구문"
            }
        },
        "step": "단계 ${step}/${total}",
        "terms": {
            "agreeBtn": "동의 및 계속",
            "declineBtn": "거부",
            "desc": "NEAR 계정 생성을 계속하면, NEAR 지갑 이용약관과 개인정보정책에 동의하게 됩니다. 신중히 읽을 것을 부탁드립니다:",
            "privacyCheck": "나는 개인정보정책에 동의합니다",
            "privacyLink": "개인정보정책",
            "termsCheck": "나는 이용약관에 동의합니다",
            "termsLink": "약관",
            "title": "약관"
        },
        "termsPage": {
            "descOne": "NEAR 계정은 <a href='https://docs.near.org/docs/concepts/account' rel='noopener noreferrer' target='_blank'>네트워크에 존재하기 위해</a> <b>적어도 ${amount}</b>의 최소 잔고가 필요합니다.",
            "descTwo": "계정 주소를 선정한 후, 계정을 활성화하기 위해서는 조금의 초기 예치금이 필요합니다.",
            "disclaimer": "NEAR 계정을 생성하면 MyNearWallet <a href='/terms' rel='noopener noreferrer' target='_blank'>이용약관</a>과 <a href='/privacy' rel='noopener noreferrer' target='_blank'>개인정보정책</a>에 동의하게됩니다.",
            "title": "시작하기 전에..."
        }
    },
    "dashboard": {
        "activity": "최근 활동",
        "noActivity": "이 계정에서 활동한 적이 없습니다."
    },
    "dashboardActivity": {
        "message": {
            "AddKey": {
                "forContract": "<span>수신자</span> <span>${permissionReceiverId}</span>",
                "forReceiver": "<span>수신자</span> <span>${receiverId}</span>"
            },
            "CreateAccount": "<span>계정</span> <span>${receiverId}</span>",
            "DeleteAccount": "<span>계정</span> <span>${receiverId}<span>",
            "DeleteKey": "",
            "DeployContract": "<span>컨트랙트</span> <span>${receiverId}</span>",
            "FunctionCall": "<span>${methodName}</span> in contract <span>${receiverId}</span>",
            "FunctionCallDetails": {
                "first": "<span>메서드</span> <span>${methodName}</span>",
                "second": "<span>In contract</span> <span>${receiverId}</span>"
            },
            "Stake": "<span>스테이크</span><span>${stake}</span>",
            "Transfer": {
                "received": "<span>발신자</span> <span>${signerId}</span>",
                "transferred": "<span>수신자</span> <span>${receiverId}</span>"
            }
        },
        "title": {
            "AddKey": {
                "forContract": "액세스 키 추가됨",
                "forReceiver": "액세스 키 추가됨"
            },
            "CreateAccount": "신규 계정 생성됨",
            "DeleteAccount": "계정 삭제됨",
            "DeleteKey": "키 삭제됨",
            "DeployContract": "컨트랙트 배포됨",
            "FunctionCall": "매서드 호출됨",
            "Stake": "스테이킹됨",
            "Transfer": {
                "received": "NEAR 받음",
                "transferred": "NEAR 보냄"
            }
        }
    },
    "disclaimer": {
        "text": "개발자의 프리뷰 지갑입니다. NEAR Protocol DevNet을 위해서만 사용해야 합니다. 다음에서 더 알아보기: ",
        "title": "DISCLAIMER"
    },
    "emailSubscribe": {
        "placeholder": "귀하의 이메일...",
        "title": "얼리 액세스를 위해 아래에 등록하십시오"
    },
    "enterAccountNameLedgerModal": {
        "header": "계정 이름 입력",
        "one": "서명할 계정의 이름을 입력하십시오."
    },
    "error": "오류",
    "errors": {
        "ledger": {
            "disconnected": "Ledger 연결이 해제되었습니다.",
            "U2F_4": "Ledger 디바이스 서명 실패: U2F DEVICE_INELIGIBLE. Ledger 디바이스 연결 상태를 확인하고 잠금을 해제하십시오.",
            "U2FNotSupported": "Ledger에 U2F 브라우저 지원이 필요합니다. Chrome, Opera, Firefox의 U2F 익스텐션을 사용하십시오. 또한 HTTPS 연결이 되었는 지 확인하십시오."
        },
        "type": {
            "LackBalanceForState": "사용가능 잔고가 너무 적어서 계정에서 어떠한 활동도 할 수 없습니다. 계정에 NEAR를 보내고 다시 시도하십시오.",
            "RetriesExceeded": "너무 많은 트랜잭션 시도를 하였습니다."
        }
    },
    "existingAccount": {
        "fundNewAccount": {
            "titleOne": "트랜잭션 승인",
            "titleTwo": "신규 계정에 입금"
        },
        "selectAccount": {
            "desc": "<b>최소 ${amount} NEAR</b>를 보유한 다른 계정이 있으시다면, 신규 계정을 활성화시키기 위해 자금을 보내십시오!",
            "descTwo": "사용할 계정을 선택하고 최저 입금액을 승인하십시오.",
            "title": "기존의 계정에서 자금 가져오기."
        }
    },
    "explore": {
        "banner": {
            "button": "NEAR 구매",
            "text": "지갑에 채우기"
        },
        "categories": {
            "collectNFTs": "NFT 수집",
            "exchanges": "거래소",
            "playToEarn": "Play To Earn",
            "startEarning": "Earn 시작"
        },
        "sectionName": "둘러보기",
        "seeAll": "전체 보기",
        "trendingProjects": "트렌딩 프로젝트"
    },
    "exploreApps": {
        "desc": "Earn, mint, play! NEAR 생태계를 탐험하십시오.",
        "exploreApps": "Apps 둘러보기",
        "exploreDeFi": "DeFi 둘러보기",
        "exploreNear": "NEAR 둘러보기",
        "text": "Earn, mint, play! NEAR 생태계를 탐험하십시오.",
        "title": "What's on NEAR?"
    },
    "exportPrivateKey": {
        "button": "로컬 개인 키 내보내기",
        "desc": "개인 키를 공유하지 마십시오! 개인 키를 가지고 있으면 계정에 대한 모든 권한을 얻게됩니다.<br /><br />",
        "enterAccountAddress": "다음을 보기 위해 계정 주소를 입력하십시오:<br />개인 키.",
        "title": "개인 키를 보겠습니까?"
    },
    "footer": {
        "contactSupport": "커뮤니티로 가기",
        "copyrights": "Genin Saifu LLC. All Rights Reserved.",
        "desc": "Solana는 빛의 속도로 킬러 앱을 원활하게 실행하는 원자적으로 구성 가능한 세계 컴퓨터입니다.",
        "learnMore": "더 알아보기",
        "needHelp": "질문이 있습니까?",
        "privacyPolicy": "개인정보정책",
        "termsOfService": "이용약관"
    },
    "fullAccessKeys": {
        "authorizedTo": "AUTHORIZED TO",
        "dashboardNoKeys": "완전 권한 액세스 키가 존재하지 않습니다",
        "deAuthorizeConfirm": {
            "desc": "완전 권한 액세스 키는 영구적으로 비활성화됩니다.",
            "title": "정말 완전 권한 액세스 키를 비활성화하겠습니까?"
        },
        "noKeys": "지갑에 연결된 App이 없습니다. 연결된 App을 여기서 관리할 수 있습니다.<br /><br />NEAR의 App을 찾으십시오:",
        "pageTitle": "완전 권한 액세스 키",
        "submitAnyTransaction": "대신해서 트랜잭션을 보낼 수 있습니다.",
        "useContract": "대신해서 <b>${receiverId}</b> 컨트랙트를 사용할 수 있습니다.",
        "viewYourAccountName": "계정 이름 보기"
    },
    "getBalance": "잔고 가져오기",
    "hardwareDevices": {
        "desc": "하드웨어 지갑을 사용하여 계정의 보안을 향상하십시오.",
        "disable": {
            "desc": "비활성화하기 전에 다른 복구 방법이 있는 지 확인하십시오.",
            "disable": "Ledger 비활성화",
            "keep": "아니요, Ledger를 유지합니다.",
            "title": "정말로 Ledger를 비활성화하겠습니까?"
        },
        "ledger": {
            "auth": "권환 승이된",
            "connect": "현재 Ledger가 연결되어 있지 않습니다. Ledger를 다시 연결해주십시오.",
            "disclaimer": "Ledger 디바이스를 비활성화하려면 다른 복구 방법이 활성화되어 있어야 합니다.",
            "title": "Ledger 하드웨어 지갑"
        },
        "title": "하드웨어 장치"
    },
    "importAccount": {
        "noId": "계정 가져오는중",
        "noIdFailed": "계정 가져오기를 실패했습니다.",
        "withId": "계정 가져오는중: ${accountId}",
        "withIdFailed": "계정 가져오기 실패: ${accountId}"
    },
    "importAccountWithLink": {
        "accountFound": "1 계정 찾음",
        "accountImported": "계정 가져옴",
        "accountsFound": "${count}개의 계정 찾음",
        "alreadyImported": "링크를 통해 계정을 가져왔습니다.",
        "continue": "브라우저를 골라서 이 과정을 계속하십시오.",
        "copyUrl": "URL 복사",
        "foundAccount": "이 링크에서 <b>1개의 계정</b>을 찾았습니다.",
        "foundAccounts": "이 링크에서 <b>${count}개의 계정</b>을 찾았습니다.",
        "goToAccount": "계정으로 가기",
        "import": "가져오기",
        "importAccount": "계정 가져오기",
        "preferedBrowser": "선호하는 브라우저가 아니라면,"
    },
    "importing": "가져오는 중",
    "initialDeposit": {
        "claimAccount": {
            "desc": "입금되었습니다! 클레임시에 신규 계정에 입금액이 전송됩니다.",
            "disclaimer": {
                "checkBox": "동의하고 계속",
                "desc": "중요: 일회성 펀딩 주소는 더 이상 유효하지 않습니다. 다른 디바이스나 거래소에 등록된 일회성 펀딩 주소를 만들어진 계정 ID로 교체하십시오."
            },
            "title": "계정을 클레임하십시오"
        },
        "creditCard": {
            "desc": "MoonPay를 통해서 <b>최소 ${amount} NEAR</b>를 구매하십시오. MoonPay가 자동으로 신규 계정에 자금을 입금해줄 것입니다.",
            "title": "신용카드를 이용해서 신규 계정에 자금을 넣으십시오."
        },
        "manualDeposit": {
            "desc": "초기 예치금으로 <b>최소 ${amount}</b>의 금액을 아래 일회성 펀딩 주소에 보내십시오.",
            "title": "Submit a manual deposit."
        }
    },
    "input": {
        "accountId": {
            "placeholder": "e.g. johndoe.near",
            "placeHolderAlt": "계정 ID",
            "subLabel": "계정 ID는 <b>.near<b>와 같은 최상위 계정을 포함하거나 64 자리로 이루어져야 합니다.",
            "title": "계정 ID"
        },
        "enterWord": {
            "placeholder": "",
            "title": "단어 <b>#${wordId}</b>"
        }
    },
    "landing": {
        "banner": "NEAR 지갑은 프라이빗 베타입니다.",
        "desc": "NEAR와 호환이 되는 자산을 <span>NEAR 지갑</span>에 안전하게 <br>보관하고 스테이킹하십시오.",
        "or": "or",
        "title": "Ready, set... SOL!"
    },
    "ledgerSignTxModal": {
        "ADD_ACCESS_KEY": "Ledger에서 로그인 세부사항을 승인해야 합니다.",
        "ADD_ACCESS_KEY_SEED_PHRASE": "Ledger에서 완전한 권환 액세스에 관한 세부사항을 승인해야 합니다.",
        "ADD_LEDGER_ACCESS_KEY": "Ledger에서 공개 키에 관한 세부사항을 승인해야 합니다.",
        "CREATE_ACCOUNT_FROM_IMPLICIT": "Ledger에서 계정 생성에 관한 세부사항을 승인해야 합니다. 임시 계정에서 잔고를 옮기는 트랜잭션을 포함합니다.",
        "CREATE_NEW_ACCOUNT": "Ledger에서 신규 계정을 위한 키 추가에 관한 세부사항을 승인해야 합니다.",
        "DEFAULT": "Ledger에서 트랜잭션에 관한 세부사항을 승인해야 합니다.",
        "DELETE_RECOVERY_METHOD": "Ledger에서 키 삭제에 관한 세부사항을 승인해야 합니다.",
        "DISABLE_LEDGER": "Ledger에서 공개 키에 관한 세부사항을 승인해야 합니다.",
        "GET_LEDGER_PUBLIC_KEY": "Ledger에서 공개 키 접근을 승인해야 합니다.",
        "REMOVE_ACCESS_KEY": "Ledger에서 키 삭제에 관한 세부사항을 승인해야 합니다.",
        "REMOVE_NON_LEDGER_ACCESS_KEYS": "Ledger에서 세부사항을 승인해야 합니다.",
        "SEND_MONEY": "Ledger에서 전송에 관한 세부사항을 승인해야 합니다.",
        "SET_SIGN_TRANSACTION_STATUS": "Ledger에서 입금에 관한 세부사항을 승인해야 합니다.",
        "SETUP_RECOVERY_MESSAGE": "Ledger에서 완전 권한 액세스에 관한 세부사항을 승인해야 합니다.",
        "SIGN_AND_SEND_TRANSACTIONS": "Ledger에서 입금에 관한 세부사항을 승인해야 합니다.",
        "STAKE": "Ledger에서 스테이킹을 승인해야 합니다.",
        "UNSTAKE": "Ledger에서 스테이킹 해제를 승인해야 합니다.",
        "WITHDRAW": "Ledger에서 인출을 승인해야 합니다."
    },
    "link": {
        "account": "계정",
        "authorizedApps": "권한을 승인받은 App",
        "buyUSN": "$USN 사기",
        "donateToUkraine": "우크라이나에 기부",
        "explore": "둘러보기",
        "fullAccessKeys": "완전 권한 액세스 키",
        "help": "Help",
        "noAccount": "다른 계정이 존재하지 않습니다",
        "receive": "받기",
        "send": "보내기",
        "staking": "스테이킹",
        "swap": "스왑",
        "switchAccount": "계정",
        "wallet": "지갑"
    },
    "linkdropLanding": {
        "claiming": "클레임하는 중",
        "ctaAccount": "기존의 계정으로 클레임",
        "ctaLogin": "로그인하고 클레임",
        "ctaNew": "신규 계정으로 클레임",
        "desc": "(로그인 된) 기존의 계정에서 NEAR 드롭을 클레임하거나, 신규 계정을 만들어 클레임하십시오.",
        "modal": {
            "desc": "NEAR 드롭이 자동으로 계정에 입금되었습니다.",
            "title": "NEAR 드롭이 클레임되었습니다."
        },
        "or": "or",
        "title": "NEAR 드롭을 받았습니다!"
    },
    "loading": "로딩중...",
    "loadingNoDots": "로딩",
    "lockedBalance": "이 NEAR는 락업 컨트랙트에 묶여있어서 인출할 수 없지만 NEAR를 위임하거나 스테이킹할 수 있습니다. 락업이 해제되면 Unlocked Balance에서 찾을 수 있으며, 인출할 수 있습니다. (moving to your Available Balance).",
    "login": {
        "cliLoginSuccess": {
            "pageText": "윈도우를 닫고 터미널의 지시를 따라주십시오.",
            "pageTitle": "성공적으로 NEAR Shell에 권한을 승인하였습니다!"
        },
        "confirm": {
            "pageText": "${appTitle}에 <b>완전 권한</b>을 부여하려고 합니다!",
            "pageTextSecondLine": "<b>승인하려면</b>, 아래에 계정 ID를 입력하십시오.",
            "pageTitle": "확실합니까?",
            "username": "계정 ID"
        },
        "details": {
            "callFunctions": "스마트 컨트랙트에서 함수 호출",
            "createAndDeleteAccessKeys": "액세스 키를 생성 혹은 삭제",
            "createNewAccounts": "신규 계정 생성",
            "deploySmartContracts": "스마트 컨트랙트 배포",
            "detailedDescription": "트랜잭션의 세부 정보",
            "forContract": "컨트랙트에",
            "function": "함수",
            "noDescription": "이 메서드에 대한 설명 없음",
            "stakeAndUnstake": "NEAR 토큰 스테이킹 혹은 해제",
            "thisAllows": "다음에게 ${appTitle}를 허용함:",
            "transferTokens": "다른 계정으로 토큰을 전송"
        },
        "form": {
            "accessYourAccount": "계정에 접근.",
            "accountIdOnly": "(이)가 계정 ID를 요구합니다.",
            "isRequestingFullAccess": "(이)가 <b>완전 권한</b>을 요구합니다",
            "isRequestingTo": "이(가) 다음을 요구합니다:",
            "thisDoesNotAllow": "앱에 토큰을 전송할 권한을 부여하지는 않습니다.",
            "thisProvidesAccess": "귀하의 <b>모든 토큰</b>에 접근 권한을 허가합니다.<br />주의하십시오!",
            "toYourAccount": "to your account."
        },
        "v2": {
            "connectConfirm": {
                "desc": "신뢰할 수 있는 사이트에만 연결하십시오. <br>연결되면 <a href='${contractIdUrl}' target='_blank' rel='noreferrer'>${appReferrer}</a>(이)가 다음을 가집니다: <b>${accessType}</b>",
                "feeAllowance": {
                    "desc": "어플리케이션은 사용중에 발생한 네트워크 수수료를 <b>${amount} NEAR</b>까지 소모할 권한을 승인받습니다.",
                    "title": "네트워크 수수료 한도"
                },
                "fullAccess": "완전한 허가",
                "fullAccessModal": {
                    "desc": "주의하십시오. 완전 권한 허용은 컨트랙트나 어플리케이션이 귀하의 토큰 잔고에 접근할 수 있도록 합니다. 승인하려면 아래에 계정 ID를 입력하십시오.",
                    "title": "계정에 대한 완전 권한을 허용합니까?"
                },
                "fullAccessWarning": "이 사이트는 계정에 대한 완전 권한을 요구합니다. 계정 ID를 인증하여 완전 권한을 허가하십시오.",
                "limitedAccess": "제한적 액세스",
                "permissions": {
                    "callMethodsOnContract": "허가된 계정을 대신해서 스마트 컨트랙트에서 매서드 호출",
                    "notTransferTokens": "앱에 토큰을 전송할 권한을 부여하지는 않습니다.",
                    "transferTokens": "계정에서 토큰 전송",
                    "viewAddress": "허가된 계정의 주소 보기",
                    "viewBalance": "허가된 계정의 잔고 보기"
                },
                "title": "다음으로 연결하기:"
            },
            "connectWithNear": {
                "desc": "어플리케이션이 다음의 액세스를 요구합니다: <b>${accessType}</b> <br> 연결하고 싶은 계정을 선택하십시오.",
                "fullAccess": "완전 권한 액세스",
                "limitedAccess": "제한된 액세스",
                "title": "Connect with NEAR"
            }
        }
    },
    "migration": {
        "redirectCaption": "더 알아보기",
        "transferCaption": "계정 전송"
    },
    "minimumBalance": "이 수치는 계정을 활성화 상태로 유지하기 위해 필요한 최소 NEAR 잔고입니다. 이 잔고는 NEAR 블록체인에서 계정이 사용하는 저장 공간의 크기에 따라 변합니다.",
    "mobileDeviceAccess": {
        "importCode": {
            "desc": "이미 데스크탑 디바이스에 로그인되어있으면 모바일 액세스 코드를 스캔해서 계정을 가져오십시오."
        },
        "showCode": {
            "button": "모바일 코드 보기",
            "desc": "모바일 디바이스에 완전한 계정 권한을 승인하기 위해 모바일 액세스 코드를 스캔하십시오.",
            "modal": {
                "desc": "모바일 디바이스에 계정을 가져오기 위해 아래에 있는 QR 코드를 스캔하십시오.",
                "disclaimer": "<b>이 코드는 민감한 정보를 포함하고 있으며 모바일 디바이스에 계정에 대한 완전한 액세스를 허용합니다.</b> 공유하거나 노출되지 않도록 조심하십시오. 소유하고 있는 안전한 디바이스에 계정을 불러오십시오.",
                "reveal": "드러내기",
                "title": "모바일 액세스 코드"
            }
        },
        "title": "모바일 디바이스 액세스"
    },
    "moreNearWalletsModal": {
        "desc": "곧 NEAR 지갑은 더 이상 지원되지 않으며, 계정을 관리하기 위해서는 다른 지갑이 필요합니다. 다른 지갑을 사용해서 신규 계정을 만드는 것을 추천합니다.",
        "primaryButton": "NEAR 지갑 추가",
        "title": "NEAR 지갑에 계정을 만들고 싶으십니까?"
    },
    "networkBanner": {
        "desc": "이 지갑과 네트워크는 테스트 목적입니다. 토큰과 다른 자산은 실제 가치가 0입니다. 생성된 계정은 메인넷으로 옮길 수 없습니다.",
        "header": "NEAR 테스트 지갑",
        "title": "테스트용 지갑"
    },
    "NFTDetail": {
        "owner": "소유자",
        "transfer": "전송"
    },
    "NFTs": {
        "emptyState": "아직 가지고 있는 컬렉션이 없습니다.",
        "loadMore": "더 불러오기"
    },
    "NFTTransfer": {
        "cancel": "취소",
        "confirm": "확인",
        "continue": "계속",
        "enterReceiver": "수신인의 주소를 입력하고 트랜잭션을 승인하십시오.",
        "next": "다음",
        "transactionComplete": "전송 완료!",
        "transferNft": "NFT 전송",
        "viewTransaction": "트랜잭션 보기",
        "youSent": "<span class='color-blue'>${title}</span>을 <span class='color-green'>${receiverId}</span>에게 보냈습니다"
    },
    "nodeDetails": {
        "pageText": "노드의 세부사항을 업데이트하십시오.",
        "pageTitle": "노드 세부사항"
    },
    "nodeStaking": {
        "nodes": {
            "noNode": {
                "text": "노드를 지갑 계정에 연결합니다.",
                "title": "노드가 설정되어 있습니까? 여기에 추가하십시오."
            },
            "title": "노드"
        },
        "pageText": "nodeandstaking.near",
        "pageTitle": "노드 & 스테이킹",
        "staking": {
            "title": "스테이킹",
            "youAreStaking": "스테이킹중:"
        },
        "steps": {
            "one": {
                "desc": "Docs에 노드를 돌리는 방법에 대한 설명이 있습니다.",
                "title": "1. 설정하고 CLI를 통해 노드 돌리기"
            },
            "three": {
                "desc": "여기나 CLI에서 스테이킹하십시오. Docs를 참고하십시오.",
                "title": "3. 스테이킹 시작"
            },
            "title": "노드를 돌리거나 스테이킹을 하려면 다음 단계를 따라주십시오:",
            "two": {
                "desc": "동기화되지 않은 노드는 블록을 생성하거나 검증할 수 없습니다.",
                "title": "2. 노드가 동기화되기까지 대기"
            }
        }
    },
    "of": "of",
    "ofTotal": "(전체 중)",
    "or": "or",
    "pageNotFound": {
        "displayTitle": "404",
        "returnToWallet": "지갑으로 돌아가기",
        "title": "페이지가 존재하지 않습니다."
    },
    "poweredByCoinGecko": "Prices powered by <a href='https://www.coingecko.com/en/api' target='_blank' rel='noopener noreferrer'>CoinGecko</a>",
    "profile": {
        "account": {
            "available": "사용가능 잔고",
            "availableToTransfer": "전송 가능",
            "availableToWithdraw": "인출 가능",
            "inStakingPools": "스테이킹풀 예치",
            "pendingRelease": "릴리즈 준비중",
            "reservedForStorage": "활성화 준비금",
            "reservedForTransactions": "트랜잭션 비용 준비금",
            "staked": "스테이킹",
            "staking": "스테이킹",
            "unstaked": "언스테이크",
            "walletBalance": "지갑 잔고",
            "walletId": "지갑 ID"
        },
        "accountDoesNotExistBanner": {
            "desc": "<a href='https://explorer.near.org/accounts/${data}' rel='noopener noreferrer' target='_blank'><b>이 계정</b></a>에 기록된 활동이 없습니다. NEAR 지갑의 모든 기능을 활용하기 위해 계정에 NEAR를 보내십시오."
        },
        "authorizedApps": {
            "title": "권한을 승인받은 앱"
        },
        "details": {
            "availableBalance": "사용가능 잔고",
            "locked": "락업",
            "lockPopup": {
                "createAnotherAccount": "다른 계정을 생성",
                "text": "계정 ID는 시스템에서 고유한 식별자이며, 데이터와 자산 소유권이 연결되어 있기 때문에 변경할 수 없습니다. 그러나 원하는 이름으로 ${link}하여 자산을 전송할 수 있습니다.",
                "title": "이것이 왜 잠겨 있습니까?"
            },
            "minBalance": "최소 잔고",
            "profile": "프로필",
            "public": "퍼블릭",
            "qrDesc": "이 주소로 보내기 위해서 핸드폰 카메라 사용",
            "staked": "스테이킹",
            "totalBalance": "총 잔고",
            "unlocked": "언락",
            "unvested": "언베스팅",
            "username": "계정 ID",
            "visibleTo": "VISIBLE TO"
        },
        "lockup": {
            "locked": "락업",
            "lockupBalance": "락업된 잔고",
            "lockupId": "락업 ID",
            "unlocked": "언락"
        },
        "lockupBanner": {
            "cta": "지갑으로 전송",
            "title": "락업 중 <b>${amount}</b> 만큼 인출할 수 있습니다!"
        },
        "pageTitle": {
            "default": "계정 세부 사항",
            "loading": "로딩중...",
            "notFound": "계정: ${accountId} 못찾음"
        },
        "security": {
            "lessSecure": "가장 편리한",
            "lessSecureDesc": "이 옵션들은 적은 양의 NEAR나 다른 자산을 가지고 있는 계정에 사용하십시오. 편리하지만 더 안전한 옵션에 비해서는 위험합니다. 이메일이나 SMS가 훼손되면 자산이 위기에 쳐할 수 있습니다.",
            "mostSecure": "가장 안전한 (추천)",
            "mostSecureDesc": "Ledger는 가장 안전한 옵션입니다. 복구 구문은 알맞게 사용하면 안전합니다.(복구 구문은 안전하게 보관되어야 합니다.)",
            "title": "보안 & 복구"
        },
        "twoFactor": "2FA",
        "twoFactorDesc": "SMS나 이메일을 통해 트랜잭션이나 서명을 인증하십시오."
    },
    "reCAPTCHA": {
        "disclaimer": "이 사이트는 reCAPTCHA로 보호되며 Google <a href='https://policies.google.com/privacy' target='_blank' rel='noopener noreferrer'>개인정보정책</a>과 <a href='https://policies.google.com/terms' target='_blank' rel='noopener noreferrer'>이용약관</a>이 적용됩니다.",
        "fail": {
            "desc": "새로운 NEAR 계정에 권한을 부여하는 데 reCAPTCHA가 필요합니다.",
            "link": "자신의 계정에 입금하십니까?",
            "title": "reCAPTCHA를 불러오지 못했습니다."
        },
        "loading": "reCAPTCHA 로딩중..."
    },
    "receivePage": {
        "addressTitle": "지갑 주소",
        "copyAddressLinkLong": "주소 URL 복사",
        "copyAddressLinkShort": "복사",
        "qrCodeTitle": "QR 코드 스캔",
        "snackbarCopySuccess": "주소 URL 복사됨!"
    },
    "recoverAccount": {
        "actionRequired": "이 메세지는 복구 링크를 포함합니다. 해당 링크를 클릭해서 복구를 시작하십시오!",
        "actionType": "복구",
        "cannotResend": "이 메세지는 이메일/휴대폰 복구 옵션을 통해 보내졌습니다. 해당 메세지는 한 번만 보내집니다.",
        "email": {
            "desc": "이메일에서 near.org에서 보낸 다음 제목의 메일을 확인하십시오:",
            "subject": "\"Important: Genin Wallet Recovery Email\".",
            "title": "이메일"
        },
        "ledger": {
            "desc": "Ledger가 안전하게 연결되었는지 확인하고, NEAR 앱이 실행되었는지 확인하십시오..",
            "title": "Ledger"
        },
        "pageText": "복구 방법을 설정했다면, 계정을 불러오기 위해 아래의 지시사항을 따라주십시오.",
        "pageTitle": "계정 불러오기",
        "phone": {
            "desc": "휴대폰 메세지함에서 아래 번호에서 전송한 SMS 메세지를 확인하십시오:",
            "number": "+14086179592.",
            "title": "휴대폰"
        },
        "phrase": {
            "desc": "12 단어 복구 구문을 가지고 있는지 확인하고 아래를 눌러 복구 작업을 시작하십시오.",
            "title": "복구 구문"
        }
    },
    "recoverSeedPhrase": {
        "couldNotFindAccountModal": {
            "buttonImport": "그래도 가져오기",
            "desc": {
                "ledger": "이 Ledger 키를 통해 <b>활성화된 계정</b>을 찾을 수 없습니다. 활성화되지 않은 계정일 수 있습니다.",
                "phrase": "이 복구 구문을 통해 <b>활성화된 계정</b>을 찾을 수 없습니다. 잘못된 복구 구문이거나 활성화되지 않은 계정일 수 있습니다."
            },
            "title": "계정을 찾을 수 없습니다"
        },
        "pageText": "백업한 복구 구문을 입력하십시오.",
        "pageTitle": "복구 구문을 통해 복구",
        "seedPhraseInput": {
            "placeholder": "correct horse battery staple...",
            "title": "복구 구문 (12 단어)"
        }
    },
    "recoverWithLink": {
        "errorP": "이메일이나 휴대폰의 최근 기록을 확인하십시오. 이전 링크는 유효하지 않습니다.",
        "errorTitle": "링크 만료됨",
        "pOne": "\"계속\"을 눌러 계정을 복구하십시오:",
        "pTwo": "선호하는 브라우저가 아니라면 URL을 복사해서 다른 브라우저에서 작업을 완료하십시오.",
        "snackbarCopySuccess": "복구 URL 복사됨",
        "title": "계정 복구"
    },
    "recoveryMgmt": {
        "disableInputPlaceholder": "승인하려면 계정 ID를 입력하십시오",
        "disableNo": "아니요, 유지합니다",
        "disableNotAllowed": "계정은 적어도 하나의 복구 수단을 가지고 있어야 합니다. 진행하려면 다른 복구 수단을 활성화하십시오.",
        "disableTextLink": "받으신 매직 링크는 영구적으로 비활성화됩니다.",
        "disableTextPhrase": "현재의 복구 구문은 영구적으로 비활성화됩니다.",
        "disableTitle": "정말로 이 수단을 비활성화하겠습니까?",
        "enabled": "활성화",
        "methodTitle": {
            "email": "이메일",
            "phone": "휴대폰",
            "phrase": "복구 구문"
        },
        "noRecoveryMethod": "계정을 복구할 수단이 존재하지 않습니다. 아래에서 복구 수단을 하나 추가하십시오.",
        "recoveryLinkSent": "복구 링크 보냄!",
        "resend": {
            "email": "이메일 다시보내기",
            "phone": "SMS 다시보내기"
        },
        "title": "복구 수단",
        "unsupportedMethodEmail": "이메일 복구수단을 제거하고 있습니다. 이 수단이 비활성화되면 다시 활성화할 수 없습니다.",
        "unsupportedMethodPhone": "SMS 복구수단을 제거하고 있습니다. 이 수단이 비활성화되면 다시 활성화할 수 없습니다."
    },
    "reduxActions": {
        "ADD_ACCESS_KEY": {
            "error": "활동을 허가하는 중에 오류가 발생했습니다. 다시 시도하십시오!",
            "success": "${title}은 이제 귀하의 계정을 사용할 수 있습니다."
        },
        "ADD_ACCESS_KEY_SEED_PHRASE": {
            "error": "복구 구문 복구를 설정하는 중에 오류가 발생했습니다. 다시 시도하십시오.",
            "success": "복구 구문 설정이 완료되었습니다."
        },
        "ADD_LEDGER_ACCOUNT_ID": {
            "error": "어떠한 계정에 대한 접근이 거부되었습니다. 계정이 복구되지 못했습니다. <br>계속하려면 Ledger 디바이스에서 read-only 액세스 권한을 허가해야합니다. 다시 시도하십시오.",
            "success": ""
        },
        "CHECK_ACCOUNT_AVAILABLE": {
            "error": "유저를 찾지 못했습니다.",
            "success": "유저를 찾았습니다."
        },
        "CHECK_IS_NEW": {
            "error": "이미 존재하는 ID입니다. 다른 ID를 선택하십시오.",
            "success": "축하합니다! ${accountId}는 사용 가능합니다."
        },
        "CHECK_NEW_ACCOUNT": {
            "error": "이미 존재하는 ID입니다. 다른 ID를 선택하십시오.",
            "success": "축하합니다! ${accountId}는 사용 가능합니다."
        },
        "CREATE_ACCOUNT_WITH_SEED_PHRASE": {
            "error": "",
            "success": ""
        },
        "CREATE_NEW_ACCOUNT": {
            "error": "이미 존재하는 ID입니다. 다른 ID를 선택하십시오.",
            "success": "축하합니다! ${accountId}는 사용 가능합니다."
        },
        "default": {
            "error": "죄송합니다, 오류가 발생했습니다. 다시 시도하십시오.",
            "success": ""
        },
        "DELETE_RECOVERY_METHOD": {
            "error": "",
            "success": ""
        },
        "DEPLOY_MULTISIG": {
            "error": "",
            "success": ""
        },
        "Deserialization": "",
        "GET_LEDGER_ACCOUNT_IDS": {
            "error": "공개 키 액세스가 거부되었습니다. 계정이 복구되지 못했습니다. <br/>계속하려면 NEAR 지갑에 공개 키 접근을 허용하십시오. 다시 시도하십시오.",
            "success": ""
        },
        "LackBalanceForState": "사용가능 잔고가 너무 적어서 계정에서 어떠한 활동도 할 수 없습니다. 계정에 NEAR를 보내고 다시 시도하십시오.",
        "NotEnoughBalance": "이 행동을 하기에는 계정의 잔고가 부족합니다.",
        "PROMPT_TWO_FACTOR": {
            "invalidCode": "유효하지 않은 2FA 코드입니다. 다시 시도하십시오."
        },
        "RECOVER_ACCOUNT_SEED_PHRASE": {
            "error": "계정 복구를 실패했습니다. 이 복구 구문에 대한 계정이 존재하지 않습니다.",
            "success": "복구 구문을 통한 복구 과정이 완료되었습니다. <br/><b>${numberOfAccounts} 계정</b>이 성공적으로 복구되었습니다.<br/>가장 최근의 계정이 Active로 설정됩니다."
        },
        "REFRESH_ACCOUNT_EXTERNAL": {
            "error": "계정: <b>${accountId}</b> 찾을 수 없음"
        },
        "REMOVE_ACCESS_KEY": {
            "error": "Ledger가 연결되었는지 확인하고, NEAR 앱이 실행되었는지 확인하십시오.",
            "success": ""
        },
        "RetriesExceeded": "과부하상태입니다. 다시 시도하십시오.",
        "SAVE_AND_SELECT_LEDGER_ACCOUNTS": {
            "error": "Ledger가 연결되었는지 확인하고, NEAR 앱이 실행되었는지 확인하십시오.",
            "success": "성공적으로 등록되었습니다.<br/><b>${numberOfAccounts}개의 계정이 복구되었습니다.</b> 가장 최근의 계정이 Active로 설정됩니다."
        },
        "SEND_MONEY": {
            "error": "죄송합니다, 오류가 발생했습니다. 다시 시도하십시오."
        },
        "SETUP_RECOVERY_MESSAGE": {
            "error": "복구 수단을 설정하는 중에 오류가 발생했습니다. 다시 시도하십시오!",
            "success": "복구 설정이 완료되었습니다."
        },
        "SIGN_AND_SEND_TRANSACTIONS": {
            "error": "",
            "success": ""
        },
        "TRANSFER_ALL_FROM_LOCKUP": {
            "error": "무언가 잘못되었습니다. 다시 시도하십시오.",
            "success": "락업에서 성공적으로 토큰을 전송했습니다!"
        },
        "U2F_4": "Ledger 디바이스 서명 실패함: U2F DEVICE_INELIGIBLE. Ledger 디바이스가 연결되어있고 잠금 해제되었는지 확인하십시오.",
        "VERIFY_TWO_FACTOR": {
            "error": "2FA 설정이 실패했습니다. 다시 시도하십시오.",
            "success": "2FA가 성공적으로 설정되었습니다!"
        }
    },
    "releaseNotesModal": {
        "desc": "요청하신 것을 수용하였습니다! 최신 업데이트는 지갑에서 잔고 표시 방식에 조정을 가져오고, 새 계정을 만드는 더 쉬운 방법을 제공합니다.",
        "subText1": "온보딩 과정에서는 이제 SMS 가능한 휴대전화 번호를 사용하여 새 계정을 확인하고 시작할 수 있습니다. 이 정보를 제공하지 않으려는 사용자는 초기 입금을 통해 새 계정을 시작할 수 있습니다.",
        "subText2": "지갑에 표시되는 주요 잔고는 이제 ‘사용가능 잔고’입니다. 이전에는 ‘총 잔고’였습니다. 사용가능 잔고는 사용자가 한 눈에 얼마나 소비와 스테이킹을 할 수 있고, 생태계와 상호 작용할 수 있는 지 알수 있도록 합니다. 스테이킹된 토큰과 같이 즉시 사용할 수 없는 토큰은 포함되지 않습니다. 또한, ‘계정’과 ‘스테이킹’ 화면에서 스테이킹된 토큰 잔고에 대한 세부 정보를 볼 수 있습니다.",
        "subTitle1": "신규 기능",
        "subTitle2": "개선 사항",
        "title": "릴리즈 노트"
    },
    "removeAccount": {
        "button": "지갑에서 계정 제거",
        "desc": "미래에 이 계정을 다시 가져오려면 복구 수단이 필요합니다. 복구 수단을 가지고 있는지 확인하십시오.",
        "disclaimer": "나는 <b>${accountId}</b>에 대한 복구 수단을 가지고 있습니다.",
        "title": "계정을 제거하겠습니까?"
    },
    "removeLinkRecovery": {
        "button": "계정으로 가기",
        "desc": "계정을 보호하기 위한 보안 수단을 업데이트했습니다. 복구 구문이나 Ledger 복구 수단을 활성화하고 <a href=\"/profile\">이메일과 SMS 복구 수단을 비활성화</a>해서 계정이 보호하십시오.<a href=\"https://near.org/blog/near-web-wallet-security-update/\" target=\"_blank\">더 알아보기</a>.",
        "title": "중요한 보안 업데이트"
    },
    "reservedForFeesInfo": "최대 ${data} NEAR가 트랜잭션 수수료로 유보되어 있습니다.",
    "riscScoring": {
        "checkbox": "나는 이 주소로 보내는 것에 대한 책임을 지는 것을 동의합니다.",
        "scamWarning": "이 주소는 위험도가 높으며 스캠으로 표시되어 있습니다. 이 주소가 안전한지 확인한 후에 자산을 보내십시오."
    },
    "selectAccountDropdown": {
        "account": "계정",
        "createAccount": "신규 계정 생성",
        "noOtherAccounts": "다른 계정이 없습니다",
        "selectAccount": "계정 선택",
        "switchAccount": "계정 전환",
        "switchAccounthNotAllowed": "이 앱은 계정 전환을 허가하지 않습니다"
    },
    "sending": "보내는중",
    "sendMoney": {
        "account": {
            "title": "보내는중:"
        },
        "amount": {
            "available": "전송 가능"
        },
        "amountStatusId": {
            "available": "사용가능 잔고:",
            "howMuch": "얼마나 보내고 싶으십니까?",
            "noMoreThan": "소수점 5자리 이하만 가능합니다",
            "notEnoughTokens": "적어도 ${amount} NEAR를 계정에 남겨두어야 합니다",
            "sending": "보내는중:"
        },
        "banner": {
            "insufficient": "사용가능 잔고 전체를 보내려고 시도중입니다. 트랜잭션 수수료를 위해 적어도 <b>${data} NEAR</b>가 필요합니다.",
            "useMax": "사용가능 잔고의 일부분(${data} NEAR) 트랜잭션 수수료 용도로 유보되었습니다."
        },
        "button": {
            "confirm": "확인 및 보내기",
            "dashboard": "대시보드로 가기",
            "send": "제출"
        },
        "confirmModal": {
            "title": "트랜잭션 승인"
        },
        "onceConfirmed": "승인되면 철회할 수 없습니다.",
        "subtitle": {
            "default": "전송할 NEAR의 양과 계정 ID를 입력하십시오",
            "success": "성공적으로 전송",
            "to": ":"
        },
        "title": {
            "default": "보내기",
            "success": "성공!"
        },
        "to": ":",
        "wasSentTo": "다음으로 전송됨:",
        "youAreSending": "다음으로 전송중:"
    },
    "sendV2": {
        "accordionTriggerTitle": {
            "balanceDetails": "잔고 세부사항",
            "transactionDetails": "트랜잭션 세부사항"
        },
        "review": {
            "title": "다음으로 전송"
        },
        "selectAsset": {
            "assetInputPlaceholder": "자산 찾기",
            "assetListNameTitle": "이름",
            "asssetListBalanceTitle": "전송 가능",
            "title": "자산 선택"
        },
        "selectReceiver": {
            "receiverInputLabel": "다음으로 전송"
        },
        "selectTokenButtonTitle": "자산 선택",
        "success": {
            "title": "전송 완료!</br> <span class='color-blue'>${amount}</span></br> 수신자: <span class='color-green'>${receiverId}</span>"
        },
        "translateIdInfoTooltip": {
            "estimatedFees": "트랜잭션 처리에 따른 예상 비용.",
            "estimatedTotal": "예상 수수료와 트랜잭션의 합.",
            "networkFees": "거래 처리 비용을 감당하기 위해 지불되는 최종 금액.",
            "reservedForFees": "계정 잔고의 사용이 불가능한 부분입니다. 이 금액은 계정의 저장 비용을 감당하기 위해 예약됩니다. 자세한 정보는 문서를 참조하십시오."
        },
        "TXEntry": {
            "status": {
                "Failure": "실패",
                "notAvailable": "상태 불명",
                "SuccessValue": "성공적"
            },
            "title": {
                "amount": "양",
                "availableBalance": "사용가능 잔고",
                "availableToSend": "전송 가능",
                "estimatedFees": "예상 수수료",
                "estimatedTotal": "예상 총액",
                "from": "발신자",
                "networkFees": "네트워크 수수료",
                "receiverId": "수신자",
                "reservedForFees": "수수료 준비금",
                "status": "TXN 상태",
                "timeStamp": "Date & time",
                "to": "수신자",
                "token": "자산"
            }
        }
    },
    "setRecoveryConfirm": {
        "didNotReceive": "코드를 받지 못했습니까?",
        "email": "이메일 주소",
        "inputError": "인증 코드를 입력하십시오.",
        "inputHeader": "인증 코드 입력",
        "inputPlaceholder": "인증 코드",
        "or": "or",
        "pageText": "6 자리 인증 코드를 다음으로 보냄:",
        "phone": "휴대폰 번호",
        "reenter": {
            "link": "클릭하십시오",
            "one": {
                "email": "아직 이 이메일을 받지 못했거나 이메일 주소가 잘못됐다면, ",
                "phoneNumber": "아직 이 SMS을 받지 못했거나 이메일 주소가 잘못됐다면, "
            },
            "two": {
                "email": " to Re-enter your email address and resend.",
                "phoneNumber": " to Re-enter your phone number and resend."
            }
        },
        "resendCode": "코드 다시 보내기",
        "resending": "다시 보내는중...",
        "sendToDifferent": "Send to a different",
        "title": "인증 코드 입력"
    },
    "setupLedger": {
        "header": "Ledger를 연결하십시오",
        "one": "USB를 통해 컴퓨터 혹은 모바일 디바이스에 Ledger 나노 S/X를 연결하고 <b>NEAR 앱을 시작하십시오.</b>",
        "two": "만약 아직 NEAR Ledger 앱을 설치하지 않았다면, ",
        "twoLink": "다음 설명을 참고하십시오"
    },
    "setupLedgerSteps": {
        "five": "두번째 팝업 창의 리스트에서 디바이스를 선택하고, 연결하십시오.",
        "four": "첫번째 팝업 창에서, 연결 버튼을 누르십시오.",
        "header": "NEAR 앱을 Ledger 디바이스에 설치",
        "install": "만약 아직 NEAR Ledger 앱을 설치하지 않았다면, ",
        "installLink": "<a href='https://www.ledger.com/ledger-live' target='_blank'>다음 설명을 참고하십시오</a>.",
        "one": "USB를 통해 Ledger를 컴퓨터에 연결하십시오.",
        "six": "아래의 계속 버튼을 눌러 Ledger 세팅을 마치십시오.",
        "three": "우상단에 있는 Ledger 연결 버튼을 선택하십시오.",
        "two": "Ledger 위에서 NEAR 앱을 실행하십시오."
    },
    "setupLedgerSuccess": {
        "header": "Ledger가 계정을 보호하게 됩니다!",
        "nextStep": {
            "header": {
                "keep": "기존 키 유지하기?",
                "remove": "키 제거 확인"
            },
            "one": {
                "keep": "<span class='color-red'>여러 복구 방법과 디바이스를 유지하면 계정의 취약점이 증가합니다.</span>",
                "remove": "기존 키와 복구 방법을 제거하기 전에 Ledger 복구 구문을 기록하고 안전하게 보관하십시오."
            },
            "two": {
                "remove": "만약 복구 구문을 잃어버린다면, NEAR Inc.는 계정 및 자금 복구에 대한 지원을 할 수 없습니다."
            }
        },
        "one": "기존 키(복구 방법과 디바이스)를 모두 제거하는 것을 권장합니다.",
        "primaryCta": "기존 키 및 복구 방법 제거",
        "secondaryCta": "기존 키 유지",
        "two": "여러 키와 복구 방법(복구 구문 포함)을 유지하면 계정의 취약점이 증가합니다."
    },
    "setupRecovery": {
        "advancedSecurity": "가장 안전 (추천)",
        "advancedSecurityDesc": "12단어 암호를 입력하거나, Ledger 디바이스로 계정 보안을 유지하십시오.",
        "basicSecurity": "가장 간편",
        "basicSecurityDesc": "이메일 주소나 전화번호를 입력해 링크를 전달받으십시오",
        "emailDesc": "이메일을 통해 인증번호와 계정 복구 링크를 받으십시오.",
        "emailPlaceholder": "example@email.com",
        "emailTitle": "이메일",
        "existingPhraseDesc": "존재하는 암호를 사용해 새 주소를 보호하십시오.",
        "existingPhraseTitle": "존재하는 암호 사용",
        "header": "보안 방식 선택",
        "ledgerDesc": "Ledger 하드웨어 지갑으로 계정을 <br>안전하게 관리하십시오.",
        "ledgerTitle": "Ledger 하드웨어 지갑",
        "newPhraseDesc": "새 암호를 활용해 계정을 보호하십시오.",
        "newPhraseTitle": "새 암호 생성",
        "notSupportedPhone": "유감스럽게 현재 지역에서는 SMS 서비스를 지원하지 않습니다. 이메일을 선택해주십시오.",
        "phoneDesc": "SMS를 통해 인증번호와 계정 복구 링크를 받으십시오.",
        "phonePlaceholder": "+1 415 797 8554",
        "phoneTitle": "휴대폰",
        "phraseDesc": "새 암호를 생성하고 안전히 보관하십시오.",
        "phraseTitle": "안전한 암호",
        "subHeader": "계정을 보호하고 복구하기 위한 방식을 선택하십시오. 각 보안 방식은 다른 디바이스에서 계정을 복구하거나 접근하는 등 중요한 활동의 검증에 쓰입니다."
    },
    "setupSeedPhrase": {
        "pageText": "아래 단어들을 순서대로 적어 안전하게 저장하십시오. <b>암호를 탈취당하면 계정을 영원히 복구할 수 없습니다!</b> 다음 단계에서 암호를 잘 가지고 있는지 검증할 것입니다.",
        "pageTitle": "안전한 암호 설정",
        "snackbarCopyImplicitAddress": "주소 복사 완료!",
        "snackbarCopySuccess": "암호 복사 완료!"
    },
    "setupSeedPhraseSuccess": {
        "pageText": "복구 구문를 사용해 계정을 복구하십시오.",
        "pageTextSecondLine": "복구 구문를 가진 누구나 자산에 접근할 수 있기 때문에, 암호를 오프라인 상 안전한 곳에 보관하십시오.",
        "pageTitle": "복구 과정이 완료되었습니다"
    },
    "setupSeedPhraseVerify": {
        "inputError": "암호를 다시 확인해주십시오.",
        "pageText": "복구 구문를 입력해 검증을 완료하십시오.",
        "pageTitle": "검증 단계",
        "startOverText": "저장해두지 않았습니까?"
    },
    "sign": {
        "accountNotFound": {
            "body": "${signCallbackUrl}는 찾을 수 없는 NEAR 계정에서 권한을 요청하고 있습니다: <b>${signTransactionSignerId}</b><br/><br/>트랜잭션을 허가하려면 우선, 계정을 가져와야합니다(import).",
            "title": "계정을 찾을 수 없음"
        },
        "ActionWarrning": {
            "binaryData": "Arguments contain binary data",
            "deleteAccount": "귀하는 계정을 지우려고 하고 있습니다! 귀하의 NEAR 잔고는 없어지고, 계정의 정보는 삭제됩니다.",
            "deployContract": "귀하는 귀하의 계정 위에 컨트랙트를 배포하려고 하고 있어요! 이 컨트랙트는 귀하의 NEAR 잔고에 접근할 수 있고, 다른 컨트랙트와 상호작용이 있을 수 있습니다.",
            "functionCall": "이 함수에 대한 별도의 설명은 없습니다.",
            "stake": "귀하는 NEAR를 스테이크하려고 합니다. 이 토큰들은 잠기게 되고, 밸리데이터의 상호작용이 없으면 잃어버릴 수도 있습니다."
        },
        "approveTransaction": "트랜잭션 승인",
        "authorizationRequestSuccessful": "권한 요청 완료",
        "authorizing": "권한 요청 중",
        "availableBalance": "사용 가능한 잔고",
        "availableToTransfer": "전송 가능",
        "contract": "컨트랙트:",
        "contractDetails": "컨트랙트 상세",
        "details": {
            "detailedDescription": "트랜잭션의 상세 설명",
            "forContract": "컨트랙트의",
            "gasLimit": "가스 한도",
            "gasPriceUnavailable": "가스 가격 추정 불가능",
            "transactionFees": "트랜잭션 수수료"
        },
        "estimatedFees": "추정 수수료",
        "feeLimit": "수수료 한도",
        "function": "함수:",
        "gasLimit": "가스 한도",
        "hereAreSomeDetails": "도움이 될 만한 상세정보입니다 .",
        "insufficientFunds": "잔고 부족",
        "insufficientFundsDesc": "이 트랜잭션을 처리할 NEAR가 부족합니다.",
        "invalidTransaction": {
            "body": "이 요청은 무효하며 서명할 수 없습니다.",
            "title": "무효한 트랜잭션"
        },
        "isRequesting": {
            "authorization": "권한을 요청하고 있음",
            "transferOf": "전송을 요청하고 있음"
        },
        "multipleAccountsError": {
            "body": "<a href='${signCallbackUrl}' target='_blank' rel='noreferrer'>${signCallbackUrl}</a> 하나 이상의 계정에서 권한 요청을 함.<br/><br/>NEAR 지갑은 한번에 하나의 트랜잭션만을 승인할 수 있어요.",
            "title": "묶음 트랜잭션 오류"
        },
        "networkFees": "네트워크 수수료",
        "nothingHasBeenTransferred": "아무것도 전송되지 않았습니다.",
        "retry": {
            "estimatedFees": "추정 수수료",
            "feeLimit": "수수료 한도",
            "link": "가스 한도가 무엇입니까?",
            "networkFees": "네트워크 수수료",
            "text": "설정된 네트워크 수수료는 트랜잭션을 처리하기에 부족합니다.<br/><br/>수수료 한도가 늘어나도록 트랜잭션을 다시 제출하십시오.",
            "title": "불충분한 네트워크 수수료"
        },
        "transactionCancelled": "트랜잭션 취소됨",
        "transactionDetails": "트랜잭션 상세",
        "transferring": "전송 중",
        "unexpectedStatus": "예상 외의 상태",
        "unknownApp": "알려지지 않은 앱",
        "wasTransferredSuccessfully": "성공적으로 전송"
    },
    "signInLedger": {
        "advanced": {
            "desc": "연결된 계정들을 불러오기 위한 HD(계층 결정) 경로를 명시하십시오.",
            "exportDesc": "계정을 내보내기 위한 HD 경로를 명시하십시오. 계정을 사용하기 위해서는 이 정보를 알고 있어야 합니다.<br/><b>추가적인 보안을 위해서 각 계정 별 다른 HD 경로를 사용하십시오.</b>",
            "setPath": "HD 경로 설정",
            "subTitle": "HD 경로",
            "title": "고급 옵션"
        },
        "firefoxBanner": {
            "desc": "다음 브라우저에서는 Ledger Connect가 지원되지 않습니다: Firefox 버전 112+, Safari. Chrome / Chromium 브라우저에서 완벽하게 지원됩니다. 불편을 드려 죄송합니다."
        },
        "header": "계정을 불러오기 위해 NEAR 지갑을 허가하십시오.",
        "modal": {
            "accountsApproved": "계정 승인",
            "confirmPublicKey": "공개 키 검증",
            "ledgerMustAdd": "Ledger는 디바이스로부터 보호받는 각 계정마다 접근 키를 추가해야합니다:",
            "status": {
                "confirm": "디바이스에서 검증",
                "error": "에러",
                "pending": "보류",
                "rejected": "거절",
                "success": "승인",
                "waiting": "대기 중"
            }
        },
        "one": "계정들을 불러오기 전에, NEAR 지갑이 계정을 접근할 수 있게 승인해야 합니다."
    },
    "stagingBanner": {
        "desc": "주의: 지금은 NEAR 지갑의 초기 버전입니다. 버그가 있을 수 있고, 이로 인해 자산의 손실이 있을 수도 있습니다. 이 버전을 계속 사용한다는 것은, 이러한 위험성을 이해하고 있고, NEAR 지갑 팀이 도움을 줄 수 없다는 것을 알고 있다는 것입니다.",
        "title": "주의: 지금은 NEAR 지갑의 초기 버전입니다. 주의하십시오!"
    },
    "stakedBalance": "이 NEAR는 현재 밸리데이터을 돕고 네트워크를 구성하는 데 쓰이고 있습니다. 이 NEAR를 언스테이크하려면, 4에포크(52~65시간)의 시간이 소요되고, 이에 따라 잔고로 표시되기 까지 시간이 좀 걸릴 것입니다.",
    "staking": {
        "alertBanner": {
            "button": "현재 밸리데이터 보기",
            "title": "새 밸리데이터에게 스테이크하기 위해서는 우선 현재 밸리데이터으로부터 스테이킹 해제와 출금을 진행해야합니다."
        },
        "amountStakingInput": {
            "placeholder": "예시: 15",
            "title": "스테이크 할 수량 입력"
        },
        "balanceBanner": {
            "currency": "NEAR",
            "title": "락업 가능한 잔고"
        },
        "balanceBox": {
            "available": {
                "button": "출금",
                "info": "스테이킹 해제가 완료되어 출금 가능한 잔고입니다.",
                "title": "출금 가능"
            },
            "farm": {
                "button": "클레임",
                "info": "이 밸리데이터은 스테이킹 보상을 NEAR가 아닌 다른 토큰으로 줍니다."
            },
            "farmed": {
                "info": "파밍 밸리데이터에게 위임하여 파밍을 통해 얻은 토큰.",
                "title": "수령 가능"
            },
            "pending": {
                "info": "현재 스테이킹이 해제되었지만 출금 준비가 되지 않은 토큰 잔고입니다. 스테이킹 해제 후 52~65시간 뒤 출금 준비가 완료됩니다.",
                "title": "출금 펜딩"
            },
            "staked": {
                "button": "스테이킹 해제",
                "info": "밸리데이터에게 스테이킹된 NEAR 잔고입니다. 현재 보상을 축적하고 있습니다. 이를 수령하려면, 우선 스테이킹 해제 후 출금하십시오.",
                "title": "스테이킹된 총 수량"
            },
            "unclaimed": {
                "info": "아직 출금하지 않은 NEAR 보상입니다. 보상은 <a target='_blank' href='https://www.investopedia.com/terms/c/compoundinterest.asp'>복리</a> 로 쌓이게 되고, 자동으로 다시 스테이킹됩니다.",
                "title": "얻은 보상",
                "unavailable": {
                    "cta": "더 알아보기",
                    "modalDescOne": "현재 2FA 인증이 가동중이라면 보상을 계산하는게 불가능합니다. 가까운 시일 내에 해당 기능을 가능케하도록 하겠습니다.",
                    "modalDescTwo": "얻은 보상은 '스테이킹된 총 수량' 아래에서 볼 수 있습니다. 보상들은 자동으로 다시 스테이킹 되기 때문에 잔고는 계속 늘어나게 될 것입니다.",
                    "modalTitle": "보상 수령 표시 불가",
                    "title": "보상 표기 불가."
                }
            }
        },
        "claimSuccess": {
            "button": "대시보드로 돌아가기",
            "desc": "밸리데이터으로부터 성공적으로 토큰을 수령했습니다",
            "descTwo": "귀하의 토큰은 이미 사용 가능합니다",
            "title": "성공!"
        },
        "noValidators": {
            "title": "현재 어떤 밸리데이터에게도 스테이킹하고 있지 않습니다."
        },
        "pageText": "CLI를 통해 노드 세팅을 하고 여기에 추가하십시오.",
        "pageTitle": "스테이킹",
        "stake": {
            "accounts": "락업된 NEAR가 있다면, 스테이킹 시 락업된 NEAR와, 계정에 있는 락업되지 않은 NEAR 중 선택할 수 있습니다. 선택한 계정의 스테이킹 포지션만 확인할 수 있습니다. 락업한 NEAR가 없다면, 이 부분은 건너뛰어도 무방합니다",
            "amount": "수량",
            "banner": {
                "insufficientBalance": "귀하는 지갑 전체의 잔고를 스테이킹하려고 합니다. 최소한 <b>${data} NEAR</b> 가 지갑에서 트랜잭션 수수료를 처리해주어야 합니다.",
                "stakeMax": "잔고의 일부분 (${data} NEAR) 이 트랜잭션을 처리하기 위해 쓰였습니다."
            },
            "button": "스테이킹",
            "confirm": "스테이킹 확인",
            "desc": "해당 밸리데이터에게 스테이킹하고자 하는 수량을 입력하십시오.",
            "from": "from",
            "input": {
                "availableBalance": "사용 가능한 잔고",
                "near": "NEAR"
            },
            "ledgerDisclaimer": "알림: 이 밸리데이터에게 스테이킹 하는 것이 처음이므로, 두 번의 트랜잭션이 필요합니다.",
            "stakeWith": "밸리데이터 정보",
            "title": "스테이킹 수량",
            "useMax": "Max",
            "with": "with"
        },
        "stakeSuccess": {
            "button": "대시보드로 돌아가기",
            "desc": "귀하의 토큰이 밸리데이터에게 성공적으로 위임되었습니다:",
            "descTwo": "이제 스테이킹 대시보드에서 위임과 스테이킹 보상을 확인할 수 있습니다.",
            "title": "성공!"
        },
        "staking": {
            "available": "사용 가능한 잔고",
            "button": "내 토큰 스테이킹",
            "checkingValidator": "밸리데이터 확인",
            "currentValidators": "현재 내 밸리데이터",
            "desc": "NEAR 토큰을 밸리데이터에게 스테이킹하십시오",
            "noValidators": "아직 밸리데이터에게 스테이킹하지 않았습니다.",
            "selectAccount": "스테이킹할 계정",
            "title": "스테이킹",
            "totalStaked": "스테이킹한 수량"
        },
        "unstake": {
            "beforeUnstakeDisclaimer": "스테이킹 해제된 토큰은 52~65시간(4에포크)의 기간이 지나야 출금할 수 있습니다.",
            "button": "토큰 스테이킹 해제",
            "confirm": "스테이킹 해제 확인",
            "desc": "현재 밸리데이터로부터 스테이킹 해제하고자 하는 토큰의 수량을 입력하십시오.",
            "input": {
                "availableBalance": "스테이킹 해제 가능한 잔고",
                "near": "NEAR"
            },
            "stakeWith": "Unstake from",
            "title": "토큰 스테이킹 해제"
        },
        "unstakeSuccess": {
            "button": "대시보드로 돌아가기",
            "desc": "현재 밸리데이터로부터 <b>${amount}</b> 만큼 성공적으로 스테이킹 해제되었습니다:",
            "descTwo": "토큰들이 출금 대기 중이며, 52~65시간(4에포크)의 기간 안에 출금 가능합니다.",
            "title": "성공!"
        },
        "validator": {
            "activeStake": "스테이킹 활성화",
            "apy": "APY",
            "button": "밸리데이터에게 스테이킹 ",
            "claimFarmRewards": "보상 수령 확인",
            "claiming": "클레임",
            "desc": "스테이킹을 하기 위해 밸리데이터에게 지불되는 수수료입니다. 이 수수료는 보상에 대해서만 지불됩니다.",
            "errorClaimRewards": "보상을 청구하는 도중 에러가 발생했습니다. 나중에 다시 시도하십시오",
            "fee": "밸리데이터 수수료",
            "notWhitelistedTokenWarning": "이 토큰은 Ref Finance에서 whitelist 되지 않은 토큰입니다. 주의하시길 바랍니다.",
            "notWhitelistedValidatorWarning": "밸리데이터의 보상 토큰이 Ref Finance에 whitelist 되지 않은 토큰입니다. 주의하시길 바랍니다.",
            "rewards": "보상",
            "title": "밸리데이터: <br/>${validator}",
            "unstake": "스테이킹 해제",
            "withdraw": "출금",
            "withdrawalDisclaimer": "자금은 52~65시간(4에포크) 내에 출금 가능할 예정입니다"
        },
        "validatorBox": {
            "cta": "선택",
            "farming": "파밍",
            "fee": "수수료",
            "near": "NEAR",
            "staking": "스테이킹",
            "state": {
                "active": "활성화",
                "inactive": "비활성화"
            },
            "with": "with"
        },
        "validators": {
            "available": "가능한 밸리데이터",
            "button": "밸리데이터 보기",
            "desc": {
                "account": "알고있는 밸리데이터를 입력하거나 리스트에서 밸리데이터를 선택해서 NEAR 토큰을 스테이크하십시오.",
                "lockup": "알고있는 밸리데이터를 입력하거나 리스트에서 밸리데이터를 선택해서 NEAR 토큰을 스테이크하십시오. 한명의 검증인에만 스테이크할 수 있습니다.",
                "unstake": "토큰을 언스테이크하고 싶은 밸리데이터 풀을 선택하십시오.",
                "withdraw": "토큰을 인출하고 싶은 밸리데이터 풀을 선택하십시오."
            },
            "inputLabel": "밸리데이터 계정 ID",
            "inputPlaceholder": "validator-name.near",
            "search": {
                "error": "밸리데이터가 없습니다.",
                "success": "밸리데이터를 찾았습니다!"
            },
            "title": "밸리데이터를 선택하십시오"
        }
    },
    "stakingPoolUnstaked": "이 NEAR는 현재 스테이킹 풀에 있지만 언스테이크 되어 있습니다. It could for example be in a pending release state.",
    "success": "성공",
    "swap": {
        "confirm": "확인 및 스왑",
        "details": "트랜잭션 세부사항",
        "fee": "예상 수수료",
        "from": "From",
        "max": "최대: ${amount} ${symbol}",
        "minReceived": "최소 금액",
        "price": "가격",
        "priceImpact": "가격 영향",
        "review": "스왑 세부사항",
        "reviewInfo": "아래 자산을 스왑합니다",
        "slippage": "슬리피지 한도",
        "successTitle": "전송 완료!</br><span class='color-blue space'>${amountFrom}</span></br>을 <span class='color-green'>${amountTo}</span>로 스왑했습니다.",
        "title": "스왑",
        "to": "To",
        "translateIdInfoTooltip": {
            "fee": "예상되는 트랙재션 처리 비용.",
            "minimumReceived": "거래에서 받을 최소 양.",
            "priceImpact": "거래 규모의 영향에 따른 예상되는 가격과 시장 가격의 차이.",
            "slippage": "트랜잭션 실패하기까지 허용되는 가격 상승 퍼센티지."
        }
    },
    "swapping": "Swapping",
    "sync": "동기화",
    "tokenBox": {
        "priceUnavailable": "가격 확인 불가능",
        "swapTo": "to"
    },
    "topLevelAccounts": {
        "body": "계정 이름은 도메인 이름과 유사합니다. ${data} 계정만이 yourname.${data}과 같은 계정을 생성할 수 있고, yourname.${data} 계정만이 app.yourname.${data} 계정을 생성할 수 있습니다. 이 지갑에서 생성된 모든 계정은 .${data} 최상위 계정(Top Level Account; TLA)을 사용합니다. 계정 이름과 자신의 TLA를 만드는 방법에 대해서는 더 알아보시려면 <a rel='noopener noreferrer' href='https://docs.nearprotocol.com/docs/concepts/account'>docs</a>를 방문하십시오.",
        "header": "최상위 계정"
    },
    "total": "total",
    "totalBalance": "총 잔고는 귀하이 통제하는 모든 NEAR 토큰을 나타냅니다. 많은 경우, 귀하는 이 전체 잔고에 즉시 접근할 수 없을 수 있습니다(예를 들어, 잠겨있거나, 위임되었거나, 스테이크 되었을 경우). 사용할 수있는, 전송 가능한, 위임 가능한, 스테이크 가능한 NEAR를 확인하려면 사용가능 잔고를 확인하십시오",
    "transaction": {
        "status": {
            "checkingStatus": "상태 확인중...",
            "Failure": "실패",
            "notAvailable": "상태 불명",
            "NotStarted": "",
            "Started": "시작",
            "SuccessValue": "성공"
        }
    },
    "transfer": {
        "estimatedFees": "예상 수수료",
        "from": "From",
        "to": "To"
    },
    "twoFactor": {
        "action": {
            "addKey": {
                "full": "아래 코드를 입력하면 다음의 NEAR 계정에 완전 권한을 승인합니다: ${accountId} 의도한 활동이 아니라면 계속하지 마십시오.",
                "limited": "제한 권한 키 추가 ${publicKey}: ${methodNames} on ${receiverId} and spend up to ${allowance} NEAR on gas"
            },
            "deleteKey": "키 삭제중 ${publicKey}",
            "functionCall": "메서드 호출중: ${methodName} in contract: ${receiverId} with amount ${deposit} NEAR and with args: <pre>${args}</pre>",
            "stake": "스테이크중: ${amount} NEAR to validator: ${receiverId}",
            "transfer": "전송중: ${amount} NEAR to ${receiverId}"
        },
        "active": "활성화",
        "alertBanner": {
            "button": "더 알아보기",
            "title": "활성화되면 <b>${data}</b>이 2FA의 컨트랙트 저장 비용으로 잠기게 됩니다."
        },
        "checkBox": "나는 2FA를 활성화함으로써 ${amount}가 컨트랙트 저장 공간을 위한 예비금이 되는 것을 인정합니다.",
        "desc": "트랜잭션을 수행할 때 SMS나 이메일을 통해 확인을 받아 계정을 보호하십시오.",
        "disable": {
            "desc": "비활성화되면 더 이상 트랜잭션이 2FA를 통해 확인될 필요없다는 것을 명심하십시오.",
            "disable": "2FA를 비활성화합니다",
            "keep": "아니요, 2FA를 유지합니다",
            "keyConversionRequired": "<br />이 계정의 2FA 액세스 키의 수 때문에, 2FA를 비활성화하기 전에 일부 액세스 키를 Full 액세스 키로 변환해야 할 수도 있습니다. 변환된 키는 2FA 프롬프트 없이 거래를 서명할 수 있으므로, 2FA를 비활성화하는 과정을 시작하면 완료하는 것이 중요합니다<br/><br/><b>2FA를 비활성화할 때 키 변환을 수행하는 추가 2FA 프롬프트가 하나 이상 제공됩니다.</b>",
            "phoneDesc": "2FA의 SMS 옵션 사용이 중지되었으며, 다시 활성화할 수 없습니다.",
            "title": "정말 2FA를 비활성화하겠습니까?"
        },
        "email": "이메일",
        "enable": "2FA 활성화",
        "notEnabled": "2FA 비활성화됨",
        "notEnoughBalance": "2FA를 활성화하려면 적어도 ${amount}의 사용가능 잔고가 필요합니다.",
        "phone": "휴대폰",
        "promptDesc": "계정 및 자산의 보안을 높이기 위해 2단계 인증 방법을 설정하는 것을 강력히 추천합니다.",
        "select": "인증 방법 선택",
        "since": "since",
        "subHeader": "2단계 인증은 계정에 추가적인 보안 레이어를 추가합니다. 비밀번호와 Ledger 키는 계정에 전체 접근 권한을 계속 제공합니다.",
        "title": "2FA",
        "verify": {
            "desc": "다음으로 6자리 인증 코드가 보내졌습니다:",
            "didntReceive": "코드를 받지 못했습니까?",
            "inputLabel": "6자리 인증 코드를 입력하십시오",
            "placeholder": "인증 코드",
            "resend": "다시 보내기",
            "resending": "보내는 중...",
            "resent": "코드가 보내졌습니다!",
            "title": "2FA 인증 코드를 입력하십시오"
        }
    },
    "twoFactorDisableLocked": {
        "descOne": "2FA 수단을 확인할 수 없었습니다: ",
        "descTwo": "계정에 더 이상 접근할 수 없다면, 키를 변경하여 복구할 수 있습니다. 계정을 잠금 해제하려면 보안 복구 구문이 필요합니다",
        "title": "계정이 잠겼습니까?"
    },
    "twoFactorDisbleBanner": {
        "button": "2FA 비활성화",
        "desc": "NEAR 지갑(wallet.near.org)은 곧 2FA 지원을 중지합니다. 연결된 계정의 2FA를 비활성화하십시오.",
        "titlePlural": "계정에 주의해주십시오",
        "titleSingular": "계정에 주의해주십시오"
    },
    "twoFactorRemoveAuth": {
        "button": "2FA 제거",
        "desc": "복구 구문을 입력해서 계정으로부터 2FA를 제거하십시오:",
        "title": "2FA를 제거하겠습니까?"
    },
    "unlockedAvailTransfer": "이 NEAR를 락업 컨트랙트 밖으로 전송할 수 있습니다.",
    "unlockedBalance": "언락되었지만 이 NEAR는 아직 락업 컨트랙트 안에 있습니다.",
    "unvestedBalance": "베스팅 해제된 NEAR은 귀하에게 할당되어 있지만 아직 소유권이 없습니다. 이 NEAR을 여전히 위임하거나 스테이킹 할 수 있으며, 보상은 전적으로 귀하의 것입니다. ",
    "verifyAccount": {
        "desc": "스팸 방지를 위해 귀하가 계정의 소유주인지 확인해야 합니다. 귀하는 일회성 패스코드를 사용하거나 계정에 입금을 하여 증명할 수 있습니다.",
        "descNoFunding": "아래에서 계정에 입금해 시작할 방법을 선택하십시오.",
        "modal": {
            "email": {
                "desc": "다른 휴대폰 번호나 다른 방법을 사용해서 계정을 인증해야합니다. 여전히 이 이메일 주소를 복구 수단으로 사용할 수 있습니다.",
                "title": "이 이메일 주소는 다른 계정을 인증하는 데 사용되고 있습니다."
            },
            "phone": {
                "desc": "다른 휴대폰 번호나 다른 방법을 사용해서 계정을 인증해야합니다. 여전히 이 번호를 복구 수단으로 사용할 수 있습니다.",
                "title": "이 휴대폰 번호는 다른 계정을 인증하는 데 사용되고 있습니다."
            }
        },
        "option": {
            "creditCard": {
                "desc": "MoonPay를 이용해서 초기 예치금으로 사용할 NEAR를 구매하십시오.",
                "title": "신용카드로 입금"
            },
            "email": {
                "desc": "이메일을 통해 일회성 인증 코드를 받으십시오.",
                "title": "이메일"
            },
            "existingAccount": {
                "desc": "기존 계정에서 자금을 가져와 신규 계정을 시작하십시오.",
                "title": "기존 계정에서 자금 가져오기"
            },
            "manualDeposit": {
                "desc": "단일 사용 주소를 통해 수동으로 입금하십시오.",
                "title": "수동 입금"
            },
            "phone": {
                "desc": "SMS를 통해 일회성 인증 코드를 받으십시오.",
                "title": "휴대폰"
            }
        },
        "options": {
            "initialDeposit": "최초 입금",
            "passCode": "일회성 패스코드"
        },
        "title": "거의 다 왔습니다! 신규 계정을 인증하십시오.",
        "titleNoFunding": "거의 다 왔습니다! 신규 계정에 입금하십시오."
    },
    "verifyOwner": {
        "invalidRequest": {
            "body": "유효하지 않은 요청이며 서명할 수 없습니다.",
            "title": "유효하지 않은 트랜잭션"
        },
        "summary": {
            "connetingWith": "다음을 연결중: ${accountId}",
            "infoMessage": "<br>연결되면 <span class='color-blue'>≪${appName}≫</span>(이)가 <span class='color-black'><b>허가</b></span>를 받습니다:",
            "permissions": "프로필 이름과 지갑 주소를 사용하여 계정을 인증하십시오.",
            "title": "권한 허가 요청"
        }
    },
    "verifyWalletDomainBanner": {
        "title": "다음을 방문하고 있습니다:"
    },
    "wallet": {
        "availableBalance": "사용가능 잔고",
        "balance": "잔고",
        "balances": "잔고",
        "balanceTitle": "NEAR 잔고",
        "collectibles": "컬렉션",
        "dateAndTime": "Date & Time",
        "depositNear": {
            "desc": "지갑에서 트랜잭션을 행하려면 NEAR 토큰이 필요합니다.",
            "title": "NEAR 입금"
        },
        "status": "상태",
        "tokenBalance": "토큰 잔고",
        "tokens": "토큰",
        "totalBalance": "총 잔고",
        "totalBalanceTitle": "총 잔고",
        "yourPortfolio": "포트폴리오"
    },
    "walletErrorCodes": {
        "addAccessKey": {
            "error": "오류가 발생했습니다.<br />계정에 접근하려면 아래의 이전 단계에서 복구 구문을 입력하십시오."
        },
        "addAccessKeySeedPhrase": {
            "errorSecond": "오류가 발생했습니다.<br />복구 구문이 계정에 추가되지 않았습니다. 다시 시도하십시오."
        },
        "addAccessKeyZeroBalanceAccountSetup": {
            "error": "활성화된 계정을 가져오는 데 오류가 발생했습니다. 계정을 지갑에 다시 가져와주십시오."
        },
        "addLedgerAccessKey": {
            "2faEnabled": "2FA가 활성화되어 있습니다. Ledger 하드웨어 지갑을 활성화하려면 2FA를 비활성화해야 합니다."
        },
        "addLedgerAccountId": {
            "errorRpc": "계정 복구 중에 오류가 발생했습니다."
        },
        "connectLedger": {
            "noClient": "Ledger가 연결되어 있지 않습니다. Ledger를 먼저 연결하십시오."
        },
        "createNewAccount": {
            "accountCreated": {
                "error": "계정 생성에는 성공했지만 오류가 발생했습니다. 계정을 가져오십시오."
            },
            "accountExists": {
                "error": "계정 생성을 실패했습니다. 존재하는 계정입니다."
            },
            "error": "계정 생성을 실패했습니다. 다시 시도하십시오."
        },
        "emailProviderInvalid": "${domainName}는 계정 인증을 위해 사용할 수 없습니다. 다른 이메일 주소를 선택하십시오.",
        "getLedgerAccountIds": {
            "aborted": "활동이 정지되었습니다.",
            "noAccounts": "이 Ledger 디바이스에 대한 계정을 찾지 못했습니다. Ledger에 계정이 존재하는지 확인해주십시오.",
            "noAccountsAccepted": "No accounts were accepted.",
            "U2FNotSupported": "Ledger에 U2F 브라우저 지원이 필요합니다. Chrome, Opera, Firefox의 U2F 익스텐션을 사용하십시오."
        },
        "initiateZeroBalanceAccount": {
            "error": "계정을 시작하는 데 실패했습니다. 다시 시도하십시오."
        },
        "initTwoFactor": {
            "ledgerEnabled": "Ledger 하드웨어 지갑이 활성화중입니다. 2FA를 활성화하려면 먼저 Ledger 하드웨어 지갑을 비활성화해야 합니다."
        },
        "invalidRecaptchaCode": "유효하지 않은 reCAPTCHA 코드; 다시 시도하십시오.",
        "lockup": {
            "transferAllWithStakingPoolBalance": "잔여 락업 잔고를 지갑으로 전송하기 위해 스테이킹을 해제하고 스테이킹 풀에서 모든 자금을 인출하십시오."
        },
        "promptTwoFactor": {
            "userCancelled": "올바르지 않은 2FA 코드입니다."
        },
        "recoverAccountLink": {
            "error": "계정 가져오기를 실패했습니다. 다시 시도하거나 도움을 위해 지원을 문의하십시오."
        },
        "recoverAccountSeedPhrase": {
            "errorInvalidSeedPhrase": "이 복구 구문에 대한 계정이 존재하지 않습니다.",
            "errorNotAbleToImportAccount": "계정 가져오기를 실패했습니다. 다시 시도하거나 도움을 위해 지원을 문의하십시오.",
            "errorSeedPhraseNotValid": "계정 가져오기를 실패했습니다. 다시 시도하거나 도움을 위해 지원을 문의하십시오."
        },
        "recoveryMethods": {
            "lastMethod": "복구 수단을 삭제할 수 없습니다. Ledger를 활성화하지 않았다면 계정 복구를 위해 적어도 하나의 복구 수단을 가지고 있어야 합니다.",
            "setupMethod": "오류가 발생했습니다. 복구 수단을 확인하십시오."
        },
        "refreshAccountOwner": {
            "error": "계정을 불러오는 중에 오류가 발생했습니다. 어떤 지갑 데이터가 최신이 아닐 수 있습니다. 브라우저를 새로고침하십시오."
        },
        "sendFungibleToken": {
            "error": "오류가 발생했습니다. 트랜잭션이 취소되었습니다."
        },
        "setupRecoveryMessage": {
            "error": "복구 수단을 셋팅하는 중에 오류가 발생했습니다. 다시 시도하십시오."
        },
        "setupRecoveryMessageNewAccount": {
            "invalidCode": "유효하지 않은 코드"
        },
        "signAndSendTransactions": {
            "notEnoughTokens": "토큰이 부족합니다."
        },
        "staking": {
            "noLockup": "계정에 락업 컨트랙트 없음",
            "noWithdraw": "밸리데이터으로부터 pending 잔고를 인출할 수 없습니다",
            "unableToCheckFAK": "죄송합니다, 밸리데이터이 Full 액세스 키를 가지고 있는지 확인하지 못했습니다.",
            "validatorHasFAK": "죄송합니다, 안전을 위해 Full 액세스 키를 가지고 있는 밸리데이터을 통해서는 스테이킹할 수 없습니다."
        }
    },
    "walletMigration": {
        "cleanKeys": {
            "accountTitle": "귀하의 키를 클린업하십시오",
            "desc": "안전을 위해 기존의 액세스 키를 제거하는 것을 추천합니다.",
            "fullAccessKeys": "Full 액세스 키",
            "keep": "유지",
            "keepDesc": "The full access key created when you rotated your keys, ledger access keys, limited access keys",
            "keyTypes": {
                "email": "이메일",
                "phrase": "복구 구문",
                "sms": "SMS",
                "unknown": "불명"
            },
            "nextSteps": "다음 단계에서 어떤 키를 제거할 지 수동으로 선택할 수 있습니다",
            "remove": "제거",
            "removeDesc": "이메일과 SMS 복구 방법, 이전 복구 구문, NEAR CLI에서 생성된 Full 액세스 키",
            "removeKeys": "키 제거",
            "title": "Full 액세스 키 비워내기",
            "verifyPassphrase": {
                "desc": "복구 구문을 입력하여 다음 계정의 Full 액세스 키를 삭제하십시오: <b>${accountId}</b>",
                "keyMessages": {
                    "doesNotExist": "입력된 시드 문구에 부합하는 ${accountId}의 Full 액세스 키가 존재하지 않습니다.",
                    "insecureRecoveryMethod": "입력된 시드 문구는 안전하지 않은 복구 방식을 불러오며 계정 마이그레이션에 사용할 수 없습니다.",
                    "toBeDeleted": "입력된 시드 문구는 삭제된 키를 가져오며 계정 마이그레이션에 사용할 수 없습니다."
                },
                "title": "Full 액세스 키를 삭제하겠습니까?"
            }
        },
        "disable2fa": {
            "desc": "계정을 다른 지갑으로 전송하기 전에 2FA를 비활성화해야 합니다.",
            "title": "2FA 비활성화"
        },
        "exportKey": {
            "desc": "개인 키를 절대 공유하지 마십시오! 개인 키를 가지고 있으면 계정에 full 액세스가 가능합니다.<br /><br />개인 키를 복사하거나 다운로드해서 보관하십시오.",
            "title": "개인 키를 내보내겠습니까?"
        },
        "logout": {
            "button": "계정에서 로그아웃)",
            "desc": "마지막 단계로 귀하의 보안을 위해 이 브라우저에 저장된 키를 비울 것입니다. wallet.near.org에서 로그아웃되며 키는 제거됩니다. <br><br>다른 지갑에서 계정에 접근할 수 있는지 확인하십시오.",
            "title": "마지막 단계"
        },
        "migrateAccounts": {
            "desc": "다음의 계정이 전송됩니다:",
            "title": "${count}개의 활성된 계정을 찾았습니다"
        },
        "migrationSecret": {
            "desc": "안전하게 계정을 가져오기 위해 이 <strong>패스워드</strong>가 필요합니다. 계정 전송이 끝날 때까지 패스워드를 적어두거나 복사하십시오.",
            "title": "계정을 가져오는데 필요한 패스워드"
        },
        "redirect": {
            "desc": "<b>5초</b> 후에 자동으로 리다이렉트됩니다. 리다이렉트 후에는 각 계정을 승인해야 합니다.",
            "title": "지갑으로 리다이렉팅"
        },
        "rotateKeys": {
            "desc": "보안을 위해서 아래의 계정에 대한 새로운 복구 구문과 Full 액세스 키를 생성할 것입니다. <br /><br /> 키를 바꾸는 것은 지갑을 보호하는데 도움이 될뿐만 아니라 계정 전송 후 기존 Full 액세스 키를 비워내는 것을 허용합니다.",
            "title": "계정을 보호하십시오"
        },
        "selectWallet": {
            "descOne": "<b>10초</b> 후에 자동으로 리다이렉트됩니다. 리다이렉트 후에는 각 계정을 승인해야 합니다.",
            "descTwo": "이 지갑은 계정 자동 전송을 지원합니다. <br /><br /> 12-단어 복구 구문을 사용하여 수동으로 기존 계정을 가져올 수 있습니다.",
            "ledgerDisclaimer": "이 지갑은 Ledger 계정과 호환되지 않습니다.",
            "ledgerSupported": "Ledger 지원 지갑",
            "ledgerUnsupported": "Ledger 미지원 지갑",
            "title": "계정을 전송할 지갑을 선택하십시오"
        },
        "verifying": {
            "desc": "새로운 지갑에서 계정에 접근할 수 있는 확인하십시오. 확인인 끝나고 나면 기존의 액세스 키는 계정 보안을 위해 삭제됩니다.",
            "disclaimer": "나는 다른 지갑에서 계정에 접근할 수 있습니다",
            "title": "계정이 사용가능한지 확인하십시오"
        }
    },
    "warning": "경고",
    "zeroBalance": {
        "addLedgerKey": {
            "error": {
                "header": "무언가 잘못되었습니다",
                "message": "계정에 추가적인 Ledger 키를 추가할 수 없습니다. 다시 시도하거나 Ledger 계정을 다시 가져오십시오."
            },
            "success": {
                "header": "Ledger 액세스 키가 추가되었습니다!",
                "message": "신규 Ledger 키가 계정에 추가되었습니다. 이제 Ledger 디바이스를 통해 계정 복구 수단을 관리할 수 있습니다."
            }
        },
        "addPhraseKey": {
            "error": {
                "header": "무언가 잘못되었습니다.",
                "message": "신규 액세스 키를 계정에 추가하지 못했습니다. 계정을 다시 가져오거나 신규 지갑 키를 추가해주십시오."
            },
            "success": {
                "header": "계정 가져오기를 성공했습니다!",
                "message": "이제 계정이 활성화되어 지갑에서 트랜잭션을 서명하기 위한 새로운 액세스 키가 추가되었습니다."
            }
        },
        "ledgerModal": {
            "addLedgerKey": "Ledger 키 추가",
            "confirmOnLedger": "랫저 디바이스에서 활동을 승인하십시오.",
            "desc": "다양한 복구 수단을 관리하기 위해 Ledger에 제한된 액세스 키를 추가하십시오.",
            "title": "이제 계정이 활성화되었습니다!"
        }
    }
}
;
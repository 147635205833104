module.exports = {
    "account": {
        "activateAccount": {
            "post": {
                "descOne": "Your initial deposit has been received and transferred to your new account. Your account is now active!",
                "descTwo": "A portion of your deposit has been reserved to cover the <a href='https://docs.SOL.org/docs/concepts/account' rel='noopener noreferrer' target='_blank'>cost of hosting your account</a>.",
                "title": "Your account has been activated."
            },
            "pre": {
                "desc": "To begin using Genin Wallet, make a minimum initial deposit by sending <b>at least ${amount}</b> to your Account ID (sometimes called an “address”).",
                "title": "Almost there! Make your initial deposit."
            }
        },
        "available": {
            "error": "User not found!",
            "errorSameAccount": "Cannot send to yourself.",
            "implicitAccount": "Always double check the account ID",
            "implicitAccountModal": "Please double check the account ID, especially if this is an exchange account. If the account ID is entered incorrectly, your funds will be lost forever.",
            "success": "User found!"
        },
        "create": {
            "checkingAvailablity": {
                "check": "Checking",
                "create": "Checking availability"
            },
            "errorAccountNotExist": "There was a problem creating your account. Please try again!",
            "errorInvalidAccountIdLength": "Account ID must be between 2 and 64 characters"
        },
        "createImplicit": {
            "post": {
                "descOne": "Your initial deposit was received! Once claimed, your deposit will be transferred to your new account.",
                "descTwo": "Important: Your single-use funding address will no longer be valid. Make sure to replace it with your Account ID on any exchanges or other devices where it may have been saved.",
                "modal": {
                    "descOne": "By continuing, you acknowledge that the single-use funding address will be replaced, and any additional funds sent to the old address will be lost.",
                    "descTwo": "Remember to replace it with your Account ID on any exchanges or other devices where it may have been saved.",
                    "title": "Update your address"
                },
                "title": "Claim your account"
            },
            "pre": {
                "addressHeader": "One-time funding address",
                "descOne": "To begin using Genin Wallet, meet the minimum initial deposit by sending <b>at least ${amount}</b> to your single-use funding address below.",
                "descTwo": "Return to this screen once your funds have been deposited to finish creating your account.",
                "moonPay": {
                    "buyWith": "Buy with",
                    "desc": "Make your initial deposit with MoonPay using your preferred payment method.",
                    "title": "Fund with MoonPay"
                },
                "title": "Almost there! Activate your account with an initial deposit.",
                "utorg": {
                    "buyWith": "Buy with",
                    "desc": "Make your initial deposit with Utorg using your preferred payment method.",
                    "title": "Fund with Utorg"
                },
                "whereToBuy": {
                    "button": "Where can I purchase SOL?",
                    "desc": "SOL tokens are available to purchase through the following exchanges",
                    "title": "Purchase SOL tokens"
                }
            },
            "success": {
                "button": "Continue to Account",
                "descOne": "Congratulations <b>${accountId}</b>! Your account has been successfully created.",
                "descThree": "Please update your address on any exchanges or other devices.",
                "descTwo": "You must now use this Account ID as your address for all SOL operations.",
                "modal": {
                    "desc": "Congratulations! Your account has been successfully created.",
                    "title": "Welcome to Genin Wallet!"
                },
                "title": "Welcome to SOL"
            }
        },
        "createImplicitAccount": {
            "createCustomNameModal": {
                "desc": "Unlike your default address, a custom address can be anything you like, making it easier to remember and share!",
                "title": "Add a Custom Address to Your Wallet"
            },
            "customAddress": "A custom address can be anything you like, making it easier to remember and share!",
            "customAmount": "Custom",
            "exchange": "exchange",
            "orAskFriend": "or ask a friend!",
            "orSendSOL": "...or send at least ${amount} SOL to your wallet address.",
            "sendFrom": "Send from an",
            "title": "Almost there! To get started, buy some SOL using one of providers...",
            "titleAlt": "Almost there! To get started, send at least ${amount} SOL to your wallet address."
        },
        "fundedStatus": {
            "active": "Active",
            "awaitingDeposit": "Awaiting deposit",
            "initialDeposit": "Initial deposit",
            "minDeposit": "Minimum deposit",
            "ready": "Ready to claim",
            "singleUse": "Single-use Funding address",
            "SOLName": "Account ID",
            "status": "Account status"
        },
        "login": {
            "details": {
                "warning": "This allows access to your entire balance. Please proceed with caution."
            },
            "incorrectContractId": {
                "error": "The contract ${contractId} does not exist. This may be an issue with the application you are trying to use, or this contract may have been deleted. You will be redirected back to the application",
                "errorTitle": "Invalid contract ID"
            }
        },
        "nameDoesntMatch": "Account name doesn't match",
        "recoverAccount": {
            "error": "Failed to recover account. No accounts were found for this passphrase.",
            "errorInvalidSeedPhrase": "No accounts were found for this passphrase.",
            "success": "Recover using passphrase is complete.<br/><b>${numberOfAccounts} account(s)</b> have been successfully recovered.<br/>The last one is set as active.",
            "zeroBalance": {
                "success": {
                    "desc": "The account has not yet been funded. Purchase $SOL to perform transactions with the account.",
                    "ledger": "The following account was successfully imported using the Ledger key you provided:",
                    "phrase": "The following account was successfully imported using the passphrase you provided:",
                    "title": "Account Imported"
                }
            }
        },
        "requestCode": {
            "error": "Failed to send SMS with code.",
            "success": "Sent SMS with code."
        },
        "sendMoney": {
            "error": "Sorry an error has occurred. You may want to try again."
        },
        "sendNewRecoveryLink": {
            "error": "Failed to resend. Please try again.",
            "success": "Recovery link successfully sent!"
        },
        "verifySeedPhrase": {
            "error": "You entered the incorrect word"
        }
    },
    "accountSelector": {
        "noAccountDesc": "You’re not currently signed in to any alternate accounts.",
        "signInButton": "Import a Different Account"
    },
    "actions": {
        "AddKey": {
            "forContract": "Access Key added for: ${permissionReceiverId}",
            "forReceiver": "Full Access Key added for: ${receiverId}"
        },
        "CreateAccount": "New account created: ${receiverId}",
        "DeleteAccount": "Account deleted: ${receiverId}",
        "DeleteKey": "Key deleted",
        "DeployContract": "Contract deployed: ${receiverId}",
        "FunctionCall": "Called method: ${methodName} in contract: ${receiverId}",
        "Stake": "Staked ${stake}",
        "Transfer": {
            "received": "Received ${deposit} from ${signerId}",
            "transferred": "Sent ${deposit} to ${receiverId}"
        }
    },
    "actionsSign": {
        "addKey": "Adding access key",
        "createAccount": "Creating Account: ${receiverId}",
        "deleteAccount": "Deleting account: ${receiverId}",
        "deleteKey": "Deleting access key",
        "deployContract": "Deploying Contract: ${receiverId}",
        "functionCall": "Calling function: ${methodName}",
        "stake": "Staking: ${stake}Ⓝ ${publicKey}...",
        "transfer": "Transferring: ${deposit}Ⓝ to ${receiverId}"
    },
    "addNode": {
        "desc": "Your node must be setup via CLI before adding it here.",
        "ipAddressInput": {
            "placeholder": "example: 0.0.0.0",
            "title": "Enter the IP Address of your Node"
        },
        "nicknameInput": {
            "placeholder": "example: AWS Instance",
            "title": "Give it a nickname (optional)"
        },
        "pageText": "Setup your Node via CLI, then add it here.",
        "pageTitle": "Add a Node"
    },
    "amount": "amount",
    "arguments": "Arguments",
    "authorizedApps": {
        "dashboardNoApps": "You have not authorized any apps",
        "feeAllowance": "Fee Allowance",
        "ledger": "Ledger",
        "pageTitle": "Authorized Apps",
        "publicKey": "Public Key"
    },
    "availableBalanceInfo": "Your spendable SOL balance excluding funds locked or staked. <a href='/profile'>View Balance Breakdown →</a>",
    "availableBalanceProfile": "This is your spendable SOL balance, and can be used or transferred immediately.",
    "back": "Back",
    "balance": {
        "balance": "Total Balance",
        "balanceLoading": "Balance loading"
    },
    "balanceBreakdown": {
        "available": "Available balance",
        "reserved": "Reserved for fees"
    },
    "batchExportAccounts": {
        "confirmExportModal": {
            "accountToExport": "Account to Export",
            "title": "Approve Account Export",
            "transactionDetails": "+ Transaction Details"
        },
        "exportScreen": {
            "desc": "Begin your export and confirm each account </br> when prompted.",
            "weFound": "We found ${noOfAccounts} accounts to export to your device."
        },
        "successScreen": {
            "title": "${noOfAccounts} account(s) were successfully </br> exported to your device"
        }
    },
    "batchImportAccounts": {
        "confirmImportModal": {
            "accountToImport": "Account to Import",
            "desc": "To ensure account security during your transfer, confirm that the URL in your browser is correct:",
            "title": "Approve Account Import from",
            "transactionDetails": "+ Transaction Details"
        },
        "confirmUrlModal": {
            "desc": "To ensure account security during your transfer, confirm that the URL in your browser is correct:",
            "title": "Confirm Your URL"
        },
        "enterKeyForm": {
            "confirmCaption": "Confirm password",
            "desc": "Enter your password to complete importing accounts:",
            "placeholder": "2cCzKMPiCkFnF5mGbJcPgTGNykvxK4f3xyNAio4q931b",
            "title": "Welcome to MySOLWallet!"
        },
        "importScreen": {
            "desc": "Begin your import and confirm each account </br> when prompted.",
            "title": "Ready to import ${noOfAccounts} account(s) from </br>"
        },
        "successScreen": {
            "desc": "Select an account to continue to your account dashboard.",
            "title": "${noOfAccounts} account(s) were successfully </br> imported from "
        }
    },
    "button": {
        "acceptAndContinue": "Accept & Continue",
        "addACustomAddress": "Add a Custom Address",
        "addFunds": "Add Funds",
        "addNode": "Add Node",
        "allow": "Allow",
        "approve": "Approve",
        "authorize": "Authorize",
        "authorizing": "Authorizing",
        "backToSwap": "Back to Swap",
        "beginExport": "Begin Export",
        "beginImport": "Begin Import",
        "browseApps": "Browse Apps",
        "buy": "Buy",
        "cancel": "Cancel",
        "cancelOperation": "Cancel Operation",
        "cancelTransfer": "Cancel Transfer",
        "claimAccount": "Claim Account",
        "claimMyAccount": "Claim My Account",
        "close": "Close",
        "Close": "Close",
        "confirm": "Confirm",
        "confirmAndSend": "Confirm & Send",
        "connect": "Connect",
        "connecting": "Connecting",
        "connectLedger": "Connect to Ledger",
        "continue": "Continue",
        "continueSetup": "Continue to Setup",
        "continueToMyAccount": "Continue to My Account",
        "copy": "Copy",
        "copyImplicitAddress": "Copy Funding Address",
        "copyPhrase": "Copy Phrase",
        "copyUrl": "Copy URL",
        "createAccount": "Create Account",
        "createAccountCapital": "Create Account",
        "createNewAccount": "Create New Account",
        "creatingAccount": "Creating account",
        "deauthorize": "Deauthorize",
        "deAuthorizing": "Deauthorizing",
        "deny": "Deny",
        "disable": "Disable",
        "disabling": "Disabling",
        "disabling2FA": "Disabling 2FA",
        "dismiss": "Dismiss",
        "download": "Download",
        "edit": "Edit",
        "enable": "Enable",
        "enabling": "Enabling",
        "findMyAccount": "Find My Account",
        "finish": "Finish",
        "fundWith": "Fund with",
        "generateNew": "Generate New",
        "getStarted": "Get Started",
        "goBack": "Go back",
        "gotIt": "Got it",
        "goToDashboard": "Go To Dashboard",
        "importAccount": "Import Account",
        "importExistingAccount": "Import Existing Account",
        "learnMore": "Learn More",
        "learnMoreAboutSOL": "Learn more about SOL",
        "loading": "Loading",
        "looksGood": "Looks Good!",
        "moreInformation": "More information",
        "needToEditGoBack": "Need to edit? Go Back",
        "next": "Next",
        "protectAccount": "Protect Account",
        "receive": "Receive",
        "reconnect": "Reconnect",
        "recoverAccount": "Recover Account",
        "recoverExistingAccount": "Recover Existing Account",
        "recovering": "Finding Account",
        "recoverYourAccount": "Recover your Account",
        "removeAccount": "Remove Account",
        "removeNode": "Remove Node",
        "removingKeys": "Removing Keys",
        "reserveMyAccountId": "Reserve My Account ID",
        "resubmit": "Resubmit",
        "retry": "Retry",
        "returnToApp": "Return to App",
        "saveChanges": "Save Changes",
        "secureMyAccount": "Secure My Account",
        "send": "Send",
        "setupPhrase": "Setup Recovery Phrase",
        "signIn": "Sign In",
        "signingIn": "Signing In",
        "signInLedger": "Sign in with Ledger",
        "skip": "Skip",
        "skipThisForNow": "Skip this for now",
        "stake": "Stake",
        "staking": "Staking",
        "startOver": "Start over",
        "subscribe": "Subscribe",
        "swap": "Swap",
        "swapUSN": "Swap USN",
        "ToMaine": "To the main screen",
        "topUp": "Buy SOL",
        "transferring": "Transferring",
        "tryAgain": "Try Again",
        "unstaking": "Unstaking",
        "useMax": "Use Max",
        "verify": "Verify & Complete",
        "verifyCode": "Verify Code",
        "verifyCodeEnable": "Verify & Continue",
        "verifying": "Verifying",
        "viewAll": "View All",
        "viewFAQ": "View FAQ",
        "viewOnExplorer": "View on Explorer",
        "viewPrivateKey": "View private key",
        "withdrawing": "Withdrawing"
    },
    "buySOL": {
        "bridgeSubTitle": "Transfer your tokens from Ethereum to SOL using the Rainbow Bridge.",
        "bridgeTokens": "Bridge From Ethereum",
        "buyWith": "Buy with",
        "coinMarketCap": "CoinMarketCap",
        "coinMarketLink": "More on CoinMarketCap",
        "descMoonpay": "Purchase SOL through MoonPay using your preferred payment method.",
        "descThree": "Transfer your tokens from Ethereum to Genin Wallet using a bridge.",
        "descTwo": "SOL is available to purchase through the following exchanges.",
        "descUtorg": "Purchase SOL through Utorg using your preferred payment method.",
        "moonPay": "MoonPay",
        "notSupported": "is not supported in your region",
        "seeMore": "See more on",
        "SOLPurchaseSubTitle": "Use your preferred payment method to purchase $SOL through one of our supported partners.",
        "SOLPurchaseTitle": "Purchase SOL",
        "subTitle": "Choose from a variety of funding options to top up your wallet!",
        "supportedExchanges": "Supported Exchanges",
        "supportedSubTitle": " $SOL is available to purchase and trade on various exchanges.",
        "title": "Top Up",
        "utorg": "Utorg"
    },
    "confirmLedgerModal": {
        "header": {
            "confirm": "Please confirm the operation on your device...",
            "processing": "Processing",
            "success": "accounts have been successfully imported from your Ledger device.",
            "weFound": "We found ${totalAccounts} accounts secured by your device."
        },
        "one": "You will need to confirm your sign-in details on your Ledger.",
        "two": "Follow the instructions on your device to confirm and grant access to each account you'd like to import."
    },
    "connecting": "Connecting",
    "connectLedger": {
        "connectLedger": "Connect Ledger",
        "ledgerConnected": "Ledger Connected",
        "modal": {
            "connect": {
                "cancelButton": "Cancel",
                "connectButton": "Connect",
                "connectionPrompt": "Make sure your Ledger is connected securely, and that the SOL app is open on your device.",
                "header": "Connect to your Ledger device."
            },
            "connection-error": {
                "cancelButton": "Cancel",
                "connectButton": "Retry",
                "connectionPrompt": "Make sure your ledger is connected securely, and that the SOL app is open on your device. In addition, you must be on a supported <a href='https://SOLhelp.zendesk.com/hc/en-us/articles/13972685751191-What-browsers-work-with-Ledger-Connect-' target='_blank' rel='noopener noreferrer'>browser</a>.",
                "header": "Connection unsuccessful."
            },
            "disconnected": {
                "cancelButton": "Dismiss",
                "connectButton": "Reconnect",
                "connectionPrompt": "Make sure your Ledger is connected securely, and that the SOL app is open on your device.",
                "header": "Your device was disconnected."
            }
        },
        "youMayNow": "You may now perform operations using your device."
    },
    "copy": {
        "default": "Copied!",
        "title": "Copy"
    },
    "createAccount": {
        "accountIdInput": {
            "placeholder": "yourname.${data}",
            "title": "Account ID"
        },
        "addACustomAddress": "Add a Custom Address",
        "alreadyHaveAnAccount": "Already have an account?",
        "invalidLinkDrop": {
            "one": "This SOL Drop has been claimed.",
            "title": "Link Invalid",
            "two": "SOL Drops can only be used to create a single account, after which the link will expire."
        },
        "landing": {
            "descOne": "Genin Wallet is a secure wallet and account manager for your accounts on the SOL blockchain.",
            "descTwo": "Once you create an account, you’ll need it to interact with applications on SOL, and to securely store your various tokens and collectibles (NFTs).",
            "title": "Create Your Account"
        },
        "note": {
            "canContain": "Your account ID can contain any of the following:",
            "cannotContain": "Your account ID CANNOT contain:",
            "characters": "Characters \"@\" or \".\"",
            "digits": "Digits (0-9)",
            "lowercase": "Lowercase characters (a-z)",
            "maxCharacters": "More than 64 characters (including .${accountSuffix})",
            "minCharacters": "Fewer than 2 characters",
            "separators": "Characters (_-) can be used as separators"
        },
        "pageText": "Enter an Account ID to use with your SOL account. Your Account ID will be used for all SOL operations, including sending and receiving assets.",
        "pageTitle": "Reserve Account ID",
        "recoverItHere": "Import Existing Account",
        "setupPassphrase": {
            "generatePassphrase": {
                "desc": "For the best security, we recommend generating a unique passphrase for each new account in your wallet.",
                "descTwo": "As a more convenient option, you may instead share an existing passphrase between multiple accounts. You can change this at any time.",
                "tite": "Generate a New Passphrase?"
            },
            "sharedPassphrase": {
                "desc": "Your passphrase has been verified! Once you’ve funded your new address, your passphrase will be shared by <b>${numberOfAccounts} accounts.</b>",
                "descTwo": "Whenever this passphrase is used to recover your wallet, the following accounts will be imported:",
                "tite": "Your Shared Passphrase"
            },
            "verifyPassphrase": {
                "desc": "Enter an existing passphrase below to secure your new account.",
                "passPhrasePlaceholder": "bubble word sun join impact exist ramp skull title hollow symbol very",
                "tite": "Verify Your Passphrase",
                "yourPassphrase": "Your Passphrase"
            }
        },
        "step": "Step ${step}/${total}",
        "terms": {
            "agreeBtn": "Agree & Continue",
            "declineBtn": "Decline",
            "desc": "By proceeding with creating a SOL account, you agree to the Genin Wallet Terms of Service and Privacy Policy. We urge you to read them carefully:",
            "privacyCheck": "I agree to the Privacy Policy",
            "privacyLink": "Privacy Policy",
            "termsCheck": "I agree to the Terms of Service",
            "termsLink": "Terms & Conditions",
            "title": "Terms & Conditions"
        },
        "termsPage": {
            "descOne": "SOL accounts require a minimum balance of <b>at least ${amount}</b> to <a href='https://docs.SOL.org/docs/concepts/account' rel='noopener noreferrer' target='_blank'>hold a place on the network.</a>",
            "descTwo": "After reserving a unique account address, you’ll be asked to make a small initial deposit to activate your account.",
            "disclaimer": "By creating a SOL account, you agree to the MySOLWallet <a href='/terms' rel='noopener noreferrer' target='_blank'>Terms of Service</a> and <a href='/privacy' rel='noopener noreferrer' target='_blank'>Privacy Policy</a>.",
            "title": "Before you begin..."
        }
    },
    "dashboard": {
        "activity": "Recent activity",
        "noActivity": "No activity has been recorded for this account."
    },
    "dashboardActivity": {
        "message": {
            "AddKey": {
                "forContract": "<span>for</span> <span>${permissionReceiverId}</span>",
                "forReceiver": "<span>for</span> <span>${receiverId}</span>"
            },
            "CreateAccount": "<span>account</span> <span>${receiverId}</span>",
            "DeleteAccount": "<span>account</span> <span>${receiverId}<span>",
            "DeleteKey": "",
            "DeployContract": "<span>contract</span> <span>${receiverId}</span>",
            "FunctionCall": "<span>${methodName}</span> in contract <span>${receiverId}</span>",
            "FunctionCallDetails": {
                "first": "<span>Method</span> <span>${methodName}</span>",
                "second": "<span>In contract</span> <span>${receiverId}</span>"
            },
            "Stake": "<span>stake</span><span>${stake}</span>",
            "Transfer": {
                "received": "<span>from</span> <span>${signerId}</span>",
                "transferred": "<span>to</span> <span>${receiverId}</span>"
            }
        },
        "title": {
            "AddKey": {
                "forContract": "Access Key added",
                "forReceiver": "Access Key added"
            },
            "CreateAccount": "New account created",
            "DeleteAccount": "Account deleted",
            "DeleteKey": "Key deleted",
            "DeployContract": "Contract deployed",
            "FunctionCall": "Method called",
            "Stake": "Staked",
            "Transfer": {
                "received": "Received SOL",
                "transferred": "Sent SOL"
            }
        }
    },
    "disclaimer": {
        "text": "This is a developers&apos; preview Wallet. It should be used for SOL Protocol DevNet only. Learn more at ",
        "title": "DISCLAIMER"
    },
    "emailSubscribe": {
        "placeholder": "Your email...",
        "title": "Sign up below for early access"
    },
    "enterAccountNameLedgerModal": {
        "header": "Enter account name",
        "one": "Enter the name of the account you’re signing in to"
    },
    "error": "Error",
    "errors": {
        "ledger": {
            "disconnected": "Ledger was disconnected.",
            "U2F_4": "Failed to sign with Ledger device: U2F DEVICE_INELIGIBLE. Please make sure that your Ledger device is connected and unlocked.",
            "U2FNotSupported": "U2F browser support is needed for Ledger. Please use Chrome, Opera or Firefox with a U2F extension. Also make sure you're on an HTTPS connection."
        },
        "type": {
            "LackBalanceForState": "Your available balance is too low to perform any actions on your account. Please send SOL to your account and then try again.",
            "RetriesExceeded": "Exceeded maximum attempts for this transaction."
        }
    },
    "existingAccount": {
        "fundNewAccount": {
            "titleOne": "Approve Transaction",
            "titleTwo": "Fund New Account"
        },
        "selectAccount": {
            "desc": "If you have an alternate account containing a balance of <b>at least ${amount} SOL</b>, you may use it to fund and initialize your new account!",
            "descTwo": "Select or sign into the account you wish to use, then continue to approve a minimum deposit.",
            "title": "Fund with an existing account."
        }
    },
    "explore": {
        "banner": {
            "button": "Buy SOL",
            "text": "Top up your wallet"
        },
        "categories": {
            "collectNFTs": "Collect NFTs",
            "exchanges": "Exchanges",
            "playToEarn": "Play To Earn",
            "startEarning": "Start Earning"
        },
        "sectionName": "Explore",
        "seeAll": "See All",
        "trendingProjects": "Trending projects"
    },
    "exploreApps": {
        "desc": "Earn, mint, and play! Discover the ecosystem of SOL applications.",
        "exploreApps": "Explore Apps",
        "exploreDeFi": "Explore DeFi",
        "exploreSOL": "Explore SOL",
        "text": "Earn, mint, and play! Discover the ecosystem of SOL applications.",
        "title": "What's on SOL?"
    },
    "exportPrivateKey": {
        "button": "Export Local Private Key",
        "desc": "Never share your private key! Anyone with access to it will also have full access to your account.<br /><br />",
        "enterAccountAddress": "Enter your account address to view your<br />private key.",
        "title": "View private key?"
    },
    "footer": {
        "contactSupport": "Join our community",
        "copyrights": "Genin Saifu LLC. All Rights Reserved.",
        "desc": "Solana is the atomically composable world computer that seamlessly runs killer apps at the speed of light.",
        "learnMore": "Learn More",
        "needHelp": "Questions?",
        "privacyPolicy": "Privacy Policy",
        "termsOfService": "Terms of Service"
    },
    "fullAccessKeys": {
        "authorizedTo": "AUTHORIZED TO",
        "dashboardNoKeys": "You have no full access keys",
        "deAuthorizeConfirm": {
            "desc": "The full access key will be permanently deactivated.",
            "title": "Are you sure you want to disable this full access key?"
        },
        "noKeys": "You have not connected your Genin Wallet to any applications. When you do, you can manage them here.<br /><br />See what's been built with SOL:",
        "pageTitle": "Full Access Keys",
        "submitAnyTransaction": "Submit any transaction on your behalf",
        "useContract": "Use <b>${receiverId}</b> contract on your behalf",
        "viewYourAccountName": "View your Account Name"
    },
    "getBalance": "Get balance",
    "hardwareDevices": {
        "desc": "Improve the security of your account by using a hardware wallet.",
        "disable": {
            "desc": "Make sure you have other recovery methods setup before disabling.",
            "disable": "Disable Ledger",
            "keep": "No, keep Ledger",
            "title": "Are you sure you want to disable Ledger?"
        },
        "ledger": {
            "auth": "Authorized",
            "connect": "Your ledger is not currently connected. We advise that you re-connect your Ledger to ensure your account is secured.",
            "disclaimer": "In order to disable your ledger device, you must first enable an alternative recovery method.",
            "title": "Ledger Hardware Wallet"
        },
        "title": "Hardware Devices"
    },
    "importAccount": {
        "noId": "Importing account",
        "noIdFailed": "Failed to import account.",
        "withId": "Importing account: ${accountId}",
        "withIdFailed": "Failed to import account: ${accountId}"
    },
    "importAccountWithLink": {
        "accountFound": "1 Account Found",
        "accountImported": "Account Imported",
        "accountsFound": "${count} Accounts Found",
        "alreadyImported": "The account secured by this link has been imported.",
        "continue": "and continue this process in your browser of choice.",
        "copyUrl": "copy the URL",
        "foundAccount": "We found <b>1 account</b> secured by this link.",
        "foundAccounts": "We found <b>${count} accounts</b> secured by this link.",
        "goToAccount": "Go to Account",
        "import": "Import",
        "importAccount": "Import Your Account",
        "preferedBrowser": "If this isn't your preferred browser,"
    },
    "importing": "Importing",
    "initialDeposit": {
        "claimAccount": {
            "desc": "Your deposit was received! Once claimed, your deposit will be transferred to your new account.",
            "disclaimer": {
                "checkBox": "Agree & Continue",
                "desc": "Important: The old single-use funding address will no longer be valid. Make sure to replace it with your new address on any exchanges or other devices where it may have been saved."
            },
            "title": "Claim Your Account"
        },
        "creditCard": {
            "desc": "Complete your purchase of <b>at least ${amount} SOL</b> through MoonPay. MoonPay will automatically deposit your funds into your new account.",
            "title": "Fund your new account using a credit card."
        },
        "manualDeposit": {
            "desc": "Meet the minimum initial deposit by sending <b>at least ${amount} SOL</b> to the single-use funding address below.",
            "title": "Submit a manual deposit."
        }
    },
    "input": {
        "accountId": {
            "placeholder": "e.g. johndoe.SOL",
            "placeHolderAlt": "Account ID",
            "subLabel": "The account ID must include a Top Level Account such as <b>.SOL</b> or contain exactly 64 characters.",
            "title": "Account ID"
        },
        "enterWord": {
            "placeholder": "",
            "title": "Word <b>#${wordId}</b>"
        }
    },
    "landing": {
        "banner": "Genin Wallet is in Private Alpha",
        "desc": "Safe & easy way for you to store, buy, send and stake across the SOL ecosystem.",
        "discoverAllTheFeaturesOfSol": "Every Solana feature at your fingertips",
        "or": "or",
        "title": "Unlock Solana"
    },
    "ledgerSignTxModal": {
        "ADD_ACCESS_KEY": "You will need to confirm login details on your Ledger.",
        "ADD_ACCESS_KEY_SEED_PHRASE": "You will need to confirm full access details on your Ledger.",
        "ADD_LEDGER_ACCESS_KEY": "You will need to confirm public key details on your Ledger.",
        "CREATE_ACCOUNT_FROM_IMPLICIT": "You will need to confirm account creation on your Ledger. This includes transactions needed to move balance from temporary account.",
        "CREATE_NEW_ACCOUNT": "You will need to confirm add a key for new account on your Ledger.",
        "DEFAULT": "You will need to confirm the transaction details on your Ledger.",
        "DELETE_RECOVERY_METHOD": "You will need to confirm delete key details on your Ledger.",
        "DISABLE_LEDGER": "You will need to confirm public key details on your Ledger.",
        "GET_LEDGER_PUBLIC_KEY": "You will need to confirm public key access on your Ledger.",
        "REMOVE_ACCESS_KEY": "You will need to confirm delete key details on your Ledger.",
        "REMOVE_NON_LEDGER_ACCESS_KEYS": "You will need to confirm details on your Ledger.",
        "SEND_MONEY": "You will need to confirm transfer details on your Ledger.",
        "SET_SIGN_TRANSACTION_STATUS": "You will need to confirm deposit details on your Ledger.",
        "SETUP_RECOVERY_MESSAGE": "You will need to confirm full access details on your Ledger.",
        "SIGN_AND_SEND_TRANSACTIONS": "You will need to confirm deposit details on your Ledger.",
        "STAKE": "You will need to confirm staking on your Ledger",
        "UNSTAKE": "You will need to confirm unstaking on your Ledger",
        "WITHDRAW": "You will need to confirm withdrawal on your Ledger"
    },
    "link": {
        "account": "Account",
        "authorizedApps": "Authorized Apps",
        "buyUSN": "Buy $USN",
        "donateToUkraine": "Donate to Ukraine",
        "explore": "Explore",
        "fullAccessKeys": "Full Access Keys",
        "help": "Help",
        "noAccount": "You have no other accounts",
        "receive": "Receive",
        "send": "Send",
        "staking": "Staking",
        "swap": "Swap",
        "switchAccount": "Account",
        "wallet": "Wallet"
    },
    "linkdropLanding": {
        "claiming": "Claiming",
        "ctaAccount": "Claim with Existing Account",
        "ctaLogin": "Log In and Claim",
        "ctaNew": "Claim with New Account",
        "desc": "You may claim your SOL drop with an existing account (must be logged in), or create a new account to receive it as an initial deposit.",
        "modal": {
            "desc": "Your SOL drop has been automatically deposited to your account.",
            "title": "Your SOL drop has been claimed"
        },
        "or": "or",
        "title": "You've received a SOL drop!"
    },
    "loading": "Loading...",
    "loadingNoDots": "Loading",
    "lockedBalance": "This SOL is locked in a lockup contract, and cannot be withdrawn. You may still delegate or stake this SOL. Once the SOL is unlocked, you can view it in your Unlocked Balance, and chose to withdraw it (moving to your Available Balance).",
    "login": {
        "cliLoginSuccess": {
            "pageText": "Please close this window and follow the instructions in the terminal.",
            "pageTitle": "You've successfully authorized SOL Shell!"
        },
        "confirm": {
            "pageText": "You are granting <b>full access</b> to ${appTitle}!",
            "pageTextSecondLine": "<b>To confirm</b>, please enter your account ID below.",
            "pageTitle": "Are you sure?",
            "username": "Account ID"
        },
        "details": {
            "callFunctions": "Call functions on any smart contract",
            "createAndDeleteAccessKeys": "Create and delete access keys",
            "createNewAccounts": "Create new accounts",
            "deploySmartContracts": "Deploy smart contracts",
            "detailedDescription": "Detailed description of transaction",
            "forContract": "For Contract",
            "function": "Function",
            "noDescription": "No description specified for this method",
            "stakeAndUnstake": "Stake and unstake SOL tokens",
            "thisAllows": "This allows ${appTitle} to:",
            "transferTokens": "Transfer tokens from your account to other accounts"
        },
        "form": {
            "accessYourAccount": "access your account.",
            "accountIdOnly": "is asking for your Account ID.",
            "isRequestingFullAccess": "is requesting <b>full access</b>",
            "isRequestingTo": "is requesting to",
            "thisDoesNotAllow": "This does not allow the app to transfer any tokens.",
            "thisProvidesAccess": "This provides access to <b>all of your tokens</b>.<br />Proceed with caution!",
            "toYourAccount": "to your account."
        },
        "v2": {
            "connectConfirm": {
                "desc": "Only connect to sites that you trust. Once connected, <a href='${contractIdUrl}' target='_blank' rel='noreferrer'>${appReferrer}</a> will have <b>${accessType}</b>:",
                "feeAllowance": {
                    "desc": "The application will be given permission to spend up to <b>${amount} SOL</b> towards network fees incurred during use.",
                    "title": "Network Fee Allowance"
                },
                "fullAccess": "full permissions",
                "fullAccessModal": {
                    "desc": "Proceed with caution. Full access permissions allow contracts and applications to access your token balance. To confirm, enter your account ID below.",
                    "title": "Grant full account access?"
                },
                "fullAccessWarning": "This site is requesting full access to your account. To enable full access, you’ll be asked to verify your account ID.",
                "limitedAccess": "limited permissions",
                "permissions": {
                    "callMethodsOnContract": "Call methods on the smart contract on behalf of your permitted account",
                    "notTransferTokens": "This does not allow the app to transfer tokens",
                    "transferTokens": "Transfer tokens from your account",
                    "viewAddress": "View the address of your permitted account",
                    "viewBalance": "View the balance of your permitted account"
                },
                "title": "Connecting with:"
            },
            "connectWithSOL": {
                "desc": "An application is requesting <b>${accessType}</b> to your SOL account. Select the account you wish to connect.",
                "fullAccess": "full access",
                "limitedAccess": "limited access",
                "title": "Connect with SOL"
            }
        }
    },
    "migration": {
        "message": "As of <b>January 8th, 2024</b> the Genin Wallet will be discontinued. No changes will be made to your account or its assets. Use your recovery phrase or the <a href='/transfer-wizard' target='_blank' rel='noopener noreferrer'>Transfer Wizard</a> to securely migrate to a different wallet. <a href='https://SOL.org/blog/embracing-decentralization-whats-next-for-the-SOL-wallet' target='_blank' rel='noopener noreferrer'><b>Learn More</b></a>",
        "redirectCaption": "Learn More",
        "transferCaption": "Transfer My Accounts"
    },
    "minimumBalance": "This is the minimum SOL balance your account must maintain to remain active. This balance represents the storage space your account is using on the SOL blockchain (with a small buffer), and will go up or down as you use more or less space.",
    "mobileDeviceAccess": {
        "importCode": {
            "desc": "If you are already logged in on a desktop device, scan your mobile access code to import your account."
        },
        "showCode": {
            "button": "View Mobile Code",
            "desc": "Scan your mobile access code to grant full account access to your mobile device.",
            "modal": {
                "desc": "Reveal and scan the QR code below to import your account on your mobile device.",
                "disclaimer": "<b>This code contains highly sensitive data and grants full account access to your mobile device.</b> Take care not to share or expose it, and only import your account to a secure device that you own.",
                "reveal": "Reveal",
                "title": "Mobile Access Code"
            }
        },
        "title": "Mobile Device Access"
    },
    "moreSOLWalletsModal": {
        "desc": "Soon Genin Wallet will no longer be supported and you’ll need another wallet to manage your accounts. We recommend that you start using a different wallet to create new accounts.",
        "primaryButton": "More Genin Wallets",
        "title": "Create an account with Genin Wallet?"
    },
    "networkBanner": {
        "desc": "This wallet (and network) are for testing purposes only. Tokens and other assets have no value. Accounts created do not transfer to Mainnet.",
        "header": "SOL test Wallet",
        "title": "Test-only Wallet"
    },
    "NFTDetail": {
        "owner": "OWNER",
        "transfer": "Transfer"
    },
    "NFTs": {
        "emptyState": "Looks like you don't have any collectibles yet.",
        "loadMore": "Load more"
    },
    "NFTTransfer": {
        "cancel": "Cancel",
        "confirm": "Confirm",
        "continue": "Continue",
        "enterReceiver": "Enter a recipient address, then proceed to confirm your transaction.",
        "next": "Next",
        "transactionComplete": "Transaction Complete!",
        "transferNft": "Transfer NFT",
        "viewTransaction": "View Receipt",
        "youSent": "You sent <span class='color-blue'>${title}</span> to <span class='color-green'>${receiverId}</span>"
    },
    "nodeDetails": {
        "pageText": "Update the details of your node.",
        "pageTitle": "Node Details"
    },
    "nodeStaking": {
        "nodes": {
            "noNode": {
                "text": "This connects your node to your wallet account.",
                "title": "Already have your node setup? Add it here."
            },
            "title": "Nodes"
        },
        "pageText": "nodeandstaking.SOL",
        "pageTitle": "Node & Staking",
        "staking": {
            "title": "Staking",
            "youAreStaking": "You are staking"
        },
        "steps": {
            "one": {
                "desc": "Instructions available in the Docs",
                "title": "1. Setup & Run a Node via CLI"
            },
            "three": {
                "desc": "Stake from here or CLI. See docs for help.",
                "title": "3. Start Staking"
            },
            "title": "To run a node or stake, please fallow these steps:",
            "two": {
                "desc": "An out of sync node cannot produce or validate",
                "title": "2. Wait for Node to Sync"
            }
        }
    },
    "of": "of",
    "ofTotal": "of Total",
    "or": "or",
    "pageNotFound": {
        "displayTitle": "404",
        "returnToWallet": "Return to Wallet",
        "title": "Whoops, looks like this page is missing."
    },
    "poweredByCoinGecko": "Prices powered by <a href='https://www.coingecko.com/en/api' target='_blank' rel='noopener noreferrer'>CoinGecko</a>",
    "profile": {
        "account": {
            "available": "Available balance",
            "availableToTransfer": "Available to transfer",
            "availableToWithdraw": "Available for withdrawal",
            "inStakingPools": "In staking pools",
            "pendingRelease": "Pending release",
            "reservedForStorage": "Reserved for storage",
            "reservedForTransactions": "Reserved for transactions",
            "staked": "Staked",
            "staking": "Staking",
            "unstaked": "Unstaked",
            "walletBalance": "Wallet balance",
            "walletId": "Wallet ID"
        },
        "accountDoesNotExistBanner": {
            "desc": "No activity has been recorded for <a href='https://explorer.SOL.org/accounts/${data}' rel='noopener noreferrer' target='_blank'><b>this account</b></a>. Send SOL to your account to begin using all features of the Genin Wallet."
        },
        "authorizedApps": {
            "title": "Authorized Apps"
        },
        "details": {
            "availableBalance": "Available Balance",
            "locked": "Locked",
            "lockPopup": {
                "createAnotherAccount": "create another account",
                "text": "Your account ID is your unique identifier in the system and cannot be changed because data and asset ownership is tied to it. However you are welcome to ${link} with desired name and transfer assets as needed.",
                "title": "Why is this locked?"
            },
            "minBalance": "Minimum Balance",
            "profile": "PROFILE",
            "public": "Public",
            "qrDesc": "Use your phone's camera app to send to this address",
            "staked": "Staked",
            "totalBalance": "Total Balance",
            "unlocked": "Unlocked",
            "unvested": "Unvested",
            "username": "Account ID",
            "visibleTo": "VISIBLE TO"
        },
        "lockup": {
            "locked": "Locked",
            "lockupBalance": "Lockup balance",
            "lockupId": "Lockup ID",
            "unlocked": "Unlocked"
        },
        "lockupBanner": {
            "cta": "Transfer to Wallet",
            "title": "You have <b>${amount}</b> available to withdraw from your lockup!"
        },
        "pageTitle": {
            "default": "Account Details",
            "loading": "Loading...",
            "notFound": "Account ${accountId} not found"
        },
        "security": {
            "lessSecure": "Most Convenient",
            "lessSecureDesc": "These options are recommended for accounts with small amounts of SOL and other assets. They are convenient, but do present additional risk to the more secure options. If your email account or SMS messages are compromised, your funds might be at risk.",
            "mostSecure": "Most Secure (Recommended)",
            "mostSecureDesc": "Ledger is the most secure option. Passphrases can be secure if used correctly (they must be written down and stored safely).",
            "title": "Security & Recovery"
        },
        "twoFactor": "Two-Factor Authentication",
        "twoFactorDesc": "Authenticate with SMS or email when authorizing transactions and/or signing in to your account."
    },
    "reCAPTCHA": {
        "disclaimer": "This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank' rel='noopener noreferrer'>Terms of Service</a> apply.",
        "fail": {
            "desc": "reCAPTCHA is required in order to authorize new accounts funded by SOL.",
            "link": "Fund your own account?",
            "title": "Failed to load reCAPTCHA."
        },
        "loading": "Loading reCAPTCHA..."
    },
    "receivePage": {
        "addressTitle": "Your Wallet Address",
        "copyAddressLinkLong": "Copy address URL",
        "copyAddressLinkShort": "COPY",
        "qrCodeTitle": "Scan QR code",
        "snackbarCopySuccess": "Address URL copied!"
    },
    "recoverAccount": {
        "actionRequired": "This message contains a magic link. Click the link to begin recovery!",
        "actionType": "Recovery",
        "cannotResend": "This message was sent when you enabled email/phone recovery. We only send this message once, and cannot resend it.",
        "email": {
            "desc": "Check your email for a message from SOL.org with the subject:",
            "subject": "\"Important: Genin Wallet Recovery Email\".",
            "title": "Email"
        },
        "ledger": {
            "desc": "Make sure your ledger is connected securely, and that the SOL app is open on the device.",
            "title": "Ledger"
        },
        "pageText": "If you’ve setup one or more account recovery methods, follow the instructions below to import your account.",
        "pageTitle": "Import Account",
        "phone": {
            "desc": "Check your phone records for an SMS message from",
            "number": "+14086179592.",
            "title": "Phone"
        },
        "phrase": {
            "desc": "Make sure you have your 12 word recovery phrase, then click below to begin the recovery process.",
            "title": "Passphrase"
        }
    },
    "recoverSeedPhrase": {
        "couldNotFindAccountModal": {
            "buttonImport": "Import Anyway",
            "desc": {
                "ledger": "We couldn't find any <b>active account(s)</b> using the provided Ledger key. This could be because the account has no activity yet.",
                "phrase": "We couldn't find any <b>active account(s)</b> using the provided passphrase. This could be because the passphrase is incorrect, or because the account has no activity yet."
            },
            "title": "Couldn't find account"
        },
        "pageText": "Enter the backup passphrase associated with the account.",
        "pageTitle": "Recover using Passphrase",
        "seedPhraseInput": {
            "placeholder": "correct horse battery staple...",
            "title": "Passphrase (12 words)"
        }
    },
    "recoverWithLink": {
        "errorP": "Please check your email or phone for the most recent message. Links in all earlier messages are invalid.",
        "errorTitle": "Link Expired",
        "pOne": "Click \"Continue\" to restore your account:",
        "pTwo": "If this isn't your preferred browser, copy the URL and complete this process in your browser of choice.",
        "snackbarCopySuccess": "Recover URL copied",
        "title": "Restore Account"
    },
    "recoveryMgmt": {
        "disableInputPlaceholder": "Enter your account ID to confirm",
        "disableNo": "No, keep",
        "disableNotAllowed": "Accounts are required to have at least one active recovery method. Please first enable an alternative recovery method to proceed.",
        "disableTextLink": "The magic link you received will be permanently deactivated.",
        "disableTextPhrase": "Your current passphrase will be permanently deactivated.",
        "disableTitle": "Are you sure you want to disable this method?",
        "enabled": "Enabled",
        "methodTitle": {
            "email": "Email",
            "phone": "Phone",
            "phrase": "Passphrase"
        },
        "noRecoveryMethod": "You have no method to recover your account. please add a method below.",
        "recoveryLinkSent": "Recovery Link Sent!",
        "resend": {
            "email": "Resend Email",
            "phone": "Resend SMS"
        },
        "title": "Recovery Methods",
        "unsupportedMethodEmail": "We're removing support for email as recovery method. Once you've disabled this method, you will not be able to re-enable it.",
        "unsupportedMethodPhone": "We're removing support for SMS as recovery method. Once you've disabled this method, you will not be able to re-enable it."
    },
    "reduxActions": {
        "ADD_ACCESS_KEY": {
            "error": "An error occurred while approving this action. Please try again!",
            "success": "${title} is now authorized to use your account."
        },
        "ADD_ACCESS_KEY_SEED_PHRASE": {
            "error": "An error occurred while setting up passphrase recovery. Please try again!",
            "success": "Passphrases recovery setup is complete."
        },
        "ADD_LEDGER_ACCOUNT_ID": {
            "error": "Access to one of the accounts was rejected. No accounts were recovered.<br>To continue, you must authorize read-only access to each account on your Ledger device. Please try again.",
            "success": ""
        },
        "CHECK_ACCOUNT_AVAILABLE": {
            "error": "User not found.",
            "success": "User found."
        },
        "CHECK_IS_NEW": {
            "error": "Account ID is taken. Try something else.",
            "success": "Congrats! ${accountId} is available."
        },
        "CHECK_NEW_ACCOUNT": {
            "error": "Account ID is taken. Try something else.",
            "success": "Congrats! ${accountId} is available."
        },
        "CREATE_ACCOUNT_WITH_SEED_PHRASE": {
            "error": "",
            "success": ""
        },
        "CREATE_NEW_ACCOUNT": {
            "error": "Account ID is taken. Try something else.",
            "success": "Congrats! ${accountId} is available."
        },
        "default": {
            "error": "Sorry an error has occurred. You may want to try again.",
            "success": ""
        },
        "DELETE_RECOVERY_METHOD": {
            "error": "",
            "success": ""
        },
        "DEPLOY_MULTISIG": {
            "error": "",
            "success": ""
        },
        "Deserialization": "",
        "GET_LEDGER_ACCOUNT_IDS": {
            "error": "Public key access was rejected. No accounts were recovered.<br/>To continue, you must allow Genin Wallet to view your public key. Please try again.",
            "success": ""
        },
        "LackBalanceForState": "Your available balance is too low to perform any actions on your account. Please send SOL to your account and then try again.",
        "NotEnoughBalance": "Your account does not have enough balance for this operation.",
        "PROMPT_TWO_FACTOR": {
            "invalidCode": "Invalid 2FA code. Please try again."
        },
        "RECOVER_ACCOUNT_SEED_PHRASE": {
            "error": "Failed to recover account. No accounts were found for this passphrase.",
            "success": "Recover using passphrase is complete.<br/><b>${numberOfAccounts} account(s)</b> have been successfully recovered.<br/>The last one is set as active."
        },
        "REFRESH_ACCOUNT_EXTERNAL": {
            "error": "Account <b>${accountId}</b> not found"
        },
        "REMOVE_ACCESS_KEY": {
            "error": "Please check that your device is connected and the SOL app is open.",
            "success": ""
        },
        "RetriesExceeded": "We are experiencing a high load. Please try again.",
        "SAVE_AND_SELECT_LEDGER_ACCOUNTS": {
            "error": "Please check that your device is connected and the SOL app is open.",
            "success": "You’ve successfully signed in.<br/><b>${numberOfAccounts} account(s) were recovered.</b> Your most recently created account has been set to active."
        },
        "SEND_MONEY": {
            "error": "Sorry an error has occurred. You may want to try again."
        },
        "SETUP_RECOVERY_MESSAGE": {
            "error": "An error occurred while setting up your recovery method. Please try again!",
            "success": "Recovery setup is complete."
        },
        "SIGN_AND_SEND_TRANSACTIONS": {
            "error": "",
            "success": ""
        },
        "TRANSFER_ALL_FROM_LOCKUP": {
            "error": "Something went wrong. Please try again.",
            "success": "You successfully transferred tokens from your lockup!"
        },
        "U2F_4": "Failed to sign with Ledger device: U2F DEVICE_INELIGIBLE. Please make sure that your Ledger device is connected and unlocked.",
        "VERIFY_TWO_FACTOR": {
            "error": "Two Factor Authentication setup failed. Please try again.",
            "success": "Two Factor Authentication successfully setup!"
        }
    },
    "releaseNotesModal": {
        "desc": "You asked, we listened! The latest update brings adjustments to how balances are displayed in the wallet, and an easier way to create new accounts.",
        "subText1": "During on-boarding, you may now use an SMS capable phone number to verify and initialize your new account. Users who don’t wish to provide this information may still initialize new accounts with an initial deposit.",
        "subText2": "The primary balance displayed in the wallet is now your 'Available Balance', where it was previously your 'Total Balance'. The Available Balance offers the most utility to users wanting to know at a glance what they have available to spend/stake/and interact with the ecosystem; it does not include any tokens that are not readily spendable, such as tokens that are staked.  You can still see your staked tokens balance details on the 'Account' and 'Staking' screens.",
        "subTitle1": "New Features",
        "subTitle2": "Enhancements",
        "title": "Release Notes"
    },
    "removeAccount": {
        "button": "Remove Account from Wallet",
        "desc": "You will need your recovery method to re-import this account in the future. Make sure you still have access to it.",
        "disclaimer": "I have access to the recovery method for <b>${accountId}</b>",
        "title": "Remove Account?"
    },
    "removeLinkRecovery": {
        "button": "Go to Account",
        "desc": "We have updated our security measures to protect your account. Please enable a passphrase or Ledger recovery method and <a href=\"/profile\">disable all email and SMS recovery methods</a> to ensure your account is protected. <a href=\"https://SOL.org/blog/SOL-web-wallet-security-update/\" target=\"_blank\">Learn more</a>.",
        "title": "Important Security Update"
    },
    "reservedForFeesInfo": "Up to ${data} SOL is reserved to cover the cost of transactions.",
    "riscScoring": {
        "checkbox": "I accept responsibility for sending funds to this address.",
        "scamWarning": "This address has a high level of risk and marked as scam. Make sure that this address is safe before sending funds."
    },
    "selectAccountDropdown": {
        "account": "Account",
        "createAccount": "Create New Account",
        "noOtherAccounts": "You have no other accounts",
        "selectAccount": "Select account",
        "switchAccount": "Switch account",
        "switchAccounthNotAllowed": "This app doesn't allow account changes"
    },
    "sending": "Sending",
    "sendMoney": {
        "account": {
            "title": "Send to"
        },
        "amount": {
            "available": "Available to send"
        },
        "amountStatusId": {
            "available": "Available Balance:",
            "howMuch": "How much do you want to send?",
            "noMoreThan": "NO MORE THAN 5 DECIMAL DIGITS",
            "notEnoughTokens": "You must leave at least ${amount} SOL in your account",
            "sending": "Sending:"
        },
        "banner": {
            "insufficient": "You are attempting to send your entire available balance. At least <b>${data} SOL</b> must be reserved to cover transaction fees.",
            "useMax": "A small portion (${data} SOL) of your available balance has been reserved to cover transaction fees."
        },
        "button": {
            "confirm": "Confirm & Send",
            "dashboard": "Go To Dashboard",
            "send": "Submit"
        },
        "confirmModal": {
            "title": "Confirm transaction"
        },
        "onceConfirmed": "Once confirmed, this cannot be undone.",
        "subtitle": {
            "default": "Enter the amount of SOL to send, and the account ID to send it to.",
            "success": "You have successfully sent",
            "to": "to:"
        },
        "title": {
            "default": "Send",
            "success": "Success!"
        },
        "to": "to",
        "wasSentTo": "was sent to:",
        "youAreSending": "You are sending"
    },
    "sendV2": {
        "accordionTriggerTitle": {
            "balanceDetails": "Balance details",
            "transactionDetails": "Transaction Details"
        },
        "review": {
            "title": "You are sending"
        },
        "selectAsset": {
            "assetInputPlaceholder": "Search asset name",
            "assetListNameTitle": "Name",
            "asssetListBalanceTitle": "Available to send",
            "title": "Select Asset"
        },
        "selectReceiver": {
            "receiverInputLabel": "Send to"
        },
        "selectTokenButtonTitle": "Select Asset",
        "success": {
            "title": "Transaction complete!</br>You sent <span class='color-blue'>${amount}</span></br>to <span class='color-green'>${receiverId}</span>"
        },
        "translateIdInfoTooltip": {
            "estimatedFees": "The estimated cost of processing your transaction.",
            "estimatedTotal": "The estimated total of your transaction including fees.",
            "networkFees": "The final amount paid to cover the costs of processing your transaction.",
            "reservedForFees": "The portion of your account balance that cannot be spent. This amount is reserved to cover the storage costs of your account. For more information, visit our documentation."
        },
        "TXEntry": {
            "status": {
                "Failure": "Failed",
                "notAvailable": "Status not available",
                "SuccessValue": "Successful"
            },
            "title": {
                "amount": "Amount",
                "availableBalance": "Available balance",
                "availableToSend": "Available to Send",
                "estimatedFees": "Estimated fees",
                "estimatedTotal": "Estimated total",
                "from": "From",
                "networkFees": "Network fees",
                "receiverId": "Sent to",
                "reservedForFees": "Reserved for fees",
                "status": "TXN Status",
                "timeStamp": "Date & time",
                "to": "To",
                "token": "Asset"
            }
        }
    },
    "setRecoveryConfirm": {
        "didNotReceive": "Didn't receive your code?",
        "email": "email address",
        "inputError": "Please enter verification code!",
        "inputHeader": "Enter verification code",
        "inputPlaceholder": "Verification Code",
        "or": "or",
        "pageText": "We've sent a 6-digit verification code to the ",
        "phone": "phone number",
        "reenter": {
            "link": "click here",
            "one": {
                "email": "If you did not yet receive this email, or the above email address is incorrect, ",
                "phoneNumber": "If you did not yet receive this SMS, or the above phone number is incorrect, "
            },
            "two": {
                "email": " to Re-enter your email address and resend.",
                "phoneNumber": " to Re-enter your phone number and resend."
            }
        },
        "resendCode": "Resend your code",
        "resending": "Resending...",
        "sendToDifferent": "Send to a different",
        "title": "Enter Verification Code"
    },
    "setupLedger": {
        "header": "Connect your Ledger device",
        "one": "Connect your Ledger Nano S or X to your computer or mobile device via USB and <b>open the SOL application.</b>",
        "two": "If you have not yet installed the SOL Ledger app, follow",
        "twoLink": "these instructions"
    },
    "setupLedgerSteps": {
        "five": "Select the device from the list in the second pop-up and select Connect.",
        "four": "On the first pop-up window, select the Connect button.",
        "header": "Install SOL on your Ledger device",
        "install": "If you have not yet installed the SOL Ledger app, follow",
        "installLink": "<a href='https://www.ledger.com/ledger-live' target='_blank'>these instructions</a>.",
        "one": "Connect your Ledger via USB to your computer.",
        "six": "Select the Continue button below to finish setting up the Ledger on this account.",
        "three": "Select the Connect Ledger button at the top right of the window.",
        "two": "Open the SOL app on the Ledger."
    },
    "setupLedgerSuccess": {
        "header": "Ledger has secured your account!",
        "nextStep": {
            "header": {
                "keep": "Keep existing keys?",
                "remove": "Confirm key removal"
            },
            "one": {
                "keep": "<span class='color-red'>Maintaining multiple recovery methods and devices increases the vulnerability of your account.</span>",
                "remove": "Before removing your existing keys and recovery methods, make sure that you have recorded and securely stored your Ledger passphrase."
            },
            "two": {
                "remove": "If you lose access to your passphrase, SOL Inc. will be unable to assist you in recovery of your account and its funds."
            }
        },
        "one": "We recommend that you remove all existing keys (recovery methods and devices).",
        "primaryCta": "Remove Existing Keys and Recovery Methods",
        "secondaryCta": "Keep existing keys",
        "two": "Maintaining multiple keys and recovery methods (including passphrases) increases the vulnerability of your account."
    },
    "setupRecovery": {
        "advancedSecurity": "Most Secure (Recommended)",
        "advancedSecurityDesc": "Write down a 12 word passphrase, or secure your account with your Ledger device.",
        "basicSecurity": "Most Convenient",
        "basicSecurityDesc": "Enter your email address or phone number and receive a magic link",
        "emailDesc": "Receive a verification code and account recovery link via email.",
        "emailPlaceholder": "example@email.com",
        "emailTitle": "Email",
        "existingPhraseDesc": "Protect your new address by sharing an existing passphrase.",
        "existingPhraseTitle": "Use an Existing Passphrase",
        "header": "Choose a Security Method",
        "ledgerDesc": "Secure your account with a Ledger hardware device.",
        "ledgerTitle": "Ledger Hardware Wallet",
        "newPhraseDesc": "Protect your new address with a new and unique passphrase.",
        "newPhraseTitle": "Generate a New Passphrase",
        "notSupportedPhone": "Unfortunately, we do not offer SMS for your region. Please choose email instead.",
        "phoneDesc": "Receive a verification code and account recovery link via SMS.",
        "phonePlaceholder": "+1 415 797 8554",
        "phoneTitle": "Phone",
        "phraseDesc": "Generate and safely store a unique passphrase.",
        "phraseTitle": "Secure Passphrase",
        "subHeader": "Select a method to secure and recover your account. This will be used to verify important activity, recover your account and access your account from other devices."
    },
    "setupSeedPhrase": {
        "pageText": "Write down the following words in order and keep them somewhere safe. <b>Anyone with access to it will also have access to your account!</b> You’ll be asked to verify your passphrase next.",
        "pageTitle": "Setup Your Secure Passphrase",
        "snackbarCopyImplicitAddress": "Funding Address Copied!",
        "snackbarCopySuccess": "Passphrase copied!"
    },
    "setupSeedPhraseSuccess": {
        "pageText": "Use your Recovery Phrase to restore your account.",
        "pageTextSecondLine": "Anyone with access to your Recovery Phrase can access your funds, so keep it offline somewhere safe and secure.",
        "pageTitle": "Recovery Setup is Complete"
    },
    "setupSeedPhraseVerify": {
        "inputError": "Please check your passphrase and try again.",
        "pageText": "Enter the following word from your recovery phrase to complete the setup process.",
        "pageTitle": "Verify Phrase",
        "startOverText": "Didn't write it down?"
    },
    "sign": {
        "accountNotFound": {
            "body": "${signCallbackUrl} is requesting authorization from a SOL account that cannot be found: <b>${signTransactionSignerId}</b><br/><br/>To approve any transactions, you’ll first need to import the account.",
            "title": "Account Not Found"
        },
        "ActionWarrning": {
            "binaryData": "Arguments contain binary data",
            "deleteAccount": "You are about to delete your account! Your SOL balance will be destroyed, and all of your account data deleted.",
            "deployContract": "You are about to deploy a contract to your account! This contract can access your SOL balance, and interact with other contracts on your behalf.",
            "functionCall": "No description specified for this function.",
            "stake": "You are about to stake SOL tokens. These tokens will be locked, and are at risk of being lost if your validator becomes unresponsive."
        },
        "approveTransaction": "Approve Transaction",
        "authorizationRequestSuccessful": "Authorization request successful",
        "authorizing": "Authorizing",
        "availableBalance": "Available Balance",
        "availableToTransfer": "Available to transfer",
        "contract": "Contract:",
        "contractDetails": "Contract Details",
        "details": {
            "detailedDescription": "Detailed description of transaction",
            "forContract": "For Contract",
            "gasLimit": "Gas Limit",
            "gasPriceUnavailable": "Gas price estimate is unavailable",
            "transactionFees": "Transaction Fees"
        },
        "estimatedFees": "Estimated Fees",
        "feeLimit": "Fee Limit",
        "function": "Function:",
        "gasLimit": "Gas Limit",
        "hereAreSomeDetails": "Here are some details that will help you.",
        "insufficientFunds": "Insufficient Funds",
        "insufficientFundsDesc": "You do not have enough available SOL to complete this transaction.",
        "invalidTransaction": {
            "body": "The request is invalid and cannot be signed.",
            "title": "Invalid Transaction"
        },
        "isRequesting": {
            "authorization": "is requesting authorization",
            "transferOf": "is requesting the transfer of"
        },
        "multipleAccountsError": {
            "body": "<a href='${signCallbackUrl}' target='_blank' rel='noreferrer'>${signCallbackUrl}</a> has requested authorization from more than one account.<br/><br/>Genin Wallet may only authorize transactions for a single account at a time.",
            "title": "Batch Transaction Error"
        },
        "networkFees": "Network Fees",
        "nothingHasBeenTransferred": "Nothing has been transferred.",
        "retry": {
            "estimatedFees": "Estimated Fees",
            "feeLimit": "Fee Limit",
            "link": "What is the fee limit?",
            "networkFees": "Network Fees",
            "text": "The default network fee was not enough to cover the cost of your transaction.<br/><br/>You may resubmit the transaction to have its fee limit automatically increased.",
            "title": "Insufficient Network Fee"
        },
        "transactionCancelled": "Transaction cancelled",
        "transactionDetails": "Transaction Details",
        "transferring": "Transferring",
        "unexpectedStatus": "Unexpected status",
        "unknownApp": "Unknown App",
        "wasTransferredSuccessfully": "was transferred successfully"
    },
    "signInLedger": {
        "advanced": {
            "desc": "Specify an HD path to import its linked accounts.",
            "exportDesc": "Specify an HD path to export your account to, you’ll need to remember your choice to use this account in the future.<br/><b>For additional security use different HD paths for each unique account.</b>",
            "setPath": "Set HD Path",
            "subTitle": "HD Path",
            "title": "Advanced Options"
        },
        "firefoxBanner": {
            "desc": "Ledger Connect is not supported in the following browsers: Firefox version 112+, Safari. It is fully supported in Chrome / Chromium browsers. We apologize for the inconvinience."
        },
        "header": "Authorize Genin Wallet to import your accounts.",
        "modal": {
            "accountsApproved": "account(s) approved",
            "confirmPublicKey": "Confirm public key",
            "ledgerMustAdd": "Ledger must add an access key for each account protected by your device:",
            "status": {
                "confirm": "Confirm on Device",
                "error": "Error",
                "pending": "Pending",
                "rejected": "Rejected",
                "success": "Approved",
                "waiting": "Up next"
            }
        },
        "one": "Before importing your accounts, you’ll need to grant Genin Wallet permission to access them."
    },
    "stagingBanner": {
        "desc": "Warning: This is a pre-release version of the Genin Wallet. There may be bugs, and these bugs may lead to loss of funds. By continuing to use this version, you agree that you understand and accept these risks, and understand that the Genin Wallet team will be unable to help you.",
        "title": "Warning: This is a pre-release version of Wallet. Use at your own risk!"
    },
    "stakedBalance": "This SOL is actively being used to back a validator and secure the network. When you decide to unstake this SOL, it will take some time to be shown in your Available Balance, as SOL takes 4 epochs (~52-65 hours) to unstake.",
    "staking": {
        "alertBanner": {
            "button": "View Current Validator",
            "title": "You must first unstake and withdraw your tokens from your current validator in order to stake with a new validator."
        },
        "amountStakingInput": {
            "placeholder": "example: 15",
            "title": "Enter amount to stake"
        },
        "balanceBanner": {
            "currency": "SOL",
            "title": "Available lockup balance"
        },
        "balanceBox": {
            "available": {
                "button": "Withdraw",
                "info": "These tokens have been unstaked, and are ready to be withdrawn.",
                "title": "Available for withdrawal"
            },
            "farm": {
                "button": "Claim",
                "info": "This validator grants staking rewards in a token other than SOL."
            },
            "farmed": {
                "info": "Collected tokens that have been earned during farming with farming validators.",
                "title": "Available for claim"
            },
            "pending": {
                "info": "These tokens have been unstaked, but are not yet ready to withdraw. Tokens are ready to withdraw 52 to 65 hours after unstaking.",
                "title": "Pending release"
            },
            "staked": {
                "button": "Unstake",
                "info": "SOL tokens currently staked with validators. These tokens are accumulating rewards. To access these tokens, you must first unstake and then withdraw them.",
                "title": "Total amount staked"
            },
            "unclaimed": {
                "info": "Rewards that have been earned, but not withdrawn. SOL token rewards are  <a target='_blank' href='https://www.investopedia.com/terms/c/compoundinterest.asp'>compounding</a> and are automatically re-staked.",
                "title": "Rewards earned",
                "unavailable": {
                    "cta": "Learn More",
                    "modalDescOne": "We are currently unable to calculate rewards when Two-Factor Authentication is enabled. We hope to enable this functionality in the SOL future.",
                    "modalDescTwo": "In the meantime, you will be able to see your rewards accumulating under ”Total amount staked”. This balance will grow while your tokens are staked, since your rewards are automatically re-staked with your current validator.",
                    "modalTitle": "Unable to display staking rewards earned",
                    "title": "Unable to display rewards earned."
                }
            }
        },
        "claimSuccess": {
            "button": "Return to Dashboard",
            "desc": "Tokens have been successfully claimed from the validator",
            "descTwo": "Your tokens are already available",
            "title": "Success!"
        },
        "noValidators": {
            "title": "You are not currently staking with any validators."
        },
        "pageText": "Setup your Node via CLI, then add it here.",
        "pageTitle": "Staking",
        "stake": {
            "accounts": "If you have locked SOL, you can choose to stake with either your locked SOL (lockup.SOL), or your unlocked SOL (your account name). You will only see the staking positions for the selected account. If you do not have locked SOL, you can ignore this section",
            "amount": "Amount",
            "banner": {
                "insufficientBalance": "You are attempting to stake your entire available balance. At least <b>${data} SOL</b> must be reserved to cover transaction fees.",
                "stakeMax": "A small portion (${data} SOL) of your available balance has been reserved to cover transaction fees."
            },
            "button": "Submit Stake",
            "confirm": "You are staking",
            "desc": "Enter the amount you would like to stake with your chosen validator.",
            "from": "from",
            "input": {
                "availableBalance": "Available balance",
                "SOL": "SOL"
            },
            "ledgerDisclaimer": "Note: Since this is your first time staking with this validator, you will need to confirm two transactions.",
            "stakeWith": "Stake with",
            "title": "Stake Amount",
            "useMax": "Use max",
            "with": "with"
        },
        "stakeSuccess": {
            "button": "Return to Dashboard",
            "desc": "Your stake has successfully been delegated to your chosen validator:",
            "descTwo": "You can now view your delegation and staking rewards from your staking dashboard.",
            "title": "Success!"
        },
        "staking": {
            "available": "Available balance",
            "button": "Stake My Tokens",
            "checkingValidator": "Checking Validator",
            "currentValidators": "Your Current Validators",
            "desc": "Stake your SOL tokens with a validator",
            "noValidators": "You haven't staked with a validator yet.",
            "selectAccount": "Staking from",
            "title": "Staking",
            "totalStaked": "Staked"
        },
        "unstake": {
            "beforeUnstakeDisclaimer": "Unstaked tokens will be made available pending a release period of ~52-65hrs (4 epochs).",
            "button": "Unstake Tokens",
            "confirm": "You are unstaking",
            "desc": "Enter the number of tokens you would like to unstake from your current validator.",
            "input": {
                "availableBalance": "Available to unstake",
                "SOL": "SOL"
            },
            "stakeWith": "Unstake from",
            "title": "Unstake Tokens"
        },
        "unstakeSuccess": {
            "button": "Return to Dashboard",
            "desc": "<b>${amount}</b> has successfully been unstaked from this validator:",
            "descTwo": "Your tokens are pending release and will be made available within ~52-65hrs (4 epochs).",
            "title": "Success!"
        },
        "validator": {
            "activeStake": "Active Stake",
            "apy": "APY",
            "button": "Stake With Validator",
            "claimFarmRewards": "You are claiming",
            "claiming": "Claiming",
            "desc": "This is the fee paid to the validator to stake on your behalf. This fee is only charged on your rewards.",
            "errorClaimRewards": "Something went wrong during claiming your farm rewards. Please, try again later",
            "fee": "Validator Fee",
            "notWhitelistedTokenWarning": "This token has not been whitelisted by ref-finance. Proceed at your own risk.",
            "notWhitelistedValidatorWarning": "Tokens awarded by this validator have not been whitelisted by ref-finance. Proceed at your own risk.",
            "rewards": "Rewards",
            "title": "Validator: <br/>${validator}",
            "unstake": "You are unstaking",
            "withdraw": "You are withdrawing",
            "withdrawalDisclaimer": "Funds pending release will be made available after ~52-65hrs (4 epochs)"
        },
        "validatorBox": {
            "cta": "Select",
            "farming": "Farming",
            "fee": "Fee",
            "SOL": "SOL",
            "staking": "Staking",
            "state": {
                "active": "active",
                "inactive": "inactive"
            },
            "with": "with"
        },
        "validators": {
            "available": "Available Validators",
            "button": "View Validator",
            "desc": {
                "account": "Enter a known validator or select a validator from the list to stake your SOL tokens.",
                "lockup": "Enter a known validator or select a validator from the list to stake your SOL tokens. You may only stake with a single validator at any given time.",
                "unstake": "Select the validator pool from which you wish to unstake your tokens.",
                "withdraw": "Select the validator pool from which you wish to withdraw your tokens."
            },
            "inputLabel": "Validator account ID",
            "inputPlaceholder": "validator-name.SOL",
            "search": {
                "error": "No matching validator.",
                "success": "Validator found!"
            },
            "title": "Select a Validator"
        }
    },
    "stakingPoolUnstaked": "This SOL is currently in a staking pool but unstaked. It could for example be in a pending release state.",
    "success": "Success",
    "swap": {
        "confirm": "Confirm & Swap",
        "details": "Transaction details",
        "fee": "Estimated fees",
        "from": "From",
        "max": "Max: ${amount} ${symbol}",
        "minReceived": "Minimum received",
        "price": "Price",
        "priceImpact": "Price impact",
        "review": "Review Swap",
        "reviewInfo": "You are swapping",
        "slippage": "Slippage tolerance",
        "successTitle": "Transaction complete!</br>You swapped <span class='color-blue space'>${amountFrom}</span></br>to <span class='color-green'>${amountTo}</span>",
        "title": "Swap",
        "to": "To",
        "translateIdInfoTooltip": {
            "fee": "The estimated cost of processing your transaction.",
            "minimumReceived": "The minimum amount you will receive from the trade.",
            "priceImpact": "The difference between the market price and estimated price due to trade size.",
            "slippage": "The percentage the price may increase before the transaction fails."
        }
    },
    "swapping": "Swapping",
    "sync": "Sync",
    "tokenBox": {
        "priceUnavailable": "Price Unavailable",
        "swapTo": "to"
    },
    "topLevelAccounts": {
        "body": "Account names are similar to domain names. Only the ${data} account can create accounts such as yourname.${data}, and only yourname.${data} can create app.yourname.${data}. All accounts created in this wallet use the .${data} Top Level Account (TLA). To learn more about account names and creating your own TLA, visit the <a rel='noopener noreferrer' href='https://docs.SOLprotocol.com/docs/concepts/account'>docs</a>.",
        "header": "Top Level Accounts"
    },
    "total": "total",
    "totalBalance": "Your total balance represents all SOL tokens under your control. In many cases, you will not have immediate access to this entire balance (e.g. if it is locked, delegated, or staked). Check your Available Balance for the SOL you can actively use, transfer, delegate, and stake.",
    "transaction": {
        "status": {
            "checkingStatus": "Checking status...",
            "Failure": "Failed",
            "notAvailable": "Status not available",
            "NotStarted": "Not started",
            "Started": "Started",
            "SuccessValue": "Succeeded"
        }
    },
    "transfer": {
        "estimatedFees": "Estimated fees",
        "from": "From",
        "to": "To"
    },
    "twoFactor": {
        "action": {
            "addKey": {
                "full": "Entering the code below will authorize full access to your SOL account: ${accountId} If you did not initiate this action, please DO NOT continue.",
                "limited": "Adding key ${publicKey} limited to call ${methodNames} on ${receiverId} and spend up to ${allowance} SOL on gas"
            },
            "deleteKey": "Deleting key ${publicKey}",
            "functionCall": "Calling method: ${methodName} in contract: ${receiverId} with amount ${deposit} SOL and with args: <pre>${args}</pre>",
            "stake": "Staking: ${amount} SOL to validator: ${receiverId}",
            "transfer": "Transferring: ${amount} SOL to ${receiverId}"
        },
        "active": "Active",
        "alertBanner": {
            "button": "Learn More",
            "title": "When enabled, <b>${data}</b> will be locked to cover the contract storage costs of two-factor authentication."
        },
        "checkBox": "I acknowledge that by enabling two-factor authentication, ${amount} will be reserved to cover contract storage.",
        "desc": "Protect your account by requiring confirmation via SMS or email when authorizing transactions.",
        "disable": {
            "desc": "Keep in mind that transactions won't have to be confirmed with 2FA once it's disabled.",
            "disable": "Disable 2FA",
            "keep": "No, keep 2FA",
            "keyConversionRequired": "<br />Due to the number of 2FA access keys on this account, some access keys will need to be converted to full access keys before 2FA can be disabled. The converted keys can sign transactions without a 2FA prompt, so it is important to complete the 2FA disable process once begun.<br/><br/><b>You will receive one or more additional 2FA prompts to perform this key conversion when disabling 2FA.</b>",
            "phoneDesc": "The SMS option for two-factor authentication is being deprecated, and cannot be re-enbaled.",
            "title": "Are you sure you want to disable 2FA?"
        },
        "email": "Email",
        "enable": "Enable Two-Factor Authentication",
        "notEnabled": "2FA Not Enabled",
        "notEnoughBalance": "To enable 2FA, your account requires a minimum available balance of ${amount}",
        "phone": "Phone",
        "promptDesc": "We highly recommend that you set up a two-factor authentication method to increase the security of your account and assets",
        "select": "Select Authentication Method",
        "since": "since",
        "subHeader": "Two factor authentication adds an extra layer of security to your account. <b>Passphrase and Ledger keys continue to allow full access to your account.</b>",
        "title": "Two-Factor Authentication",
        "verify": {
            "desc": "A 6-digit verification code has been sent to:",
            "didntReceive": "Didn't receive your code?",
            "inputLabel": "Enter your 6-digit verification code",
            "placeholder": "Verification Code",
            "resend": "Resend",
            "resending": "Sending...",
            "resent": "Code sent!",
            "title": "Enter Two-Factor Verification Code"
        }
    },
    "twoFactorDisableLocked": {
        "descOne": "We couldn’t verify your 2FA method for ",
        "descTwo": "If you can no longer access your account, you can rotate your keys and recover it. You will need your secure passphrase to unlock your account.",
        "title": "Is your account locked?"
    },
    "twoFactorDisbleBanner": {
        "button": "Disable 2FA",
        "desc": "Genin Wallet (wallet.sol.org) will soon remove support for two-factor authentication, please disable 2FA for your connected accounts.",
        "titlePlural": "Accounts Need Your Attention",
        "titleSingular": "Account Needs Your Attention"
    },
    "twoFactorRemoveAuth": {
        "button": "Remove 2FA",
        "desc": "Enter your secure passphrase to remove 2FA from the account:",
        "title": "Remove Two-Factor Authentication?"
    },
    "unlockedAvailTransfer": "This SOL is unlocked, and can be transferred out of your lockup contract.",
    "unlockedBalance": "This SOL is still in a lockup contract, but unlocked.",
    "unvestedBalance": "Unvested SOL is earmarked to you, but not yet under your ownership. You can still delegate or stake this SOL, and the rewards will be entirely yours. As your SOL is vested, it will appear in either your Locked or Unlocked balance.",
    "verifyAccount": {
        "desc": "To prevent spamming of new accounts, we'll need to verify you as the owner. You may verify using a one-time passcode or by making a small initial deposit.",
        "descNoFunding": "Choose a method below to fund and initialize your new account.",
        "modal": {
            "email": {
                "desc": "You'll need to use a different email address or select an alternate method to verify this account. You may still use this address as a recovery method.",
                "title": "This email address has already been used to verify a different account."
            },
            "phone": {
                "desc": "You'll need to use a different phone number or select an alternate method to verify this account. You may still use this number as a recovery method.",
                "title": "This phone number has already been used to verify a different account."
            }
        },
        "option": {
            "creditCard": {
                "desc": "Purchase SOL through MoonPay to be used as your initial deposit.",
                "title": "Fund with a Credit Card"
            },
            "email": {
                "desc": "Receive a one-time verification code via email.",
                "title": "Email"
            },
            "existingAccount": {
                "desc": "Use an existing account to fund and initialize your new account.",
                "title": "Fund with an existing account"
            },
            "manualDeposit": {
                "desc": "Submit a manual deposit via a single-use funding address.",
                "title": "Manual deposit"
            },
            "phone": {
                "desc": "Receive a one-time verification code via SMS.",
                "title": "Phone"
            }
        },
        "options": {
            "initialDeposit": "Initial Deposit",
            "passCode": "One-time passcode"
        },
        "title": "Almost there! Verify your new account.",
        "titleNoFunding": "Almost there! Fund your new account."
    },
    "verifyOwner": {
        "invalidRequest": {
            "body": "The request is invalid and cannot be signed.",
            "title": "Invalid Transaction"
        },
        "summary": {
            "connetingWith": "Connecting with ${accountId}",
            "infoMessage": "Once connected, <span class='color-blue'>≪${appName}≫</span> will have <span class='color-black'><b>permissions</b></span>:",
            "permissions": "Verify your account using your profile name and wallet address.",
            "title": "Authorization Request"
        }
    },
    "verifyWalletDomainBanner": {
        "title": "Please make sure you are visiting"
    },
    "wallet": {
        "availableBalance": "Available Balance",
        "balance": "Balance",
        "balances": "Balances",
        "balanceTitle": "SOL Balance",
        "collectibles": "Collectibles",
        "dateAndTime": "Date & Time",
        "depositSOL": {
            "desc": "You’ll need SOL tokens to perform transactions with your wallet.",
            "title": "Deposit SOL"
        },
        "status": "Status",
        "tokenBalance": "Token Balance",
        "tokens": "Tokens",
        "totalBalance": "Total Balance",
        "totalBalanceTitle": "Total Balance",
        "yourPortfolio": "Your Portfolio"
    },
    "walletErrorCodes": {
        "addAccessKey": {
            "error": "An error has occurred.<br />To access your account, please enter the passphrase from the previous step below."
        },
        "addAccessKeySeedPhrase": {
            "errorSecond": "An error has occurred.<br />The passphrase was not added to your account. Please try again."
        },
        "addAccessKeyZeroBalanceAccountSetup": {
            "error": "Something went wrong while finishing import of your now active account. Please re-import your account in the wallet."
        },
        "addLedgerAccessKey": {
            "2faEnabled": "Two-Factor Authentication is enabled. To enable Ledger Hardware Wallet you need to disable Two-Factor Authentication first."
        },
        "addLedgerAccountId": {
            "errorRpc": "Error appears while recovering the account."
        },
        "connectLedger": {
            "noClient": "Ledger is not connected yet. Please connect ledger first."
        },
        "createNewAccount": {
            "accountCreated": {
                "error": "Account creation succeeded but an error occurred. Please import your account."
            },
            "accountExists": {
                "error": "Account creation failed. Account already exists."
            },
            "error": "Account creation failed. You may want to try again."
        },
        "emailProviderInvalid": "${domainName} cannot be used for account verification.  Please provide an e-mail address from a different email provider.",
        "getLedgerAccountIds": {
            "aborted": "Action was aborted.",
            "noAccounts": "No accounts were found for this Ledger device. Please confirm you have associated an account with this Ledger.",
            "noAccountsAccepted": "No accounts were accepted.",
            "U2FNotSupported": "U2F browser support is needed for Ledger. Please use Chrome, Opera or Firefox with U2F extension."
        },
        "initiateZeroBalanceAccount": {
            "error": "Something went wrong while initiating your account. Please try again."
        },
        "initTwoFactor": {
            "ledgerEnabled": "Ledger Hardware Wallet is enabled. To enable Two-Factor Authentication you need to disable Ledger Hardware Wallet first."
        },
        "invalidRecaptchaCode": "Invalid reCAPTCHA code; try again!",
        "lockup": {
            "transferAllWithStakingPoolBalance": "Unstake and withdraw all funds from the staking pool in order to transfer your remaining lockup balance to Wallet."
        },
        "promptTwoFactor": {
            "userCancelled": "2FA code was not verified correctly."
        },
        "recoverAccountLink": {
            "error": "Import of your account failed. Please try again or contact support for assistance."
        },
        "recoverAccountSeedPhrase": {
            "errorInvalidSeedPhrase": "No accounts were found for this passphrase.",
            "errorNotAbleToImportAccount": "Import of your account failed. Please try again or contact support for assistance.",
            "errorSeedPhraseNotValid": "The provided passphrase is not valid. Please check your passphrase and try again."
        },
        "recoveryMethods": {
            "lastMethod": "Cannot delete your last recovery method. Unless you have Ledger enabled, you need to keep at least one recovery method active to ensure recoverability of your account.",
            "setupMethod": "An error occurred. Please check your recovery method."
        },
        "refreshAccountOwner": {
            "error": "An error occured while loading your account. Some wallet data may not be up-to-date. Please try refreshing your browser."
        },
        "sendFungibleToken": {
            "error": "An error occurred. Your send transaction was cancelled."
        },
        "setupRecoveryMessage": {
            "error": "An error occurred while setting up your recovery method. Please try again!"
        },
        "setupRecoveryMessageNewAccount": {
            "invalidCode": "Invalid code"
        },
        "signAndSendTransactions": {
            "notEnoughTokens": "Not enough tokens."
        },
        "staking": {
            "noLockup": "No lockup contract for account",
            "noWithdraw": "Unable to withdraw pending balance from validator",
            "unableToCheckFAK": "Sorry, we are not able to check if Validator has full access key.",
            "validatorHasFAK": "Sorry, we are not allowing you to stake with Validator that has a full access key, for your safety."
        }
    },
    "walletMigration": {
        "cleanKeys": {
            "accountDesc": "Now we’ll go through each account and remove your old keys. Next You’ll need your secure passphrase to authorize key removal.",
            "accountDescTwo": "By removing your keys, you may need to reconnect your account to wallets and dApps.",
            "accountTitle": "Clean up your keys",
            "currentAccessKeyTooltip": "This key will be automatically deleted when transfer process is completed.",
            "desc": "For your security, we highly recommend you remove most of your existing access keys.",
            "fullAccessKeys": "Full Access Keys",
            "keep": "Keep",
            "keepDesc": "The full access key created when you rotated your keys, ledger access keys, limited access keys",
            "keyTypes": {
                "currentAccessKey": "Current Access Key",
                "email": "Email",
                "phrase": "Recovery Phrase",
                "rotatedKey": "Rotated Key",
                "sms": "SMS",
                "unknown": "Unknown"
            },
            "nextSteps": "You can manually select which keys to remove in the next step",
            "remove": "Remove",
            "removeDesc": "Full access keys from email and SMS recovery methods, previously-generated passphrases, keys generated in the SOL CLI",
            "removeKeys": "Remove Keys",
            "rotatedKeyTooltip": "This key will be used to transfer the account to a new wallet.",
            "title": "Clean up full access keys",
            "verifyPassphrase": {
                "desc": "Enter your secure passphrase to remove your full access keys from the account: <br /><br /><h4><b>${accountId}</b></h4><br />",
                "keyMessages": {
                    "doesNotExist": "The input seedphrase does not correspond to any full access keys on ${accountId}.",
                    "insecureRecoveryMethod": "The input seedphrase is associated with an insecure recovery method and may not be used to migrate accounts.",
                    "toBeDeleted": "The input seedphrase corresponds to a key to be deleted and may not be used to migrate accounts."
                },
                "title": "Remove your Full Access Keys?"
            }
        },
        "cleanKeysComplete": {
            "desc": "You will be redirected to the next step to export accounts to another wallet shortly.",
            "title": "Your keys have been cleaned up!"
        },
        "disable2fa": {
            "desc": "2FA must be disabled before you can transfer an account to a new wallet.",
            "title": "Disable two-factor authentication"
        },
        "exportKey": {
            "desc": "Never share your private key! Anyone with access to it will also have full access to your account. <br /><br />Copy or download your private key for safekeeping.",
            "title": "Export private key?"
        },
        "logout": {
            "button": "Log Out of My Account(s)",
            "desc": "As the last step, we are going clear out the keys stored on this browser for your security. You will be logged out of wallet.SOL.org and your keys will be removed. <br><br>Make sure you have access to your account in another wallet.",
            "title": "One last step"
        },
        "migrateAccounts": {
            "desc": "The following accounts will be transferred:",
            "title": "We found ${count} active accounts"
        },
        "migrationSecret": {
            "desc": "You'll need this <strong>password</strong> to securely import your accounts. Copy or write it down until you've completed transferring your accounts.",
            "title": "Password required to import accounts"
        },
        "quitMigration": {
            "button": "Quit",
            "desc": "If you quit now, you will need to start the migration process again.",
            "title": "Are you sure you want to quit the migration?"
        },
        "redirect": {
            "desc": "You’ll be automatically redirected in <b>5 seconds.</b> Once redirected, you’ll need to approve each account as it is added to your new wallet.",
            "title": "Redirecting to Selected Wallet"
        },
        "rotateKeys": {
            "desc": "For your security, we are going to generate a new passphrase and full access key for the below accounts. <br /><br /> Rotating your key will help protect your wallet and allow you to clean up your old full access keys after you transfer your accounts.",
            "title": "Let’s secure your accounts"
        },
        "selectWallet": {
            "descOne": "You’ll be automatically redirected in <b>10 seconds.</b> Once redirected, you’ll need to approve each account as it is added to your new wallet.",
            "descTwo": "These wallets support automatically transferring accounts. <br /><br /> You can manually import an existing account to any wallet using your 12-word recovery phrase.",
            "ledgerDisclaimer": "Transfer to this wallet will exclude any ledger account(s) due to incompetibility.",
            "ledgerSupported": "Ledger supported wallet(s)",
            "ledgerUnsupported": "Ledger unsupported wallet(s)",
            "title": "Select a wallet to transfer accounts"
        },
        "verifying": {
            "desc": "Check your new wallet to make sure you have access to your account(s). We will log you out from Genin Wallet.",
            "disclaimer": "I have access to my account in another wallet",
            "title": "Verify your account is available"
        }
    },
    "warning": "Warning",
    "zeroBalance": {
        "addLedgerKey": {
            "error": {
                "header": "Something went wrong",
                "message": "We were unable to add an additional Ledger key to your account. Please try again or re-import your Ledger account."
            },
            "success": {
                "header": "Ledger access key added successfully!",
                "message": "A new Ledger key was added to your account. You can now manage your accounts recovery methods using your Ledger device."
            }
        },
        "addPhraseKey": {
            "error": {
                "header": "Something went wrong",
                "message": "We were unable to add a new access key to your account. Please re-import your account to add a new wallet key."
            },
            "success": {
                "header": "Your account has been successfully imported!",
                "message": "Now that your account is active, a new access key has been added to your account to sign transactions in the wallet."
            }
        },
        "ledgerModal": {
            "addLedgerKey": "Add Ledger Key",
            "confirmOnLedger": "Confirm the action on your Ledger device.",
            "desc": "Add a Ledger limited access key to the wallet to manage multiple recovery methods.",
            "title": "Your account is now active!"
        }
    }
};
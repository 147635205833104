module.exports = {
  "CREATE_USN_CONTRACT": {
    "createdBy": "OlegYak ",
    "createdAt": "2022-03-24T13:11:21.223Z",
    "development": {
      "enabled": true,
      "lastEditedBy": "esaminu",
      "lastEditedAt": "2022-05-11T20:22:15.771Z"
    },
    "testnet": {
      "enabled": true,
      "lastEditedBy": "esaminu",
      "lastEditedAt": "2022-05-11T22:37:08.020Z"
    },
    "mainnet": {
      "enabled": true,
      "lastEditedBy": "esaminu",
      "lastEditedAt": "2022-05-11T22:37:08.020Z"
    },
    "mainnet_STAGING": {
      "enabled": true,
      "lastEditedBy": "esaminu",
      "lastEditedAt": "2022-05-11T22:37:08.020Z"
    },
    "testnet_STAGING": {
      "enabled": true,
      "lastEditedBy": "esaminu",
      "lastEditedAt": "2022-05-11T22:37:08.020Z"
    },
    "testnet_NEARORG": {
      "enabled": false,
      "lastEditedBy": "esaminu",
      "lastEditedAt": "2022-05-11T22:37:08.020Z"
    },
    "mainnet_NEARORG": {
      "enabled": false,
      "lastEditedBy": "esaminu",
      "lastEditedAt": "2022-05-11T22:37:08.020Z"
    },
    "mainnet_STAGING_NEARORG": {
      "enabled": false,
      "lastEditedBy": "esaminu",
      "lastEditedAt": "2022-05-11T22:37:08.020Z"
    }
  },
  "DONATE_TO_UKRAINE": {
    "createdBy": "OlegYak",
    "createdAt": "2022-05-06T07:29:20.914Z",
    "development": {
      "enabled": true,
      "lastEditedBy": "OlegYak",
      "lastEditedAt": "2022-05-06T07:29:20.914Z"
    },
    "testnet": {
      "enabled": true,
      "lastEditedBy": "OlegYak",
      "lastEditedAt": "2022-05-06T07:29:20.914Z"
    },
    "mainnet": {
      "enabled": false,
      "lastEditedBy": "OlegYak",
      "lastEditedAt": "2022-05-06T07:29:20.914Z"
    },
    "mainnet_STAGING": {
      "enabled": false,
      "lastEditedBy": "OlegYak",
      "lastEditedAt": "2022-05-06T07:29:20.914Z"
    },
    "testnet_STAGING": {
      "enabled": true,
      "lastEditedBy": "OlegYak",
      "lastEditedAt": "2022-05-06T07:29:20.914Z"
    },
    "testnet_NEARORG": {
      "enabled": true,
      "lastEditedBy": "esaminu",
      "lastEditedAt": "2022-05-10T17:07:20.351Z"
    },
    "mainnet_NEARORG": {
      "enabled": false,
      "lastEditedBy": "esaminu",
      "lastEditedAt": "2022-05-10T17:07:20.351Z"
    },
    "mainnet_STAGING_NEARORG": {
      "enabled": false,
      "lastEditedBy": "esaminu",
      "lastEditedAt": "2022-05-10T17:07:20.351Z"
    }
  },
  "FT_TRANSFER_REGISTRATION": {
    "createdBy": "Andy Haynes",
    "createdAt": "2022-12-06T21:19:25.267Z",
    "development": {
      "enabled": true,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2022-12-06T21:19:25.267Z"
    },
    "testnet": {
      "enabled": true,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2022-12-08T18:46:45.482Z"
    },
    "mainnet": {
      "enabled": true,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2022-12-14T23:10:14.362Z"
    },
    "mainnet_STAGING": {
      "enabled": true,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2022-12-14T23:10:14.362Z"
    },
    "testnet_STAGING": {
      "enabled": true,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2022-12-08T18:46:45.482Z"
    },
    "testnet_NEARORG": {
      "enabled": true,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2022-12-08T18:46:45.482Z"
    },
    "mainnet_NEARORG": {
      "enabled": true,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2022-12-14T23:10:14.362Z"
    },
    "mainnet_STAGING_NEARORG": {
      "enabled": true,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2022-12-14T23:10:14.362Z"
    }
  },
  "HAPI_RISK_SCORING": {
    "createdBy": "Den Ilin",
    "createdAt": "2022-07-22T14:03:54.488Z",
    "development": {
      "enabled": true,
      "lastEditedBy": "Den Ilin",
      "lastEditedAt": "2022-07-22T14:03:54.487Z"
    },
    "testnet": {
      "enabled": true,
      "lastEditedBy": "Den Ilin",
      "lastEditedAt": "2022-07-22T14:03:54.488Z"
    },
    "mainnet": {
      "enabled": false,
      "lastEditedBy": "Den Ilin",
      "lastEditedAt": "2022-07-22T14:03:54.488Z"
    },
    "mainnet_STAGING": {
      "enabled": false,
      "lastEditedBy": "Den Ilin",
      "lastEditedAt": "2022-07-22T14:03:54.488Z"
    },
    "testnet_STAGING": {
      "enabled": true,
      "lastEditedBy": "Den Ilin",
      "lastEditedAt": "2022-07-22T14:03:54.488Z"
    },
    "testnet_NEARORG": {
      "enabled": false,
      "lastEditedBy": "Den Ilin",
      "lastEditedAt": "2022-07-22T14:03:54.488Z"
    },
    "mainnet_NEARORG": {
      "enabled": false,
      "lastEditedBy": "Den Ilin",
      "lastEditedAt": "2022-07-22T14:03:54.488Z"
    },
    "mainnet_STAGING_NEARORG": {
      "enabled": false,
      "lastEditedBy": "Den Ilin",
      "lastEditedAt": "2022-07-22T14:03:54.488Z"
    }
  },
  "RENDER_MIGRATION": {
    "createdBy": "Andy Haynes",
    "createdAt": "2022-06-22T01:17:14.439Z",
    "development": {
      "enabled": true,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2022-06-29T18:19:13.375Z"
    },
    "testnet": {
      "enabled": true,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2022-06-29T18:19:13.377Z"
    },
    "mainnet": {
      "enabled": true,
      "lastEditedBy": "Morgan McCauley",
      "lastEditedAt": "2022-07-26T22:40:11.936Z"
    },
    "mainnet_STAGING": {
      "enabled": true,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2022-07-18T18:59:32.023Z"
    },
    "testnet_STAGING": {
      "enabled": true,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2022-06-29T18:19:13.377Z"
    },
    "testnet_NEARORG": {
      "enabled": true,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2022-06-29T18:19:13.377Z"
    },
    "mainnet_NEARORG": {
      "enabled": true,
      "lastEditedBy": "Morgan McCauley",
      "lastEditedAt": "2022-07-26T22:40:11.936Z"
    },
    "mainnet_STAGING_NEARORG": {
      "enabled": true,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2022-07-18T18:59:32.024Z"
    }
  },
  "SHOW_MIGRATION_BANNER": {
    "createdBy": "gutsyphilip",
    "createdAt": "2022-05-25T01:11:55.121Z",
    "development": {
      "enabled": true,
      "lastEditedBy": "gutsyphilip",
      "lastEditedAt": "2022-06-20T18:11:26.582Z"
    },
    "testnet": {
      "enabled": false,
      "lastEditedBy": "esaminu",
      "lastEditedAt": "2022-06-24T21:26:00.267Z"
    },
    "mainnet": {
      "enabled": false,
      "lastEditedBy": "gutsyphilip",
      "lastEditedAt": "2022-05-25T01:11:55.121Z"
    },
    "mainnet_STAGING": {
      "enabled": false,
      "lastEditedBy": "gutsyphilip",
      "lastEditedAt": "2022-05-25T01:11:55.121Z"
    },
    "testnet_STAGING": {
      "enabled": false,
      "lastEditedBy": "gutsyphilip",
      "lastEditedAt": "2022-05-25T01:11:55.121Z"
    },
    "testnet_NEARORG": {
      "enabled": false,
      "lastEditedBy": "esaminu",
      "lastEditedAt": "2022-06-24T21:26:00.267Z"
    },
    "mainnet_NEARORG": {
      "enabled": false,
      "lastEditedBy": "esaminu",
      "lastEditedAt": "2022-06-20T18:11:26.582Z"
    },
    "mainnet_STAGING_NEARORG": {
      "enabled": false,
      "lastEditedBy": "esaminu",
      "lastEditedAt": "2022-06-20T18:11:26.582Z"
    }
  },
  "WEB3AUTH": {
    "createdBy": "Den Ilin",
    "createdAt": "2022-07-19T05:34:11.690Z",
    "development": {
      "enabled": true,
      "lastEditedBy": "Den Ilin",
      "lastEditedAt": "2022-07-19T05:34:11.690Z"
    },
    "testnet": {
      "enabled": true,
      "lastEditedBy": "Den Ilin",
      "lastEditedAt": "2022-07-19T05:34:11.690Z"
    },
    "mainnet": {
      "enabled": false,
      "lastEditedBy": "Den Ilin",
      "lastEditedAt": "2022-07-19T05:34:11.690Z"
    },
    "mainnet_STAGING": {
      "enabled": false,
      "lastEditedBy": "Den Ilin",
      "lastEditedAt": "2022-07-19T05:34:11.690Z"
    },
    "testnet_STAGING": {
      "enabled": false,
      "lastEditedBy": "Den Ilin",
      "lastEditedAt": "2022-07-19T05:34:11.690Z"
    },
    "testnet_NEARORG": {
      "enabled": false,
      "lastEditedBy": "Den Ilin",
      "lastEditedAt": "2022-07-19T05:34:11.690Z"
    },
    "mainnet_NEARORG": {
      "enabled": false,
      "lastEditedBy": "Den Ilin",
      "lastEditedAt": "2022-07-19T05:34:11.690Z"
    },
    "mainnet_STAGING_NEARORG": {
      "enabled": false,
      "lastEditedBy": "Den Ilin",
      "lastEditedAt": "2022-07-19T05:34:11.690Z"
    }
  },
  "WEP_DISABLE_ACCOUNT_CREATION": {
    "createdBy": "Andy Haynes",
    "createdAt": "2023-02-13T18:46:04.151Z",
    "development": {
      "enabled": true,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2023-02-13T18:46:04.150Z"
    },
    "testnet": {
      "enabled": false,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2023-02-13T18:46:04.151Z"
    },
    "mainnet": {
      "enabled": false,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2023-02-13T18:46:04.151Z"
    },
    "mainnet_STAGING": {
      "enabled": false,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2023-02-13T18:46:04.151Z"
    },
    "testnet_STAGING": {
      "enabled": false,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2023-02-13T18:46:04.151Z"
    },
    "testnet_NEARORG": {
      "enabled": false,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2023-02-13T18:46:04.151Z"
    },
    "mainnet_NEARORG": {
      "enabled": false,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2023-02-13T18:46:04.151Z"
    },
    "mainnet_STAGING_NEARORG": {
      "enabled": false,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2023-02-13T18:46:04.151Z"
    }
  },
  "WEP_PHASE_ONE": {
    "createdBy": "roshaans",
    "createdAt": "2022-09-29T12:47:40.121Z",
    "development": {
      "enabled": true,
      "lastEditedBy": "hcho112",
      "lastEditedAt": "2022-11-10T19:42:24.864Z"
    },
    "testnet": {
      "enabled": false,
      "lastEditedBy": "roshaans",
      "lastEditedAt": "2022-09-29T12:47:40.121Z"
    },
    "mainnet": {
      "enabled": false,
      "lastEditedBy": "roshaans",
      "lastEditedAt": "2022-09-29T12:47:40.121Z"
    },
    "mainnet_STAGING": {
      "enabled": false,
      "lastEditedBy": "roshaans",
      "lastEditedAt": "2022-09-29T12:47:40.121Z"
    },
    "testnet_STAGING": {
      "enabled": false,
      "lastEditedBy": "roshaans",
      "lastEditedAt": "2022-09-29T12:47:40.121Z"
    },
    "testnet_NEARORG": {
      "enabled": true,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2023-02-13T22:23:12.644Z"
    },
    "mainnet_NEARORG": {
      "enabled": true,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2023-06-22T20:52:01.722Z"
    },
    "mainnet_STAGING_NEARORG": {
      "enabled": true,
      "lastEditedBy": "Andy Haynes",
      "lastEditedAt": "2023-02-21T18:13:15.474Z"
    }
  }
}
;
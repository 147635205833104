import { useSelector } from 'react-redux';

import { NEAR_TOKEN_ID, USN_CONTRACT } from '../config';
import selectNEARAsTokenWithMetadata from '../redux/selectors/crossStateSelectors/selectNEARAsTokenWithMetadata';
import { selectActiveAccountId } from '../redux/slices/activeAccount';
import { selectTokensFiatValueUSD } from '../redux/slices/tokenFiatValues';
import { selectTokensWithMetadataForAccountId } from '../redux/slices/tokens';
import compare from '../utils/compare';


export const useFungibleTokensIncludingNEAR = function ({ showTokensWithZeroBalance = false, includeNearContractName = false } = {}) {
    const NEARAsTokenWithMetadata = useSelector((state) => selectNEARAsTokenWithMetadata(state, { includeNearContractName }));
    const accountId = useSelector(selectActiveAccountId);
    const fungibleTokens = useSelector((state) =>
        selectTokensWithMetadataForAccountId(state, { accountId, showTokensWithZeroBalance })
    );

    const fungibleTokenPrices = useSelector(selectTokensFiatValueUSD);

    const fungibleTokensWithPrices = fungibleTokens.map((ft) => {
        let fiatValueMetadata;
        if (fungibleTokenPrices[ft.onChainFTMetadata.coingeckoId]) {
            fiatValueMetadata =
                { ...fungibleTokenPrices[ft.onChainFTMetadata.coingeckoId] };
        } else {
            fiatValueMetadata = ft.fiatValueMetadata;
        }
        return { ...ft, fiatValueMetadata };
    });
    const sortingOrder = {
        [USN_CONTRACT]: 1,
        [NEAR_TOKEN_ID]: 2
    };
    fungibleTokensWithPrices.sort(compare({ key: 'contractName', sortingOrder }));

    return [NEARAsTokenWithMetadata, ...fungibleTokensWithPrices];
};

import React from 'react';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import { isWhitelabel } from '../../config/whitelabel';
import { Mixpanel } from '../../mixpanel/index';
import { selectAccountId } from '../../redux/slices/account';
import isMobile from '../../utils/isMobile';
import { FooterLeftContainer, StyledContainer } from '../styles/FooterStyle';
import GeninWalletLogo from '../svg/GeninWalletLogo';

const FooterLeft = () => (
    <FooterLeftContainer>
        <div>
            <GeninWalletLogo mode='footer' />
        </div>
        <div>
            &copy; {new Date().getFullYear()} <Translate id='footer.copyrights' />
            <div>
                <a
                    href='/terms'
                    rel='noopener noreferrer'
                    target='_blank'
                    onClick={() => Mixpanel.track('Footer Click terms of service')}
                >
                    <Translate id='footer.termsOfService' />
                </a>
                <span className='color-brown-grey'>|</span>
                <a href='/privacy'
                    rel='noopener noreferrer'
                    target='_blank'
                    onClick={() => Mixpanel.track('Footer Click privacy policy')}
                >
                    <Translate id='footer.privacyPolicy' />
                </a>
            </div>
        </div>
    </FooterLeftContainer>
);

const Footer = () => {
    const location = useLocation();
    const isPositionRelative = ['/', '/recover-account', '/create', '/?tab=collectibles', '/set-recovery-implicit-account', '/setup-passphrase-new-account'].includes(location.pathname);
    const accountId = useSelector(selectAccountId);


    const checkPositionRelative = () => {
        if (accountId && location.pathname === '/' && !isMobile()) {
            return false;
        } else {
            return isPositionRelative;
        }
    };

    return (
        <StyledContainer className='wallet-footer' position={checkPositionRelative() ? 'relative' : 'fixed'} pathname={location.pathname} isLoggedIn={accountId}>
            <FooterLeft />
            <div className='center'>
                <Translate id='footer.desc' />&nbsp;
                <a
                    href='https://solana.com/learn/blockchain-basics'
                    rel='noopener noreferrer'
                    target='_blank'
                    onClick={() => Mixpanel.track('Footer Click Learn More')}
                >
                    <Translate id='footer.learnMore' />
                </a>
            </div>
            <div className='right'>
                <Translate id='footer.needHelp' /><br />
                <a
                    href={isWhitelabel ? 'https://discord.gg/Gyf6CkfXDn' : 'https://discord.gg/Gyf6CkfXDn'}
                    rel='noopener noreferrer'
                    target='_blank'
                    onClick={() => Mixpanel.track('Footer Click Join Community')}
                >
                    <Translate id='footer.contactSupport' />
                </a>
            </div>
        </StyledContainer>
    );
};

export default Footer;

import { getOrCreateAssociatedTokenAccount, createTransferInstruction } from '@solana/spl-token';
import { Keypair, PublicKey, SystemProgram, Transaction, sendAndConfirmTransaction } from '@solana/web3.js';
import * as bip39 from 'bip39';
import bs58 from 'bs58';
import { derivePath } from 'ed25519-hd-key';

import Wallet from './wallet';

const normalizeSeedPhrase = (seedPhrase) => seedPhrase.trim().split(/\s+/).map((part) => part.toLowerCase()).join(' ');

export const generateSeedPhrase = () => {
    const seedPhrase = normalizeSeedPhrase(bip39.generateMnemonic());
    return seedPhrase;
};

export const parseSolanaSeedPhrase = (seedPhrase, accountIndex = 0) => {
    const seed = bip39.mnemonicToSeedSync(seedPhrase, '');
    const path = `m/44'/501'/${accountIndex}'/0'`;

    const keypair = Keypair.fromSeed(derivePath(path, seed.toString('hex')).key);

    const publicKey = bs58.encode(keypair.publicKey.toBuffer());
    const secretKey = bs58.encode(Buffer.from(keypair.secretKey));
    const recoveryKeyPair = keypair;

    return { seedPhrase, secretKey, publicKey, recoveryKeyPair };
};

export class Account {
    constructor(accountId, connection) {
        this.accountId = accountId;
        this.connection = connection;
        this.wallet = new Wallet();
    }

    async getAccountBalance() {
        const account = new PublicKey(this.accountId);

        const balance = await this.connection.getBalance(account);

        return { available: balance || 0, balanceAvailable: balance || 0 };
    }

    async signAndSendTransaction(signAndSendTransactionOptions) {
    }

    async sendMoney(receiver, amount) {
        const recoveryKeyPair = await this.wallet.getLocalKeyPair(this.accountId);
        const senderPublicKey = recoveryKeyPair.publicKey;
        const recipientPublicKey = new PublicKey(receiver);

        // amount of SOL to be transfered (in lamports)
        const lamports = amount;

        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: senderPublicKey,
                toPubkey: recipientPublicKey,
                lamports: lamports,
            })
        );

        transaction.feePayer = senderPublicKey;
        transaction.recentBlockhash = (await this.connection.getRecentBlockhash()).blockhash;
        transaction.sign(recoveryKeyPair);


        return await this.connection.sendTransaction(transaction, [recoveryKeyPair]);
    }

    async sendSPLToken(receiver, amount, tokenAccount) {
        try {
            const recoveryKeyPair = await this.wallet.getLocalKeyPair(this.accountId);
            const fromWallet = recoveryKeyPair.publicKey;
            const toWallet = new PublicKey(receiver);

            const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
                this.connection,
                recoveryKeyPair,
                new PublicKey(tokenAccount),
                fromWallet
            );

            const toTokenAccount = await getOrCreateAssociatedTokenAccount(
                this.connection,
                recoveryKeyPair,
                new PublicKey(tokenAccount),
                toWallet
            );

            const transaction = new Transaction().add(
                createTransferInstruction(
                    fromTokenAccount.address,
                    toTokenAccount.address,
                    fromWallet,
                    amount
                )
            );

            const signature = await sendAndConfirmTransaction(this.connection, transaction, [recoveryKeyPair]);

            return signature;
        } catch (error) {
            console.log(error);
        }
    }

    async state() {
        return {
            amount: '0'
        };
    }

    async getAccessKeys() {
        return [];
    }
}

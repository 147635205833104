import React from 'react';

const SolanaCircleIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_2"
        width={58.8}
        height={58.65}
        {...props}
    >
        <defs>
            <clipPath id="clippath">
                <path
                    d="M18.91 19.5h20.52v18.38H18.91z"
                    style={{
                        strokeWidth: 0,
                        fill: 'none',
                    }}
                />
            </clipPath>
            <style>{'.cls-5{stroke-width:0;fill:#b4b4b4}'}</style>
        </defs>
        <g id="Layer_1-2">
            <path
                d="M40.27 55.59c-.12 1-.7 1.56-1.6 1.71-.86.14-1.51-.26-1.6-1.15-.13-1.26.88-1.47 1.79-1.6.75-.1 1.25.31 1.41 1.04ZM20.76 3.86c-.84-.09-1.3-.6-1.26-1.46.04-.9.64-1.3 1.47-1.32.87-.02 1.43.43 1.44 1.32 0 1.01-.64 1.44-1.65 1.47ZM2.28 22.82c-.83-.03-1.23-.53-1.23-1.27 0-.84.18-1.77 1.21-1.89.85-.1 1.44.54 1.43 1.36-.01.85-.43 1.6-1.41 1.79ZM58.34 24.75c-.03 1.03-.45 1.58-1.19 1.55-.94-.03-1.51-.7-1.53-1.61-.02-.8.47-1.49 1.34-1.44.99.06 1.22.93 1.39 1.5ZM58.8 29.59c-.11.91-.59 1.55-1.38 1.58-.86.04-1.34-.73-1.34-1.48 0-.84.33-1.69 1.42-1.63.96.05 1.02.93 1.3 1.53ZM5.37 41.46c0 .85-.41 1.41-1.3 1.42-.85.02-1.42-.48-1.5-1.33-.08-.83.29-1.49 1.19-1.58.99-.09 1.51.48 1.62 1.48ZM46.33 52.83c-.29.82-.83 1.48-1.76 1.44-.82-.03-1.48-.62-1.33-1.47.16-.88.91-1.39 1.85-1.33.76.05 1.16.56 1.24 1.35ZM48.37 7.4c0 .86-.43 1.4-1.15 1.4-1.04 0-1.64-.72-1.72-1.71-.06-.79.49-1.16 1.27-1.11.93.06 1.5.53 1.6 1.41ZM52.92 12.21c0 .81-.24 1.57-1.18 1.5-.89-.06-1.47-.75-1.55-1.64-.07-.76.52-1.29 1.17-1.34.93-.08 1.49.5 1.56 1.48ZM55.26 19.82c-1.04-.06-1.39-.86-1.55-1.67-.15-.76.44-1.36 1.12-1.38.95-.03 1.48.65 1.54 1.61.05.77-.33 1.26-1.11 1.44ZM7.54 45.66c.82.29 1.34.86 1.39 1.76.04.76-.33 1.34-1.14 1.23-.83-.11-1.63-.48-1.64-1.51 0-.85.53-1.32 1.38-1.49ZM26.08 1.22c.1-.92.76-1.21 1.55-1.22.76 0 1.6.11 1.53 1.15-.06.82-.6 1.32-1.5 1.33-.86 0-1.4-.37-1.58-1.25Z"
                className="cls-5"
            />
            <path
                d="M14.68 4c.81-.07 1.26.28 1.3 1.1.05 1.03-.59 1.52-1.48 1.59-.7.06-1.34-.29-1.31-1.11.03-.91.52-1.52 1.5-1.58Z"
                style={{
                    fill: '#aeaeae',
                    strokeWidth: 0,
                }}
            />
            <path
                d="M5.31 13.51c.76.19 1.16.65 1.12 1.42-.06.99-.61 1.55-1.6 1.49-.76-.04-1.28-.54-1.16-1.31.13-.88.74-1.44 1.64-1.6ZM57.83 36.21c-.23.77-.37 1.65-1.46 1.69-.79.02-1.3-.46-1.25-1.22.05-.82.24-1.73 1.37-1.72.76 0 1.19.48 1.34 1.25ZM55.31 42.7c-.17.84-.62 1.54-1.54 1.63-.81.09-1.19-.54-1.23-1.25-.06-.97.46-1.57 1.45-1.61.78-.04 1.19.44 1.32 1.23ZM13.95 51.91c-.09.82-.39 1.45-1.18 1.43-.91-.03-1.55-.65-1.67-1.55-.11-.79.46-1.23 1.2-1.27.98-.04 1.51.52 1.65 1.39ZM18.44 54c.79.1 1.43.42 1.48 1.33.05.87-.41 1.5-1.3 1.42-.86-.08-1.59-.63-1.56-1.6.02-.75.59-1.13 1.37-1.14ZM26.5 56.93c-.09 1.31-.72 1.56-1.57 1.48-.85-.08-1.47-.54-1.32-1.44.13-.78.7-1.37 1.58-1.24.81.11 1.36.59 1.31 1.2ZM31.97 56c.87.1 1.47.45 1.43 1.37-.04.92-.72 1.29-1.5 1.27-.79-.02-1.45-.43-1.39-1.36.06-.89.69-1.22 1.47-1.28ZM42.42 3.87c-.03.76-.3 1.39-1.12 1.31-.81-.08-1.66-.4-1.75-1.41-.06-.76.44-1.23 1.19-1.28.96-.06 1.52.44 1.67 1.37ZM10.79 9.55c-.29.75-.79 1.37-1.72 1.39-.84.02-1.12-.56-1.07-1.3.06-1 .75-1.47 1.63-1.43.65.03 1.25.41 1.16 1.33ZM51.35 48.25c-.3.86-.74 1.58-1.67 1.59-.75 0-1.22-.57-1.12-1.3.12-.83.51-1.61 1.55-1.55.76.04 1.08.6 1.24 1.27ZM2.47 28.82c0 .21-.42.69-1.37.63-.85-.05-1.12-.61-1.1-1.36.02-.82.31-1.55 1.25-1.53.89.03 1.25.69 1.22 2.25ZM35.93 1.54c.03.98-.39 1.38-1.16 1.41-.8.04-1.52-.21-1.66-1.08-.15-.9.42-1.36 1.28-1.38.82-.02 1.37.39 1.55 1.04ZM1.81 36.39C.94 36.27.54 35.73.49 34.9c-.05-.71.11-1.36.97-1.41 1.04-.06 1.45.61 1.49 1.54.03.75-.39 1.21-1.13 1.36Z"
                className="cls-5"
            />
            <g
                style={{
                    clipPath: 'url(#clippath)',
                }}
            >
                <path
                    d="m39.32 33.99-3.39 3.64c-.07.08-.16.14-.26.19a.87.87 0 0 1-.31.07H19.3c-.08 0-.15-.02-.22-.06a.39.39 0 0 1-.14-.17c-.03-.07-.04-.15-.03-.22.01-.08.05-.15.1-.2l3.39-3.64c.07-.08.16-.14.26-.18.1-.04.21-.07.31-.07h16.05c.08 0 .15.02.22.06.06.04.11.1.15.17.03.07.04.15.03.22-.01.08-.05.15-.1.2Zm-3.39-7.33a.832.832 0 0 0-.26-.19.87.87 0 0 0-.31-.07H19.3c-.08 0-.15.02-.22.06a.39.39 0 0 0-.14.17c-.03.07-.04.15-.03.22.01.08.05.15.1.2l3.39 3.64c.07.08.16.14.26.18.1.04.21.07.31.07h16.05c.08 0 .15-.02.22-.06.06-.04.11-.1.15-.17.03-.07.04-.15.03-.22a.348.348 0 0 0-.1-.2l-3.39-3.64ZM19.3 24.05h16.06c.11 0 .21-.02.31-.07a.7.7 0 0 0 .26-.19l3.39-3.64c.05-.06.09-.13.1-.2.01-.08 0-.15-.03-.22a.353.353 0 0 0-.15-.17.403.403 0 0 0-.22-.06H22.97a.67.67 0 0 0-.31.07c-.1.04-.19.11-.26.18l-3.39 3.64c-.05.06-.09.13-.1.2a.409.409 0 0 0 .17.39c.06.04.14.06.22.06Z"
                    style={{
                        fill: '#afafaf',
                        strokeWidth: 0,
                    }}
                />
            </g>
        </g>
    </svg>
);
export default SolanaCircleIcon;

const isDeviceMatch = (userAgent, target) => {
    const isWindowsPhone = /windows phone/i.test(userAgent);
    const isAndroid = /android/i.test(userAgent);
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

    switch (target) {
        case 'iOS':
            return isIOS;
        default:
            return isWindowsPhone || isAndroid || isIOS;
    }
};

const isMobile = (target = 'any') => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return isDeviceMatch(userAgent, target);
};

export default isMobile;

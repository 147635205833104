module.exports = {
    "account": {
        "activateAccount": {
            "post": {
                "descOne": "Ваш первоначальный депозит был получен и переведен на ваш новый счет. Ваша учетная запись теперь активна!",
                "descTwo": "Часть вашего депозита была зарезервирована для покрытия <a href='https://docs.near.org/docs/concepts/account' rel='noopener noreferrer' target='_blank'>стоимость размещения вашей учетной записи</a>.",
                "title": "Ваша учетная запись активирована."
            },
            "pre": {
                "desc": "Чтобы начать пользоваться кошельком NEAR, внесите минимальный первоначальный депозит, отправив <b>по крайней мере ${amount}</b> к логину вашей учетной записи (иногда называемый “Адрес”).",
                "title": "Почти готово! Внесите свой первоначальный депозит."
            }
        },
        "available": {
            "error": "Учетная запись не найдена!",
            "errorSameAccount": "Невозможно отправить самому себе!",
            "implicitAccount": "Всегда дважды перепроверьте имя пользователя",
            "implicitAccountModal": "Пожалуйста, проверьте дважды имя пользователя, особенно если это биржевой аккаунт. Если ввести неправильно имя пользователя, то вы навсегда утратите свои средства.",
            "success": "Действительная учетная запись"
        },
        "create": {
            "checkingAvailablity": {
                "check": "Идет проверка",
                "create": "Проверяем доступность"
            },
            "errorAccountNotExist": "При создании аккаунта произошла ошибка. Пожалуйста, попробуйте еще раз.",
            "errorInvalidAccountIdLength": "Имя пользователя должно содержать от 2 до 64 символов"
        },
        "createImplicit": {
            "post": {
                "descOne": "Ваш первоначальный депозит был получен! После получения заявки ваш депозит будет переведен на ваш новый счет.",
                "descTwo": "Важно: Ваш адрес для одноразового финансирования больше не будет действительным. Обязательно замените его логином вашей учетной записи на любых биржах или других устройствах, где он мог быть сохранен.",
                "modal": {
                    "descOne": "Продолжая, вы подтверждаете, что адрес одноразового финансирования будет заменен, и любые дополнительные средства, отправленные на старый адрес, будут потеряны.",
                    "descTwo": "Не забудьте заменить его логиномом вашей учетной записи на любых биржах или других устройствах, где он мог быть сохранен.",
                    "title": "Обновите свой адрес"
                },
                "title": "Востребуйте свой аккаунт"
            },
            "pre": {
                "addressHeader": "Одноразовый адрес для пополнения",
                "descOne": "Инициализируйте свою учетную запись, отправив хотя бы <b>${amount}</b> на указанный ниже одноразовый адрес для пополнения.",
                "descTwo": "Вернитесь на данный экран после пополнения средств, чтобы завершить процесс создания учетной записи.",
                "moonPay": {
                    "buyWith": "Покупайте с",
                    "desc": "Внесите свой первоначальный депозит с помощью MoonPay, используя предпочитаемый вами способ оплаты.",
                    "title": "Оплатить с помощью MoonPay"
                },
                "title": "Пополните свою учетную запись",
                "utorg": {
                    "buyWith": "Купить через",
                    "desc": "Сделайте свой первый депозит через Utorg используя удобный для Вас метод платежа.",
                    "title": "Пополнить с помощью Utorg"
                },
                "whereToBuy": {
                    "button": "Где можно купить NEAR?",
                    "desc": "NEAR токены доступны для покупки на следующих биржах",
                    "title": "Покупка токенов NEAR"
                }
            },
            "success": {
                "button": "Перейти к учетной записи",
                "descOne": "Примите поздравления, <b>${accountId}</b>! Ваша учетная запись успешно создана.",
                "descThree": "Пожалуйста, обновите адрес на всех биржах или на других устройствах.",
                "descTwo": "Вы должны использовать эту учетную запись для всех операций с NEAR.",
                "title": "Добро пожаловать в NEAR"
            }
        },
        "createImplicitAccount": {
            "createCustomNameModal": {
                "desc": "В отличие от вашего адреса по умолчанию, пользовательский адрес может быть любым, что вам нравится, что облегчает запоминание и обмен информацией!",
                "title": "Добавьте пользовательский адрес в Свой кошелек"
            },
            "customAddress": "Пользовательский адрес может быть любым, что вам нравится, что облегчает запоминание и обмен!",
            "customAmount": "Другая",
            "exchange": "Обмен",
            "orAskFriend": "или спроси у друга!",
            "orSendNear": "...или отправьте по крайней мере $ {сумму} РЯДОМ с адресом вашего кошелька.",
            "sendFrom": "Отправить с",
            "title": "Почти на месте! Для начала купите несколько монет с помощью одного из провайдеров...",
            "titleAlt": "Почти на месте! Для начала отправьте хотя бы ${amount} NEAR на адрес вашего кошелька."
        },
        "login": {
            "details": {
                "warning": "Это позволит получить доступ к вашему кошельку. Пожалуйста, действуйте с осторожностью!"
            },
            "incorrectContractId": {
                "error": "Контракт ${contractId} не существует. Это может быть проблема с приложением, которое вы пытаетесь использовать, или этот контракт, возможно, был удален. Вы будете перенаправлены обратно в приложение."
            }
        },
        "nameDoesntMatch": "Имя учетной записи не совпадает",
        "recoverAccount": {
            "error": "Не удалось восстановить учетную запись.",
            "errorInvalidSeedPhrase": "Учетные записи, соответствующие данной мнемонической фразе, не найдены.",
            "success": "Восстановление по мнемонической фразе завершено.<br/>Вы успешно восстановили <b>${numberOfAccounts} учетную(ые) запись(и)</b>.<br/>Последняя из них выбрана как активная."
        },
        "requestCode": {
            "error": "Не удалось отправить SMS-сообщение.",
            "success": "SMS-сообщение успешно отправлено."
        },
        "sendMoney": {
            "error": "К сожалению, произошла ошибка. Попробуйте еще раз."
        },
        "sendNewRecoveryLink": {
            "error": "Ошибка отправки. Пожалуйста, попробуйте еще раз.",
            "success": "Ссылка для восстановления успешно отправлена!"
        },
        "verifySeedPhrase": {
            "error": "Вы ввели неверное слово!"
        }
    },
    "actions": {
        "AddKey": {
            "forContract": "Ключ доступа для контракта: ${permissionReceiverId} добавлен",
            "forReceiver": "Новый ключ для ${receiverId} добавлен"
        },
        "CreateAccount": "Новая учетная запись создана: ${receiverId}",
        "DeleteAccount": "Имя пользователя удалено: ${receiverId}",
        "DeleteKey": "Ключ удален",
        "DeployContract": "Контракт развернут: ${receiverId}",
        "FunctionCall": "Вызов метода: ${methodName} в контракте: ${receiverId}",
        "Stake": "Стейкинг ${stake}",
        "Transfer": {
            "received": "Получено ${deposit} от ${signerId}",
            "transferred": "Отправлено ${deposit} к ${receiverId}"
        }
    },
    "actionsSign": {
        "addKey": "Добавление ключа доступа",
        "createAccount": "Создание учетной записи: ${receiverId}",
        "deleteAccount": "Удаление учетной записи: ${receiverId}",
        "deleteKey": "Удаление ключа доступа",
        "deployContract": "Развертывание контракта: ${receiverId}",
        "functionCall": "Вызов функции: ${methodName}",
        "stake": "Стейкинг: ${stake}Ⓝ ${publicKey}...",
        "transfer": "Перевод: ${deposit}Ⓝ к ${receiverId}"
    },
    "addNode": {
        "desc": "Ваш узел должен быть настроен через CLI, прежде чем добавлять его сюда.",
        "ipAddressInput": {
            "placeholder": "например: 0.0.0.0",
            "title": "Введите IP-адрес вашего узла"
        },
        "nicknameInput": {
            "placeholder": "Например: AWS",
            "title": "Придумайте имя (необязательно)"
        },
        "pageText": "Настройте узел с помощью CLI и добавьте его здесь",
        "pageTitle": "Добавление узла"
    },
    "amount": "сумма",
    "arguments": "Аргументы",
    "authorizedApps": {
        "dashboardNoApps": "Вы не авторизовали ни одного приложения",
        "feeAllowance": "Оплата за хранение",
        "ledger": "Ledger",
        "pageTitle": "Доверенные приложения",
        "publicKey": "Публичный ключ"
    },
    "availableBalanceInfo": "Ваш расходуемый баланс в Near, за исключением застейканных или размещенных средств. <a href='/profile'>Просмотр Расходуемого баланса →</a>",
    "availableBalanceProfile": "Это ваш расходуемый баланс в NEAR, может быть незамедлительно потрачен или передан. Это значение ниже, чем ваш общий баланс.",
    "back": "Назад",
    "balance": {
        "balance": "Общий баланс",
        "balanceLoading": "Загрузка баланса"
    },
    "balanceBreakdown": {
        "available": "Доступный баланс",
        "reserved": "Зарезерировано для комиссий"
    },
    "batchImportAccounts": {
        "confirmImportModal": {
            "accountToImport": "Аккаунт для переноса",
            "title": "Подтвердите перенос аккаунта с",
            "transactionDetails": "+ Детали Транзакции"
        },
        "confirmUrlModal": {
            "desc": "Для обеспечения безопасности переноса вашего аккаунта, убедитесь что URL текущего кошелька в браузере верный:",
            "title": "Убедитесь в корректности URL"
        },
        "enterKeyForm": {
            "confirmCaption": "Подтвердить пароль",
            "desc": "Введите ваш пароль для переноса аккаунтов:",
            "placeholder": "2cCzKMPiCkFnF5mGbJcPgTGNykvxK4f3xyNAio4q931b",
            "title": "Добро пожаловать в MyNearWallet!"
        },
        "importScreen": {
            "desc": "Начните перенос и подтвердите каждую учетную запись </br> при появлении соответствующего запроса.",
            "title": "Готовы перенести ${noOfAccounts} аккаунт(ов) с </br>"
        },
        "successScreen": {
            "desc": "Выберите аккаунт для продолжения работы с кошельком.",
            "title": "${noOfAccounts} аккаунт(ов) было успешно </br> перенесено с "
        }
    },
    "button": {
        "addAccount": "Добавить учетную запись",
        "addFunds": "ДОБАВИТЬ СРЕДСТВА",
        "addNode": "ДОБАВИТЬ ПРИМЕЧАНИЕ",
        "allow": "ПРИНЯТЬ",
        "approve": "Подтвердить",
        "authorizing": "Авторизация",
        "beginImport": "Начать перенос",
        "browseApps": "ОБЗОР ПРИЛОЖЕНИЙ",
        "cancel": "ОТМЕНА",
        "cancelOperation": "Отменить операцию",
        "cancelTransfer": "ОТМЕНИТЬ ПЕРЕВОД",
        "claimAccount": "Затребовать учетную запись",
        "close": "ЗАКРЫТЬ",
        "Close": "Закрыть",
        "confirm": "ПОДТВЕРДИТЬ",
        "confirmAndSend": "ПОДТВЕРДИТЬ",
        "connect": "Подключить",
        "connecting": "Подключение",
        "connectLedger": "Подключить Ledger",
        "continue": "ПРОДОЛЖИТЬ",
        "continueSetup": "Продолжить установку",
        "copyImplicitAddress": "Скопировать адрес для пополнения",
        "copyPhrase": "СКОПИРОВАТЬ ФРАЗУ",
        "copyUrl": "Скопировать ссылку",
        "createAccount": "Создать учетную запись",
        "createAccountCapital": "Создать учетную запись",
        "createNewAccount": "Создать учетную запись",
        "deauthorize": "ДЕАВТОРИЗОВАТЬ",
        "deAuthorizing": "Снятие авторизации",
        "deny": "ОТКЛОНИТЬ",
        "disable": "Отключить",
        "disabling": "Выключение",
        "dismiss": "Отказаться",
        "edit": "Редактировать",
        "enable": "Включить",
        "enabling": "Включение",
        "findMyAccount": "НАЙТИ УЧЕТНУЮ ЗАПИСЬ",
        "finish": "Закончить",
        "goBack": "Вернуться назад",
        "goToDashboard": "ПЕРЕЙТИ В ПАНЕЛЬ УПРАВЛЕНИЯ",
        "importExistingAccount": "Импорт существующей учетной записи",
        "looksGood": "Выглядит Отлично!",
        "moreInformation": "Получить больше информации",
        "needToEditGoBack": "Вернуться назад для редактирования",
        "protectAccount": "ЗАЩИТИТЬ УЧЕТНУЮ ЗАПИСЬ",
        "recoverAccount": "Восстановить учетную запись",
        "recovering": "Поиск учетной записи",
        "recoverYourAccount": "Восстановить учетную запись",
        "removeNode": "УДАЛИТЬ ПРИМЕЧАНИЕ",
        "removingKeys": "Удаление ключей",
        "retry": "Повторить",
        "saveChanges": "СОХРАНИТЬ ИЗМЕНЕНИЯ",
        "send": "ОТПРАВИТЬ",
        "setupPhrase": "ИСПОЛЬЗОВАТЬ ФРАЗУ ВОССТАНОВЛЕНИЯ",
        "signIn": "Войти",
        "signingIn": "Входим",
        "signInLedger": "Войти с помощью Ledger",
        "skip": "Пропустить",
        "stake": "СТЕЙКИНГ",
        "staking": "Стейкинг",
        "startOver": "Начать сначала",
        "subscribe": "Подписаться",
        "swap": "Обмен",
        "transferring": "Перевод",
        "unstaking": "Снятие со стейкинга",
        "verify": "ПРОВЕРИТЬ И ЗАВЕРШИТЬ",
        "verifyCode": "Верифицировать код",
        "verifyCodeEnable": "Верифицировать и продолжить",
        "verifying": "Верификация",
        "viewAll": "Весь список",
        "withdrawing": "Вывод"
    },
    "confirmLedgerModal": {
        "header": {
            "confirm": "Пожалуйста, подтвердите операцию на вашем устройстве...",
            "processing": "Обработка"
        },
        "one": "Вы должны будете подтвердить данные входа на вашем устройстве Ledger."
    },
    "connecting": "Соединение",
    "copy": {
        "default": "Скопировано!"
    },
    "createAccount": {
        "accountIdInput": {
            "placeholder": "yourname.${data}",
            "title": "Выберите имя пользователя"
        },
        "addACustomAddress": "Добавьте пользовательский адрес",
        "alreadyHaveAnAccount": "Уже есть учетная запись?",
        "invalidLinkDrop": {
            "one": "Ссылка NEAR Drop уже была ранее активирована.",
            "title": "Некорректная ссылка",
            "two": "Ссылка NEAR Drop может быть использована для создания одной учетной записи, после чего ссылка станет недействительной."
        },
        "note": {
            "canContain": "Ваше имя пользователя может содержать любое из следующих значений:",
            "cannotContain": "Ваше имя пользователя не может содержать:",
            "characters": "Символы \"@\" или \".\"",
            "digits": "Цифры (0-9)",
            "lowercase": "Строчные буквы (a-z)",
            "separators": "Символы (_-) могут быть использованы в качестве разделителей"
        },
        "pageText": "Создание учетной записи не займет у вас много времени.",
        "pageTitle": "Создать учетную запись",
        "recoverItHere": "Восстановить доступ",
        "step": "Шаг ${step}/${total}",
        "termsPage": {
            "descOne": "NEAR аккаунт требует минимального баланса <b>по крайней мере ${amount}</b> для <a href='https://docs.near.org/docs/concepts/account' rel='noopener noreferrer' target='_blank'>занимаемого места в сети</a>",
            "descTwo": "После резервирования уникального адреса учетной записи вам будет предложено внести небольшой первоначальный депозит для активации вашей учетной записи.",
            "disclaimer": "Создавая учетную запись NEAR, вы соглашаетесь на использование MyNearWallet <a href='/terms' rel='noopener noreferrer' target='_blank'>условия обслуживания</a> and <a href='/privacy' rel='noopener noreferrer' target='_blank'>политика конфиденциальности</a>.",
            "title": "Прежде чем вы начнете..."
        }
    },
    "dashboard": {
        "activity": "Транзакции"
    },
    "dashboardActivity": {
        "message": {
            "AddKey": {
                "forContract": "для <span>${permissionReceiverId}</span>",
                "forReceiver": "для <span>${receiverId}</span>"
            },
            "CreateAccount": "<span>${receiverId}</span>",
            "DeleteAccount": "<span>${receiverId}<span>",
            "DeleteKey": "",
            "DeployContract": "<span>${receiverId}</span>",
            "FunctionCall": "<span>${methodName}</span> в контракте <span>${receiverId}</span>",
            "Stake": "<span>${stake}</span>",
            "Transfer": {
                "received": "от <span>${signerId}</span>",
                "transferred": "к <span>${receiverId}</span>"
            }
        },
        "title": {
            "AddKey": {
                "forContract": "Добавлен ключ доступа",
                "forReceiver": "Добавлен ключ доступа"
            },
            "CreateAccount": "Создана новая учетная запись",
            "DeleteAccount": "Учетная запись удалена",
            "DeleteKey": "Ключ удален",
            "DeployContract": "Контракт развернут",
            "FunctionCall": "Метод, называемый",
            "Stake": "Застейкано",
            "Transfer": {
                "received": "Полученно NEAR",
                "transferred": "Отправленно NEAR"
            }
        }
    },
    "disclaimer": {
        "text": "Это предварительный кошелек разработчиков. Он должен использоваться только для NEAR Protocol DevNet. Узнайте больше на сайте ",
        "title": "ОТКАЗ ОТ ОТВЕТСТВЕННОСТИ"
    },
    "emailSubscribe": {
        "placeholder": "Ваш емейл...",
        "title": "Подпишитесь ниже для получения раннего доступа"
    },
    "enterAccountNameLedgerModal": {
        "header": "Введите имя учетной записи",
        "one": "Введите имя учетной записи, в которую вы осуществляете вход"
    },
    "error": "Ошибка",
    "errors": {
        "ledger": {
            "U2F_4": "Ошибка входа через устройство Ledger: U2F DEVICE_INELIGIBLE. Пожалуйста, убедитесь, что ваше устройство Ledger подключено и разблокировано.",
            "U2FNotSupported": "Для использования устройства Ledger требуется поддержка U2F в браузере. Пожалуйта, используйте Chrome, Opera или Firefox с расширением U2F. Также убедитесь, что вы используете HTTPS соединение"
        },
        "type": {
            "LackBalanceForState": "Ваш доступный баланс слишком мал для выполнения каких-либо действий с вашей учетной записью. Отправьте NEAR на свой счет и попробуйте еще раз.",
            "RetriesExceeded": "Превышено максимальное количество попыток для проведения данной транзакции."
        }
    },
    "footer": {
        "contactSupport": "Обратитесь в службу поддержки",
        "copyrights": "Genin Saifu LLC. Все права защищены.",
        "desc": "Solana — это атомарно компонуемый мировой компьютер, который легко запускает киллер-приложения на скорости света.",
        "learnMore": "Узнать подробнее",
        "needHelp": "Требуется помощь?",
        "privacyPolicy": "Политика конфиденциальности",
        "termsOfService": "Условия обслуживания"
    },
    "fullAccessKeys": {
        "authorizedTo": "АВТОРИЗОВАН",
        "dashboardNoKeys": "У вас нет полных ключей доступа",
        "noKeys": "Вы не подключили свой NEAR кошелек к каким-либо приложениям. Когда вы это сделаете, вы сможете управлять ими здесь.<br /><br />Посмотрите, что было построено с помощью NEAR:",
        "pageTitle": "Ключи Полного Доступа",
        "submitAnyTransaction": "Отправлять любые транзакции от вашего имени",
        "useContract": "Использовать <b>${receiverId}</b> контракт от вашего имени",
        "viewYourAccountName": "Просмотр имени вашей учетной записи"
    },
    "hardwareDevices": {
        "desc": "Повысьте безопасность вашей учетной записи с помощью аппаратных кошельков.",
        "disable": {
            "desc": "Убедитесь что у вас подключены другие методы восстановления, и только потом отключайте имеющийся метод.",
            "disable": "Отключить Ledger",
            "keep": "Нет,  оставить Ledger",
            "title": "Вы уверены в том, что хотите отключить Ledger?"
        },
        "ledger": {
            "auth": "Авторизован",
            "connect": "Ваше устройство Ledger в данный момент не подлючено. Мы советуем переподключить свой Ledger, чтобы убедиться в защищенности учетной записи.",
            "disclaimer": "Перед тем как отключить устройство Ledger, вы должны подключить альтернативный метод восстановления.",
            "title": "Аппаратный кошелек Ledger"
        },
        "title": "Аппаратные устройства"
    },
    "importAccountWithLink": {
        "accountFound": "1 Account Found",
        "accountImported": "Account Imported",
        "accountsFound": "${count} Accounts Found",
        "alreadyImported": "The account secured by this link has been imported.",
        "continue": "and continue this process in your browser of choice.",
        "copyUrl": "copy the URL",
        "foundAccount": "We found <b>1 account</b> secured by this link.",
        "foundAccounts": "We found <b>${count} accounts</b> secured by this link.",
        "goToAccount": "Go to Account",
        "import": "Import",
        "importAccount": "Import Your Account",
        "preferedBrowser": "If this isn't your preferred browser,"
    },
    "initialDeposit": {
        "claimAccount": {
            "desc": "Ваш депозит был получен! После получения заявки ваш депозит будет переведен на вашу новую учётную запись.",
            "disclaimer": {
                "checkBox": "Соглашайтесь и продолжайте",
                "desc": "Важно: Старый адрес одноразового финансирования больше не будет действительным. Обязательно замените его своим новым адресом на любых биржах или других устройствах, где он мог быть сохранен."
            },
            "title": "Востребуйте Свой Аккаунт"
        },
        "creditCard": {
            "desc": "Завершите покупку <b>по крайней мере ${amount} NEAR</b> через Мунпэй. MoonPay автоматически переведет ваши средства на ваш новый аккаунт.",
            "title": "Пополните свой новый аккаунт с помощью кредитной карты."
        },
        "manualDeposit": {
            "desc": "Соберите минимальный начальный депозит, отправив <b>по крайней мере ${amount} NEAR</b> на указанный ниже адрес одноразового финансирования.",
            "title": "Отправьте депозит вручную."
        }
    },
    "input": {
        "accountId": {
            "placeholder": "например, vasya.near",
            "subLabel": "Имя учетной записи должно включать имя учетной записи высшего уровня, например, <b>.near</b> или содержать ровно 64 символа.",
            "title": "Учетная запись"
        },
        "enterWord": {
            "placeholder": "",
            "title": "Слово <b>#${wordId}</b>"
        }
    },
    "landing": {
        "banner": "Кошелек NEAR находится в стадии Private Beta",
        "desc": "Безопасно храните токены NEAR и другие поддерживаемые активы в <span>кошельке NEAR</span>.",
        "or": "или",
        "title": "NEAR здесь"
    },
    "ledgerSignTxModal": {
        "ADD_ACCESS_KEY": "Вы должны будете подтвердить данные для входа на вашем устройстве Ledger.",
        "ADD_ACCESS_KEY_SEED_PHRASE": "Вы должны будете подтвердить данные для полного доступа на вашем устройстве Ledger.",
        "ADD_LEDGER_ACCESS_KEY": "Вы должны будете подтвердить данные публичного ключа на вашем устройстве Ledger.",
        "CREATE_ACCOUNT_FROM_IMPLICIT": "Вы должны будете подтвердить создание учетной записи на вашем устройстве Ledger. Это включает транзакции, необходимые для перевода баланса с временной учетной записи.",
        "CREATE_NEW_ACCOUNT": "Вы должны будете подтвердить добавление ключа для новой учетной записи на вашем устройстве Ledger.",
        "DELETE_RECOVERY_METHOD": "Вы должны будете подтвердить данные для удаления ключа на вашем устройстве Ledger.",
        "DISABLE_LEDGER": "Вы должны будете подтвердить данные публичного ключа на вашем устройстве Ledger.",
        "GET_LEDGER_PUBLIC_KEY": "Вы должны будете подтвердить доступ к публичному ключу на вашем устройстве Ledger.",
        "REMOVE_ACCESS_KEY": "Вы должны будете подтвердить данные для удаления ключа на вашем устройстве Ledger.",
        "REMOVE_NON_LEDGER_ACCESS_KEYS": "Вы должны будете подтвердить данные на вашем устройстве Ledger.",
        "SEND_MONEY": "Вы должны будете подтвердить реквизиты перевода на вашем устройстве Ledger.",
        "SET_SIGN_TRANSACTION_STATUS": "Вы должны будете подтвердить данные пополнения на вашем устройстве Ledger.",
        "SETUP_RECOVERY_MESSAGE": "Вы должны будете подтвердить данные для полного доступа на вашем устройстве Ledger.",
        "SIGN_AND_SEND_TRANSACTIONS": "Вы должны будете подтвердить данные пополнения на вашем устройстве Ledger.",
        "STAKE": "Вы должны будете подтвердить стейкинг на вашем устройстве Ledger",
        "UNSTAKE": "Вы должны будете подтвердить снятие со стейкинга на вашем устройстве Ledger",
        "WITHDRAW": "Вы должны будете подтвердить вывод средств на вашем устройстве Ledger"
    },
    "link": {
        "account": "Аккаунт",
        "authorizedApps": "Доверенные приложения",
        "buyUSN": "Buy $USN",
        "donateToUkraine": "Пожертвовать Украине",
        "fullAccessKeys": "Ключи полного доступа",
        "help": "ПОМОЩЬ",
        "noAccount": "У вас нет других учетных записей",
        "profile": "Профиль",
        "receive": "ПОЛУЧИТЬ",
        "send": "ОТПРАВИТЬ",
        "staking": "Стейкинг",
        "summary": "ГЛАВНОЕ",
        "switchAccount": "Переключить учетную запись",
        "wallet": "Кошелёк"
    },
    "loading": "Загрузка...",
    "lockedBalance": "Эти NEAR заблокированы в lockup-контракте и не могут быть выведены. Вы все еще можете делегировать или отправлять в стейкинг эти токены NEAR. После того, как NEAR станут разблокированными, вы можете увидеть их на своем разблокированном балансе и вывести их (что будет означать их переход на «Доступный баланс»).",
    "login": {
        "cliLoginSuccess": {
            "pageText": "Пожалуйста, закройте это окно и следуйте инструкциям в терминале.",
            "pageTitle": "Вы успешно автрризовали NEAR Shell!"
        },
        "confirm": {
            "pageText": "Вы даете согласие <b>на полный доступ</b> к ${appTitle}!",
            "pageTextSecondLine": "<b>Для подтверждения</b>, пожалуйста, введите свое имя пользователя ниже.",
            "pageTitle": "Вы уверены?",
            "username": "Имя пользователя"
        },
        "details": {
            "callFunctions": "Вызывать функцию в любом смарт контракте",
            "createAndDeleteAccessKeys": "Создавать и удалять ключи доступа",
            "createNewAccounts": "Создавать новую учетную запись",
            "deploySmartContracts": "Разворачивать смарт контракты",
            "detailedDescription": "Подробное описание транзакции",
            "forContract": "Для контрактов",
            "function": "Функция",
            "noDescription": "Описание этого метода не указано",
            "stakeAndUnstake": "Начинать и останавливать стейкинг NEAR токенов",
            "thisAllows": "Разрешить ${appTitle} для ",
            "transferTokens": "Переводить NEAR с вашей учетной записи на другие"
        },
        "form": {
            "accessYourAccount": "доступ к вашей учетной записи.",
            "isRequestingFullAccess": "запрашивает <b>полный доступ</b>",
            "isRequestingTo": "запрашивает",
            "thisDoesNotAllow": "Это не позволит приложению передавать токены NEAR.",
            "thisProvidesAccess": "Это обеспечит доступ <b>ко всем вашим токенам</b>.<br />Будьте осторожны!",
            "toYourAccount": "к вашей учетной записи."
        },
        "v2": {
            "connectConfirm": {
                "desc": "Подключайтесь только к сайтам, которым вы доверяете. Однажды подключенный, <a href='${contractIdUrl}' target='_blank' rel='noreferrer'>${appReferrer}</a>сможет <b>${accessType}</b>:",
                "feeAllowance": {
                    "desc": "Приложению будет разрешено потратить от вашего имени до <b>${amount} NEAR</b> на комиссии для выполнения операций. Данная сумма может быть израсходавана только на оплату газа.",
                    "title": "Лимит оплаты комиссий"
                },
                "fullAccess": "Полный доступ",
                "fullAccessModal": {
                    "desc": "Действуйте с осторожностью. Разрешения на полный доступ позволяют контрактам и приложениям получать доступ к вашему балансу токенов. Для подтверждения введите свой логин учетной записи ниже.",
                    "title": "Предоставить полный доступ к учетной записи?"
                },
                "fullAccessWarning": "Этот сайт запрашивает полный доступ к вашей учетной записи. Чтобы включить полный доступ, вам будет предложено подтвердить логин вашей учетной записи.",
                "limitedAccess": "Ограниченный доступ",
                "permissions": {
                    "notTransferTokens": "Это не позволяет приложению передавать токены",
                    "transferTokens": "Перевод токенов с вашего аккаунта",
                    "viewAddress": "Просмотреть адрес вашей разрешенной учетной записи",
                    "viewBalance": "Просмотр баланса вашего разрешенного аккаунта"
                },
                "title": "Соединяясь с:"
            },
            "connectWithNear": {
                "desc": "Приложение запрашивает <b>${accessType}</b> на ваш Near аккаунт. Выберите учетную запись, которую вы хотите подключить.",
                "fullAccess": "полный доступ",
                "limitedAccess": "ограниченный доступ",
                "title": "Соединяйтесь с NEAR"
            }
        }
    },
    "migration": {
        "redirectCaption": "Перейти на MyNearWallet",
        "transferCaption": "Перенести Мои Аккаунты"
    },
    "minimumBalance": "Минимальный баланс в NEAR, который должен храниться на учетной записи для того, чтобы она оставалась активной. Этот баланс представляет собой расходы на поддержание хранилища для вашей учетной записи в блокчейне NEAR (с небольшим запасом), и может расти или падать в завимости от того, требуется ли для учетной записи больше или меньше места.",
    "mobileDeviceAccess": {
        "importCode": {
            "desc": "Если вы уже вошли в систему на настольном устройстве, отсканируйте код мобильного доступа, чтобы импортировать свою учетную запись."
        },
        "showCode": {
            "button": "Просмотр Кода мобильного телефона",
            "desc": "Отсканируйте свой код мобильного доступа, чтобы предоставить полный доступ к учетной записи вашего мобильного устройства.",
            "modal": {
                "desc": "Откройте и отсканируйте QR-код ниже, чтобы импортировать свою учетную запись на свое мобильное устройство.",
                "disclaimer": "<b>Этот код содержит конфиденциальные данные и предоставляет полный доступ к учетной записи вашего мобильного устройства.</b> Будьте осторожны, чтобы не делиться и не раскрывать это, и импортируйте свою учетную запись только на защищенное устройство, которым вы владеете.",
                "reveal": "Раскрыть",
                "title": "Код мобильного Доступа"
            }
        },
        "title": "Доступ к Мобильному Устройству"
    },
    "networkBanner": {
        "desc": "Этот кошелек (и сеть) доступны только для целей тестирования. Токены и другие активы не имеют ценности. Созданные учетные записи не будут перенесены в Mainnet.",
        "header": "Тестовый кошелек NEAR",
        "title": "Тестовый кошелек"
    },
    "NFTTransfer": {
        "youSent": "You sent <span class='color-blue'>${title}</span> to <span class='color-green'>${receiverId}</span>"
    },
    "nodeDetails": {
        "pageText": "Обновить сведения о вашем узле.",
        "pageTitle": "Детали узла"
    },
    "nodeStaking": {
        "nodes": {
            "noNode": {
                "text": "Это соединит ваш узел с вашей учетной записью кошелька.",
                "title": "У вас уже имеется настроенный узел? Добавьте его сюда."
            },
            "title": "Узлы"
        },
        "pageText": "nodeandstaking.near",
        "pageTitle": "Узел и Стейкинг",
        "staking": {
            "title": "Стейкинг",
            "youAreStaking": "Вы делаете стейкинг"
        },
        "steps": {
            "one": {
                "desc": "Инструкции доступны в документации",
                "title": "1. Установите и Запустите Узел через CLI"
            },
            "three": {
                "desc": "Стейкинг отсюда или через CLI. Для помощи вы можете обратиться к документации.",
                "title": "3. Начните делать стейкинг"
            },
            "title": "Чтобы запустить узел или стейкинг, выполните следующие действия:",
            "two": {
                "desc": "Несинхронизированный узел не может производить или валидировать блоки",
                "title": "2. Дождитесь синхронизации узла"
            }
        }
    },
    "of": "из",
    "ofTotal": "из всего",
    "or": "ИЛИ",
    "profile": {
        "account": {
            "available": "Доступно",
            "availableToTransfer": "Доступно для пересылки",
            "availableToWithdraw": "Доступно для вывода",
            "inStakingPools": "В стейкинг-пулах",
            "pendingRelease": "В ожидании выхода из стейкинга",
            "reservedForStorage": "Зарезерировано под оплату хранилища",
            "reservedForTransactions": "Зарезерировано на оплату транзакций",
            "staked": "В стейкинге",
            "unstaked": "Снято со стейкинга",
            "walletBalance": "Баланс кошелька",
            "walletId": "Учетная запись"
        },
        "authorizedApps": {
            "title": "Авторизованные приложения"
        },
        "details": {
            "availableBalance": "Доступный баланс",
            "locked": "Заблокировано",
            "lockPopup": {
                "createAnotherAccount": "создать еще одну учетную запись",
                "text": "Ваше имя пользователя является вашим уникальным логиномом в системе и не может быть изменено, поскольку к нему привязаны данные и право собственности на активы. Однако вы можете ${link} с желаемым именем и отправлять активы по мере необходимости.",
                "title": "Почему это заблокировано?"
            },
            "minBalance": "Минимальный баланс",
            "profile": "ПРОФИЛЬ",
            "public": "Всех",
            "qrDesc": "Используйте камеру вашего телефона для отправки на этот адрес",
            "staked": "В стейкинге",
            "totalBalance": "Общий баланс",
            "unlocked": "Разблокировано",
            "unvested": "Зарезервированные токены",
            "username": "Имя пользователя",
            "visibleTo": "ВИДЕН ДЛЯ"
        },
        "lockup": {
            "locked": "Заблокировано",
            "lockupBalance": "Баланс Lockup-контракта",
            "lockupId": "Lockup-контракт",
            "unlocked": "Разблокировано"
        },
        "lockupBanner": {
            "cta": "Перевести в кошелек",
            "title": "<b>${amount}</b> доступны для вывода из вашего lockup-контракта!"
        },
        "pageTitle": {
            "default": "Имя пользователя",
            "loading": "Загрузка...",
            "notFound": "Имя пользователя ${accountId} не найдено"
        },
        "security": {
            "lessSecure": "Менее безопасные",
            "lessSecureDesc": "Эти варианты рекомендуются для учетных записей с небольшими суммами NEAR и других активов. Они удобны, но представляют дополнительный риск по сравнению с более безопасными вариантами. Если ваш адрес электронной почты или SMS-сообщения будут скомпрометированы, ваши средства могут оказаться под угрозой.",
            "mostSecure": "Наиболее безопасные (рекомендовано)",
            "mostSecureDesc": "Ledger - это самый безопасный вариант. Мнемоническая фраза остается в безопасности в случае правильного использования (должна быть записана и сохранена в надежном месте).",
            "title": "Безопасность и восстановление"
        },
        "twoFactor": "Двухфакторная аутентификация",
        "twoFactorDesc": "Аутентификация через SMS или электронную почту при авторизации транзакций и/или входе в свою учетную запись."
    },
    "reCAPTCHA": {
        "disclaimer": "Этот сайт защищен reCAPTCHA и Google <a href='https://policies.google.com/privacy' target='_blank' rel='noopener noreferrer'>политика конфиденциальности</a> и <a href='https://policies.google.com/terms' target='_blank' rel='noopener noreferrer'>условия обслуживания</a> принять.",
        "fail": {
            "desc": "reCAPTCHA требуется для авторизации новых учетных записей, пополняемых NEAR.",
            "link": "Пополнить свой собственный аккаунт?",
            "title": "Не удалось загрузить рекапчу."
        },
        "loading": "Загрузка рекапчи..."
    },
    "receivePage": {
        "addressTitle": "Ваш адрес",
        "copyAddressLinkLong": "Копировать ссылку",
        "copyAddressLinkShort": "Копировать",
        "qrCodeTitle": "Сканировать QR код",
        "snackbarCopySuccess": "Ссылка скопирована!"
    },
    "recoverAccount": {
        "actionRequired": "Это сообщение содержит волшебную ссылку. Перейдите по ссылке, чтобы начать процесс восстановления",
        "actionType": "Восстановление",
        "cannotResend": "Это сообщение было отправлено вам во время включения методе восстановления по электронной почте/SMS. Мы отправляем это сообщение лишь единожды и не сможет переслать его еще раз.",
        "email": {
            "desc": "Проверьте свою электронную почту от near.org с темой письма:",
            "subject": "\"Важно: Письмо восстановления кошелька Near\" (\"Важно: Восстановление кошелька Near\").",
            "title": "С помощью электронной почты"
        },
        "ledger": {
            "desc": "Убедитесь, что ваш Ledger надежно подключен, а приложение NEAR запущено на устройстве.",
            "title": "Ledger"
        },
        "pageText": "Cледуйте инструкциям, чтобы начать процес восстановления доступа.",
        "pageTitle": "Восстановить учетную запись",
        "phone": {
            "desc": "Проверьте ваш мобильный телефон на наличие SMS-сообщения от",
            "number": "+14086179592.",
            "title": "С помощью мобильного телефона"
        },
        "phrase": {
            "desc": "Убедитесь, что у вас есть мнемоническая фраза восстановления из 12 слов, а затем нажмите ниже, чтобы начать процесс восстановления",
            "title": "С помощью мнемонической фразы"
        }
    },
    "recoverSeedPhrase": {
        "pageText": "Введите мнемоническую фразу резервной копии, связанную с учетной записью.",
        "pageTitle": "Восстановление с помощью мнемонической фразы",
        "seedPhraseInput": {
            "placeholder": "correct horse battery staple...",
            "title": "Мнемоническая фраза (12 слов)"
        }
    },
    "recoverWithLink": {
        "errorP": "Пожалуйста, проверьте свою электронную почту или телефон на наличие самого последнего сообщения. Ссылки во всех предыдущих сообщениях более недействительны.",
        "errorTitle": "Срок действия ссылки истек",
        "pOne": "Нажмите кнопку \"Продолжить\", чтобы восстановить учетную запись:",
        "pTwo": "Если это не ваш браузер по умолчанию, скопируйте URL-адрес и завершите процесс восстановления в удобном для вас браузере.",
        "snackbarCopySuccess": "Ссылка для восстанавления скопирована",
        "title": "Восстановление Учетной Записи"
    },
    "recoveryMgmt": {
        "disableInputPlaceholder": "Введите свое имя пользователя для подтверждения",
        "disableNo": "Нет, продолжить",
        "disableNotAllowed": "Учетные записи требуют наличия хотя бы одного активного способа восстановления. Пожалуйста, для продолжения включите альтернативный способ восстановления.",
        "disableTextLink": "Полученная вами волшебная ссылка будет навсегда деактивирована.",
        "disableTextPhrase": "Ваша текущая мнемоническая фраза будет навсегда деактивирована.",
        "disableTitle": "Вы уверены, что хотите отключить этот метод?",
        "enabled": "Подключено",
        "methodTitle": {
            "email": "Электронный адрес",
            "phone": "Телефонный номер",
            "phrase": "Мнемоническая фраза"
        },
        "noRecoveryMethod": "У вас нет доступных методов для восстановления учетной записи, пожалуйста, добавьте один из методов ниже.",
        "recoveryLinkSent": "Ссылка на восстановление отправлена!",
        "resend": {
            "email": "Отправить повторно",
            "phone": "Отправить повторно"
        },
        "title": "Метод восстановления",
        "unsupportedMethodEmail": "Мы прекращаем поддержку метода восстановления аккаунта с помощью email. Отключив этот способ, вы больше не сможете подключить его обратно",
        "unsupportedMethodPhone": "Мы прекращаем поддержку метода восстановления аккаунта с помощью SMS. Отключив этот способ, вы больше не сможете подключить его обратно"
    },
    "reduxActions": {
        "ADD_ACCESS_KEY": {
            "error": "При подтверждении этого действия произошла ошибка. Пожалуйста, попробуйте еще раз!",
            "success": "${title} теперь имеет право использовать вашу учетную запись"
        },
        "ADD_ACCESS_KEY_SEED_PHRASE": {
            "error": "При настройке мнемонической фразы произошла ошибка. Повторите еще раз!",
            "success": "Настройка мнемонической фразы восстановления успешно завершена"
        },
        "ADD_LEDGER_ACCOUNT_ID": {
            "error": "Отказано в доступе к одной из учетных записей. Учетные записи не были восстановлены.<br>Для продолжения вы должны авторизовать доступ для чтения к каждой учетной записи на своем устройстве Ledger. Пожалуйста, попробуйте еще раз.",
            "success": ""
        },
        "CHECK_ACCOUNT_AVAILABLE": {
            "error": "Пользователь не найден.",
            "success": "Пользователь найден."
        },
        "CHECK_IS_NEW": {
            "error": "Имя пользователя занято. Попробуйте другое.",
            "success": "Отлично! Имя пользователя ${accountId} доступно."
        },
        "CHECK_NEW_ACCOUNT": {
            "error": "Имя пользователя занято. Попробуйте другое.",
            "success": "Отлично! Имя пользователя ${accountId} доступно."
        },
        "CREATE_ACCOUNT_WITH_SEED_PHRASE": {
            "error": "Ошибка создания аккаунта с Seed фразой",
            "success": "Аккаунт успешно создан"
        },
        "CREATE_NEW_ACCOUNT": {
            "error": "Имя пользователя занято. Попробуйте другое.",
            "success": "Отлично! Имя пользователя ${accountId} доступно."
        },
        "default": {
            "error": "К сожалению, произошла ошибка. Попробуйте еще раз.",
            "success": "Успешно"
        },
        "DELETE_RECOVERY_METHOD": {
            "error": "Ошибка удаления метода восстановления.",
            "success": "Метод восстановления успешно удалён"
        },
        "DEPLOY_MULTISIG": {
            "error": "Ошибка создания мультисига",
            "success": "Мультисиг успешно создан"
        },
        "Deserialization": "",
        "GET_LEDGER_ACCOUNT_IDS": {
            "error": "Публичный ключ был отклонен. Учетные записи не были восстановлены.<br/>Для продолжения вы должны дать разрешение NEAR кошельку на просмотр вашего публичного ключа. Пожалуйста, попробуйте еще раз.",
            "success": ""
        },
        "PROMPT_TWO_FACTOR": {
            "invalidCode": "Неправильный 2FA-код. Пожалуйста, попробуйте еще раз."
        },
        "RECOVER_ACCOUNT_SEED_PHRASE": {
            "error": "Не удалось восстановить учетную запись.",
            "success": "Восстановление по мнемонической фразе завершено.<br/>Вы успешно восстановили <b>${numberOfAccounts} учетную(ые) запись(и)</b>.<br/>Последняя из них выбрана как активная."
        },
        "REFRESH_ACCOUNT_EXTERNAL": {
            "error": "Учетная запись <b>${accountId}</b> не найдена"
        },
        "REMOVE_ACCESS_KEY": {
            "error": "Пожалуйста, проверьте подключение устройства и удостоверьтесь, что там открыто приложение NEAR.",
            "success": ""
        },
        "RetriesExceeded": "Превышено максимальное количество попыток для проведения данной транзакции.",
        "SAVE_AND_SELECT_LEDGER_ACCOUNTS": {
            "error": "Пожалуйста, проверьте подключение устройства и удостоверьтесь, что там открыто приложение NEAR.",
            "success": "Вы успешно авторизовались.<br/>Вы успешно восстановили <b>${numberOfAccounts} учетную(ые) запись(и).</b> Последняя из них выбрана как активная."
        },
        "SEND_MONEY": {
            "error": "К сожалению, произошла ошибка. Попробуйте еще раз."
        },
        "SETUP_RECOVERY_MESSAGE": {
            "error": "Во время установки метода восстановления произошла ошибка. Пожалуйста, попробуйте еще раз!",
            "success": "Установка метода восстановления завершена."
        },
        "SIGN_AND_SEND_TRANSACTIONS": {
            "error": "Ошибка подписи и отправки транзакции.",
            "success": "Транзакция успешно подписана и отправлена."
        },
        "TRANSFER_ALL_FROM_LOCKUP": {
            "error": "Что-то пошло не так. Пожалуйста, попробуйте еще раз.",
            "success": "Вы успешно перевели токены из вашего lockup-контракта!"
        },
        "U2F_4": "Ошибка входа через устройство Ledger: U2F DEVICE_INELIGIBLE. Пожалуйста, убедитесь, что ваше устройство Ledger подключено и разблокировано.",
        "VERIFY_TWO_FACTOR": {
            "error": "Двухфакторная аутентификация не была установлена. Пожалуйста, попробуйте еще раз.",
            "success": "Двухфакторная аутентификация была успешно включена!"
        }
    },
    "reservedForFeesInfo": "Вплоть до ${data} NEAR зарезервировано для покрытия расходов на транзакции.",
    "selectAccountDropdown": {
        "createAccount": "Создать новую учетную запись",
        "noOtherAccounts": "У вас нет других учетных записей",
        "selectAccount": "Выбрать учетную запись",
        "switchAccount": "Сменить учетную запись",
        "switchAccounthNotAllowed": "Это приложение не позволяет изменять учетную запись"
    },
    "sending": "ОТПРАВКА",
    "sendMoney": {
        "account": {
            "title": "Отправить на"
        },
        "amount": {
            "available": "Доступно для отправки"
        },
        "amountStatusId": {
            "available": "Доступный баланс:",
            "howMuch": "Сколько вы хотите отправить?",
            "noMoreThan": "НЕ БОЛЕЕ 5 ДЕСЯТИЧНЫХ ЗНАКОВ",
            "notEnoughTokens": "Вы должны оставить хотя бы ${amount} NEAR в своей учетной записи",
            "sending": "Отправка:"
        },
        "banner": {
            "insufficient": "Вы пытаетесь отправить весь доступный баланс. Хотя бы <b>${data} NEAR</b> должны быть зарезервированы для покрытия комиссий на транзакции.",
            "useMax": "Небольшая часть (${data} NEAR) доступного баланса была зарезервирована для покрытия комиссий на транзакции."
        },
        "button": {
            "confirm": "Подтвердить и отправить",
            "dashboard": "Перейти в панель управления",
            "send": "Подтвердить"
        },
        "confirmModal": {
            "title": "Подтвердить транзакцию"
        },
        "onceConfirmed": "Отменить транзакцию будет невозможно!",
        "subtitle": {
            "default": "Введите количество токенов NEAR для отправки и имя учетной записи получателя.",
            "success": "Вы успешно отправили NEAR"
        },
        "title": {
            "default": "Отправить",
            "success": "Успешно!"
        },
        "to": "к",
        "wasSentTo": "Отправлено к:",
        "youAreSending": "Вы собираетесь отправить"
    },
    "sendV2": {
        "accordionTriggerTitle": {
            "balanceDetails": "Сведения о балансе",
            "transactionDetails": "Детали транзакции"
        },
        "review": {
            "title": "Вы отправляете"
        },
        "selectAsset": {
            "assetInputPlaceholder": "Имя актива для поиска",
            "assetListNameTitle": "название",
            "asssetListBalanceTitle": "Доступно для отправки",
            "title": "Выберите актив"
        },
        "selectReceiver": {
            "receiverInputLabel": "Отправить к"
        },
        "selectTokenButtonTitle": "Выберите актив",
        "success": {
            "title": "Транзакция завершена!</br>Вы послали <span class='color-blue'>${amount}</span></br>к <span class='color-green'>${receiverId}</span>"
        },
        "translateIdInfoTooltip": {
            "estimatedFees": "Ориентировочная стоимость обработки вашей транзакции.",
            "estimatedTotal": "Предполагаемая общая сумма вашей транзакции, включая комиссионные.",
            "networkFees": "Окончательная сумма, уплаченная для покрытия расходов на обработку вашей транзакции.",
            "reservedForFees": "Часть баланса вашего аккаунта, которая не может быть потрачена. Эта сумма зарезервирована для покрытия расходов на хранение вашей учетной записи. Для получения дополнительной информации посетите нашу документацию."
        },
        "TXEntry": {
            "status": {
                "Failure": "Неудачно",
                "notAvailable": "Статус недоступен",
                "SuccessValue": "Успешно"
            },
            "title": {
                "amount": "Сумма",
                "availableBalance": "Доступный баланс",
                "availableToSend": "Доступно для отправки",
                "estimatedFees": "Предполагаемые комиссии",
                "estimatedTotal": "весь Предполагаемый",
                "from": "от",
                "networkFees": "Сетевые комиссии",
                "receiverId": "отправлено к",
                "reservedForFees": "Зарезервировано для комиссий",
                "status": "Статус транзакции",
                "timeStamp": "Дата и время",
                "to": "К",
                "token": "токен"
            }
        }
    },
    "setRecoveryConfirm": {
        "didNotReceive": "Не получили код?",
        "email": "адрес электронной почты",
        "inputHeader": "Введите код подтверждения",
        "inputPlaceholder": "Код подтверждения",
        "invalidCode": "Некорректный код",
        "or": "или",
        "pageText": "Мы отправили код верификации из 6 цифр на ",
        "phone": "номер телефона",
        "reenter": {
            "link": "нажмите здесь",
            "one": {
                "email": "Если вы не получили это электронное письмо или адрес электронной почты выше некорректный, ",
                "phoneNumber": "Если вы не получили это SMS или номер телефона выше некорректный, "
            },
            "two": {
                "email": ", чтобы заново ввести адрес электронной почты и отправить сообщение.",
                "phoneNumber": ", чтобы заново ввести номер телефона и отправить сообщение."
            }
        },
        "resendCode": "Отправить код еще раз",
        "sendToDifferent": "Отправить на другой",
        "title": "Введите код подтверждения"
    },
    "setupLedger": {
        "header": "Подключите своё устройство Ledger",
        "one": "Подключите Ledger Nano S к своему компьютеру или мобильному устройству через USB и <b>откройте приложение NEAR</b>.",
        "two": "Если вы еще не установили приложение NEAR на Ledger, следуйте",
        "twoLink": "данным инструкциям"
    },
    "setupLedgerSteps": {
        "five": "Следуйте инструкциям по установке <span class='color-black'>приложения NEAR</span> на ваше устройство.",
        "four": "Вернитесь во вкладку <span class='color-black'>Manager</span> и запустите поиск по слову <span class='color-black'>NEAR</span>.",
        "header": "Установите NEAR на своё устройство Ledger",
        "one": "Откройте <a rel='noopener noreferrer' href='https://www.ledger.com/ledger-live' target='_blank'>Ledger Live,</a> установите все доступные обновления прошивки.",
        "three": "Перейдите в раздел <span class='color-black'>Экспериментальные функции</span> (<span class='color-black'>Experimental Features</span>), и убедитесь, что <span class='color-black'>Режим разработчика</span> (<span class='color-black'>Developer Mode</span>) был <span class='color-black'>включен</span>.",
        "two": "Перейдите в <span class='color-black'>Настройки</span> (<span class='color-black'>Settings</span>)."
    },
    "setupLedgerSuccess": {
        "header": "Ledger подтвержден в вашей учетной записи!",
        "nextStep": {
            "header": {
                "keep": "Сохранить существующие ключи?",
                "remove": "Подтвердите удаление ключа"
            },
            "one": {
                "keep": "<span class='color-red'>Использование нескольких методов восстановления и устройств повышает уязвимость вашей учетной записи.</span>",
                "remove": "Перед удалением существующих ключей и методов восстановления убедитесь, что вы записали и надежно сохранили исходную мнемоническую фразу Ledger."
            },
            "two": {
                "remove": "Если вы потеряете доступ к своей исходной мнемонической фразе, NEAR Inc. не сможет помочь вам в восстановлении вашей учетной записи и средств на ней."
            }
        },
        "one": "Мы рекомендуем удалить все существующие ключи (методы восстановления и устройства).",
        "primaryCta": "Удалите существующие ключи и методы восстановления",
        "secondaryCta": "Сохранить существующие ключи",
        "two": "Использование нескольких ключей и методов восстановления (включая мнемонические фразы) увеличивает уязвимость вашей учетной записи."
    },
    "setupRecovery": {
        "advancedSecurity": "Повышенная безопасность",
        "advancedSecurityDesc": "Запишите фразу из 12 слов и надежно храните её.",
        "basicSecurity": "Базовая безопасность",
        "basicSecurityDesc": "Введите адрес электронной почты или номер телефона для получения волшебной ссылки",
        "emailPlaceholder": "example@email.com",
        "emailTitle": "Электронная почта для восстановления",
        "header": "Защитите вашу учетную запись",
        "ledgerTitle": "Ledger Nano S или X",
        "notSupportedPhone": "К сожалению, мы не можем предоставить SMS-сервис в вашем регионе. Пожалуйста, выберите метод восстановления по электронной почте.",
        "phoneDesc": "Введите ниже свой номер телефона и получите волшебную ссылку для восстановления",
        "phonePlaceholder": "+7 920 555 5555",
        "phoneTitle": "Телефон для восстановления",
        "phraseTitle": "Мнемоническая фраза",
        "subHeader": "Выберите метод восстановления:"
    },
    "setupSeedPhrase": {
        "pageText": "Запишите следующие слова по порядку и храните их в надежном месте. <b>Любой, у кого есть доступ к нему, также будет иметь доступ к вашей учетной записи!</b> Затем вас попросят подтвердить вашу кодовую фразу.",
        "pageTitle": "Настройте Свою Безопасную Seed фразу",
        "snackbarCopyImplicitAddress": "Адрес для пополнения скопирован!",
        "snackbarCopySuccess": "Мнемоническая фраза скопирована"
    },
    "setupSeedPhraseSuccess": {
        "pageText": "Используйте фразу восстановления, чтобы восстановить вашу учетную запись.",
        "pageTextSecondLine": "Любой человек, имеющий доступ к вашей фразе восстановления, может получить доступ к вашим средствам, держите её в безопасном месте!",
        "pageTitle": "Установка восстановления завершена"
    },
    "setupSeedPhraseVerify": {
        "pageText": "Введите следующее слово из фразы восстановления, чтобы завершить процесс установки.",
        "pageTitle": "Проверить фразу",
        "startOverText": "Не записали фразу?"
    },
    "sign": {
        "accountNotFound": {
            "body": "${signCallbackUrl} is requesting authorization from a NEAR account that cannot be found: <b>${signTransactionSignerId}</b><br/><br/>To approve any transactions, you’ll first need to import the account.",
            "title": "Account Not Found"
        },
        "ActionWarrning": {
            "binaryData": "Аргументы содержат бинарные данные",
            "deleteAccount": "Вы собираетесь удалить свою учетную запись! Ваш баланс NEAR будет уничтожен, а все данные вашей учетной записи удалены.",
            "deployContract": "Вы собираетесь развернуть контракт на своей учетной записи! Этот контракт может получить доступ к вашему балансу NEAR и взаимодействовать с другими контрактами от вашего имени.",
            "functionCall": "Для этой функции не указано описание.",
            "stake": "Вы собираетесь стейкнуть ваши токены NEAR. Эти токены будут заблокированы и могут быть потеряны, если ваш валидатор перестанет отвечать на запросы."
        },
        "authorizationRequestSuccessful": "Запрос на авторизацию успешно выполнен",
        "authorizing": "Выполняется авторизация",
        "contract": "Контракт:",
        "currentBalance": "Текущий баланс",
        "details": {
            "detailedDescription": "Подробное описание транзакции",
            "forContract": "Для контракта",
            "gasLimit": "Лимит газа",
            "gasPriceUnavailable": "Оценка цены на газ недоступна",
            "transactionFees": "Стоимость сделки"
        },
        "hereAreSomeDetails": "Вот некоторые детали, которые помогут вам.",
        "insufficientFunds": "Недостаточно средств",
        "invalidTransaction": {
            "body": "The request is invalid and cannot be signed.",
            "title": "Invalid Transaction"
        },
        "isRequesting": {
            "authorization": "запрашивает разрешение",
            "transferOf": "запрашивает перевод"
        },
        "multipleAccountsError": {
            "body": "<a href='${signCallbackUrl}' target='_blank' rel='noreferrer'>${signCallbackUrl}</a> has requested authorization from more than one account.<br/><br/>Genin Wallet may only authorize transactions for a single account at a time.",
            "title": "Batch Transaction Error"
        },
        "nothingHasBeenTransferred": "Ничего не было передано.",
        "transactionCancelled": "Транзакция отменена",
        "transferring": "Передача",
        "unexpectedStatus": "Неожиданный статус",
        "unknownApp": "Неизвестное приложение",
        "wasTransferredSuccessfully": "Транзакция успешно выполнена"
    },
    "signInLedger": {
        "firefoxBanner": {
            "desc": "Ledger Connect не поддерживается в следующих браузерах: Firefox версии 112+, Safari. Он полностью поддерживается в браузерах Chrome/Chromium. Приносим извинения за неудобства."
        },
        "header": "Войти с помощью устройства Ledger",
        "modal": {
            "accountsApproved": "аккунт(ы) подтвержден(ы)",
            "confirmPublicKey": "Подтвердите публичный ключ",
            "ledgerMustAdd": "Ledger должен добавить ключ доступа к каждой учетной записи, защищенной вашим данным устройством:",
            "status": {
                "confirm": "Подтвердить",
                "error": "Ошибка",
                "pending": "В обработке",
                "rejected": "Отказано",
                "success": "Подтверждено",
                "waiting": "На очереди"
            }
        },
        "one": "Убедитесь, что ваш Ledger надежно подключен, а приложение NEAR запущено на устройстве."
    },
    "stagingBanner": {
        "desc": "Внимание: Это предварительная версия NEAR кошелька. Здесь могут содержаться ошибки, и эти ошибки могут привести к потере средств. Продолжая использовать эту версию, вы соглашаетесь, что понимаете и принимаете данные риски, а также понимаете, что команда кошелька NEAR не сможет вам помочь.",
        "title": "Внимание: Это предварительная версия кошелька. Используйте на свой риск!"
    },
    "stakedBalance": "Эти токены NEAR активно используется для поддержки валидатора и защиты сети. Когда вы решите снять со стейкинга эти NEAR, то потребуется некоторое время, чтобы она отобразилась в вашем «Доступном балансе», так как в NEAR требуется 4 эпохи (~ 52-65 часов), чтобы снять токены из стейкинга.",
    "staking": {
        "alertBanner": {
            "button": "Посмотреть текущего валидатора",
            "title": "Перед тем как начать стейкинг в пуле нового валидатора, вы сначала должны снять токены со стейкинга вашего текущего валидатора и вывести их из пула."
        },
        "amountStakingInput": {
            "placeholder": "например: 15",
            "title": "Введите сумму для стейкинга"
        },
        "balanceBanner": {
            "currency": "NEAR",
            "title": "Доступный lockup-баланс"
        },
        "balanceBox": {
            "available": {
                "button": "Вывод",
                "info": "Эти токены сняты со стейкинга и теперь могут быть выведены.",
                "title": "Доступно для вывода"
            },
            "farm": {
                "button": "Получить",
                "info": "Этот валидатор предоставляет вознаграждение за ставки в токене, отличном от NEAR."
            },
            "pending": {
                "info": "Эти токены сняты со стейкинга, но еще не могут быть выведены. Токенам требуется от 52 до 65 часов на вывод со стейкинга.",
                "title": "Ожидается снятие"
            },
            "staked": {
                "button": "Снять со стейкинга",
                "info": "Что обозначает общий размер стейкинга?",
                "title": "Общий размер стейкинга"
            },
            "unclaimed": {
                "info": "Награды, которые были заработаны, но не сняты. Награды в Near токене <a target='_blank' href='https://www.investopedia.com/terms/c/compoundinterest.asp'>реинвестируются</a> и автоматически стейкаются.",
                "title": "Незатребованные награды",
                "unavailable": {
                    "cta": "Подробнее",
                    "modalDescOne": "В данный момент мы не можем посчитать награды, если включена двухфакторная аутентификация. Мы надеямся включить эту возможность в ближайшем будущем.",
                    "modalDescTwo": "В то же время вы можете видеть, как накапливаются ваши награды, в разделе «Общий размер стейкинга». Этот баланс будет расти, пока ваши токены находятся в стейкинге, поскольку полученные награды автоматически направляются в стейкинг-пул текущего валидатора.",
                    "modalTitle": "Невозможно отобразить полученные награды стейкинга",
                    "title": "Невозможно отобразить полученные награды."
                }
            }
        },
        "noValidators": {
            "title": "В данный момент у вас нет стейкинга в пуле какого-либо валидатора."
        },
        "pageText": "Настройте свой узел через CLI, а затем добавьте его сюда.",
        "pageTitle": "Стейкинг",
        "stake": {
            "accounts": "Если у вас есть заблокированные токены NEAR, вы можете отправить их в стейкинг с помощью lockup-контракта NEAR (lockup.near) или разблокированного контракта NEAR (имя вашей учетной записи). Вы увидите размер стейкинга только для выбранной учетной записи. Если у вас нет заблокированных токенов NEAR, вы можете проигнорировать этот раздел",
            "amount": "Размер",
            "banner": {
                "insufficientBalance": "Вы пытаетесь отправить в стейкинг весь доступный баланс. Хотя бы <b>${data} NEAR</b> должны быть зарезервированы для покрытия комиссий на транзакции.",
                "stakeMax": "Небольшая часть (${data} NEAR) доступного баланса была зарезервирована для покрытия комиссий на транзакции."
            },
            "button": "Подтвердить стейкинг",
            "confirm": "Ваш стейкинг",
            "desc": "Введите размер стейкинга, который будет делегирован выбранному валидатору.",
            "from": "от",
            "input": {
                "availableBalance": "Доступно для стейкинга",
                "near": "NEAR"
            },
            "ledgerDisclaimer": "Примечание: Так как вы впервые отправляете токены в стейкинг данному валидатору, вам надо будет подтвердить 2 транзакции.",
            "stakeWith": "Стейкинг в",
            "title": "Размер стейкинга",
            "useMax": "Максимум",
            "with": "с"
        },
        "stakeSuccess": {
            "button": "Вернуться в панель управления",
            "desc": "Ваш стейкинг успешно было отправлен в делегирование выбранному валидатору:",
            "descTwo": "Вы можете просмотреть свое делегирование и награды за стейкинг в панели управления стейкингом.",
            "title": "Готово!"
        },
        "staking": {
            "available": "Доступен",
            "button": "Стейкинг NEAR",
            "currentValidators": "Ваши текущие валидаторы",
            "desc": "Отправьте токены NEAR в стейкинг валидатору",
            "noValidators": "Вы еще не начали стейкинг в пуле валидатора.",
            "selectAccount": "Стейкинг от:",
            "title": "Стейкинг",
            "totalStaked": "Всего в стейкинге"
        },
        "unstake": {
            "beforeUnstakeDisclaimer": "Снятые со стейкинга токены станут доступны по прошествии периода в ~ 52-65 часов (4 эпохи).",
            "button": "Снять со стейкинга",
            "confirm": "Вы снимаете со стейкинга",
            "desc": "Введите количество токенов, которые вы хотели бы снять со стейкинга от текущего валидатора.",
            "input": {
                "availableBalance": "Доступно для снятия со стейкинга",
                "near": "NEAR"
            },
            "stakeWith": "Снять со стейкинга от",
            "title": "Вывести токены из стейкинга"
        },
        "unstakeSuccess": {
            "button": "Вернуться в панель управления",
            "desc": "Ваш стейкинг в размере <b>${amount}</b> был успешно извлечен из пула выбранного валидатора:",
            "descTwo": "Ваши токены находятся в ожидании вывода из стейкинга и станут доступны для вывода по прошествии ~52-65 часов (4 эпохи).",
            "title": "Готово!"
        },
        "validator": {
            "button": "Стейкинг с валидатором",
            "desc": "Данная комиссия выплачивается валидатору стейкинга от вашего имени. Комиссия налагается только на ваши награды.",
            "fee": "Комиссия за стейкинг",
            "title": "Валидатор: <br/> @${validator}",
            "unstake": "Вы снимаете со стейкинга",
            "withdraw": "Вы выводите",
            "withdrawalDisclaimer": "Токены станут доступны для вывода после периода ожидания выхода из стейкинга, который занимает ~52-65 часов (4 эпохи)"
        },
        "validatorBox": {
            "cta": "Выберите",
            "fee": "Комиссия",
            "near": "NEAR",
            "staking": "Стейкинг",
            "with": "с"
        },
        "validators": {
            "available": "Доступные валидаторы",
            "button": "Просмотр валидатора",
            "desc": {
                "account": "Введите название существующего валидатора для стейкинга ваших токенов NEAR.",
                "lockup": "Введите название существующего валидатора для стейкинга ваших токенов NEAR. В каждый момент времени вы можете создать стейкинг в пуле только одного выбранного валидатора."
            },
            "inputLabel": "Укажите название пула валидатора",
            "inputPlaceholder": "validator-name.near",
            "search": {
                "error": "Подходящий валидатор не найден.",
                "success": "Валидатор найден!"
            },
            "title": "Выберите валидатора"
        }
    },
    "stakingPoolUnstaked": "Эти NEAR в настоящее время находятся в стейкинг-пуле, но не участвуют в стейкинге. Например, они могут находиться в состоянии снятия из стейкинга, в ожидании операции вывода.",
    "success": "Успех!",
    "swap": {
        "confirm": "Confirm & Swap",
        "details": "Transaction details",
        "fee": "Estimated fees",
        "from": "From",
        "max": "Max: ${amount} ${symbol}",
        "minReceived": "Minimum received",
        "price": "Price",
        "priceImpact": "Price impact",
        "review": "Review Swap",
        "reviewInfo": "You are swapping",
        "slippage": "Slippage tolerance",
        "successTitle": "Transaction complete!</br>You swapped <span class='color-blue space'>${amountFrom}</span></br>to <span class='color-green'>${amountTo}</span>",
        "title": "Swap",
        "to": "To",
        "translateIdInfoTooltip": {
            "fee": "The estimated cost of processing your transaction.",
            "minimumReceived": "The minimum amount you will receive from the trade.",
            "priceImpact": "The difference between the market price and estimated price due to trade size.",
            "slippage": "The percentage the price may increase before the transaction fails."
        }
    },
    "swapping": "Swapping",
    "topLevelAccounts": {
        "body": "Имена учетных записей похожи на доменные имена. Только учетная запись ${data} может создавать такие учетные записи, как yourname.${data}, и только yourname.${data} может создать app.yourname.${data}. Все учетные записи, созданные в этом кошельке, используют .${data} Учетные записи верхнего уровня (TLA). Чтобы узнать больше об именах учетных записей и создать собственную учетную запись верхнего уровня, прочтите документацию <a rel='noopener noreferrer' href='https://docs.near.org/docs/concepts/account'>docs</a>.",
        "header": "Учетные записи верхнего уровня"
    },
    "total": "всего",
    "totalBalance": "Общий баланс показывает все токены NEAR, находящиеся под вашим контролем. Во многих случаях у вас не будет немедленного доступа ко всему содержимому общего баланса (например, если он заблокирован, делегирован или находится в стейкинге). Проверьте свой «Доступный баланс», чтобы узнать количество токенов NEAR, который вы можете активно использовать, передавать, делегировать и отправлять в стейкинг.",
    "transaction": {
        "status": {
            "checkingStatus": "Проверка состояния",
            "Failure": "Ошибка",
            "notAvailable": "Статус не доступен",
            "NotStarted": "Не начато",
            "Started": "Начато",
            "SuccessValue": "Успех"
        }
    },
    "twoFactor": {
        "active": "Включена",
        "alertBanner": {
            "button": "Подробнее",
            "title": "<b>${data}</b> будут заблокированы для покрытия расходов на хранение контракта двухфакторной аутентификации до тех пор, пока она включена."
        },
        "desc": "Защитите свою учетную запись за счет необходимости обязательного подтверждения по электронной почти или SMS всех транзакций, нуждающихся в авторизации.",
        "disable": {
            "desc": "Имейте в виду, что после отключения транзакции не будут требовать подтверждения по 2FA.",
            "disable": "Отключить 2FA",
            "keep": "Нет, оставить 2FA",
            "title": "Вы уверены, что хотите отключить 2FA?"
        },
        "email": "Адрес электронной почты",
        "enable": "Включить двухфакторную аутентификацию",
        "notEnabled": "2FA не включена",
        "notEnoughBalance": "Чтобы подключить 2FA, ваша учетная запись требует наличия минимального баланса в размере ${amount}",
        "phone": "Телефонный номер",
        "promptDesc": "Мы очень рекомендуем настроить метод двухфакторной аутентификации для повышения безопасности вашей учетной записи и имеющихся средств",
        "select": "Выберите метод аутентификации",
        "since": "sс",
        "subHeader": "Двухфакторная аутентификация добавляет дополнительный уровень безопасности вашей учетной записи. <b>Seed фраза и Ledger ключи продолжают разрешать полный доступ к вашей учетной записи.</b>",
        "title": "Двухфакторная аутентификация",
        "verify": {
            "desc": "Верификационный код из 6 цифр был отправлен на:",
            "didntReceive": "Не получили свой код?",
            "inputLabel": "Введите код подтверждения из 6 цифр",
            "placeholder": "Код подтверждения",
            "resend": "Отправить заново",
            "resending": "Отправка...",
            "resent": "Код отправлен!",
            "title": "Введите двухфакторный код подтверждения"
        }
    },
    "unlockedAvailTransfer": "Эти токены NEAR разблокированы и могут быть выведены из вашего lockup-контракта.",
    "unlockedBalance": "Эти токены NEAR все еще находятся в lockup-контракте, но они разблокированы.",
    "unvestedBalance": "Данные токены NEAR зарезервированы для вас, но еще не находятся в вашей собственности. Вы по-прежнему можете делегировать или отправить их в стейкинг, и награды будут полностью вашими. Как только эти NEAR перейдут в вашу собственность, они появятся на вашем заблокированном или разблокированном балансе.",
    "walletErrorCodes": {
        "addAccessKey": {
            "error": "Произошла ошибка.<br />Для доступа к вашей учетной записи, пожалуйста, введите ниже мнемоническую фразу из предыдущего шага."
        },
        "addAccessKeySeedPhrase": {
            "errorSecond": "Произошла ошибка.<br />Мнемоническая фраза не была добавлена к вашей учетной записи. Пожалуйста, попробуйте еще раз."
        },
        "addLedgerAccountId": {
            "errorRpc": "Во время восстановления учетной записи произошла ошибка."
        },
        "getLedgerAccountIds": {
            "aborted": "Действие было прервано.",
            "noAccounts": "Ни одна учетная запись не была связана с данным устройством Ledger. Вы должны сначала создать учетную запись и потом использовать Ledger для входа.",
            "noAccountsAccepted": "Ни одна учетная запись не была подтверждена.",
            "U2FNotSupported": "Для использования устройства Ledger требуется поддержка U2F в браузере. Пожалуйста, используйте Chrome, Opera или Firefox с расширением U2F."
        },
        "lockup": {
            "transferAllWithStakingPoolBalance": "Снимите со стейкинга и выведите все средства из стейкинг-пула перед тем как перевести оставшийся баланс lockup-контракта в кошелек Near."
        },
        "promptTwoFactor": {
            "userCancelled": "2FA-код не был корректно верифицирован."
        },
        "recoverAccountSeedPhrase": {
            "errorInvalidSeedPhrase": "Учетные записи, соответствующие данной мнемонической фразе, не найдены."
        },
        "recoveryMethods": {
            "lastMethod": "Невозможно удалить ваш последний метод восстановления. Пока вы используете Ledger, вам нужно оставить хотя бы один метод восстановления, чтобы гарантировать возможность восстановления вашей учетной записи.",
            "setupMethod": "Произошла ошибка. Пожалуйста, проверьте свой метод восстановления."
        },
        "setupRecoveryMessage": {
            "error": " Во время установки метода восстановления произошла ошибка. Пожалуйста, попробуйте еще раз."
        },
        "signAndSendTransactions": {
            "notEnoughTokens": "Недостаточно токенов."
        },
        "staking": {
            "noLockup": "Lockup-контракт не найден в данной учетной записи",
            "noWithdraw": "Ожидающий баланс невозможно снять с валидатора"
        }
    },
    "walletMigration": {
        "migrateAccounts": {
            "desc": "Следующие аккаунты будут перенесены в MyNearWallet:",
            "title": "Мы нашли ${count} активных аккаунтов."
        },
        "migrationPrompt": {
            "desc": "Genin Wallet отныне называется MyNearWallet и чтобы отпраздновать это событие, мы перемещаемся на новый домен. Нас можно найти на <a href='https://mynearwallet.com' target='_blank'>https://mynearwallet.com</a>. <br/><br/> Здесь вы можете быстро и просто перенести свои аккаунты если вы этого ещё не сделали:",
            "title": "У Genin Wallet новый дом!",
            "transferAccountsBtn": "Перенести Мои Аккаунты",
            "useDifferentWallet": "Использовать другой кошелёк"
        },
        "migrationSecret": {
            "desc": "Вам нужен <strong>этот пароль</strong> для безопасного переноса аккаунтов в MyNearWallet. Скопируйте или запишите его-куда нибудь, и сохраните до того момента, пока перенос аккаунтов не будет успешно завершён.",
            "title": "Необходимый пароль который нужен для переноса аккаунтов"
        },
        "selectWallet": {
            "title": "Выберите кошелёк для переноса аккаунтов"
        }
    },
    "warning": "Предупреждение"
}
;
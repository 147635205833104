export function reorganizeAndInsertInURL(url, insertion) {
    try {
        const parsedUrl = new URL(url);
        const mainDomain = `${parsedUrl.protocol}//${parsedUrl.host}`;
        const newUrl = `${mainDomain}${insertion}${parsedUrl.search}`;

        return newUrl;
    } catch (error) {
        console.error('URL invalid:', error);
        return null;
    }
}
